import React, { useEffect, useState } from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import PublishIcon from '@mui/icons-material/Publish'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CommonTableTransaction from '../common/CommonTable/CommonTableTransaction'
import { columns } from './propertyTableColumns'
import Filters from './filters'
import useApi from '../hooks/useApi'
import _ from 'lodash'
import { ImportModal } from './ImportModal'
import { getUserId } from '../helpers/common'
import { TablePagination } from '../helpers/components/TablePagination'
import { AddModal } from './AddModal'
import { DeleteModal } from './DeleteModal'
import { AddCommentModal } from './AddCommentModal'

export default function Treasurer () {
  const [filters, setFilters] = useState({ userId: getUserId() })
  const [portfolios, setPortfolios] = useState([])
  const [office, setOffice] = useState([])
  const [bo, setBo] = useState([])
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [addModalVisible, setAddModalVisible] = useState({
    visible: false,
    aspect: '',
    portfolio: {}
  })
  const [deleteModalVisible, setDeleteModalVisible] = useState({
    visible: false,
    portfolio: {}
  })
  const [commentModalVisible, setCommentModalVisible] = useState({
    visible: false,
    portfolio: {}
  })

  const [trigger, setTrigger] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 500,
    totalRecords: 0
  })

  const onFormSubmit = () => {
    setTrigger(+new Date())
  }

  const handleEdit = (row) => {
    setAddModalVisible({
      visible: true,
      aspect: 'edit',
      portfolio: {
        id:	row.Id,
        portfolioId: row.PortfolioId,
        office: row.Office,
        boNumber: row.BONumber,
        bo: row.BO,
        boType: row.BOType,
        ubo: row.UBO,
        propId: row.PropId,
        currency: row.Currency,
        country: row.Country,
        active: row.Active,
        propType: row.PropType,
        tenureFinalDt: row.TenureFinalDt != null ? row.TenureFinalDt.split('T')[0] : row.TenureFinalDt,
        project: row.Project,
        unit: row.Unit,
        type: row.Type,
        existingUse: row.ExistingUse,
        acquisitionDt: row.AcquisitionDt != null ? row.AcquisitionDt.split('T')[0] : row.AcquisitionDt,
        valuationDt: row.ValuationDt != null ? row.ValuationDt.split('T')[0] : row.ValuationDt,
        floorArea: row.FloorArea,
        valuationAmt: row.ValuationAmt,
        purchasePrice: row.PurchasePrice,
        capex: row.Capex,
        historicalCost: row.HistoricalCost,
        valuationCostUSD: row.ValuationCostUSD,
        acquisitionCostUSD: row.AcquisitionCostUSD,
        fxAcquisition: row.FxAcquisition,
        fxActual: row.FxActual,
        client: row.Client,
        confirmed: row.Confirmed
      }
    })
  }

  const handleAddComment = (row) => {
    setCommentModalVisible({
      visible: true,
      portfolio: row
    })
  }

  const handleDelete = (row) => {
    setDeleteModalVisible({
      visible: true,
      portfolio: {
        id:	row.Id,
        portfolioId: row.PortfolioId,
        office: row.Office,
        boNumber: row.BONumber,
        bo: row.BO,
        boType: row.BOType,
        ubo: row.UBO,
        propId: row.PropId,
        country: row.Country,
        currency: row.Currency,
        active: row.Active,
        propType: row.PropType,
        tenureFinalDt: row.TenureFinalDt,
        project: row.Project,
        unit: row.Unit,
        type: row.Type,
        existingUse: row.ExistingUse,
        acquisitionDt: row.AcquisitionDt,
        valuationDt: row.ValuationDt,
        floorArea: row.FloorArea,
        valuationAmt: row.ValuationAmt,
        purchasePrice: row.PurchasePrice,
        capex: row.Capex,
        historicalCost: row.HistoricalCost,
        valuationCostUSD: row.ValuationCostUSD,
        acquisitionCostUSD: row.AcquisitionCostUSD,
        fxAcquisition: row.FxAcquisition,
        fxActual: row.FxActual,
        client: row.Client,
        confirmed: row.Confirmed
      }
    })
  }

  const filterOptions = useApi({
    method: 'POST',
    options: {
      ...filters
    },
    trigger: null,
    url: `${process.env.REACT_APP_BASE_URL}/forms/property`
  })

  const treasurers = useApi({
    method: 'POST',
    options: {
      ...filters,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    },
    trigger,
    url: `${process.env.REACT_APP_BASE_URL}/property`
  })

  useEffect(() => {
    if (filterOptions.response == null && filterOptions.error == null) { return }
    if (filterOptions.error == null) {
      if (filterOptions.response.portfolios && filterOptions.response.portfolios.length > 0 && portfolios.length === 0) {
        setPortfolios(_.map(filterOptions.response.portfolios, (portfolio) => {
          return { id: portfolio.portfolio_id, name: portfolio.portfolio_name }
        }))
      } else if (filters.portfolioId) {
        const { office, bo } = filterOptions.response
        setBo(bo)
        setOffice(office);
      }
    }
  }, [filterOptions.response, filterOptions.error])

  useEffect(() => {
    if (filters.portfolioId) {
      filterOptions.executeRequest()
    }
  }, [filters.portfolioId])

  useEffect(() => {
    if (!filters.portfolioId) { return }

    onFormSubmit()
  }, [pagination.pageIndex, pagination.pageSize])

  return (
    <Container fluid>
        <Row>
          <Col lg={12}>
          <Row style={{ marginBottom: '8px' }}>
                <span className="p-2 pageheader">
                    <h3 className="pagetitle" >
                    Real Estate
                    </h3>
                </span>

                <div
                    className="p-2"
                    onClick={() => {}}
                    style={{ position: 'fixed', right: '45px', marginTop: '0.5%', cursor: 'pointer' }}
                >
                    <AddCircleOutlineOutlinedIcon style={{ color: '#FFC107', fontSize: '27px' }} onClick={() => { setAddModalVisible({ visible: true, aspect: 'add', portfolio: {} }) }}/>
                    <PublishIcon style={{ color: '#FFC107', fontSize: '27px' }} onClick={() => { setImportModalVisible(true) }}/>
                </div>
            </Row>

            <Filters
              filters={filters}
              setFilters={setFilters}
              portfolios={portfolios}
              office={office}
              bo={bo}
              onFormSubmit={onFormSubmit}
              />

            <div className='transaction'>
                <CommonTableTransaction
                        loading={treasurers?.loading || false}
                        data={treasurers?.response?.data || []}
                        columns={columns({ handleEdit, handleDelete, roleId: localStorage.getItem('role').split(','), handleAddComment })}
                    />
            </div>
            <ImportModal visible={importModalVisible} portfolios={portfolios} setImportModalVisible={setImportModalVisible} refreshTable={treasurers?.executeRequest}/>
            <AddModal visible={addModalVisible.visible} aspect={addModalVisible.aspect} portfolios={portfolios} setAddModalVisible={setAddModalVisible} portfolio={addModalVisible.portfolio} refreshTable={treasurers?.executeRequest}/>
            <DeleteModal visible={deleteModalVisible.visible} portfolio={deleteModalVisible.portfolio} handleClose={() => setDeleteModalVisible({ visible: false, portfolio: {} })} refreshTable={treasurers?.executeRequest}/>
            <AddCommentModal visible={commentModalVisible.visible} action={commentModalVisible.action} portfolio={commentModalVisible.portfolio} handleClose={() => setCommentModalVisible({ visible: false, portfolio: {} }) } refreshTable={treasurers?.executeRequest}/>
          </Col>

          <Col lg={12}>
            <TablePagination
              totalRecords={treasurers?.response?.totalRecords}
              pageIndex={pagination.pageIndex}
              pageSize={pagination.pageSize}
              setPagination={setPagination}
            />
          </Col>
        </Row>
      </Container>
  )
}

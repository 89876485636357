import React,{useState,useEffect, useRef, forwardRef} from 'react';
import {Modal,Form,Button} from "react-bootstrap";
import { useSelector,useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import FormControl from '@mui/material/FormControl'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment'
import SelectSearchBox from '../common/SelectSearchBox'
import { SelectBox } from '../common/SelectBox';
import Paper from '@material-ui/core/Paper'
import Autocomplete from '@mui/material/Autocomplete'
import { Alert, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import InputLabel from '@mui/material/InputLabel'
import Tooltip from '@mui/material/Tooltip';
import { styled, lighten, darken } from '@mui/system';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles'
import {setOpenModalToEditSelectedRow,setAddCheckboxValue,setResultShowData, setEditAccountancyRowData, setChekcedStatusValue, setCheckedValue} from "../Redux/appSlice";
import { ka } from 'date-fns/locale';
// import { setCommentRange } from 'typescript';
const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
   color: 'white',
   fontWeight:'bold'
  // backgroundColor: 'darkgrey'
    // theme.palette.mode === 'light'
      // ? lighten(theme.palette.primary.light, 0.85)
      // : darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled('li')({
  padding: 0,
  paddingLeft:'2rem'
});
const useStyles = makeStyles({
  paper: {
    background: 'rgb(31, 33, 37) !important',
    color: 'white !important'
  },
  option: {
    '&:hover': {
      backgroundColor: 'grey !important',
      color: 'white !important'
    }
  }
})
let shareArray=[]
let ownerArray=[]
let shareHoldArray=[]
const ModalForEditSelectedRows= forwardRef((props,ref) => {
  const styles = useStyles()
    const dispatch=useDispatch();
    const [errorCurrency,setErrorCurrency]=useState(false)
    const [errorType,setErrorType]=useState(false)
   const [validated, setValidated]=useState(false)
   const [ownerZeroErr,setOwnerZeroErr]=useState(false)
   const  [shareHolderErr, setShareholderErr]=useState(false)
   const [selectedOption,setSelectedOption]=useState([])
   const [alertSameError,setAlertSameError]=useState(false)
   const [alertResultErr,setAlertResultErr]=useState(false)
   const [selectedShareholder, setSelectedShareholder]=useState([])
    const {openModalToEditSelectedRow,addCheckboxValue,editAccountancyRowData}=useSelector((store)=>store.app);
    const [shareholderEdit,setShareholderEdit]=useState("");
    const [ownershipEdit,setOwnershipEdit]=useState("");
    const [countValue,setCountValue]=useState(0);
    const [defaultSelect,setDefaultSelect]=useState('')
    const [inputValues, setInputValues] = useState([]);
    const [alertGameUpdateSuccess, setAlertGameUpdateSuccess] = useState(false)
    const [temp, setTemp] = useState('')
    const [change, setChange] = useState('')
    const [temp1, setTemp1] = useState('')
    const [alertOwnershipLessError,setAlertOwnershipLessError]=useState(false)
    const [alertOwnershipError, setAlertOwnershipError] = useState(false)
    const [gameDetails,setGameDetails]=useState('');
    const [venue,setVenue]=useState('');
    const[type,setType]=useState('');
    const [resultEdit, setResultEdit] = useState('')
    const[host,setHost]=useState('');
    const[creditor,setCreditor]=useState('');
    const[player,setPlayer]=useState('');
    const [ownershipError, setOwnershipError] = useState(false)
    const [temp0, setTemp0] = useState('')
    const [entityData,setEntityData]=useState([])
    const [hostErr, setHostErr]=useState(false)
    const [GroupErr, setGroupErr]=useState(false)
    const [accountingData, setAccountancyData] = useState([])
    const [shareError, setShareError] = useState(false)
    const [date,setDate]=useState(new Date(Date.now()).toISOString().split('T')[0]);
    const[resultShareholdersData,setResultShareholdersData]=useState([]);
    const [currencyValue,setCurrencyValue]=useState()
    const [exchangeRate, setExchangeRate]=useState('')
    const [comment, setComment]=useState('')
    let selectArray=props.selectArray
    const currencyList = [
      { title: 'BTC', type:'Crypto:' },{ title: 'ETH',type:'Crypto:'},{ title: 'USDT',type:'Crypto:' },{ title: 'USDC',type:'Crypto:' },
      { title: 'USD', type:'FIAT:' },{ title: 'EUR',type:'FIAT:'},{ title: 'GBP',type:'FIAT:' },{ title: 'HKD',type:'FIAT:' },
      { title: 'AED', type:'FIAT:' },{ title: 'CNY',type:'FIAT:'},{ title: 'VND',type:'FIAT:' },{ title: 'MYR',type:'FIAT:' },
      { title: 'PHP', type:'FIAT:' },{ title: 'THB',type:'FIAT:'},{ title: 'AUD',type:'FIAT:' },{ title: 'CAD',type:'FIAT:' },
      { title: 'CAD', type:'FIAT:' },{ title: 'BNY',type:'FIAT:'},{ title: 'MMK',type:'FIAT:' },{ title: 'DKK',type:'FIAT:' },
      { title: 'HRK', type:'FIAT:' },{ title: 'HUF',type:'FIAT:'},{ title: 'INR',type:'FIAT:' },{ title: 'ISK',type:'FIAT:' },
      { title: 'JPY', type:'FIAT:' },{ title: 'CHF',type:'FIAT:'},{ title: 'MXN',type:'FIAT:' },{ title: 'NOK',type:'FIAT:' },
      { title: 'NZD', type:'FIAT:' },{ title: 'PLN',type:'FIAT:'},{ title: 'CZK',type:'FIAT:' },{ title: 'RON',type:'FIAT:' },
      { title: 'RUB', type:'FIAT:' },{ title: 'LKR',type:'FIAT:'},{ title: 'TWD',type:'FIAT:' },{ title: 'TRY',type:'FIAT:' },
    ]
    const options = currencyList.map((option) => {
      const currencyType = option.type;
      return {
        firstLetter: /[0-9]/.test(currencyType) ? 'FIAT:' : currencyType,
        ...option,
      };
    });
    const getEntityApi = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/get_entity`, {
        })
        .then(function (response) {
          if(response.data.length==0){
           }else{
            // response.data.sort((a, b) => a.name?.localeCompare(b.name))
            setEntityData(response.data.filter(i=>i.name!=null).sort((a, b) => a.name.localeCompare(b.name)))
           }
        })
        .catch(function (error) {
        })
    }
    const shareholderListData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/get_share_holder`, {
        })
        .then(function (response) {
          setResultShareholdersData(response.data)
        })
        .catch(function (error) {
        })
    }
    const handleData=async ()=>{
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/get_accountancy`,
      }
      await axios(config).then(function (response) {
        setSelectedOption([])
        setDefaultSelect('')
      
        // setAccountancyData(response.data)
        const data1 = response.data.filter(item => item.status == 'A')
        const ONE_WEEK = 60 * 60 * 24 * 7;
    const temp2 = data1?.map(record => {
      let currentDate = new Date();
      let res = parseFloat(record.result) / parseFloat(record.exchange_rate) 
      let rowDate = moment(record.date, 'YYYY-MM-DD', true).isValid() ? new Date(record.date).getTime() / 1000 : new Date(record.date.split("/")[2],record.date.split("/")[1]-1,record.date.split("/")[0]).getTime() / 1000
      let startDate = new Date(new Date(currentDate.getFullYear(), 0, 1)).getTime() / 1000;
      // var days = Math.floor((rowDate - startDate) /
      //     (24 * 60 * 60 * 1000));   
      // var weekNumber = Math.ceil(days / 7);
      let diff = Math.ceil((rowDate - startDate))
      let weekNumber = Math.ceil(diff / ONE_WEEK)
      return { ...record, weeks: weekNumber, result_USD: parseFloat(parseFloat(res).toFixed(2)) ,result:parseFloat(record.result), game_id: parseInt(record.game_id),exchange_rate:parseFloat(record.exchange_rate) }
    })
      const gameArray = [...temp2] ;
        for(let i=0 ;i <gameArray.length;i++){
          gameArray[i].shares=[]
          gameArray[i].id=i+1
          let shareArray = [];
          let shareholderArray = [] 
          shareholderArray = gameArray[i].shareholders.split(',');
          let ownerShipArray = []
          ownerShipArray = gameArray[i].shareholders_percentage.split(',');

            for(let j=0;j<shareholderArray.length;j++){
              let shareObject = {
                shareholder : shareholderArray[j],
                owner_ship : ownerShipArray[j]
              }
              shareArray.push(shareObject);
            } 
            gameArray[i].shares = [...shareArray];
            shareArray = [];
            shareholderArray = [];
            ownerShipArray = [];
        }
          if (gameArray) {
            gameArray.sort((a, b) => {
            const x = new Date(a.date_updated).getTime() / 1000
            const y = new Date(b.date_updated).getTime() / 1000
            return x > y ? -1 : x < y ? 1 : 0
          })
        }
       dispatch(setResultShowData(gameArray))
      }).catch(function (error) {
      })
  
    }
    let selectData=[]
    const handleAddEntity = async (data1) => {
      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/accountancy_entity`,
        data:
        {
          "name":data1.toLowerCase(),
          "type":'Other'
        }
      }
      axios(config).then(function (response) {
      }).catch(function (error) {
      })
     
  }
    const handleSubmitForm=async(e)=>{
       e.preventDefault()
    if( selectedOption.includes('gameDetails') && gameDetails=='' || selectedOption.includes('venue') && venue=='' || selectedOption.includes('player') && player=='' || selectedOption.includes('result') && resultEdit=='' || selectedOption.includes('exchange') && exchangeRate=='' || selectedOption.includes('date') && date=='' ){
      setValidated(true)
    if(selectedOption.includes('type') && type==''){
        setErrorType(true)
      }
      
      if(selectedOption.includes('host') && host=='' || selectedOption.includes('host') && host==null ){
        setHostErr(true)
        console.log('host1')
      }
      if(selectedOption.includes('group') && creditor=='' || selectedOption.includes('group') && creditor==null ){
        setGroupErr(true)
        console.log('g1')
      }
      if(selectedOption.includes('result') && parseFloat(resultEdit)==0){
        setAlertResultErr(true)
        setTimeout(()=>setAlertResultErr(false),3000)
      }
     
       if(selectedOption.includes('currency') && currencyValue==null){
        setErrorCurrency(true)
      }
      return
    }
    if(selectedOption.includes('host') && host=='' && selectedOption.includes('group') && creditor=='' || selectedOption.includes('host') && host==null && selectedOption.includes('group') && creditor==null ){
      setHostErr(true)
      setGroupErr(true)
      console.log('host1')
      return
    }
    else if(selectedOption.includes('host') && host=='' || selectedOption.includes('host') && host==null ){
      setHostErr(true)
      console.log('host2')
      return
    }
    else if(selectedOption.includes('group') && creditor=='' || selectedOption.includes('group') && creditor==null ){
      setGroupErr(true)
      return
    }
    else if(selectedOption.includes('type') && type==''){
      setErrorType(true)
      return
    }
    if(selectedOption.includes('result') && parseFloat(resultEdit)==0){
      setAlertResultErr(true)
      setTimeout(()=>setAlertResultErr(false),3000)
      return
    }
    else if(selectedOption.includes('currency') && currencyValue==null){
      setErrorCurrency(true)
      return
    }
      else{
        if(host!='' && host.toLowerCase()==creditor!='' && creditor.toLowerCase()){
          setAlertSameError(true)
          setTimeout(()=>setAlertSameError(false),3000)
         }else{
         let duplicateCheck= entityData.filter(i=>i.name==host || i.name==creditor)
         if(duplicateCheck.length==0){
           handleAddEntity(host)
           handleAddEntity(creditor)
            postAccountApi()
         }else if(entityData.filter(i=>i.name==host).length>0){
           let data1=entityData.filter(i=>i.name==host)
          //  =creditor ? host : creditor
          //  console.log(data1)
           handleAddEntity(host)
             postAccountApi()
         }else if(entityData.filter(i=>i.name==creditor).length>0){
          let data1=entityData.filter(i=>i.name==creditor)
         //  =creditor ? host : creditor
         //  console.log(data1)
          handleAddEntity(creditor)
            postAccountApi()
        }
        }
   }
    //  location.reload();
    }
    const postAccountApi=()=>{
      let finalShareholder = [...[...new Map(resultShareholdersData.map(x=>[(x.share_holder_name),x])).values()]].length!=selectedShareholder.length ? [...selectedShareholder, {shareholder:temp1,owner:temp}] : selectedShareholder
         props.selectData.map(e=>{             
           shareArray.push({'game_id':e.game_id,'game_details':gameDetails,"venue":venue,"type":type,"host":host.toLowerCase(),"currency":currencyValue,"group_":creditor.toLowerCase(),"date":date,"player":player,"result":resultEdit,"exchange_rate":exchangeRate,"comment":comment,'shareholders':finalShareholder.length==0 ? temp1.toString() : finalShareholder.map(e=>e.shareholder).toString(),'shareholders_percentage':finalShareholder.length==0 ? temp.toString() : finalShareholder.map(e=>e.owner).toString()})
             })
             let t=0
             for(const a of finalShareholder){
              if(a.shareholder==''){
                setShareholderErr(true)
                setTimeout(()=>setShareholderErr(false),3000)
                return
               }else if(parseFloat(a.owner)==0){
                 setOwnerZeroErr(true)
                 setTimeout(()=>setOwnerZeroErr(false),3000)
                 console.log('no')
                 return
               }
               t= parseFloat(a.owner) + t
             } 
             if(finalShareholder.length>0 && parseFloat(t)>100){
              setAlertOwnershipError(true)
              setTimeout(()=>{
                setAlertOwnershipError(false)
              },3000)
            }
            else if(finalShareholder.length>0 && parseFloat(t)<100){
              setAlertOwnershipLessError(true)
              setTimeout(()=>{
                setAlertOwnershipLessError(false)
              },3000)
            }else{
        const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/update_all_game`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          "share_holder":shareArray,
        }
      }
      console.log(config.data)
       axios(config)
        .then(function (response) {
          exportApiPost(shareArray)
          setAlertGameUpdateSuccess(true)
          shareArray=[]
          finalShareholder=[]
          setSelectedShareholder([])
          dispatch(setAddCheckboxValue({type:"REMOVE_ITEM",value:[]}))
          setTimeout(()=>{
            setAlertGameUpdateSuccess(false)
            dispatch(setOpenModalToEditSelectedRow(false))
          },2000)
         
          handleData()
          // shareHoldArray=[]
          // ownerArray=[]
          // shareArray=[]
             location.reload()
        })
        .catch(function (error) {
        })
      }
    }
   
    const exportApiPost = async (shareArray) => {
    
      let importData=shareArray.map(el=>{
       
       return {...el, host: el.host.toLowerCase(), group_:el.group_.toLowerCase()}
      })
      
     const config = {
       method: 'post',
       url: `${process.env.REACT_APP_BASE_URL}/update_all_game_ledgre`,
       headers: {
         'Content-Type': 'application/json'
       },
       data: {
         "share_holder": importData,
       }
     }
     // console.log(config.data)
     await axios(config)
       .then(function (response) {
       })
       .catch(function (error) {
       })
   }

    useEffect(async()=>{
      await handleData();
      getEntityApi()
      await shareholderListData()
     
    },[])
  return (
    <>
     <Modal
        show={openModalToEditSelectedRow}
        style={{ width: '28%', marginLeft: '35%' }} >
        <div style={{ border: '1px solid white' }}>
          <Modal.Header
            style={{ backgroundColor: '#222429', border: 'none' }}
          >
            <IconButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'white'
              }}
              onClick={() => {
                dispatch(setOpenModalToEditSelectedRow(false))
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          
          <Modal.Body style={{ backgroundColor: '#222429' }}>
            <h4 style={{color:'white'}}>Multiple Update</h4>
            <Form
              className="custom-form"
              noValidate
              validated={validated}
              onSubmit={handleSubmitForm}
            >
              <div style={{marginBottom:'-2em'}}>
              <SelectBox
              label='Select'
              value={defaultSelect}
              err={false}
              options={selectArray}
              onChange={(e)=>{
                selectedOption.push(e.target.value)
                setDefaultSelect(e.target.value)
              }
              }
              />
              </div>
              {selectedOption.includes('gameDetails') ?
              <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': gameDetails
                })}
                style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
              >
                <Form.Control
                  type="text"
                  id="game_detail"
                  name="name"
                  value={gameDetails}
                  // placeholder="Game Details"
                  onChange={(e)=>setGameDetails(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
              <span className='label_text'>Game Details</span>
              <span style={{marginTop:"10px",position:'absolute',left:'100%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove game-details">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='gameDetails'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
             <Form.Control.Feedback type="invalid">
                        Game-Details is Required.
                      </Form.Control.Feedback>
              </Form.Label> : <></>}
              {selectedOption.includes('venue') ?
              <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': venue
                })}
                style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
              >
                <Form.Control
                  type="text"
                  id="venue"
                  name="name"
                  value={venue}
                  // placeholder="Venue"
                  
                  onChange={(e)=>setVenue(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
               <span className='label_text'>Venue</span>
               <span style={{marginTop:"10px",position:'absolute',left:'100%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove venue">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='venue'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
             <Form.Control.Feedback type="invalid">
                        Venue is Required.
                      </Form.Control.Feedback> 
              </Form.Label> :<></>}
              {selectedOption.includes('type') ?
              <FormControl
                style={{
                  width: '72%',
                  marginLeft:'1.9%',
                   marginTop:'3%',
                 
                }}
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  style={{
                    fontSize: '17px',
                    overflow: 'visible',
                    color: 'grey',
                    top:'1px',
                    left:'-8px',
                    background:'#1f2125'
                   
                  }}
                >
                  {' '}
                  Type
                </InputLabel>

                <Select
                  MenuProps={{
                    classes: {
                      paper: styles.paper
                    },
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        }
                      }
                    }
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                 value={type}
                  label="Select"
                  style={{
                    // width:"239px",
                    height: '50px',
                    border:'1px solid hsl(0deg 0% 44% / 63%) ',
                    boxShadow: 'none'
                  }}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px',
                        height:'54px',
                        // border:'1px solid hsl(0deg 0% 44% / 63%) !important',
                        borderColor:'unset',
                        // padding:'0 50%'
                    },
                    '.MuiInputLabel-root': {
                      color: 'grey !important',
                       marginTop: '2px !important',
                    marginLeft: '-1px !important',
                    background:'rgb(31, 33, 37)'
                    },
                    '.MuiSelect-icon': {
                      fill: 'grey !important'
                    },
                  ".MuiOutlinedInput-input": {
                    color: "white !important",
                     fontSize: "15px",
                    //  backgroundColor:'white',
                     paddingBottom:'13px',
                     paddingRight:'62%',
                     paddingTop:'12px !important',
                },
                    ' .MuiInputLabel-root.Mui-focused': {
                      marginTop: '-3% !important',
                      marginLeft: '-5% !important'
                    }
                  }}
                  onChange={(e)=>{
                    setType(e.target.value)
                    setErrorType(false)
                  }}
                >
                <MenuItem value={'CG'}>CG</MenuItem>
                  <MenuItem value={'Tournament'}>Tournament</MenuItem>
                  <MenuItem value={'Expense'}>Expense</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
            <span style={{marginTop:"3%",position:'absolute',left:'102%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove type">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='type'))
               setErrorType(false)
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
              </FormControl> :<></>}
               {errorType==true ? <span style={{color:'#dc3545',fontSize:'.875em',display:'flex', justifyContent:'center'}}>Type is required.</span> : <></>}  
              {selectedOption.includes('date') ?
               <Form.Label
               htmlFor="exchange"
               className={cx('custom-form-box', {
                 'focus-add': date
               })}
               style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
             ><span style={{color:'white',fontSize:'10px',marginTop:'5px',background:'rgb(31, 33, 37)'}}>Date</span>
               <Form.Control
                 type="date"
                 id="date"
                 name="name"
                 value={date}
                 // placeholder="Host"
                 onChange={(e) => setDate(e.target.value)}
                 required
                 style={{ color: 'white' }}
               />
             <span className='label_text'>Date</span>
             <span style={{marginTop:"10px",position:'absolute',left:'100%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove date">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='date'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
             <Form.Control.Feedback type="invalid">
                        Date is Required.
                      </Form.Control.Feedback> 
             </Form.Label>:<></>}
             {selectedOption.includes('host') ?
              // <Form.Label
              //   htmlFor="exchange"
              //   className={cx('custom-form-box', {
              //     'focus-add': host
              //   })}
              //   style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
              // >
              //   <Form.Control
              //     type="text"
              //     id="host"
              //     name="name"
              //     value={host}
              //     // placeholder="Host"
              //     onChange={(e)=>setHost(e.target.value)}
              //     required
              //     style={{ color: 'white' }}
              //   />
              // <span className='label_text'>Host</span>
              <div style={{width:'100%',display:'block',marginTop:'13%'}}>
              <SelectSearchBox
              label='Host'
              value={host}
              err={hostErr}
              options={entityData.filter(i=>i.name!=creditor )}
              onChange={(e,k)=>{
                     let res_type= entityData.filter(i=>i.name==k)
                 if(res_type.length>0){
                  setHostErr(false)
                  setHost(k)
                  // setInvestCheck(k)
                 }else{ 
                  setHostErr(false)
                  //  setInvestType('')
                   setHost('')
                 }
              }}
              onChange1={(e)=>{
                if(entityData.filter(i=>i.name===e.target.value).length==0){
                  setHostErr(false)
                  setHost(e.target.value)
                }else{
                  setHostErr(false)
                setHost(e.target.value)
                }
              }}
              
              />
              {/* <span style={{marginTop:"1em",backgroundColor:"transparent",marginLeft:'-11%',zIndex:1}}> */}
              <Tooltip title="Remove host">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107',
                  position:'relative',
                  marginLeft:'81%',
                  marginTop:'-3em'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='host'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            {/* </span> */}
             </div>  : <></>}
              {selectedOption.includes('group') ?
             <div style={{width:'100%',display:'block',marginTop:'13%'}}>
              <SelectSearchBox
              label='Group'
              value={creditor}
               err={GroupErr}
              options={entityData.filter(i=>i.name!=host)}
              onChange={(e,k)=>{
                     let res_type= entityData.filter(i=>i.name==k)
                 if(res_type.length>0){
                  setGroupErr(false)
                  setCreditor(k)
                  // setInvestCheck(k)
                 }else{ 
                  setGroupErr(false)
                  //  setInvestType('')
                   setCreditor('')
                 }
              }}
              onChange1={(e)=>{
                if(entityData.filter(i=>i.name===e.target.value).length==0){
                  setGroupErr(false)
                  setCreditor(e.target.value)
                }else{
                  setGroupErr(false)
                setCreditor(e.target.value)
                }
              }}
              />
                {/* <span style={{marginTop:"1em",backgroundColor:"transparent",marginLeft:'-11%',zIndex:1}}> */}
              <Tooltip title="Remove group">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107',
                  position:'relative',
                  marginLeft:'81%',
                  marginTop:'-3em'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='group'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            {/* </span> */}
                  </div> :<></>}
              {selectedOption.includes('player') ?
              <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': player
                })}
                style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
              >
                {/* span style={{color:'white',fontSize:'10px',marginTop:'5px',background:'rgb(31, 33, 37)'}}>Player</span> */}
                <Form.Control
                  type="text"
                  id="player"
                  name="name"
                  value={player}
                  // placeholder="Player"
                  onChange={(e)=>setPlayer(e.target.value)}
                  
                  required
                  style={{ color: 'white' }}
                />
                 <span className='label_text'>Player</span>
                 <span style={{marginTop:"10px",position:'absolute',left:'100%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove player">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='player'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
             <Form.Control.Feedback type="invalid">
                        Player is Required.
                      </Form.Control.Feedback> 
              </Form.Label> :<></>}
              {selectedOption.includes('result') ?
                    <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': resultEdit
                })}
                style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
              >
                     <Form.Control
                  type="number"
                  id="result"
                  name="name"
                  value={resultEdit}
                  // placeholder="Result"
                  onChange={(e)=>{
                    const res1 = e.target.value
                    if(  res1.match(/^[-]?\d*\.?\d*$/) ){
                      if(res1.indexOf(".") >= 0){
                       setResultEdit(res1.substr(0, res1.indexOf(".")) + res1.substr(res1.indexOf("."), 3))
                      }else{
                       setResultEdit(res1)
                      }   
                    }
                  }}
                  required
                  style={{ color: 'white' }}
                />
                 <span className='label_text'>Result</span>
                 <span style={{marginTop:"10px",position:'absolute',left:'100%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove result">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='result'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
             <Form.Control.Feedback type="invalid">
                        Result is Required.
                      </Form.Control.Feedback> 
              </Form.Label>  :<></>}
              {selectedOption.includes('currency') ?
                <div style={{width:'86%',display:'flex',flexDirection:'row',marginLeft:'15%',marginTop:'10%'}}>
              <>
             <Autocomplete
                  id="grouped-demo"
                  // value={currencyValue}
                  options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.title}
                  classes={{
                    option: styles.option
                  }}
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                    </Paper>
                  )}
                  onChange={(e,k)=>{
                    
                    if(k==null){
                      setErrorCurrency(false)
                      setCurrencyValue(null)
                    }else{
                    setErrorCurrency(false)
                    setCurrencyValue(k.title)
                    }
                  }}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    fontSize: '14px',
                    borderRadius: '30%',
                    width:'100%',
                    // marginBottom:'1em'
                  }}
                  sx={{
                    // width: '200px',
                    // height: '32px',
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '47px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border:'1px solid grey !important',
                      marginBottom:'1em',
                      paddingTop:'12px !important'
                    },
                    '.MuiInputBase-input': {
                      height: '1rem'
                    },
                    '.MuiInputLabel-root':{
                      top:'1px',
                      left:'-8px',
                      background:'#1f2125'
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Currency" />}
                  renderGroup={(params) => (
                <li>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
                  )}
                /> 
        <span style={{marginTop:"1em",backgroundColor:"transparent",marginLeft:'-15%',zIndex:1}}>
                <Tooltip title="Remove currency">
                <div                    
                  style={{
                    boxShadow: 'none',
                    cursor: 'pointer',
                    background: 'transparent',
                    color: '#FFC107'
                  }}
                  onClick={()=>{
                 setSelectedOption(selectedOption.filter(i=>i!='currency'))
                  }}
                >
                   <RemoveCircleOutlineSharpIcon /> 
                  {/* <RemoveIcon /> */}
                </div>
                </Tooltip>
              </span></> </div>
                 : <></>}
                {errorCurrency==true  ? <span style={{color:'#dc3545',fontSize:'.875em',display:'flex',justifyContent:'center',marginTop:'-10px',marginBottom:'20px'}}>Currency is required.</span> : <></>}  
           {selectedOption.includes('exchange') ?
                    <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': exchangeRate
                })}
                style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
              >
                     <Form.Control
                  type="text"
                  id="exchange_rate"
                  name="name"
                  value={exchangeRate}
                  // placeholder="Exchange-rate"
                  onChange={(e)=>{
                    const exchPer1 = e.target.value
                    if (!exchPer1 || exchPer1.match(/^\d{1,}(\.\d{0,2})?$/)) {
                      setExchangeRate(exchPer1)
                    }
                  }}
                  required
                  style={{ color: 'white' }}
                />
                <span className='label_text'>Exchange Rate</span>
                <span style={{marginTop:"10px",position:'absolute',left:'100%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove exchange">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='exchange'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
             <Form.Control.Feedback
                  type="invalid"
                >
                  Exchange-Rate is Required.
                </Form.Control.Feedback> 
              </Form.Label>  :<></>}   
                    
              {   selectedOption.includes('shareholder') ?
               [...[...new Map(resultShareholdersData.map(x=>[(x.share_holder_name),x])).values()]].length!=selectedShareholder.length ?
              <>
              <FormControl
                style={{
                  width:'44%',
                  marginRight:'31%',
                  marginLeft:'6%',
                  marginTop:'13%'
                  // width: '46%',
                  // marginLeft:'-23%',
                  // marginBottom: '23px'
                }}
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  style={{
                    fontSize: '17px',
                    overflow: 'visible',
                    color: 'grey',
                    top:'1px',
                    left:'-8px',
                    background:'#1f2125'
                  }}
                >
                  {' '}
                  shareholder
                </InputLabel>

                <Select
                  MenuProps={{
                    classes: {
                      paper: styles.paper
                    },
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        }
                      }
                    }
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                 value={shareholderEdit}
                  label="Select"
                  style={{
                    // width:"239px",
                    height: '50px',
                    // borderRadius: '15px',
                    boxShadow: 'none',
                    border:'1px solid hsl(0deg 0% 44% / 63%) '
                  }}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px',
                        height:'54px',
                        // border:'1px solid hsl(0deg 0% 44% / 63%) !important',
                        borderColor:'unset',
                        padding:'0 73px'
                    },
                    '.MuiInputLabel-root': {
                      color: 'grey !important'
                    },
                    '.MuiSelect-icon': {
                      fill: 'grey !important'
                    },
                    ".MuiOutlinedInput-input": {
                      color: "white !important",
                       fontSize: "15px",
                      //  backgroundColor:'white',
                       paddingBottom:'13px',
                       paddingRight:'62%',
                       paddingTop:'12px',
                  },
                    ' .MuiInputLabel-root.Mui-focused': {
                      marginTop: '-3% !important',
                      marginLeft: '-5% !important'
                    }
                  }}
                
                  onChange={(event)=>{
                    setShareholderEdit(event.target.value)
                     setTemp1(event.target.value)
                  }}
                >
                   {resultShareholdersData.filter(x => !selectedShareholder.some(y => y.shareholder === x.share_holder_name)).map((item,index)=>(
                  <MenuItem value={item.share_holder_name}>{item.share_holder_name}</MenuItem>
                  ))}
                </Select>
                
              </FormControl>
              
              <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': ownershipEdit
                })}
                style={{ marginTop:"-3.5em",marginLeft:"63%",width:'24%'}}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  value={ownershipEdit}
                  placeholder="%"
                  onChange={(e)=>{
                    const ownPer1 = e.target.value
                    if (!ownPer1 || ownPer1.match(/^\d{1,}(\.\d{0,1})?$/)) {
                      setOwnershipEdit(ownPer1)
                      setTemp(ownPer1)
                      setShareError(false)
                    }
                  }}
                  
                  required
                  style={{ color: 'white' }}
                />
                
                {resultShareholdersData.filter(x => !selectedShareholder.some(y => y.shareholder === x.share_holder_name)).length!=1 ? 
                <span style={{marginTop:"12%",position:'absolute',left:'96.5%',backgroundColor:"transparent"}}>
                   <Tooltip title="Add More Shareholders">
                   {shareholderEdit=='' ?
                    <div                    
                      style={{
                        boxShadow: 'none',
                        // cursor: 'pointer',
                        background: 'transparent',
                        color: 'grey'
                      }}
                      
                    >
                       <AddCircleOutlineOutlinedIcon /> 
                      {/* <RemoveIcon /> */}
                    </div> :
                    <div                    
                    style={{
                      boxShadow: 'none',
                      cursor: 'pointer',
                      background: 'transparent',
                      color: '#FFC107'
                    }}
                    onClick={()=>{
                     
                      setCountValue(countValue+1)
                      // shareholdArray.push(temp1.toLowerCase())
                      // ownerArray.push(temp)
                      // console.log(shareholdArray)
                      setSelectedShareholder([...selectedShareholder, {shareholder:temp1}])
                      setShareholderEdit('')
                      setOwnershipEdit('')
                      setTemp('')
                      setTemp1('')
                    }}
                  >
                     <AddCircleOutlineOutlinedIcon /> 
                    {/* <RemoveIcon /> */}
                  </div>
}
                   </Tooltip>
                   <Tooltip title="Remove shareholder">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='shareholder'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
                  </span> : <></>}
              </Form.Label>
              </> : <></> : <></>}
              {selectedShareholder.length>0 && selectedShareholder.map((c, index) => (
             index>=0 ? 
             <>
              <FormControl
             style={{
            width:'44%',
            marginRight:'31%',
            marginLeft:'6%'
             }}
           >
             <InputLabel
               id="demo-simple-select-helper-label"
               style={{
                 fontSize: '17px',
                 overflow: 'visible',
                 color: 'grey',
                 top:'1px',
                 left:'-8px',
                 background:'#1f2125'
               }}
             >
               {' '}
               shareholder
             </InputLabel>

             <Select
               MenuProps={{
                 classes: {
                   paper: styles.paper
                 },
                 PaperProps: {
                   sx: {
                     '& .MuiMenuItem-root:hover': {
                       backgroundColor: 'lightgrey',
                       color: 'black'
                     },
                     '& .MuiMenuItem-root.Mui-selected:hover': {
                       backgroundColor: 'lightgrey',
                       color: 'black'
                     }
                   }
                 }
               }}
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               // className={index}
              value={c.shareholder}
             //  disabled={countValue>5}
               label="Select"
               style={{
                 // width:"239px",
                 height: '50px',
                 // borderRadius: '15px',
                 boxShadow: 'none',
                 border:'1px solid hsl(0deg 0% 44% / 63%)',
               }}
               sx={{
                 '.MuiOutlinedInput-notchedOutline': {
                   borderRadius: '0px',
                     height:'54px',
                     
                     borderColor:'unset'
                 },
                 '.MuiInputLabel-root': {
                   color: 'grey !important'
                 },
                 '.MuiSelect-icon': {
                   fill: 'grey !important'
                 },
                 ".MuiOutlinedInput-input": {
                   color: "white !important",
                    fontSize: "15px",
                   //  backgroundColor:'white',
                    paddingBottom:'13px',
                    paddingRight:'62%',
                    paddingTop:'12px',
                    WebkitTextFillColor:'white'
               },
                 ' .MuiInputLabel-root.Mui-focused': {
                   marginTop: '-3% !important',
                   marginLeft: '-5% !important'
                 },
                
               }}
               disabled={selectedShareholder.length>0}
               // onChange={handleshare}
             >
               {resultShareholdersData.map((item,index)=>(
               <MenuItem key={index} value={item.share_holder_name}>{item.share_holder_name}</MenuItem>
               ))}
             </Select>
           </FormControl>

           <Form.Label
             htmlFor="exchange"
             className={cx('custom-form-box', {
               'focus-add': ownershipEdit
             })}
             style={{ marginTop:"-3.5em",marginLeft:"63%",width:'24%'}}
           >
             <Form.Control
               type="number"
               id="name"
               name="name"
               value={c.owner}
               placeholder="%"
               onChange={(e)=>{
                 const ownPer1 = e.target.value
                 if (!ownPer1 || ownPer1.match(/^\d{1,}(\.\d{0,1})?$/)) {
                  
                  setSelectedShareholder((data)=>
                  data.map((list,ind)=>
                  ind==index ? {...list,owner:ownPer1} : list))
               
               }
               }}
               required
               style={{ color: 'white',backgroundColor:'rgb(31, 33, 37)' }}
             />
              <span style={{marginTop:"12%",position:'absolute',left:'96.5%',backgroundColor:"transparent"}}>
                 <Tooltip title="Remove Shareholders">
                 <div                    
                   style={{
                     boxShadow: 'none',
                     cursor: 'pointer',
                     background: 'transparent',
                     color: '#FFC107'
                   }}
                   onClick={()=>{
                     setCountValue(countValue-1)
                     setSelectedShareholder(selectedShareholder.filter(i=>i!=c))
                    //  setTemp('')
                    //  setTemp1('')
                   }}
                 >
                    <RemoveCircleOutlineSharpIcon />
                 </div>
                 </Tooltip>
               </span>  
           </Form.Label>
             </>
             :
             <></>
))}
               {selectedOption.includes('comment') ?
                    <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': comment
                })}
                style={{ width: '72%', marginLeft: '15%',marginTop:'10%' }}
              >
                     <Form.Control
                  type="text"
                  id="comment"
                  name="name"
                  value={comment}
                  onChange={(e)=>setComment(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
                  <span className='label_text'>Comment</span>
                  <span style={{marginTop:"10px",position:'absolute',left:'100%',backgroundColor:"transparent"}}>
              <Tooltip title="Remove comment">
              <div                    
                style={{
                  boxShadow: 'none',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: '#FFC107'
                }}
                onClick={()=>{
               setSelectedOption(selectedOption.filter(i=>i!='comment'))
                }}
              >
                 <RemoveCircleOutlineSharpIcon /> 
                {/* <RemoveIcon /> */}
              </div>
              </Tooltip>
            </span>
              </Form.Label>:<></>}
              <Button
                type="submit"
                variant=""
                disabled={selectedOption.length==0}
                className="btn-gray"
                style={{
                  width: '50%',
                  marginLeft: '25%',
                  boxShadow: 'none',
                  color: 'white',
                  marginTop:'10%'
                
                }}
              >
                Save
              </Button>
            </Form>
          </Modal.Body>
          {alertGameUpdateSuccess
            ? (
              <Snackbar
                open={alertGameUpdateSuccess}
                onClose={()=>setAlertGameUpdateSuccess(false)}
                sx={{
                  marginLeft: '35%',
                  marginBottom: '38%',
                  width: '25%'
                }}
              >
                <Alert
                  onClose={()=>setAlertGameUpdateSuccess(false)}
                  severity="success"
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                >
                  Update successfully
                </Alert>
              </Snackbar>
              )
            : (
              <></>
              )}
              {alertSameError
            ? (
              <Snackbar
                open={alertSameError}
                onClose={()=>setAlertSameError(false)}
                sx={{
                  marginLeft: '35%',
                  marginBottom: '38%',
                  width: '25%'
                }}
              >
                <Alert
                  onClose={()=>setAlertSameError(false)}
                  severity="error"
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                >
                  Host and Group can not be same
                </Alert>
              </Snackbar>
              )
            : (
              <></>
              )}
              {ownerZeroErr ? (
            <Snackbar
              open={ownerZeroErr}
              // autoHideDuration={4000}
              onClose={() => setOwnerZeroErr(false)}
              sx={{
                marginLeft: '35%',
                marginBottom: '38%',
                width: '25%'
              }}
            >
              <Alert
                onClose={() => setOwnerZeroErr(false)}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Ownership can not be 0
              </Alert>
            </Snackbar>
          )
            : (
              <></>
              )}
              {shareHolderErr
            ? (
              <Snackbar
                open={shareHolderErr}
                onClose={()=>setShareholderErr(false)}
                sx={{
                  marginLeft: '35%',
                  marginBottom: '38%',
                  width: '25%'
                }}
              >
                <Alert
                  onClose={()=>setShareholderErr(false)}
                  severity="error"
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                >
                  please enter shareholder name 
                </Alert>
              </Snackbar>
              )
            : (
              <></>
              )}
              {alertResultErr ? (
            <Snackbar
              open={alertResultErr}
              // autoHideDuration={4000}
              onClose={() => setAlertResultErr(false)}
              sx={{
                marginLeft: '35%',
                marginBottom: '38%',
                width: '25%'
              }}
            >
              <Alert
                onClose={() => setAlertResultErr(false)}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Amount can not be 0
              </Alert>
            </Snackbar>
          )
            : (
              <></>
              )}
          {alertOwnershipError
            ? (
              <Snackbar
                open={alertOwnershipError}
                onClose={()=>setAlertOwnershipError(false)}
                sx={{
                  marginLeft: '35%',
                  marginBottom: '38%',
                  width: '25%'
                }}
              >
                <Alert
                  onClose={()=>setAlertOwnershipError(false)}
                  severity="error"
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                >
                  shareholder % not be greater than 100%.
                </Alert>
              </Snackbar>
              )
            : (
              <></>
              )}
              {alertOwnershipLessError
            ? (
              <Snackbar
                open={alertOwnershipLessError}
                onClose={()=>setAlertOwnershipLessError(false)}
                sx={{
                  marginLeft: '35%',
                  marginBottom: '38%',
                  width: '25%'
                }}
              >
                <Alert
                  onClose={()=>setAlertOwnershipLessError(false)}
                  severity="error"
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                >
                  shareholder % not be less than 100%.
                </Alert>
              </Snackbar>
              )
            : (
              <></>
              )}
        </div>
      </Modal>
    </>
  )
}
)
export default ModalForEditSelectedRows
import React, { useState, useEffect } from "react";
import CommonAccountantTable from "../common/CommonTable/CommonAccountantTable";
import axios from "axios";
import cx from "classnames";
import Spinner from "../common/spinner";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useSelector, useDispatch } from "react-redux";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Select from "@mui/material/Select";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { useLocalStorage } from "../common/useLocalStorage";
import { Alert, TextField, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import { v4 as uuidv4 } from "uuid";
import SelectSearchBox from "../common/SelectSearchBox";
import { Link, useNavigate } from "react-router-dom";
import {
  textFilter,
  dateFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../common/Header/Header";
import SidebarAdmin from "../Admin/DashboardAdmin/SidebarAdmin";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchBox from "../common/SearchBox/SearchBox";
import { LegendToggleRounded } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { styled, lighten, darken } from "@mui/system";
import { SelectBox } from "../common/SelectBox";
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: "white",
  fontWeight: "bold",
  // backgroundColor: 'darkgrey'
  // theme.palette.mode === 'light'
  // ? lighten(theme.palette.primary.light, 0.85)
  // : darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled("li")({
  padding: 0,
  paddingLeft: "2rem",
});
const useStyles = makeStyles({
  paper: {
    background: "rgb(31, 33, 37) !important",
    color: "white !important",
  },
  option: {
    "&:hover": {
      backgroundColor: "grey !important",
      color: "white !important",
    },
  },
});

function Payments() {
  const styles = useStyles();
  const navigate = useNavigate();
  let selectColumnId = localStorage.getItem("key");
  const getId = localStorage.getItem("sub_Id");
  const { selectedColumnId } = useSelector((store) => store.app);
  const [result4, setResult4] = useState([]);
  const [alertSameError, setAlertSameError] = useState(false);
  const [entityData, setEntityData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [alertPayment, setAlertPayment] = useState(false);
  const [showPopupDeletePayment, setShowPopupDeletePayment] = useState(false);
  const [sea, setSea] = useState("");
  const [loading, setLoading] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [payment, setPayment] = useState("");
  const [alertNoRecord, setAlertNoRecord] = useState(false);
  const [sender, setSender] = useState("");
  const [receiver, setReceiver] = useState("");
  const [amount, setAmount] = useState("");
  const [selectCurrencyErr, setSelectCurrencyErr] = useState(false);
  const [currency, setCurrency] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [result, setResult] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [comment, setComment] = useState("");
  const [commentEdit, setCommentEdit] = useState("");
  const [senderEdit, setSenderEdit] = useState("");
  const [receiverEdit, setReceiverEdit] = useState("");
  const [spaceError, setSpaceError] = useState(false);
  const [amountEdit, setAmountEdit] = useState("");
  const [currencyEdit, setCurrencyEdit] = useState("");
  const [exchangeRateEdit, setExchangeRateEdit] = useState("");
  const [resultEdit, setResultEdit] = useState("");
  const [paymentTypeEdit, setPaymentTypeEdit] = useState("");
  const [fullPaymentData, setFullPaymentData] = useState([]);
  const [search, setSearch] = useState([]);
  const [resultShareholdersData, setResultShareholdersData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [alertPaymentSuccess, setAlertPaymentSuccess] = useState(false);
  const [alertPaymentEditSuccess, setAlertPaymentEditSuccess] = useState(false);
  const [showPopupPayment, setShowPopupPayment] = useState(false);
  const [showEditPopupPayment, setShowEditPopupPayment] = useState(false);
  const [currencyValue, setCurrencyValue] = useState();
  const [alertAmountErr, setAlertAmountErr] = useState(false);
  const [senderErr, setSenderErr] = useState(false);
  const [rowEdit, setRowEdit] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [balanceData, setBalanceData] = useState([]);
  const [recieverErr, setRecieverErr] = useState(false);
  const [entityInfo, setEntityInfo] = useState([]);
  console.log(selectedColumnId);
  const columnHideArray = [];

  let senderFilter;
  const currencyList = [
    { title: "BTC", type: "Crypto:" },
    { title: "ETH", type: "Crypto:" },
    { title: "USDT", type: "Crypto:" },
    { title: "USDC", type: "Crypto:" },
    { title: "USD", type: "FIAT:" },
    { title: "EUR", type: "FIAT:" },
    { title: "GBP", type: "FIAT:" },
    { title: "HKD", type: "FIAT:" },
    { title: "AED", type: "FIAT:" },
    { title: "CNY", type: "FIAT:" },
    { title: "VND", type: "FIAT:" },
    { title: "MYR", type: "FIAT:" },
    { title: "PHP", type: "FIAT:" },
    { title: "THB", type: "FIAT:" },
    { title: "AUD", type: "FIAT:" },
    { title: "CAD", type: "FIAT:" },
    { title: "CAD", type: "FIAT:" },
    { title: "BNY", type: "FIAT:" },
    { title: "MMK", type: "FIAT:" },
    { title: "DKK", type: "FIAT:" },
    { title: "HRK", type: "FIAT:" },
    { title: "HUF", type: "FIAT:" },
    { title: "INR", type: "FIAT:" },
    { title: "ISK", type: "FIAT:" },
    { title: "JPY", type: "FIAT:" },
    { title: "CHF", type: "FIAT:" },
    { title: "MXN", type: "FIAT:" },
    { title: "NOK", type: "FIAT:" },
    { title: "NZD", type: "FIAT:" },
    { title: "PLN", type: "FIAT:" },
    { title: "CZK", type: "FIAT:" },
    { title: "RON", type: "FIAT:" },
    { title: "RUB", type: "FIAT:" },
    { title: "LKR", type: "FIAT:" },
    { title: "TWD", type: "FIAT:" },
    { title: "TRY", type: "FIAT:" },
  ];
  const options = currencyList.map((option) => {
    const currencyType = option.type;
    return {
      firstLetter: /[0-9]/.test(currencyType) ? "FIAT:" : currencyType,
      ...option,
    };
  });
  // const options = currencyList.map((option) => {
  //   const firstLetter = option.title[0].toUpperCase();
  //   return {
  //     firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
  //     ...option,
  //   };
  // });
  let shareholderdata = [];
  //list of shareholder api
  const shareholderListData = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/get_share_holder`, {})
      .then(function (response) {
        if (response.data.length == 0) {
          setAlertNoRecord(true);
          setLoading(false);
          setResultShareholdersData([]);
        } else {
          shareholderdata = response.data;
          setResultShareholdersData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //list of balance api
  const BalanceGetApiUpdate = async (name, reciever) => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/get_balnce`, {
        params: { name: name },
      })
      .then(function (response1) {
        if (senderEdit == "") {
          let finalArr = response1.data.filter(
            (i) =>
              (i.person == reciever &&
                i.balance_status == "Active" &&
                i["sum(amount)"] != 0) ||
              (i.person == reciever &&
                i.balance_status == null &&
                i["sum(amount)"] != 0)
          );
          console.log(
            finalArr,
            senderEdit,
            receiverEdit,
            reciever,
            response1.data
          );
          setBalanceData(
            finalArr.map((rec) => {
              return { ...rec, name: rec.currency };
            })
          );
        } else {
          let finalArr = response1.data.filter(
            (i) =>
              (i.person == senderEdit?.split("-")[0].trim("") &&
                i.balance_status == null &&
                i["sum(amount)"] != 0) ||
              (i.person == receiverEdit?.split("-")[0].trim("") &&
                i.balance_status == null &&
                i["sum(amount)"] != 0) ||
              (i.balance_status == "Active" &&
                i["sum(amount)"] != 0 &&
                i.person == senderEdit?.split("-")[0].trim("")) ||
              (i.balance_status == "Active" &&
                i["sum(amount)"] != 0 &&
                i.person == receiverEdit?.split("-")[0].trim(""))
          );
          console.log(finalArr, senderEdit, receiverEdit);
          setBalanceData(
            finalArr.map((rec) => {
              return { ...rec, name: rec.currency };
            })
          );
        }
      });
  };

  //list of balance api
  const BalanceGetApi = async (name) => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/get_balnce`, {
        params: { name: name },
      })
      .then(function (response1) {
        let finalArr = response1.data.filter(
          (i) =>
            (i.person == sender?.split("-")[0].trim("") &&
              i.balance_status == null &&
              i["sum(amount)"] != 0) ||
            (i.person == receiver?.split("-")[0].trim("") &&
              i.balance_status == null &&
              i["sum(amount)"] != 0) ||
            (i.balance_status == "Active" &&
              i["sum(amount)"] != 0 &&
              i.person == sender?.split("-")[0].trim("")) ||
            (i.balance_status == "Active" &&
              i["sum(amount)"] != 0 &&
              i.person == receiver?.split("-")[0].trim(""))
        );
        console.log(finalArr);
        setBalanceData(
          finalArr.map((rec) => {
            return { ...rec, name: rec.currency };
          })
        );
      });
  };

  //list of entity api
  const getEntityApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/get_entity`, {})
      .then(function (response) {
        console.log(response.data);
        if (response.data.length == 0) {
          setEntityData([]);
        } else {
          setEntityInfo(response.data.filter((i) => i.type != "Other"));
          // response.data.sort((a, b) => a.name.localeCompare(b.name))
          console.log(response.data, shareholderdata);
          console.log([
            ...response.data.map((e) => {
              return { ...e, name: e.name + "(entity)" };
            }),
            ...shareholderdata.map((e) => {
              return { ...e, name: e.share_holder_name + "(shareholder)" };
            }),
          ]);
          setEntityData([
            ...response.data
              .map((e) => {
                return {
                  ...e,
                  e_name: e.name,
                  name: e.name + " -" + "  " + "(entity)",
                };
              })
              .filter((i) => i.type != "Other"),
            ...shareholderdata.map((e) => {
              return {
                ...e,
                e_name: e.share_holder_name,
                name: e.share_holder_name + " -" + "  " + "(shareholder)",
              };
            }),
          ]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log(entityData);
  const handleShowPayment = () => {
    setValidated(false);
    setExchangeRate("");
    setCurrencyValue("");
    setAmount("");
    setReceiver();
    setComment("");
    setSender();
    setSenderErr(false);
    setRecieverErr(false);
    setCurrencyError(false);
    setShowPopupPayment(true);
  };
  const handleChangePayment = (e) => {
    setPaymentType(e.target.value);
    console.log(e.target.value);
  };
  const handleChangePaymentEdit = (e) => {
    setPaymentTypeEdit(e.target.value);
    console.log(e.target.value);
  };
  const handleEditPopupPayment = (row) => {
    setSelectCurrencyErr(false);
    setBalanceData([]);
    setRowEdit(row);
    setSenderErr(false);
    setRecieverErr(false);
    setShowEditPopupPayment(true);
    setPaymentId(row.payment_id);
    setSenderEdit(
      row.type_of_sender != "null" && row.type_of_sender != null
        ? row.sender + " -" + "  " + "(" + row.type_of_sender + ")"
        : row.sender
    );
    setReceiverEdit(
      row.type_of_reciever != "null" && row.type_of_reciever != null
        ? row.reciever + " -" + "  " + "(" + row.type_of_reciever + ")"
        : row.reciever
    );
    setAmountEdit(row.amount);
    setCurrencyEdit(row.comment?.split(".")[1]==undefined ? row.currency : row.comment?.split(".")[0]?.split(" ")[4]);
    setAmountEdit(row.amount);
    setCurrencyError(false);
    setSelectedCurrency(row.comment?.split(".")[1]==undefined ? '' : row.currency);
    setResultEdit(row.result_usd);
    setExchangeRateEdit(row.exchange_rate);
    setPaymentTypeEdit(row.payment_type);
    setCommentEdit(row.comment?.split(".")[1]==undefined  ? row.comment : row.comment?.split(".")[1]);
    BalanceGetApiUpdate(row.sender, row.reciever);

    console.log(row.comment?.split(".")[1]);
  };
  const handleDeletePopupPayment = (row) => {
    setShowPopupDeletePayment(true);
    setPaymentId(row.payment_id);
    console.log(row);
  };
  const handleClose = () => {
    setShowPopupDeletePayment(false);
  };

  //list of payment api
  const paymentApi = async () => {
    console.log(entityData)
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/get_payment`)
      .then(function (response) {
        console.log(response.data, entityData);
        const temp2 = response.data?.map((record) => {
          let type;
          if (
            record.currency === "BTC" ||
            record.currency === "ETH" ||
            record.currency === "USDT" ||
            record.currency === "USDC"
          ) {
            type = "Crypto";
          } else {
            type = "FIAT";
          }
          let res =
            parseFloat(record.amount) / parseFloat(record.exchange_rate);
          return {
            ...record,
            payment_id: parseInt(record.payment_id),
            payment_type: type,
            result: res,
            date_updated: moment(record.date_updated).format("YYYY-MM-DD"),
            amount: parseFloat(record.amount),
            exchange_rate: parseFloat(record.exchange_rate),
            date: record.date_updated,
          };
        });
        if (temp2) {
          temp2.sort((a, b) => {
            const x = new Date(a.date).getTime() / 1000;
            const y = new Date(b.date).getTime() / 1000;
            return x > y ? -1 : x < y ? 1 : 0;
          });
        }
        if (temp2.length == 0) {
          setLoading(false);
          setAlertNoRecord(true);
          setPaymentData([]);
        }
        setLoading(false);
        setFullPaymentData(temp2);
        setPaymentData(
          temp2.filter((i) => i.status == "Active" || i.status == null)
        );
      });
  };

  //add payment api
  const AddPaymentApi = async (e) => {
    e.preventDefault();
    setValidated(true);
    console.log(sender, receiver, payment, currencyValue);
    const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.preventDefault()
    //   e.stopPropagation()
    // }
    if (amount === "" || exchangeRate == "") {
      setValidated(true);
      if (sender == undefined || sender=='') {
        console.log("senderErr");
        setSenderErr(true);
      }
      if (receiver == undefined || receiver=='') {
        console.log("recieverErr");
        setRecieverErr(true);
      }
      if (parseFloat(amount) == 0) {
        setAlertAmountErr(true);
        setTimeout(() => setAlertAmountErr(false), 3000);
        // console.log('amount can not 0')
      }
      if (parseFloat(exchangeRate) == 0) {
        setAlertAmountErr(true);
        setTimeout(() => setAlertAmountErr(false), 3000);
        // console.log('amount can not 0')
      }
      if (currencyValue == "" || currencyValue == null) {
        setCurrencyError(true);
      }
      if (
        (entityData.filter((i) => i.name == sender).length > 0 &&
          balanceData.length > 0 &&
          entityData.filter((i) => i.name == receiver).length > 0 &&
          selectedCurrency == "") ||
        (selectedCurrency == null &&
          entityData.filter((i) => i.name == sender).length > 0 &&
          balanceData.length > 0 &&
          entityData.filter((i) => i.name == receiver).length > 0)
      ) {
        setSelectCurrencyErr(true);
      }
    } else if (sender == undefined && sender=='') {
      console.log("senderErr");
      setSenderErr(true);
    } else if (receiver == undefined && receiver=='') {
      console.log("recieverErr");
      setRecieverErr(true);
    } else if (
      (entityData.filter((i) => i.name == sender).length > 0 &&
        balanceData.length > 0 &&
        entityData.filter((i) => i.name == receiver).length > 0 &&
        selectedCurrency == "") ||
      (selectedCurrency == null &&
        entityData.filter((i) => i.name == sender).length > 0 &&
        balanceData.length > 0 &&
        entityData.filter((i) => i.name == receiver).length > 0)
    ) {
      setSelectCurrencyErr(true);
    } else if (currencyValue == null || currencyValue == "") {
      setCurrencyError(true);
      console.log("currency", currencyValue);
    } else if (parseFloat(amount) == 0) {
      setAlertAmountErr(true);
      setTimeout(() => setAlertAmountErr(false), 3000);
    } else if (parseFloat(exchangeRate) == 0) {
      setAlertAmountErr(true);
      setTimeout(() => setAlertAmountErr(false), 3000);
      // console.log('amount can not 0')
    }
    // else if(sender.trim()=='' || receiver.trim()==''){
    //   setSpaceError(true)
    // setTimeout(()=>{
    //   setSpaceError(false)
    //   // setShowPopupPayment(false)
    // },3000)
    // }
    else {
      console.log(
        paymentData,
        paymentData.filter((i) => i.sender == receiver).length > 0
          ? "-" + amount
          : paymentData.filter((i) => i.reciever == sender).length > 0
          ? "-" + amount
          : amount
      );
      fullPaymentData?.sort((a, b) => {
        const x = parseInt(a.payment_id);
        const y = parseInt(b.payment_id);
        return x < y ? -1 : x > y ? 1 : 0;
      });
      if (sender == receiver) {
        setAlertSameError(true);
        setTimeout(() => setAlertSameError(false), 3000);
      } else {
        console.log(sender,entityData);
      
        for (let i = 1; i < 100000; i++) {
          let m = fullPaymentData.filter((f) => f.payment_id == i);
          if (m.length === 0 || fullPaymentData.length == 0) {
            const config = {
              method: "post",
              url: `${process.env.REACT_APP_BASE_URL}/create_payment`,
              data: {
                user_id: getId,
                payment_id:
                  fullPaymentData.length == 0
                    ? 1
                    : parseInt(fullPaymentData.slice(-1)[0]?.payment_id) + 1,
                payment: payment,
                sender: sender.split("-")[0].trim(""),
                sender_type:
                  entityData.filter((i) => i.name == sender).length == 0
                    ? "Other"
                    : "Main",
                reciever_type:
                  entityData.filter((i) => i.name == receiver).length == 0
                    ? "Other"
                    : "Main",
                reciever: receiver.split("-")[0].trim(""),
                amount: amount,
                type_of_sender:
                  entityData.filter((i) => i.name == sender).length > 0
                    ? sender?.split("(")[1]?.replace(")", "")
                    : "null",
                type_of_reciever:
                  entityData.filter((i) => i.name == receiver).length > 0
                    ? receiver?.split("(")[1]?.replace(")", "")
                    : "null",
                result_usd:
                  paymentData.filter(
                    (i) =>
                      i.sender == receiver.split("-")[0].trim("") &&
                      i.reciever == sender.split("-")[0].trim("")
                  ).length > 0
                    ? "-" + amount
                    : amount,
                currency:
                  selectedCurrency != "" ? selectedCurrency : currencyValue,
                exchange_rate: exchangeRate,
                payment_type:
                  currencyValue === "BTC" ||
                  currencyValue === "ETH" ||
                  currencyValue === "USDT" ||
                  currencyValue === "USDT"
                    ? "Crypto"
                    : "FIAT",
                comment:
                  selectedCurrency != ""
                    ? `payment has done in ${currencyValue}` + "." + comment
                    : comment,
              },
            };
            console.log(config.data,selectedCurrency);
            await axios(config)
              .then(function (response) {
                console.log(response);
                console.log(response.data);
                if(entityData.filter(i=>i.name==receiver).length==0 && entityData.filter(i=>i.name==sender).length==0){
                  console.log('call reciever & sender')
                  handleAddEntity(sender)
                  handleAddEntity(receiver)
                }
                else if(entityData.filter(i=>i.name==sender).length==0){
                  console.log('call sender')
                  handleAddEntity(sender)
                }else if(entityData.filter(i=>i.name==receiver).length==0){
                  console.log('call reciever')
                  handleAddEntity(receiver)
                }
                paymentApi();
                setSelectedCurrency("");
                setSelectCurrencyErr(false);
                setAlertPaymentSuccess(true);
                setTimeout(() => {
                  setAlertPaymentSuccess(false);
                  setShowPopupPayment(false);
                }, 2000);
              })
              .catch(function (error) {
                console.log(error);
              });
            break;
          }
        }
      }
    }
  };
  const handleAddEntity = async (data1) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/accountancy_entity`,
      data:
      {
        "name":data1.toLowerCase(),
        "type":'Other'
      }
    }
    axios(config).then(function (response) {
    }).catch(function (error) {
    })
}
  //update payment api
  const EditPaymentApi = async (e) => {
    e.preventDefault();
    setValidated(true);
    console.log(sender, receiver, payment);
    const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.preventDefault()
    //   e.stopPropagation()
    // }
    if (amountEdit === "" || exchangeRateEdit == "") {
      setValidated(true);
      if (senderEdit == undefined || senderEdit =='') {
        console.log("senderErr");
        setSenderErr(true);
      }
      if (receiverEdit == undefined || receiverEdit == '') {
        console.log("recieverErr");
        setRecieverErr(true);
      }
      if (parseFloat(amountEdit) == 0) {
        setAlertAmountErr(true);
        setTimeout(() => setAlertAmountErr(false), 3000);
      }
      if (parseFloat(exchangeRateEdit)== 0) {
        setAlertAmountErr(true);
        setTimeout(() => setAlertAmountErr(false), 3000);
      }
      if (
        (balanceData.length > 0 &&
          entityData.filter((i) => i.name == senderEdit).length > 0 &&
          entityData.filter((i) => i.name == receiverEdit).length > 0 &&
          selectedCurrency == "") ||
        (selectedCurrency == null &&
          entityData.filter((i) => i.name == senderEdit).length > 0 &&
          entityData.filter((i) => i.name == receiverEdit).length > 0 &&
          balanceData.length > 0)
      ) {
        setSelectCurrencyErr(true);
      }
      if (currencyEdit == "" || currencyEdit == null) {
        setCurrencyError(true);
      }
    } else if (
      (entityData.filter((i) => i.name == senderEdit).length > 0 &&
        balanceData.length > 0 &&
        entityData.filter((i) => i.name == receiverEdit).length > 0 &&
        selectedCurrency == "") ||
      (selectedCurrency == null &&
        entityData.filter((i) => i.name == senderEdit).length > 0 &&
        balanceData.length > 0 &&
        entityData.filter((i) => i.name == receiverEdit).length > 0)
    ) {
      setSelectCurrencyErr(true);
    } else if (currencyEdit == null || currencyEdit == "") {
      setCurrencyError(true);
    } else if (senderEdit == undefined || senderEdit == '') {
      console.log("senderErr");
      setSenderErr(true);
    } else if (receiverEdit == undefined || receiverEdit == '') {
      console.log("recieverErr");
      setRecieverErr(true);
    } else if (parseFloat(amountEdit) == 0) {
      setAlertAmountErr(true);
      setTimeout(() => setAlertAmountErr(false), 3000);
    } else if (parseFloat(exchangeRateEdit) == 0) {
      setAlertAmountErr(true);
      setTimeout(() => setAlertAmountErr(false), 3000);
    } else {
      if (senderEdit == receiverEdit) {
        setAlertSameError(true);
        setTimeout(() => {
          setAlertSameError(false);
        }, 3000);
      } else {
        console.log(
          entityData.filter((i) => i.name == senderEdit.split("-")[0].trim("")),
          rowEdit.type_of_reciever,
          entityData.filter(
            (i) => i.e_name == receiverEdit.split("-")[0].trim("")
          ),
          receiverEdit
        );
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/update_payment`,
          data: {
            user_id: getId,
            payment_id: paymentId,
            sender: senderEdit.split("-")[0].trim(""),
            sender_type:
              entityData.filter(
                (i) => i.e_name == senderEdit.split("-")[0].trim("")
              ).length == 0
                ? "Other"
                : "main",
            reciever_type:
              entityData.filter(
                (i) => i.e_name == receiverEdit.split("-")[0].trim("")
              ).length == 0
                ? "Other"
                : "main",
            reciever: receiverEdit.split("-")[0].trim(""),
            amount: amountEdit,
            type_of_sender:
              entityData.filter((i) => i.name == senderEdit).length == 0
                ? "null"
                : senderEdit?.split("(")[1]?.replace(")", ""),
            type_of_reciever:
              entityData.filter((i) => i.name == receiverEdit).length == 0
                ? "null"
                : receiverEdit?.split("(")[1]?.replace(")", ""),
            currency: selectedCurrency != "" ? selectedCurrency : currencyEdit,
            exchange_rate: exchangeRateEdit,
            payment_type: paymentTypeEdit,
            result_usd:
              paymentData.filter(
                (i) => i.sender == receiverEdit && i.reciever == senderEdit
              ).length > 0
                ? "-" + amount
                : amount,
            comment:
              selectedCurrency != ""
                ? `payment has done in ${currencyEdit}` + "." + commentEdit
                : commentEdit,
          },
        };
        // console.log(config.data,currencyEdit);
        await axios(config)
          .then(function (response) {
            console.log(response);
            console.log(response.data);
            if(entityData.filter(i=>i.name==receiverEdit).length==0 && entityData.filter(i=>i.name==senderEdit).length==0){
              console.log('call reciever & sender')
              handleAddEntity(senderEdit)
              handleAddEntity(receiverEdit)
            }
            else if(entityData.filter(i=>i.name==senderEdit).length==0){
              console.log('call sender')
              handleAddEntity(senderEdit)
            }else if(entityData.filter(i=>i.name==receiverEdit).length==0){
              console.log('call reciever')
              handleAddEntity(receiverEdit)
            }
            paymentApi();
            setSelectedCurrency("");
            setSelectCurrencyErr(false);
            setAlertPaymentEditSuccess(true);
            setTimeout(() => {
              setAlertPaymentEditSuccess(false);
              setShowEditPopupPayment(false);
            }, 2000);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  //delete payment api
  const DeletePaymentApi = async () => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/update_payment_status`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        payment_id: paymentId,
        user_id: getId,
      },
    };
    await axios(config)
      .then(function (response) {
        paymentApi();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(async () => {
    await shareholderListData();
    await getEntityApi();
    await paymentApi();

    //await accountant()
    //await allportfolioUser()
  }, []);
  console.log(paymentData);
  const columns4 = [
    // {
    //   dataField: 'payment',
    //   text: 'Name',
    //   sort: true
    // },
    {
      dataField: "payment_id",
      text: "Id",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("payment_id") == true,
      toggle: false,
      filter: textFilter({
        placeholder: "id",
        // getFilter: filter => {
        //   idFilter = filter
        // }
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div>
              <span style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
                {row.payment_id}
              </span>
            </div>
          </>
        );
      },
    },
    {
      dataField: "date_updated",
      text: "Date",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("date_updated") == true,
      filter: dateFilter({
        placeholder: "date",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{moment(row.date_updated).format("Do MMMM YYYY")}</span>;
      },
    },
    {
      dataField: "sender",
      text: "Sender",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("sender") == true,
      filter: textFilter({
        placeholder: "sender",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span>
            {row.type_of_sender != null && row.type_of_sender != "null" && row.type_of_sender=='Main' ||
            row.type_of_sender != null && row.type_of_sender != "null" && row.type_of_sender=='Control' ||
            row.type_of_sender != null && row.type_of_sender != "null" && row.type_of_sender=='entity'
              ? `${row.sender} - (entity)` :
              row.type_of_sender != null && row.type_of_sender != "null" && row.type_of_sender=='shareholder'
              ? `${row.sender} - (shareholder)` 
              : row.sender}
          </span>
        );
      },
    },
    {
      dataField: "reciever",
      text: "Receiver",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("reciever") == true,
      filter: textFilter({
        placeholder: "receiver",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span>
            {row.type_of_reciever != null && row.type_of_reciever != "null" && row.type_of_reciever=='Main' ||
            row.type_of_reciever != null && row.type_of_reciever != "null" && row.type_of_reciever=='Control' ||
            row.type_of_reciever != null && row.type_of_reciever != "null" && row.type_of_reciever=='entity'
              ? `${row.reciever} - (entity)` :
              row.type_of_reciever != null && row.type_of_reciever != "null" && row.type_of_reciever=='shareholder'
              ? `${row.reciever} - (shareholder)` 
              : row.reciever}
          </span>
        );
      },
    },
    {
      dataField: "payment_type",
      text: "Payment Type",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("payment_type") == true,
      filter: textFilter({
        placeholder: "payment-type",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span>
            {row.currency === "BTC" ||
            row.currency === "ETH" ||
            row.currency === "USDT" ||
            row.currency === "USDC"
              ? "Crypto"
              : "FIAT"}
          </span>
        );
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("amount") == true,
      filter: textFilter({
        placeholder: "amount",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            {parseInt(row.amount) >= 0 ? (
              <span style={{ color: "#00ff00" }}>
                {parseFloat(row.amount)
                  .toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .replace(/\.00$/, "")}
              </span>
            ) : (
              <span style={{ color: "#ff0000" }}>
                {parseFloat(row.amount)
                  .toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .replace(/\.00$/, "")}
              </span>
            )}
          </>
        );
      },
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("currency") == true,
      filter: textFilter({
        placeholder: "currency",
      }),
    },
    {
      dataField: "exchange_rate",
      text: "Exchange Rate($)",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("exchange_rate") == true,
      filter: textFilter({
        placeholder: "exchange-rate",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span>
            {parseFloat(row.exchange_rate)
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              .replace(/\.00$/, "")}
          </span>
        );
      },
    },
    {
      dataField: "result",
      text: "Result($)",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("result") == true,
      filter: textFilter({
        placeholder: "result",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const res = parseFloat(row.amount) / parseFloat(row.exchange_rate);
        // console.log(res)
        return (
          <>
            {parseFloat(row.result) > 0 ? (
              <span style={{ color: "#00ff00" }}>
                {parseFloat(row.result)
                  .toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .replace(/\.00$/, "")}
              </span>
            ) : (
              <span style={{ color: "#ff0000" }}>
                {parseFloat(row.result)
                  .toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .replace(/\.00$/, "")}
              </span>
            )}
          </>
        );
      },
    },
    {
      dataField: "comment",
      text: "Remarks",
      sort: true,
      hidden: selectColumnId?.split(",")?.includes("comment") == true,
      filter: textFilter({
        placeholder: "remarks",
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span>
            {row.comment != undefined ? (
              <p style={{ color: "white" }}>{row.comment}</p>
            ) : (
              <p style={{ color: "white", marginLeft: "2em" }}>-</p>
            )}
          </span>
        );
      },
    },

    {
      dataField: "action",
      text: "Action",
      sort: false,
      hidden: selectColumnId?.split(",")?.includes("action") == true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <span
              style={{ cursor: "pointer", color: "#F1C40F" }}
              onClick={() => {
                handleEditPopupPayment(row);
              }}
            >
              <EditOutlinedIcon />
            </span>
            <span
              style={{ cursor: "pointer", color: "#b30000" }}
              onClick={() => handleDeletePopupPayment(row)}
            >
              {" "}
              <DeleteOutlineOutlinedIcon />
            </span>
          </div>
        );
      },
    },
    // {
    //   dataField: 'portfolio',
    //   text: 'Portfolios',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //       <>
    //         {row.portfolio?.[0]?.map(i =>
    //           <li>{i.portfolio_name}</li>
    //         )}
    //       </>
    //     )
    //   }
    // }
  ];
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Row className="d-flex justify-content-center">
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Payments</h3>
              </span>
              <span className="p-2 pageheader">
                <Link
                  to="#"
                  style={{
                    boxShadow: "none",
                    cursor: "pointer",
                    background: "none",
                    color: "#FFC107",
                    top: "13px",
                    position: "relative",
                  }}
                  onClick={handleShowPayment}
                >
                  <AddCircleOutlineOutlinedIcon />
                </Link>
              </span>
              <span
                className="p-2 pageheader"
                onClick={() => navigate("/PMS/payment_logs")}
                // onMouseEnter={handleMouseEnterColor}
                // onMouseLeave={handleMouseLeaveColor}
                style={{
                  background: "transparent",
                  color: "#FFC107",
                  cursor: "pointer",
                  top: "11px",
                  position: "relative",
                }}
              >
                <Tooltip title="Payment Logs">
                  <ReceiptLongIcon />
                </Tooltip>
              </span>
              <SearchBox
                className="auto-ml p-2 pageheader"
                onChange={(event) => {
                  setSea(event.target.value);
                  const x = paymentData?.filter(
                    (i) =>
                      String(i.payment_id).includes(event.target.value) ||
                      parseFloat(i.result)
                        .toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .replace(/\.00$/, "") == event.target.value ||
                      parseFloat(i.amount)
                        .toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .replace(/\.00$/, "") == event.target.value ||
                      i.sender
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase()) ||
                      i.reciever
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase()) ||
                      i.payment_type
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase()) ||
                      (i.currency != null &&
                        i.currency
                          .toLowerCase()
                          .includes(event.target.value.toLowerCase())) ||
                      (i.comment != null &&
                        i.comment
                          .toLowerCase()
                          .includes(event.target.value.toLowerCase())) ||
                      parseFloat(i.exchange_rate)
                        .toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .replace(/\.00$/, "") == event.target.value ||
                      moment(i.date).format("Do MMMM YYYY") ==
                        event.target.value
                  );
                  if (x.length == 0) {
                    //  setAlertNoRecord([])
                    setSearch([]);
                  } else {
                    setSearch(x);
                  }
                }}
              />
            </Row>
            {loading ? (
              <Spinner
                style={{
                  position: "fixed",
                  top: "20em",
                  left: "59%",
                  height: "70px",
                  width: "70px",
                }}
                animation="border"
                variant="primary"
              />
            ) : null}
            {sea ? (
              <CommonAccountantTable data={search} columns={columns4} />
            ) : (
              <CommonAccountantTable
                loading={loading}
                data={paymentData}
                columns={columns4}
              />
            )}
          </Col>
        </Row>
        <Modal
          show={showPopupPayment}
          // onHide={()=>setShowPopupPayment(false)}
          style={{ width: "28%", marginLeft: "35%" }}
        >
          <div style={{ border: "1px solid white" }}>
            <Modal.Header
              style={{ backgroundColor: "#222429", border: "none" }}
            >
              {/* <Modal.Title>Edit PortFolio Section</Modal.Title> */}
              <IconButton
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "white",
                }}
                onClick={() => setShowPopupPayment(false)}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#222429" }}>
              <Form
                className="custom-form"
                noValidate
                validated={validated}
                onSubmit={AddPaymentApi}
              >
                <h4>Add Payment</h4>
                {/* <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': payment
                })}
                style={{ width: '72%', marginLeft: '15%' }}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="payment"
                  placeholder="name"
                  onChange={(e) => setPayment(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
                <Form.Control.Feedback type="invalid">
                   Name Required.
                </Form.Control.Feedback>
              </Form.Label> */}
                <div style={{ marginBottom: "6%" }}>
                  <SelectSearchBox
                    label="Sender"
                    value={sender}
                    err={senderErr}
                    options={entityData
                      .filter((i) => i.name != receiver)
                      .sort((a, b) => -b.e_name.localeCompare(a.e_name))}
                    onChange={(e, k) => {
                      let res_type = entityData.filter((i) => i.name == k);
                      if (res_type.length > 0) {
                        setSenderErr(false);
                        setSender(k);
                        BalanceGetApi(res_type?.[0]?.e_name);
                        // setInvestCheck(k)
                      } else {
                        setSenderErr(true);
                        setBalanceData([]);
                        //  setInvestType('')
                        setSender("");
                      }
                    }}
                    onChange1={(e) => {
                      if (
                        entityData.filter((i) => i.name === e.target.value)
                          .length == 0
                      ) {
                        setSenderErr(false);
                        setBalanceData([]);
                        setSender(e.target.value);
                      } else {
                        setSenderErr(false);
                        setBalanceData([]);
                        setSender(e.target.value);
                      }
                    }}
                  />
                </div>
                <div style={{ marginBottom: "6%" }}>
                  <SelectSearchBox
                    label="Receiver"
                    value={receiver}
                    err={recieverErr}
                    options={entityData
                      .filter((i) => i.name != sender)
                      .sort((a, b) => -b.e_name.localeCompare(a.e_name))}
                    onChange={(e, k) => {
                      let res_type = entityData.filter((i) => i.name == k);
                      if (res_type.length > 0) {
                        setRecieverErr(false);
                        setReceiver(k);
                        BalanceGetApi(res_type?.[0]?.e_name);
                        // setInvestCheck(k)
                      } else {
                        setRecieverErr(true);
                        //  setInvestType('')
                        setBalanceData([]);
                        setReceiver("");
                      }
                    }}
                    onChange1={(e) => {
                      if (
                        entityData.filter((i) => i.name === e.target.value)
                          .length == 0
                      ) {
                        setRecieverErr(false);
                        setBalanceData([]);
                        setReceiver(e.target.value);
                      } else {
                        setRecieverErr(false);
                        setBalanceData([]);
                        setReceiver(e.target.value);
                      }
                    }}
                  />
                </div>
                {entityData.filter((i) => i.name == sender).length > 0 &&
                entityData.filter((i) => i.name == receiver).length > 0 &&
                balanceData.length > 0 ? (
                  <SelectBox
                    label="Exchange currency"
                    options={balanceData}
                    err={selectCurrencyErr}
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                  />
                ) : (
                  <></>
                )}
                {/* <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': sender
                })}
                style={{ width: '72%', marginLeft: '15%' }}
              >
                <Form.Control
                  type="text"
                  id="sender"
                  name="sender"
                  onChange={(e) => setSender(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
                   <span className='label_text'>Sender</span>
                <Form.Control.Feedback type="invalid">
                   Sender Required.
                </Form.Control.Feedback>
              </Form.Label> */}
                {/* <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': receiver
                })}
                style={{ width: '72%', marginLeft: '15%' }}
              >
                <Form.Control
                  type="text"
                  id="receiver"
                  name="receiver"
                  onChange={(e) => setReceiver(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
                   <span className='label_text'>Receiver</span>
                <Form.Control.Feedback type="invalid">
                   Receiver Required.
                </Form.Control.Feedback>
              </Form.Label> */}
                <Autocomplete
                  id="grouped-demo"
                  // value={currencyValue}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.title}
                  classes={{
                    option: styles.option,
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{ background: "rgb(31, 33, 37)", color: "white" }}
                    >
                      {children}
                    </Paper>
                  )}
                  onChange={(e, k) => {
                    if (k == null) {
                      setCurrencyError(false);
                      setCurrencyValue(null);
                    } else {
                      setCurrencyError(false);
                      setCurrencyValue(k.title);
                    }
                  }}
                  style={{
                    fill: "white",
                    boxShadow: "none",
                    fontSize: "14px",
                    borderRadius: "30%",
                    marginLeft: "15%",
                    marginBottom: "1em",
                  }}
                  sx={{
                    // width: '200px',
                    // height: '32px',
                    ".MuiButtonBase-root": {
                      color: "white",
                    },
                    ".MuiOutlinedInput-root": {
                      borderRadius: "4px",
                      width: "84%",
                      height: "47px",
                      // backgroundColor: '#fff',
                      fontSize: "14px",
                      border: "1px solid grey !important",
                      marginBottom: "1em",
                      paddingTop: "12px !important",
                    },
                    ".MuiInputBase-input": {
                      height: "1rem",
                    },
                    ".MuiInputLabel-root": {
                      top: "1px",
                      left: "-8px",
                      background: "#1f2125",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Currency" />
                  )}
                  renderGroup={(params) => (
                    <li>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                />
                {currencyError == true ? (
                  <span
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-22px",
                      marginBottom: "20px",
                    }}
                  >
                    Currency is required.
                  </span>
                ) : (
                  <></>
                )}
                <Form.Label
                  htmlFor="name"
                  className={cx("custom-form-box", {
                    "focus-add": amount,
                  })}
                  style={{ width: "72%", marginLeft: "15%" }}
                >
                  <Form.Control
                    type="text"
                    id="amount"
                    name="amount"
                    value={amount}
                    // placeholder="Amount"
                    onChange={(e) => {
                      const amt1 = e.target.value;
                      if (
                        !amt1 ||
                        amt1.match(/^-\d{1,}(\.\d{0,2})?$/) ||
                        amt1.match(/^\d{1,}(\.\d{0,2})?$/)
                      ) {
                        setAmount(amt1);
                      }
                    }}
                    required
                    style={{ color: "white" }}
                  />
                  {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                  <span className="label_text">Amount</span>
                  <Form.Control.Feedback type="invalid">
                    Amount Required.
                  </Form.Control.Feedback>
                </Form.Label>

                <Form.Label
                  htmlFor="name"
                  className={cx("custom-form-box", {
                    "focus-add": exchangeRate,
                  })}
                  style={{ width: "72%", marginLeft: "15%" }}
                >
                  <Form.Control
                    type="text"
                    id="exchange_rate"
                    name="exchangeRate"
                    value={exchangeRate}
                    // placeholder="Exchange-Rate"
                    onChange={(e) => {
                      const exchPer1 = e.target.value;
                      if (!exchPer1 || exchPer1.match(/^\d{1,}(\.\d{0,2})?$/)) {
                        setExchangeRate(exchPer1);
                      }
                    }}
                    required
                    style={{ color: "white" }}
                  />
                  {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                  <span className="label_text">Exchange Rate</span>
                  <Form.Control.Feedback type="invalid">
                    Exchange-rate Required.
                  </Form.Control.Feedback>
                </Form.Label>
                <Form.Label
                  htmlFor="name"
                  className={cx("custom-form-box", {
                    "focus-add": comment,
                  })}
                  style={{ width: "72%", marginLeft: "15%" }}
                >
                  <Form.Control
                    type="text"
                    id="comment"
                    name="comment"
                    // placeholder="Remarks"
                    onChange={(e) => setComment(e.target.value)}
                    required
                    style={{ color: "white" }}
                  />
                  <span className="label_text">Comment</span>
                  {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                  {/* <Form.Control.Feedback type="invalid">
                   Remarks Required.
                </Form.Control.Feedback> */}
                </Form.Label>
                <Button
                  type="submit"
                  // disabled={alertPaymentSuccess}
                  variant=""
                  className="btn-gray"
                  style={{
                    width: "50%",
                    marginLeft: "25%",
                    marginTop: "-3%",
                    boxShadow: "none",
                  }}
                >
                  Save
                </Button>
                {alertPaymentSuccess ? (
                  <Snackbar
                    open={alertPaymentSuccess}
                    // autoHideDuration={4000}
                    onClose={() => setAlertPaymentSuccess(false)}
                    sx={{
                      marginLeft: "36%",
                      marginBottom: "40%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setAlertPaymentSuccess(false)}
                      severity="success"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      Payment added successfully
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}
                {spaceError ? (
                  <Snackbar
                    open={spaceError}
                    // autoHideDuration={4000}
                    onClose={() => setSpaceError(false)}
                    sx={{
                      marginLeft: "35%",
                      marginBottom: "38%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setSpaceError(false)}
                      severity="error"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      Please enter valid input
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}
                {alertSameError ? (
                  <Snackbar
                    open={alertSameError}
                    // autoHideDuration={4000}
                    onClose={() => setAlertSameError(false)}
                    sx={{
                      marginLeft: "35%",
                      marginBottom: "38%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setAlertSameError(false)}
                      severity="error"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      Sender and Reciever can not be the same
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}
                {alertAmountErr ? (
                  <Snackbar
                    open={alertAmountErr}
                    // autoHideDuration={4000}
                    onClose={() => setAlertAmountErr(false)}
                    sx={{
                      marginLeft: "35%",
                      marginBottom: "38%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setAlertAmountErr(false)}
                      severity="error"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      {parseFloat(amount) == 0
                        ? "Amount can not be 0"
                        : parseFloat(exchangeRate) == 0
                        ? "exchange rate can not be 0"
                        : ""}
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}
              </Form>
            </Modal.Body>
          </div>
        </Modal>
        <Modal
          show={showEditPopupPayment}
          // onHide={()=>setShowEditPopupPayment(false)}
          style={{ width: "28%", marginLeft: "35%" }}
        >
          <div style={{ border: "1px solid white" }}>
            <Modal.Header
              style={{ backgroundColor: "#222429", border: "none" }}
            >
              {/* <Modal.Title>Edit PortFolio Section</Modal.Title> */}
              <IconButton
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "white",
                }}
                onClick={() => setShowEditPopupPayment(false)}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#222429" }}>
              <Form
                className="custom-form"
                noValidate
                validated={validated}
                onSubmit={EditPaymentApi}
              >
                <h4>Update Payment</h4>
                <div style={{ marginBottom: "6%" }}>
                  <SelectSearchBox
                    label="Sender"
                    value={senderEdit}
                    err={senderErr}
                    options={entityData
                      .filter((i) => i.name != receiverEdit)
                      .sort((a, b) => -b.e_name.localeCompare(a.e_name))}
                    onChange={(e, k) => {
                      let res_type = entityData.filter((i) => i.name == k);
                      if (res_type.length > 0) {
                        setSenderErr(false);
                        setSenderEdit(k);
                        BalanceGetApiUpdate(res_type?.[0].name);
                        // setInvestCheck(k)
                      } else {
                        setSenderErr(true);
                        //  setInvestType('')
                        setSenderEdit("");
                      }
                    }}
                    onChange1={(e) => {
                      if (
                        entityData.filter((i) => i.name === e.target.value)
                          .length == 0
                      ) {
                        setSenderErr(false);
                        setSenderEdit(e.target.value);
                      } else {
                        setSenderErr(false);
                        setSenderEdit(e.target.value);
                      }
                    }}
                  />
                </div>
                <div style={{ marginBottom: "6%" }}>
                  <SelectSearchBox
                    label="Receiver"
                    value={receiverEdit}
                    err={recieverErr}
                    options={entityData
                      .filter((i) => i.name != senderEdit)
                      .sort((a, b) => -b.e_name.localeCompare(a.e_name))}
                    onChange={(e, k) => {
                      let res_type = entityData.filter((i) => i.name == k);
                      if (res_type.length > 0) {
                        setRecieverErr(false);
                        setReceiverEdit(k);
                        BalanceGetApiUpdate(res_type?.[0].name);
                        // setInvestCheck(k)
                      } else {
                        setRecieverErr(true);
                        //  setInvestType('')
                        setReceiverEdit("");
                      }
                    }}
                    onChange1={(e) => {
                      if (
                        entityData.filter((i) => i.name === e.target.value)
                          .length == 0
                      ) {
                        setRecieverErr(false);
                        setReceiverEdit(e.target.value);
                      } else {
                        setRecieverErr(false);
                        setReceiverEdit(e.target.value);
                      }
                    }}
                  />
                </div>
                {entityData.filter((i) => i.name == senderEdit).length > 0 &&
                entityData.filter((i) => i.name == receiverEdit).length > 0 &&
                balanceData.length > 0 ? (
                  <SelectBox
                    label="Exchange currency"
                    options={balanceData}
                    err={selectCurrencyErr}
                    value={selectedCurrency}
                    onChange={(e) => {
                      setSelectedCurrency(e.target.value);
                    }}
                  />
                ) : (
                  <></>
                )}
                {/* <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': paymentEdit
                })}
                style={{ width: '72%', marginLeft: '15%' }}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="payment"
                  placeholder="name"
                  onChange={(e) => setPaymentEdit(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
             
                <Form.Control.Feedback type="invalid">
                   Name Required.
                </Form.Control.Feedback>
              </Form.Label> */}
                {/* <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': senderEdit
                })}
                style={{ width: '72%', marginLeft: '15%' }}
              >
                <Form.Control
                  type="text"
                  id="sender"
                  name="sender"
                  value={senderEdit}
                  // placeholder="Sender"
                  onChange={(e) => setSenderEdit(e.target.value)}
                  required
                  style={{ color: 'white' }}
                /> */}
                {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                {/* <span className='label_text'>Sender</span>
                <Form.Control.Feedback type="invalid">
                   Sender Required.
                </Form.Control.Feedback>
              </Form.Label> */}
                {/* <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': receiverEdit
                })}
                style={{ width: '72%', marginLeft: '15%' }}
              >
                <Form.Control
                  type="text"
                  id="receiver"
                  name="receiver"
                  value={receiverEdit}
                  placeholder="Receiver"
                  onChange={(e) => setReceiverEdit(e.target.value)}
                  required
                  style={{ color: 'white' }}
                /> */}
                {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                {/* <span className='label_text'>Receiver</span>
                <Form.Control.Feedback type="invalid">
                   Receiver Required.
                </Form.Control.Feedback>
              </Form.Label> */}
                <Autocomplete
                  id="grouped-demo"
                  value={options.find(
                    (option) => option.title === currencyEdit
                  )}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.title}
                  classes={{
                    option: styles.option,
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{ background: "rgb(31, 33, 37)", color: "white" }}
                    >
                      {children}
                    </Paper>
                  )}
                  onChange={(e, k) => {
                    if (k == null) {
                      setCurrencyError(false);
                      setCurrencyEdit(null);
                    } else {
                      setCurrencyError(false);
                      setCurrencyEdit(k.title);
                    }
                  }}
                  style={{
                    fill: "white",
                    boxShadow: "none",
                    fontSize: "14px",
                    borderRadius: "30%",
                    marginLeft: "15%",
                    marginBottom: "1em",
                  }}
                  sx={{
                    // width: '200px',
                    // height: '32px',
                    ".MuiButtonBase-root": {
                      color: "white",
                    },
                    ".MuiOutlinedInput-root": {
                      borderRadius: "4px",
                      width: "84%",
                      height: "47px",
                      // backgroundColor: '#fff',
                      fontSize: "14px",
                      border: "1px solid grey !important",
                      marginBottom: "1em",
                      paddingTop: "12px !important",
                    },
                    ".MuiInputBase-input": {
                      height: "1rem",
                    },
                    ".MuiInputLabel-root": {
                      top: "1px",
                      left: "-8px",
                      background: "#1f2125",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Currency" />
                  )}
                  renderGroup={(params) => (
                    <li>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                />
                {currencyError == true ? (
                  <span
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-22px",
                      marginBottom: "20px",
                    }}
                  >
                    Currency is required.
                  </span>
                ) : (
                  <></>
                )}
                <Form.Label
                  htmlFor="name"
                  className={cx("custom-form-box", {
                    "focus-add": amountEdit,
                  })}
                  style={{ width: "72%", marginLeft: "15%" }}
                >
                  <Form.Control
                    type="text"
                    id="amount"
                    name="amount"
                    value={amountEdit}
                    placeholder="Amount"
                    onChange={(e) => {
                      const amt2 = e.target.value;
                      if (!amt2 || amt2.match(/^\d{1,}(\.\d{0,2})?$/)) {
                        setAmountEdit(amt2);
                      }
                    }}
                    required
                    style={{ color: "white" }}
                  />
                  {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                  <span className="label_text">Amount</span>
                  <Form.Control.Feedback type="invalid">
                    Amount Required.
                  </Form.Control.Feedback>
                </Form.Label>

                <Form.Label
                  htmlFor="name"
                  className={cx("custom-form-box", {
                    "focus-add": exchangeRateEdit,
                  })}
                  style={{ width: "72%", marginLeft: "15%" }}
                >
                  <Form.Control
                    type="text"
                    id="exchange_rate"
                    name="exchangeRate"
                    value={exchangeRateEdit}
                    placeholder="Exchange-Rate"
                    onChange={(e) => {
                      const exchPer2 = e.target.value;
                      if (!exchPer2 || exchPer2.match(/^\d{1,}(\.\d{0,2})?$/)) {
                        setExchangeRateEdit(exchPer2);
                      }
                    }}
                    required
                    style={{ color: "white" }}
                  />
                  {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                  <span className="label_text">Exchange Rate</span>
                  <Form.Control.Feedback type="invalid">
                    Exchange-rate Required.
                  </Form.Control.Feedback>
                </Form.Label>
                <Form.Label
                  htmlFor="name"
                  className={cx("custom-form-box", {
                    "focus-add": commentEdit,
                  })}
                  style={{ width: "72%", marginLeft: "15%" }}
                >
                  <Form.Control
                    type="text"
                    id="comment"
                    name="comment"
                    value={commentEdit}
                    placeholder="Remarks"
                    onChange={(e) => setCommentEdit(e.target.value)}
                    required
                    style={{ color: "white" }}
                  />
                  {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                  <span className="label_text">Comment</span>
                  {/* <Form.Control.Feedback type="invalid">
                   Remarks Required.
                </Form.Control.Feedback> */}
                </Form.Label>

                <Button
                  type="submit"
                  variant=""
                  className="btn-gray"
                  style={{
                    width: "50%",
                    marginLeft: "25%",
                    marginTop: "-3%",
                    boxShadow: "none",
                  }}
                >
                  Save
                </Button>
                {alertPaymentEditSuccess ? (
                  <Snackbar
                    open={alertPaymentEditSuccess}
                    // autoHideDuration={4000}
                    onClose={() => setAlertPaymentEditSuccess(false)}
                    sx={{
                      marginLeft: "36%",
                      marginBottom: "40%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setAlertPaymentEditSuccess(false)}
                      severity="success"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      Payment updated successfully
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}
                {alertAmountErr ? (
                  <Snackbar
                    open={alertAmountErr}
                    // autoHideDuration={4000}
                    onClose={() => setAlertAmountErr(false)}
                    sx={{
                      marginLeft: "35%",
                      marginBottom: "38%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setAlertAmountErr(false)}
                      severity="error"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      {parseFloat(amountEdit) == 0
                        ? "Amount can not be 0"
                        : parseFloat(exchangeRateEdit) == "0"
                        ? "exchange rate can not be 0"
                        : ""}
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}

                {alertSameError ? (
                  <Snackbar
                    open={alertSameError}
                    // autoHideDuration={4000}
                    onClose={() => setAlertSameError(false)}
                    sx={{
                      marginLeft: "35%",
                      marginBottom: "38%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setAlertSameError(false)}
                      severity="error"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      Sender and Reciever can not be the same
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}
                {spaceError ? (
                  <Snackbar
                    open={spaceError}
                    // autoHideDuration={4000}
                    onClose={() => setSpaceError(false)}
                    sx={{
                      marginLeft: "35%",
                      marginBottom: "38%",
                      width: "25%",
                    }}
                  >
                    <Alert
                      onClose={() => setSpaceError(false)}
                      severity="error"
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      Please enter valid input
                    </Alert>
                  </Snackbar>
                ) : (
                  <></>
                )}
              </Form>
            </Modal.Body>
          </div>
        </Modal>
        <Modal
          show={showPopupDeletePayment}
          onHide={handleClose}
          style={{
            width: "30rem",
            marginTop: "17rem",
            overflow: "hidden",
            marginLeft: "35%",
            backgroundColor: "#222429",
            height: "8rem",
            border: "1px solid grey",
            borderRadius: "15px",
          }}
        >
          <Modal.Header style={{ backgroundColor: "#222429", border: "none" }}>
            <Modal.Title
              style={{
                color: "white",
                fontSize: "16px",
                marginTop: "-5%",
                marginLeft: "11%",
              }}
            >
              Are you sure you want to Delete this Payment ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer
            style={{
              backgroundColor: "#222429",
              borderTop: "none",
              paddingRight: "34%",
              marginTop: "-4%",
            }}
          >
            <Button
              variant="success"
              style={{ width: "25%", backgroundColor: "#006400" }}
              onClick={() => {
                DeletePaymentApi();
                // handleDeleteUpdate()
                handleClose();
              }}
            >
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={() => setShowPopupDeletePayment(false)}
              style={{ width: "25%", backgroundColor: "#b30000" }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <Modal
        show={alertNoRecord}
        onHide={()=>setAlertNoRecord(false)}
        style={{
          width: '14rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '45%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '18px',
              marginTop: '-13%',
              marginLeft: '15%',
              fontWeight:'bold'
            }}
          >
            No record found.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            borderTop: 'none',
            paddingRight: '34%',
            paddingTop:'0%',
            // marginTop: '-10%',
            width:'19.5em',
            justifyContent:'center'
          }}
        >
          <button
          //  variant="success"
           className='no-record-found'
          
            onClick={() => {
              setAlertNoRecord(false)
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal> */}
      </Container>
    </React.Fragment>
  );
}
export default Payments;

import React, { useEffect, useState } from 'react'
import { AlertBox } from '../helpers/components/AlertBox'
import { Button, Form, Modal } from 'react-bootstrap'
import { Autocomplete, IconButton, Paper, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useApi from '../hooks/useApi'
import { getUserId } from '../helpers/common'
import _ from 'lodash'

export const AddModal = ({ visible = false, portfolios = [], setAddModalVisible = () => {}, aspect = 'add', portfolio = {}, refreshTable = () => {} }) => {
  const [forms, setForms] = useState({})
  const [trigger, setTrigger] = useState()
  const [formErrors, setFormErrors] = useState({})
  const [alert, setAlert] = useState({
    visible: false,
    type: 'success',
    msg: ''
  })

  const handleFormSubmit = (e) => {
    e.preventDefault()

    setForms({
      ...forms,
      userId: getUserId(),
      id: aspect === 'add' ? 0 : portfolio?.id,
      batchId: '-'
    })

    validateForm()
  }

  const validateForm = () => {
    setFormErrors({
      portfolioId: !forms.portfolioId || forms.portfolioId === '' ? 'Portfolio is required' : undefined,
      acctNo: !forms.acctNo || forms.acctNo === '' ? 'Account No is required' : undefined,
      acctName: !forms.acctName || forms.acctName === '' ? 'Account Name is required' : undefined,
      acctType: !forms.acctType || forms.acctType === '' ? 'Account Type is required' : undefined,
      bankName: !forms.bankName || forms.bankName === '' ? 'Bank Name is required' : undefined,
      region: !forms.region || forms.region === '' ? 'Region is required' : undefined,
      currency: !forms.currency || forms.currency === '' ? 'Currency is required' : undefined,
      boCode: !forms.boCode || forms.boCode === '' ? 'BO Code is required' : undefined,
      boType: !forms.boType || forms.boType === '' ? 'BO Type is required' : undefined,
      boName: !forms.boName || forms.boName === '' ? 'BO Name is required' : undefined,
      prevBalance: !forms.prevBalance || forms.prevBalance === '' || isNaN(forms.prevBalance) ? 'Previous Balance has to be a number' : undefined,
      currentBalance: !forms.currentBalance || forms.currentBalance === '' || isNaN(forms.currentBalance) ? 'Current Balance has to be a number' : undefined
    })
  }

  function formatDate (date) {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [year, month, day].join('-')
  }

  const response = useApi({
    method: 'POST',
    options: {
      ...forms
    },
    trigger,
    url: `${process.env.REACT_APP_BASE_URL}/treasure/save`
  })

  useEffect(() => {
    if (!_.isEmpty(formErrors)) {
      if (!_.some(formErrors, (x) => x !== undefined)) {
        setTrigger(+new Date())
      }
    }
  }, [formErrors])

  useEffect(() => {
    if (aspect === 'add' && visible === true) {
      setForms({
        ...forms,
        lastUpdate: new Date(Date.now()).toISOString().split('T')[0]
      })
    } else {
      setForms([])
    }
    setTrigger()
  }, [visible])

  useEffect(() => {
    if (response.response) {
      refreshTable()
      setAlert({
        visible: true,
        msg: `Record ${aspect == 'edit' ? 'updated' : 'added'} successfully!`,
        type: 'success'
      })
      setForms([])
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
        setAddModalVisible({
          visible: false,
          aspect: ''
        })
      }, 2000)
    }

    if (response.error) {
      setAlert({
        visible: true,
        msg: response.error,
        type: 'error'
      })
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
      }, 5000)
    }
  }, [response?.response, response?.error])

  useEffect(() => {
    if (!_.isEmpty(portfolio)) {
      console.log(portfolio)
      setForms(portfolio)
    }
  }, [portfolio])

  return (
    <>
    <Modal
      onHide={() => { setAddModalVisible({ visible: false, aspect: '' }) }}
      show={visible}
      style={{
        width: '29%',
        marginLeft: '35%',
        overflowY: 'auto'
      }} >
      <div style={{ border: '1px solid white' }}>
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <IconButton
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: 'white'
            }}
            onClick={() => { setAddModalVisible({ visible: false, aspect: '' }) }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#222429' }}>
            <Form
                className="custom-form"
                noValidate
                validated={false}
                onSubmit={handleFormSubmit}
              >
                <h4>
                {aspect == 'edit' ? 'Update' : 'Add'} Treasury
                </h4>
              <Autocomplete
                  disabled={aspect === 'edit'}
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={portfolios.find((p) => { return p.id === forms.portfolioId })?.name || null}
                  options={portfolios?.map((e) => e.name)}
                  onChange={(e, k) => { setForms({ ...forms, portfolioId: portfolios.find((p) => { return p.name == k }).id }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Portfolios"
                  />
                  )}
              />
              {formErrors.portfolioId
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.portfolioId}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.acctNo || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, acctNo: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Account No."
                      onChange={(e) => { setForms({ ...forms, acctNo: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.acctNo
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.acctNo}</span>
                : <></>}

             <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.acctType || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, acctType: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }
                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Account Type"
                      onChange={(e) => { setForms({ ...forms, acctType: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.acctType
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.acctType}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.acctName || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, acctName: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Account Name"
                      onChange={(e) => { setForms({ ...forms, acctName: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.acctName
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.acctName}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.bankName || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, bankName: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Bank Name"
                      onChange={(e) => { setForms({ ...forms, bankName: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.bankName
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.bankName}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.region || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, region: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Region"
                      onChange={(e) => { setForms({ ...forms, region: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.region
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.region}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.currency || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, currency: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Currency"
                      onChange={(e) => { setForms({ ...forms, currency: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.currency
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.currency}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.boCode || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, boCode: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }
                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="BO Code"
                      onChange={(e) => { setForms({ ...forms, boCode: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.boCode
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.boCode}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.boType || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, boType: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }
                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="BO Type"
                      onChange={(e) => { setForms({ ...forms, boType: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.boType
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.boType}</span>
                : <></>}

            <Autocomplete
                  disabled={aspect === 'edit'}
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.boName || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, boName: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }
                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="BO Name"
                      onChange={(e) => { setForms({ ...forms, boName: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.boName
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.boName}</span>
                : <></>}

            <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.prevBalance || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, prevBalance: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Prev Balance"
                      onChange={(e) => { setForms({ ...forms, prevBalance: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.prevBalance
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.prevBalance}</span>
                : <></>}

            <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.currentBalance || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, currentBalance: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Current Balance"
                      onChange={(e) => { setForms({ ...forms, currentBalance: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.currentBalance
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.currentBalance}</span>
                : <></>}

            <LocalizationProvider className='p-2' dateAdapter={AdapterDayjs} style={{ transform: 'none' }}>
                <DatePicker
                    label="Last Update"
                    className='p-2'
                    inputFormat="DD/MM/YYYY"
                    autoOk
                    value={forms.lastUpdate}
                    onChange={(newValue) => {
                      console.log(newValue.$d)
                      setForms({ ...forms, lastUpdate: formatDate(newValue.$d) })
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{
                          borderRadius: '15px',
                          color: 'white',
                          width: '81%',
                          marginTop: '2%',
                          marginLeft: '10%'
                        }}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            borderRadius: '4px',
                            width: '90%',
                            height: '50px',
                            // backgroundColor: '#fff',
                            fontSize: '14px',
                            border: '1px solid grey !important'

                          },
                          '.MuiInputBase-input': {
                            height: '0rem'
                          },
                          '.MuiSvgIcon-root': {
                            fill: 'white'
                          },
                          '.MuiInputLabel-root': {
                            top: '9px',
                            left: '-8px',
                            fontSize: '14px',
                            background: '#1f2125'
                          }
                        }}
                    />
                    )}
                />
                </LocalizationProvider>

            <Button
              type="submit"
              variant=""
              className="btn-gray"
              style={{
                width: '50%',
                marginLeft: '25%',
                boxShadow: 'none',
                color: 'white',
                marginBottom: '50px',
                marginTop: '10px'
              }}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </div>
    </Modal>

    <AlertBox type={alert.type} show={alert.visible} text={alert.msg}/>
    </>
  )
}

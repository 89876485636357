import React, { useState, useEffect, useRef } from 'react'

import CommonTable from '../../common/CommonTable/CommonTable'
import { Link, useNavigate } from 'react-router-dom'
import cx from 'classnames'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import axios from 'axios'
import SearchBox from '../../common/SearchBox/SearchBox'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Spinner from '../../common/spinner'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import '../../common/Modal.css'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, TextField } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import '../DashboardAdmin/DashboardAdmin.css'
import Dropdown from 'react-bootstrap/Dropdown'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined'
import '../styling.css'
import Snackbar from '@mui/material/Snackbar'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core/styles'
import { ref } from 'yup'
import { postAsync } from '../../common/HttpClient';
const useStyles = makeStyles({
  paper: {
    background: 'rgb(31, 33, 37) !important',
    color: 'white !important'
    // So we see the popover when it's empty.
    // It's most likely on issue on userland.
  },
  option: {
    '&:hover': {
      backgroundColor: 'grey !important',
      color: 'white !important'
    }
  }

  //  },
})

function MainAdmin () {
  const styles = useStyles()

  const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: 'green'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red'
      },
      '&:hover fieldset': {
        borderColor: 'yellow'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green'
      }
    }
  })
  const getId = localStorage.getItem('sub_Id')
  const roleId = localStorage.getItem('role')?.split(',') ?? []

  // <DeleteOutlineOutlinedIcon style={{ color: "#b30000" }} />;
  const [validated, setValidated] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertNoRecord, setAlertNoRecord] = useState(false)
  const [loading, setLoading] = useState(true)
  const inputRef = useRef(null)
  const [alertPort, setAlertPort] = useState(false)
  const navigate = useNavigate()

  const handleClosePort = () => {
    setAlertPort(false)
  }
  const [credentialsInfo, setCredentialsInfo] = useState({
    user_id: getId
  })
  const [result4, setResult4] = useState([])
  const [dataRow, setDataRow] = useState([])
  const [search, setSearch] = useState([])
  const [sea, setSea] = useState('')
  const [portId, setPortId] = useState('')
  const [alertDeleteError, setAlertDeleteError] = useState(false)
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [isWalletConnected, setIsWalletConnected] = useState(false)
  const [result, setResult] = useState([])
  const [result2, setResult2] = useState([])
  const [result21, setResult21] = useState([])
  const [result3, setResult3] = useState([])
  const [result5, setResult5] = useState([])
  const [port_wal, setport_wal] = useState([])
  const [Port, setResultPort] = useState([])
  const [name, setname] = useState('')
  const [blankSpaceError, setBlankSpaceError] = useState(false)
  const [p_name, setp_name] = useState('')
  const [editPort, setEditPort] = useState('')
  const [alertAl, setAlertAl] = useState(false)
  const [alertSp, setAlertSp] = useState(false)
  const [showEp, setShowEp] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const [resultAccountant, setResultAccount] = useState([])
  const [showAccountant, setShowAccountant] = useState(false)
  const [accoutantName, setAccountantName] = useState('')
  const [portfolioId, setPortfolioId] = useState('')
  const [portfolioName, setPortfolioName] = useState('')
  const [alertAccountant, setAlertAccountant] = useState(false)
  const [isOpenDeleteAccountant, setIsDeleteAccountant] = useState(false)
  const [accountantId, setAccountantId] = useState([])
  const [blankAccountantError, setBlankAccountantError] = useState(false)
  const [resultAccountantData, setResultAccountantData] = useState([])
  const [portfolios, setPortfolios] = useState([])
  const [portfolioSummary, setPortfolioSummary] = useState({
    totalInvAmt: 0,
    grandTotal: 0,
    totalExchangeAmt: 0,
    totalWalletAmt: 0,
    totalTreasuryAmt: 0
  })
  const base_url = process.env.REACT_APP_BASE_URL
  const [spError, setSpError] = useState(false);
  const [respMessage, setRespMessage] = useState('');

  const handleAccountant = (row) => {
    setPortfolioId(row.portfolioId)
    setPortfolioName(row.portfolioName)
    setShowAccountant(true)
  }

  const handleDropDown = () => {
    if (dropdown === true) {
      setDropdown(false)
      // console.log(dropdown)
    } else {
      setDropdown(true)
      // console.log(dropdown)
    }
  }

  const handleDeleteAccountant = async (accountant_id, portfolio_id) => {
    setAccountantId(portfolio_id + accountant_id)
    setIsDeleteAccountant(true)
  }

  // delete accountant
  const DeleteAccountant = async () => {
    await axios
      .delete(
        `${base_url}/delete_accountant_portfolio`,
        {
          params: {
            portfolio_account_id: accountantId
          }
        }
      )
      .then((response) => {
        setIsDeleteAccountant(false)
      })
    getPortfolios()
  }

  // add accountant with portfolio api
  const handleSubmitAccountant = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else if (accoutantName == '' || accoutantName == null) {
      setBlankAccountantError(true)
    } else if (
      resultAccountant?.filter(
        (i) =>
          i.accountant_name === accoutantName && i.portfolio_id == portfolioId
      ).length > 0
    ) {
      setAlertAl(true)
      setTimeout(() => {
        setAlertAl(false)
      }, 3000)
    } else {
      const accountantId = resultAccountantData?.filter(
        (i) => i.username === accoutantName
      )
      const config = {
        method: 'post',
        url: `${base_url}/admin/add_portfolio_manager`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          user_id: getId,
          portfolio_id: portfolioId,
          accountant_id: accountantId?.[0]?.user_id,
          user_role: 'admin'
        }
      }
      await axios(config).then(function (response) {
        // accountant();
        setAlertAccountant(true)
        getPortfolios()
        setTimeout(() => {
          setAccountantName('')
          setAlertAccountant(false)
          setShowAccountant(false)
        }, 3000)
      })
    }
  }

  const getCryptoBalance = async (addr, cryptoType) => {
    let result = {}
    let urlSwitch = ''

    if (cryptoType === 'ETH') urlSwitch = 'get_filtered_eth_balance'
    if (cryptoType === 'BTC') urlSwitch = 'get_filtered_btc_balance'
    if (cryptoType === 'TRON') urlSwitch = 'get_filtered_tron_balance'

    await axios
      .get(`${base_url}/${urlSwitch}/${addr}`)
      .then((response) => {
        result = response.data
      }).catch(err => {
        console.log(err)
      })

    return result
  }

  const repResData = async (data) => {
    for (const portfolio of data) {
      for (const addrlist of portfolio.walletsdata) {
        let wCollectBal = 0

        for (const addr of addrlist.addresseslistdata) {
          const ret = await getCryptoBalance(addr.address_id, addr.address_type)

          if (!ret) {
            continue
          }

          if (addr.address_type === 'ETH') {
            wCollectBal += (ret.totalBalance)
          } else {
            wCollectBal += (ret.totalBalance * ret.exchangeRate)
          }
        }
        addrlist.total_bal = wCollectBal
      }
    }

    return data
  }

  // list of accountant api
  const accountantData = async () => {
    if (roleId.includes('superadmin') || roleId.includes('admin')) {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/accontant_list`, {
          params: {
            user_role: 'admin'
          }
        })
        .then(function (response) {
        // console.log(response.data)
          setResultAccountantData(response.data)
        })
    }
  }

  let acdata = []
  // list all accountant with portfolio api
  const accountant = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/all_accountant_and_portfolio`)
      .then(function (response) {
        const p_data = response.data
        acdata = p_data

        setResultAccount(p_data)
      })
  }

  // portfolio details api
  const allportfolioUser = async () => {
    setLoading(true)
    setSea('')
    await axios
      .get(`${base_url}/all_portfolios_details`, {
        params: {
          user_id: getId
        }
      })
      .then(async (response) => {
        const rs = response.data

        if (!rs) {
          setLoading(false)
          return
        }

        for (const a of rs) {
          const total = 0
          a.obj2 = []

          for (const b of a.walletsdata) {
            b.total_bal = parseFloat(b.totalwalletbalance) + parseFloat(b.totalwalletbalance_btc) + parseFloat(b.totalwalletbalance_tron)
          }

          var holder = {}

          a.total_investments
            .filter((i) => i.status === 'Active')
            .forEach(function (d) {
              if (holder.hasOwnProperty(d.investment_name)) {
                holder[d.investment_name] =
                  parseFloat(holder[d.investment_name]) +
                  parseFloat(d.buy_value)
              } else {
                holder[d.investment_name] = parseFloat(d.buy_value)
              }
            })

          for (const prop in holder) {
            a.obj2.push({ investment_name: prop, buy_value: holder[prop] })
          }
        }

        const temp = rs.map((record) => {
          return { ...record, total_investments: record.obj2 }
        })

        const arr = []
        if (rs !== 'Error // Cannot find portfolio list') {
          if (roleId.includes('accountant')) {
            const a = acdata?.filter((i) => i.accountant_id === getId)
            a?.forEach((el) => {
              const m = temp?.filter((j) => j.portfolio_id == el.portfolio_id)
              if (m.length > 0) {
                const me = { ...m }
                arr.push(Object.values(me)[0])
              }
            })

            const temp1 = await repResData(arr)
            setPortfolios(temp1.map(value => value.portfolio_id))
            setResult3(temp1)
            setLoading(false)
          } else {
            const temp1 = await repResData(temp)
            setPortfolios(temp1.map(value => value.portfolio_id))
            setResult3(temp1)
            setLoading(false)
          }
        } else {
          setPortfolios([])
          setResult3([])
          setLoading(false)
        }
      })
  }

  // portfolio user data api
  const portfolio_data = async () => {
    await axios
      .get(`${base_url}/all_portfolio_users_data`, {
        params: {
          user_role: roleId,
          portfolio_ids: portfolios
        }
      })
      .then((response) => {
        const rs1 = response.data
        if (rs1 !== 'error') {
          setResult5(rs1)
        } else {
          setResult5([])
        }
      })
  }

  const getPortfolios = async () => {
    /// wallet
    await axios
      .post(`${base_url}/wallet`, { userId: getId })
      .then((response) => {
        const rs = response.data.results
        setPortfolios(rs.data)
        setPortfolioSummary(rs.summary)
        setLoading(false)
      })
  }

  useEffect(async () => {
    await accountantData()
    await getPortfolios()
  }, [])

  for (const r of result3) {
    r.partydata1 = []
    r.portfolio = []
    r.p_data = []
    const x = result5?.filter((i) => i.portfolio_id === r.portfolio_id)
    const y = resultAccountant?.filter(
      (i) => i.portfolio_id === r.portfolio_id
    )
    r.portfolio.push(y)
    r.partydata1.push(x)
    if (x?.[0]?.partydata.length > 0) {
      r.p_data.push(...x?.[0]?.partydata)
    }
  }

  // update portfolio name
  const handleSubmitEditForm = async (e) => {
    e.preventDefault()
    setValidated(true)
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else if (p_name.trim() == '') {
      //  console.log('not accept blank space')
      setBlankSpaceError(true)
      setTimeout(() => {
        setBlankSpaceError(false)
      }, 3000)
    } else {
          var payload = {
            portfolio_name: p_name,
            portfolio_id: editPort,
            userId: getId
          };
          var resp = await postAsync(`EditPortfolioName_New`, payload);
    
          if (!resp.success) {
            setRespMessage(resp.message);
            setSpError(true);
            setTimeout(() => {
                setRespMessage('');
                setSpError(false);
            }, 3000)                
        }
        else {          
          setAlert(true)
          setTimeout(() => {
            setAlert(false)
            setShowEp(false)
          }, 3000)
          getPortfolios();
          setValidated(false)          
        }
    }
  }

  const handleClose = () => {
    setSea('')
    // inputRef.current.value = null;
    setSea('')
    setShow(false)
    setShow1(false)
    setShow2(false)
    setShowEp(false)
  }

  const handleShow = (e) => {
    setAlertPort(false)
    setShow(true)
    setValidated(false)
  }

  const handleShow1 = (row, rowIndex) => {
    setPortId(row.portfolioId)
    setShow1(true)
  }

  // delete portfolio name
  const handleDeleteUpdate = async () => {
    const del_port = result3.filter((i) => i.portfolioId == portId)
    if (
      del_port[0]?.total_investments.length > 0 ||
      del_port[0]?.walletsdata.length > 0 ||
      del_port[0]?.total_exchange.length > 0
    ) {
      setAlertDeleteError(true)
      setTimeout(() => setAlertDeleteError(false), 3000)
    } else {
      await axios
        .delete(`${base_url}/deletePortfolioData`, {
          params: { type: 'CRYPTO', portfolio_id: portId, userId: getId }
        })
        .then((response) => {
          getPortfolios()
          if (response.data == 'PORTFOLIO DELETED') {
            setSearch([])
            setSea('')
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    }
  }

  const handleShowEp = (row) => {
    console.log(row);
    setEditPort(row.portfolioId)
    setp_name(row.portfolioName)
    setShowEp(true)
    setValidated(false)
  }

  // create portfolio api
  const handleSubmitForm = async (e) => {
    setValidated(true)
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else if (name.trim() == '') {
      setBlankSpaceError(true)
      setTimeout(() => {
        setBlankSpaceError(false)
      }, 3000)
    } else {
      // console.log(m)
      if (name !== ' ') {
        const config = {
          method: 'post',
          url: `${base_url}/admin/createPortfolio`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            portfolio_name: name,
            user_id: getId,
            user_role: 'admin',
            portfolio_type: 'CRYPTO'
          }
        }
        await axios(config)
          .then(function (response) {
            // console.log(response)
            if (response.data == 'ALREADY EXIST') {
              // console.log('already exist')
              setAlertAl(true)
              setTimeout(() => {
                setAlertAl(false)
              }, 2000)
            } else {
              getPortfolios()
              setAlertPort(true)
              setTimeout(() => {
                setAlertPort(false)
                setShow(false)
              }, 3000)
            }
            setValidated(true)
          })
          .catch(function (error) {
            // console.log(error)
          })
      } else {
        setAlertSp(true)
        setTimeout(() => {
          setAlertSp(false)
          setShow(false)
        }, 2000)
      }
    }
  }

  const columns = [
    {
      dataField: 'portfolioName',
      text: 'Name',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        let total = 0
        row.wallets?.map((wallet) => {
          return wallet.addresses.map(address => {
            total += address.usd_Balance
            return total
          })
        })
        row.investments?.map((e) => {
          total += parseFloat(e.buy_value) * parseFloat(e.exchange_rate ?? 1)
        })

        row?.exchanges.map((exchange) => {
          total += exchange.balance
        })

        return (
          <div>
            <div style={{ width: '200px' }} key={row.portfolioId}>
              <Tooltip title={'view portfolio'}>
                <span
                  onClick={() =>
                    navigate(`/PMS/Admin/Assets/${row.portfolioId}`, {
                      state: { id: 1, data: row }
                    })
                  }
                  className="namePortData"
                  style={{ cursor: 'pointer' }}
                >
                  {row.portfolioName.charAt(0).toUpperCase() +
                    row.portfolioName.slice(1)}
                </span>
              </Tooltip>
            </div>
            {
            <span style={{ fontWeight: 'bold' }}>{
              '$' + total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              </span>
          }
            </div>
        )
      },
      footer: 'Total',
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    },
    // Wallet Column
    {
      dataField: 'wallets',
      text: 'Wallets',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        let totalUsdBalance = 0
        return (

          <div>
          <ul style={{ marginLeft: '-3em' }}>
            {row.wallets?.map((wallet) => {
              return wallet.addresses.map((address) => {
                totalUsdBalance += address.usd_Balance
                return (
                <li
                key={address.address}
                style={{
                  cursor: 'pointer',
                  color: '#FFC107',
                  whiteSpace: 'nowrap'
                }}
                onClick={() =>
                  navigate('/PMS/Admin/Transactions', {
                    state: {
                      id: 1,
                      // data3: { w_id: address.walletId, p_id: row.portfolioId }
                      data: { portfolioId: row.portfolioId, walletId: wallet.walletId, addresses: wallet.addresses },
                      value: address.address
                    }
                  })
                }
              >
                {address.addressDesc.length > 50
                  ? (
                  <Tooltip title={'view wallet'}>
                    <span
                      className="namePortData"
                      style={{ whiteSpace: 'break-spaces' }}
                    >
                      {' '}
                      {address.addressDesc.charAt(0).toUpperCase() +
                        address.addressDesc.slice(1).toLowerCase()}
                      -
                      {address.usd_Balance >= 0
                        ? ' ' + '$' + address.usd_Balance
                          .toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                          .replace(/\.00$/, '').replace('-', '')
                        : '  ' + '-' + '$' + address.usd_Balance
                          .toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                          .replace(/\.00$/, '').replace('-', '')
                      }
                    </span>
                  </Tooltip>
                    )
                  : (
                  <Tooltip title={'view wallet'}>
                    <span className="namePortData">
                      {' '}
                      {address.addressDesc.charAt(0).toUpperCase() +
                        address.addressDesc.slice(1).toLowerCase()}
                      -
                      {address.usd_Balance >= 0
                        ? ' ' + '$' + address.usd_Balance
                          .toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                          .replace(/\.00$/, '').replace('-', '')
                        : '  ' + '-' + '$' + address.usd_Balance
                          .toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                          .replace(/\.00$/, '').replace('-', '')
                      }
                    </span>
                  </Tooltip>
                    )}
              </li>
                )
              })
            })}
          </ul>
          {
            <span style={{ fontWeight: 'bold' }}>{
              (totalUsdBalance >= 0
                ? '$'
                : '- $') + totalUsdBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }</span>
          }
          </div>
        )
      },
      footer: (columnData, column, columnIndex) => {
        return (
        <div>
          {
            '$' + portfolioSummary.totalWalletAmt
              .toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              .replace(/\.00$/, '').replace('-', '')
          }
        </div>
        )
      },
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    },
    {
      dataField: 'investments',
      text: 'Investment',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        let totalInvest = 0
        return (
          <div>
          <ul style={{ marginLeft: '-3em' }}>
            {row.investments?.map((e) => {
              totalInvest += parseFloat(e.buy_value) * parseFloat(e.exchange_rate ?? 1)
              return (
              <li
                key={e.investment_id}
                style={{
                  cursor: 'pointer',
                  color: '#FFC107',
                  whiteSpace: 'nowrap'
                }}
                onClick={() =>
                  navigate('/PMS/TransactionInvestmentHistory', {
                    state: {
                      id: 1,
                      dataI: row.portfolioName,
                      inv_name: e.investment_name
                    }
                  })
                }
              >
                <Tooltip title={'view investment'}>
                  <span className="namePortData">
                    {e.investment_name.charAt(0).toUpperCase() +
                      e.investment_name.slice(1)}{' '}
                    - $
                    {(parseFloat(e.buy_value) * parseFloat(e.exchange_rate ?? 1)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </Tooltip>
              </li>
              )
            })}
          </ul>
          {
            <span style={{ fontWeight: 'bold' }}>{
              (totalInvest >= 0
                ? '$'
                : '- $') + totalInvest.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }</span>
          }</div>

        )
      },
      footer: (columnData, column, columnIndex) => {
        return (
        <div>
          {
            '$' + portfolioSummary.totalInvAmt
              .toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              .replace(/\.00$/, '').replace('-', '')
          }
        </div>
        )
      },
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    },
    {
      dataField: 'exchanges',
      text: 'Exchange',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const t = 0
        let totalExchange = 0
        return (
          <div>
          <ul style={{ marginLeft: '-3em' }}>
            {row?.exchanges.map((exchange) => {
              totalExchange += exchange.balance
              return (
              <li
                style={{
                  cursor: 'pointer',
                  color: '#FFC107',
                  whiteSpace: 'nowrap'
                }}
                // onClick={() =>
                //   navigate("/PMS/TransactionExchangeHistory", {
                //     state: {
                //       id: 1,
                //       data: row.portfolioId,
                //       e_name: exchange.exchange_name,
                //     },
                //   })
                // }
                key={exchange.exchange_apikey}
              >
                {/* <Tooltip title={"view exchange"}> */}
                  <span className="namePortData">
                    {exchange.exchange_name.charAt(0).toUpperCase() +
                      exchange.exchange_name.slice(1)}{' '}
                    - {' '}$
                    {parseFloat(exchange.balance.toFixed(2))
                      .toLocaleString()
                      .replace(/\.00$/, '')}
                  </span>
                {/* </Tooltip> */}
              </li>
              )
            })}
          </ul>
          {
            <span style={{ fontWeight: 'bold' }}>{
              (totalExchange >= 0
                ? '$'
                : '- $') + totalExchange.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }</span>
          }
          </div>
        )
      },
      footer: (columnData, column, columnIndex) => {
        return (
          <div>
          {
            '$' + portfolioSummary.totalExchangeAmt
              .toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              .replace(/\.00$/, '').replace('-', '')
          }
          </div>
        )
      },
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    },
    // Treasury Column
    {
      dataField: 'treasury',
      text: 'Treasury',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const t = 0
        return (
          <div>
          <ul style={{ marginLeft: '-3em' }}>
            {row?.treasury.map((tre) => {
              return (
              <li
                style={{
                  cursor: 'pointer',
                  color: '#FFC107',
                  whiteSpace: 'nowrap'
                }}
                // onClick={() =>
                //   navigate("/PMS/TransactionExchangeHistory", {
                //     state: {
                //       id: 1,
                //       data: row.portfolioId,
                //       e_name: exchange.exchange_name,
                //     },
                //   })
                // }
                key={tre.acctNo}
              >
                {/* <Tooltip title={"view exchange"}> */}
                  <span className="namePortData">
                    {tre.acctNo}{' '}-{' '}$
                    {parseFloat(tre.balance.toFixed(2))
                      .toLocaleString()
                      .replace(/\.00$/, '')}
                  </span>
                {/* </Tooltip> */}
              </li>
              )
            })}
          </ul>
          {
            <span style={{ fontWeight: 'bold' }}>{
              (row.usd_treasuryBalance >= 0
                ? '$'
                : '- $') + row.usd_treasuryBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }</span>
          }
          </div>
        )
      },
      footer: (columnData, column, columnIndex) => {
        return (
          <div>
          {            
            '$' + portfolioSummary.totalTreasuryAmt
              .toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              .replace(/\.00$/, '').replace('-', '')
          }
          </div>
        )
      },
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    },

    {
      dataField: 'p_data',
      text: 'Person/Organisation',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <ul style={{ marginLeft: '-3em' }}>
            {row.ownerships?.map((ownership) => (
              <li
                key={row.portfolioId + ownership.name}
                style={{
                  color: '#FFC107',
                  cursor: 'pointer',
                  whiteSpace: 'break-spaces'
                }}
                onClick={() =>
                  navigate(
                    `/PMS/Admin/SinglePortfolioPage/${row.portfolioId}/${row.portfolioName}`
                  )
                }
              >
                <span className="namePortData">
                  {' '}
                  {ownership.name.charAt(0).toUpperCase() + ownership.name.slice(1)} -{' '}
                  {ownership.ownership_percentage}%{' '}
                </span>
              </li>
            ))}
          </ul>
        )
      },
      footer: (columnData, column, columnIndex) => {
        return (
        <div>
        </div>
        )
      },
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    }
  ]

  const admin_columns = [
    {
      dataField: 'portfolio',
      text: 'Accountant',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <ul style={{ marginLeft: '-3em' }}>
            {row.accountants.map((accountant) => (
              <li
                key={row.portfolioId + accountant.accountant_id}
                style={{ color: '#FFC107', whiteSpace: 'break-spaces' }}
              >
                <span className="namePortData">
                  {' '}
                  {accountant.username.charAt(0).toUpperCase() +
                    accountant.username.slice(1)}
                </span>{' '}
                <span
                  style={{ color: '#b30000', cursor: 'pointer' }}
                  onClick={() => { handleDeleteAccountant(accountant.accountant_id, row.portfolioId) }}
                >
                  <HighlightOffIcon style={{ height: '16px' }} />
                </span>
              </li>
            ))}
          </ul>
        )
      },
      footer: (columnData, column, columnIndex) => {
        return (
        <div>
        </div>
        )
      },
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    },
    {
      dataField: '',
      text: 'Action',
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          roleId.includes('superadmin') || roleId.includes('admin')
            ? (<div
            style={{
              cursor: 'pointer',
              lineHeight: 'normal'
            }}
            onClick={handleDropDown}
          >
            <Dropdown>
              <Dropdown.Toggle
                id={'options-button'}
                variant="borderless-dark"
                bsPrefix="no-chevron"
                size="sm"
                style={{
                  color: '#FFC107',
                  fontSize: '25px',
                  fontWeight: '700'
                }}
              >
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  willChange: 'transform',
                  fontSize: '14px',
                  background: '#222429',
                  border: '0.2em solid white'
                }}
              >
                <Dropdown.Item
                  href="#"
                  onClick={() =>
                    navigate('/PMS/MainManageAssetsWallets', {
                      state: { id: 1, data: row }
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      fontSize: '14px',
                      color: '#FFC107',
                      marginRight: '4px',
                      marginLeft: '-15px',
                      marginTop: '-3px'
                    }}
                  />
                  <span>Add wallet</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() =>
                    navigate('/PMS/Investments', {
                      state: { id: 1, data: row }
                    })
                  }
                >
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      fontSize: '14px',
                      color: '#FFC107',
                      marginRight: '4px',
                      marginLeft: '-15px',
                      marginTop: '-3px'
                    }}
                  />
                  <span>Add investment</span>
                </Dropdown.Item>
                <Dropdown.Item
                    href="#"
                    onClick={() =>
                      navigate('/PMs/ViewExchanges', {
                        state: { id: 1, data: row }
                      })
                    }
                  >
                    <AddCircleOutlineOutlinedIcon
                      style={{
                        fontSize: '14px',
                        color: '#FFC107',
                        marginRight: '4px',
                        marginLeft: '-15px',
                        marginTop: '-3px'
                      }}
                    />
                    <span>Add exchange</span>
                </Dropdown.Item>
                <Dropdown.Item
                    href="#"
                    onClick={() => handleAccountant(row)}
                  >
                    <AddCircleOutlineOutlinedIcon
                      style={{
                        fontSize: '14px',
                        color: '#FFC107',
                        marginRight: '4px',
                        marginLeft: '-15px',
                        marginTop: '-3px'
                      }}
                    />
                    <span>Add Accountant</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href={`/PMS/Admin/SinglePortfolioPage/${row.portfolioId}/${row.portfolioName}`}
                >
                  <SwitchAccountOutlinedIcon
                    style={{
                      fontSize: '14px',
                      color: '#FFC107',
                      marginRight: '4px',
                      marginLeft: '-15px',
                      marginTop: '-3px'
                    }}
                  />
                  <span>Manage ownership</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => handleShowEp(row)}>
                  <EditOutlinedIcon
                    style={{
                      fontSize: '14px',
                      color: '#FFC107',
                      marginRight: '4px',
                      marginLeft: '-15px',
                      marginTop: '-3px'
                    }}
                  />
                  <span>Edit Portfolio Name</span>
                </Dropdown.Item>
                <Dropdown.Item
                    href="#"
                    onClick={() => handleShow1(row, rowIndex)}
                  >
                    <DeleteOutlineOutlinedIcon
                      style={{
                        fontSize: '14px',
                        color: '#FFC107',
                        marginRight: '4px',
                        marginLeft: '-15px',
                        marginTop: '-3px'
                      }}
                    />
                    <span>Delete</span>
                  </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>)
            : (<></>)
        )
      },
      footer: (columnData, column, columnIndex) => {
        return (
        <div>
        </div>
        )
      },
      footerStyle: { borderTop: '1px solid rgb(255 255 255 / 15%)' }
    }
  ]

  if (roleId.includes('superadmin') || roleId.includes('admin')) { Array.prototype.push.apply(columns, admin_columns) }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Row className="d-flex justify-content-center">
              <span className="p-2 pageheader" style={{ marginBottom: '10px' }}>
                <Row>
                  <h3 className="pagetitle">Crypto Portfolio</h3>
                  <h3 className="pagetitle">Grand Total: $ {
                        portfolioSummary.grandTotal
                          .toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                          .replace(/\.00$/, '').replace('-', '')
                  }</h3>
                </Row>
              </span>
              {roleId.includes('superadmin') || roleId.includes('admin')
                ? (
                <Link
                  className="p-2 pageheader"
                  to="#"
                  style={{
                    boxShadow: 'none',
                    cursor: 'pointer',
                    background: 'none',
                    marginLeft: '1%',
                    color: '#FFC107',
                    top: '11px',
                    position: 'relative'
                  }}
                  onClick={handleShow}
                >
                  <AddCircleOutlineOutlinedIcon />
                </Link>
                  )
                : (
                <></>
                  )}
              <SearchBox
                value={sea}
                onChange={(event) => {
                  setSea(event.target.value)
                  const x = portfolios?.filter(
                    (i) =>
                      i.portfolioName
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase())
                  )
                  if (x.length == 0) {
                    setAlertNoRecord(true)
                    setSearch([])
                  } else {
                    setSearch(x)
                  }
                }}
              />
            </Row>
            {loading
              ? (
              <Spinner
                style={{
                  position: 'fixed',
                  top: '20em',
                  left: '59%',
                  height: '70px',
                  width: '70px'
                }}
                animation="border"
                variant="primary"
              />
                )
              : null}
              <CommonTable
                loading={loading}
                data={sea ? search : portfolios}
                columns={columns}
                className="portfolio"
              />
          </Col>
        </Row>
      </Container>

      <Modal
        show={showAccountant}
        onHide={() => setShowAccountant(false)}
        style={{ width: '28%', marginLeft: '35%' }}
      >
        <div style={{ border: '1px solid white' }}>
          <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
            <IconButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'white'
              }}
              onClick={() => setShowAccountant(false)}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          {alertAl
            ? (
            <Snackbar
              open={alertAl}
              onClose={() => setAlertAl(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={() => setAlertAl(false)}
                severity="error"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Accountant name already exist
              </Alert>
            </Snackbar>
              )
            : (
            <></>
              )}
          {alertAccountant
            ? (
            <Snackbar
              open={alertAccountant}
              onClose={() => setAlertAccountant(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={() => setAlertAccountant(false)}
                severity="success"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Added accountant successfully
              </Alert>
            </Snackbar>
              )
            : (
            <></>
              )}
          <Modal.Body style={{ backgroundColor: '#222429' }}>
            <Form
              className="custom-form"
              noValidate
              validated={validated}
              onSubmit={handleSubmitAccountant}
            >
              <h4>Add Accountant</h4>
              <span
                style={{
                  color: 'white',
                  marginLeft: '4px',
                  fontWeight: 'bold'
                }}
              >
                Portfolio name -{' '}
                <span style={{ marginLeft: '2px' }}>{portfolioName}</span>
              </span>
              <FormControl
                style={{
                  marginLeft: '10px',
                  marginBottom: '33px',
                  marginTop: '7px'
                }}
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  style={{
                    fontSize: '17px',
                    marginLeft: '-2%',
                    marginTop: '-1%',
                    overflow: 'visible',
                    color: 'grey'
                  }}
                >
                  Accountant
                </InputLabel>

                <Select
                  MenuProps={{
                    classes: {
                      paper: styles.paper
                    },
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        }
                      }
                    }
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={accoutantName}
                  label="Select"
                  style={{
                    width: '253px',
                    borderRadius: '15px',
                    boxShadow: 'none'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-notchedOutline': {
                      borderRadius: '0px',
                      height: '54px',
                      border: '1px solid hsl(0deg 0% 44% / 63%) !important'
                    },
                    '.MuiInputLabel-root': {
                      color: 'grey !important'
                    },
                    '.MuiSelect-icon': {
                      fill: 'grey !important'
                    },
                    '.MuiOutlinedInput-input': {
                      color: 'white !important',
                      fontSize: '15px',
                      //  backgroundColor:'white',
                      paddingBottom: '13px',
                      paddingRight: '61%',
                      paddingTop: '12px'
                    }
                  }}
                  onChange={(e) => {
                    setAccountantName(e.target.value)
                    setBlankAccountantError(false)
                  }}
                >
                  {resultAccountantData?.map((i) => (
                    <MenuItem value={i.username} key={i.user_id}>{i.username}</MenuItem>
                  ))}
                </Select>
                {blankAccountantError == true
                  ? <span style={{
                    width: '100%',
                    marginTop: '0.25rem',
                    fontSize: '.875em',
                    color: '#dc3545'
                  }}>Accountant name is required</span>
                  : <></>}
              </FormControl>

              <Button
                type="submit"
                variant=""
                className="btn-gray"
                style={{
                  width: '50%',
                  marginLeft: '25%',
                  marginTop: '-3%',
                  color: 'white',
                  boxShadow: 'none'
                }}
              >
                Save
              </Button>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showEp}
        style={{ width: '28%', marginLeft: '35%' }}
      >
        <div style={{ border: '1px solid white' }}>
          <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
            {/* <Modal.Title>Create PortFolio Section</Modal.Title> */}
            <IconButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'white'
              }}
              onClick={() => setShowEp(false)}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          {alert
            ? (
            <Snackbar
              open={alert}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Portfolio Name Updated Successfully
              </Alert>
            </Snackbar>
              )
            : (
            <></>
              )}
          {alertAl ? (
            <Snackbar
              open={alertAl}
              onClose={() => setAlertAl(false)}
              sx={{
                // marginTop:'-2%',
                marginLeft: '35%',
                marginBottom: '38%',

                width: '25%'
              }}
            >
              <Alert
                onClose={() => setAlertAl(false)}
                severity="error"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Already exist
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {spError ? (
              <Snackbar
                  open={spError}
                  onClose={() => setSpError(false)}
                  sx={{
                      // marginTop:'-2%',
                      marginLeft: '35%',
                      marginBottom: '38%',

                      width: '25%'
                  }}
              >
                  <Alert
                      onClose={() => setSpError(false)}
                      severity="error"
                      sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                  >
                      {respMessage}
                  </Alert>
              </Snackbar>
          ) : (
              <></>
          )}          
          {blankSpaceError ? (
            <Snackbar
              open={blankSpaceError}
              onClose={() => setBlankSpaceError(false)}
              sx={{
                // marginTop:'-2%',
                marginLeft: '35%',
                marginBottom: '38%',

                width: '25%'
              }}
            >
              <Alert
                onClose={() => setBlankSpaceError(false)}
                severity="error"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Portfolio name cannot be blank
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          <Modal.Body style={{ backgroundColor: '#222429' }}>
            {/* {!isWalletConnected ? ( */}
            <Form
              className="custom-form"
              noValidate
              validated={validated}
              onSubmit={handleSubmitEditForm}
            >
              <h4>Edit Portfolio</h4>

              <Form.Label
                htmlFor="exchange"
                className={cx('custom-form-box', {
                  'focus-add': p_name
                })}
                style={{ width: '62%', marginLeft: '19%' }}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  value={p_name}
                  onChange={(e) => setp_name(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
                <Form.Control.Feedback type="invalid">
                  Portfolio name is Required.
                </Form.Control.Feedback>
              </Form.Label>
              <Button
                type="submit"
                variant=""
                className="btn-gray"
                style={{
                  width: '50%',
                  marginLeft: '25%',
                  boxShadow: 'none',
                  color: 'white'
                }}
              >
                save
              </Button>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose}
        style={{
          width: '100%',
          paddingBottom: '6%',
          marginTop: '3%',
          marginLeft: '2%',
          height: '95%'
        }}
      >
        <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
          <IconButton
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: 'white'
            }}
            onClick={() => setShow2(false)}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: '#222429'
          }}
        >
          <div>
            <h3 style={{ fontSize: '18px' }}>List of portfolio</h3>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose}
        style={{
          width: '30rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '35%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '16px',
              marginTop: '-5%',
              marginLeft: '11%'
            }}
          >
            Are you sure you want to Delete this portfolio ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            border: 'none',
            paddingRight: '34%',
            marginTop: '-3%'
          }}
        >
          <Button
            variant="success"
            style={{
              width: '25%',
              marginBottom: '2%',
              backgroundColor: '#006400'
            }}
            onClick={() => {
              handleDeleteUpdate()
              handleClose()
            }}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={handleClose}
            style={{ width: '25%', backgroundColor: '#b30000' }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isOpenDeleteAccountant}
        onHide={() => setIsDeleteAccountant(false)}
        style={{
          width: '30rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '35%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '16px',
              marginTop: '-5%',
              marginLeft: '11%'
            }}
          >
            Are you sure you want to Delete this accountant ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            border: 'none',
            paddingRight: '34%',
            marginTop: '-3%'
          }}
        >
          <Button
            variant="danger"
            style={{
              width: '25%',
              marginBottom: '2%',
              backgroundColor: '#b30000'
            }}
            onClick={() => {
              DeleteAccountant()
              setIsDeleteAccountant(false)
            }}
          >
            Yes
          </Button>
          <Button
            variant="success"
            onClick={() => setIsDeleteAccountant(false)}
            style={{ width: '25%', backgroundColor: '#006400' }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        style={{ width: '28%', marginLeft: '35%' }}
      >
        <div style={{ border: '1px solid white' }}>
          <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
            <IconButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'white'
              }}
              onClick={() => setShow(false)}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: '#222429' }}>
            {!isWalletConnected
              ? (
              <Form
                className="custom-form"
                noValidate
                validated={validated}
                onSubmit={handleSubmitForm}
              >
                <h4>Create Crypto Portfolio</h4>
                <Form.Label
                  htmlFor="exchange"
                  className={cx('custom-form-box', {
                    'focus-add': name
                  })}
                  style={{ width: '62%', marginLeft: '19%' }}
                >
                  <Form.Control
                    type="text"
                    id="portfolio_name"
                    name="portfolio_name"
                    placeholder="Portfolio Name"
                    onChange={(e) => {
                      setname(e.target.value)
                    }}
                    required
                    style={{ color: 'white' }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Portfolio Name is Required.
                  </Form.Control.Feedback>
                </Form.Label>
                <Button
                  type="submit"
                  variant=""
                  className="btn-gray"
                  style={{ width: '50%', marginLeft: '25%', boxShadow: 'none' }}
                >
                  Save
                </Button>
              </Form>
                )
              : (
              <SuccessMessage message=" Exchange Successfully Added" />
                )}
          </Modal.Body>
          {alertPort ? (
            <Snackbar
              open={alertPort}
              onClose={handleClosePort}
              sx={{
                // marginTop:'-2%',
                marginLeft: '35%',
                marginBottom: '38%',

                width: '25%'
              }}
            >
              <Alert
                onClose={handleClosePort}
                severity="success"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Portfolio Added successfully
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {blankSpaceError ? (
            <Snackbar
              open={blankSpaceError}
              onClose={() => setBlankSpaceError(false)}
              sx={{
                // marginTop:'-2%',
                marginLeft: '35%',
                marginBottom: '38%',

                width: '25%'
              }}
            >
              <Alert
                onClose={() => setBlankSpaceError(false)}
                severity="error"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Portfolio name cannot be blank
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {alertSp ? (
            <Snackbar
              open={alertSp}
              onClose={() => setAlertSp(false)}
              sx={{
                // marginTop:'-2%',
                marginLeft: '35%',
                marginBottom: '38%',

                width: '25%'
              }}
            >
              <Alert
                onClose={() => setAlertSp(false)}
                severity="error"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Please enter your proper name
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {alertAl ? (
            <Snackbar
              open={alertAl}
              onClose={() => setAlertAl(false)}
              sx={{
                // marginTop:'-2%',
                marginLeft: '35%',
                marginBottom: '38%',
                width: '25%'
              }}
            >
              <Alert
                onClose={() => setAlertAl(false)}
                severity="error"
                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
              >
                Already exist
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
        </div>
      </Modal>

      {alertDeleteError
        ? (
        <Snackbar
          open={alertDeleteError}
          onClose={() => setAlertDeleteError(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          sx={{
            width: '20em'
          }}
        >
          <Alert
            onClose={() => setAlertDeleteError(false)}
            severity="error"
            sx={{
              width: '100%',
              backgroundColor: 'white',
              color: 'black'
            }}
          >
            Portfolio contains data of wallet, investment, exchange you can not
            delete this portfolio
          </Alert>
        </Snackbar>
          )
        : (
        <></>
          )}
    </React.Fragment>
  )
}
export default MainAdmin

import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { styled, lighten, darken } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, TextField, Tooltip } from "@mui/material";
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: "white",
  fontWeight: "bold",
}));
const GroupItems = styled("li")({
  padding: 0,
  paddingLeft: "2rem",
});
const useStyles = makeStyles({
  option: {
    "&:hover": {
      backgroundColor: "rgb(31, 33, 37) !important",
      color: "white !important",
    },
  },
});
const SelectSearchBox = ({
  label,
  value,
  options,
  onChange,
  onChange1,
  err,
}) => {
  const styles = useStyles();
  // console.log(value,options,onChange,err)
  return (
    <>
      <Autocomplete
        // className="p-2 pageheader"
        value={value}
        freeSolo
        options={options.map((e) => e.name)}
        onChange={onChange}
        //   let res_type= result.filter(i=>i.investment_name==k)
        //  if(res_type.length>0){
        //   setBlankInvestError(false)
        //   setInvestType(res_type?.[0]?.investment_type)
        //   setInvestName(k)
        //   // setInvestCheck(k)
        //  }else{
        //   setBlankInvestError(false)
        //   //  setInvestType('')
        //    setInvestName('')
        //  }
        classes={{
          option: styles.option,
        }}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              background: "rgb(31, 33, 37)",
              color: "white",
            }}
          >
            {children}
          </Paper>
        )}
        style={{
          fill: "white",
          boxShadow: "none",
          borderRadius: "30%",
          width: "84%",
          marginTop: "2%",
          //   marginBottom:'6%',
          marginLeft: "14%",
        }}
        sx={{
          width: 300,
          ".MuiOutlinedInput-root": {
            borderRadius: "4px",
            width: "84%",
            height: "50px",
            // backgroundColor: '#fff',
            fontSize: "14px",
            border: "1px solid grey !important",
            left: "4px",
            paddingTop: "14px !important",
          },
          ".MuiButtonBase-root": {
            color: "white",
          },
          ".MuiInputLabel-root": {
            top: "1px",
            left: "-8px",
            fontSize: "10px",
            background: "#1f2125",
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onChange1}
            //   if(result.filter(i=>i.investment_name===e.target.value).length==0){
            //     setInvestHide(true)
            //     setBlankInvestError(false)
            //     setInvestName(e.target.value)
            //   }else{
            //     setBlankInvestError(false)
            //   setInvestName(e.target.value)
            //   setInvestType(result.filter(i=>i.investment_name==e.target.value)?.[0]?.investment_type)
            //   }

            style={{ color: "white" }}
            label={label != null && label}
          />
        )}
      />
      {err == true ? (
        <span
          style={{
            width: "100%",
            marginTop: "0.25rem",
            fontSize: ".875em",
            color: "#dc3545",
          }}
        >
          {label} is required
        </span>
      ) : (
        <></>
      )}
    </>
  );
};
export default SelectSearchBox;

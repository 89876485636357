import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "./CommonTable.css";
import { Image, Row, Col, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SortIcon from "@mui/icons-material/Sort";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import AuditHistorySingleInvestment from '../../Admin/DashboardAdmin/AuditHistorySingleInvestment'
import WalletInfoFirstLevel from "../../Admin/DashboardAdmin/WalletInfoFirstLevel";

const CommonTableWalletInfo = (props) => {
  const expandRow = {
    renderer: (row) => (
      <div>
        <WalletInfoFirstLevel address_id={row} />
      </div>
    ),
    showExpandColumn: true,
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  return (
    <div
      className="tablet wallet-table"
      style={{ width: "100%", overflow: "auto" }}
    >
      {props.data && (
        <ToolkitProvider
          bootstrap4
          keyField="address"
          data={props.data}
          columns={props.columns}
          columnToggle
          draggable
          search
          exportCSV={{
            fileName: "Transactions.csv",
            onlyExportFiltered: true,
            exportAll: false,
          }}
          defaultSorted={defaultSorted}
        >
          {(props) => (
            <Row>
              <Col
                style={{ flexDirection: "column", maxWidth: "100%", flex: 1 }}
              >
                <BootstrapTable
                  filter={filterFactory()}
                  {...props.baseProps}
                  wrapperClasses=" investment-border"
                  // noDataIndication="no data found"
                  expandRow={expandRow}
                />
              </Col>
            </Row>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
};

export default CommonTableWalletInfo;

import { Autocomplete, IconButton, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@material-ui/core/styles'
import useApi from '../hooks/useApi'
import { getUserId } from '../helpers/common'
import { AlertBox } from '../helpers/components/AlertBox'
import CommonTableTransaction from '../common/CommonTable/CommonTableTransaction'
import { columns } from './failedImportTableColumns'

const useStyles = makeStyles({
  option: {
    '&:hover': {
      backgroundColor: 'rgb(31, 33, 37) !important',
      color: 'white !important'
    }
  }
})

export const ImportModal = ({ visible, portfolios, setImportModalVisible, refreshTable = () => {} }) => {
  const styles = useStyles()
  const [forms, setForms] = useState([])
  const [trigger, setTrigger] = useState()
  const [triggerConfirm, setTriggerConfirm] = useState()

  const [alertVisible, setAlertVisible] = useState(false)
  const [importError, setImportError] = useState(false)
  const [importSuccess, setImportSuccess] = useState(false)

  // FUNCTIONS //
  const handleImport = (e) => {
    e.preventDefault()
    setForms({
      ...forms,
      userId: getUserId()
    })
    setTrigger(+new Date())
  }

  const confirmUpload = () => {
    setTriggerConfirm(+new Date())
  }

  // HOOKS //
  const importResponse = useApi({
    method: 'POST',
    options: { ...forms },
    trigger,
    file: true,
    url: `${process.env.REACT_APP_BASE_URL}/treasure/excel/import`
  })

  const confirmResponse = useApi({
    method: 'POST',
    trigger: triggerConfirm,
    options: {
      batchId: importResponse?.response?.batchId,
      userId: getUserId()
    },
    url: `${process.env.REACT_APP_BASE_URL}/treasure/excel/confirm-import`
  })

  useEffect(() => {
    if ((importResponse.response == null && importResponse.error == null)) { return }

    document.getElementById('inputGroupFile').value = null
    setForms({ ...forms, file: null })
    if (importResponse.error) {
      return
    }

    if (importResponse.response.totalFailed > 0) {
      setImportError(true)
      setImportSuccess(false)
    } else if (importResponse.response.totalRecords === importResponse.response.totalSuccess) {
      setImportError(false)
      setImportSuccess(true)
    }
  }, [importResponse?.response, importResponse?.error])

  useEffect(() => {
    if (confirmResponse.response == null && confirmResponse.error == null) { return }

    if (confirmResponse.response) {
      setAlertVisible(true)
      refreshTable()
      setForms([])
      setImportError(false)
      setImportSuccess(false)
      setTimeout(() => {
        setAlertVisible(false)
        setImportModalVisible(false)
      }, 2000)
    }
  }, [confirmResponse?.response])

  useEffect(() => {
    if (visible) {
      setForms([])
      setAlertVisible(false)
      setImportError(false)
      setImportSuccess(false)
    }
  }, [visible])

  // RENDER //
  return (
    <>
    <Modal
      show={visible}
      onHide={() => { setImportModalVisible(false) }}
      style={importError
        ? {
            width: '100%',
            marginLeft: '2%',
            overflowY: 'auto'
          }
        : {
            width: '28%',
            marginLeft: '35%',
            overflowY: 'auto'
          }} >
      <div style={{ border: '1px solid white' }}>
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <IconButton
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: 'white'
            }}
            onClick={() => setImportModalVisible(false)}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#222429' }}>
            <Form
                className="custom-form"
                noValidate
                validated={false}
                onSubmit={handleImport}
              >
                <h4>
                  Import Treasurer
                </h4>
              <Autocomplete
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={portfolios.find((p) => { return p.id === forms.portfolioId })?.name || null}
                  options={portfolios?.map((e) => e.name)}
                  onChange={(e, k) => { setForms({ ...forms, portfolioId: k ? portfolios.find((p) => { return p.name == k }).id : '' }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  classes={{
                    option: styles.option
                  }}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Portfolios"
                  />
                  )}
              />

            <div className="custom-file mb-3" style={{ width: '69%', marginLeft: '8px' }}>
              <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={(e) => { setForms({ ...forms, file: e.target.files[0] }) }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              <label className="custom-file-label" htmlFor="inputGroupFile">{forms?.file?.name ?? 'Choose file'}</label>
            </div>

            <Button
              type="submit"
              variant=""
              className="btn-gray"
              style={{
                width: '50%',
                marginLeft: '25%',
                boxShadow: 'none',
                color: 'white',
                marginBottom: '50px'
              }}
            >
              Upload
            </Button>
          </Form>

              {
                importError && (

                    <>
                        <span style={{ color: 'orange' }}>Failed {importResponse.response?.totalFailed} out of {importResponse.response?.totalRecords}</span>
                        <CommonTableTransaction
                            loading={false}
                            data={importResponse.response?.rows || []}
                            columns={columns}
                        />
                    </>
                )
              }

              {
                importSuccess && (
                    <>
                        <span style={{ color: 'green' }}>Uploaded {importResponse.response?.totalRecords} records</span>
                        <Button
                          type="button"
                          variant=""
                          className="btn-gray"
                          style={{
                            width: '50%',
                            marginLeft: '25%',
                            boxShadow: 'none',
                            color: 'white',
                            marginBottom: '50px'
                          }}
                          onClick={confirmUpload}
                        >
                          Confirm
                        </Button>
                    </>
                )
              }

              {
                importResponse.error && (<span style={{ color: 'orange' }}>{importResponse.error}</span>)
              }

        </Modal.Body>
      </div>
    </Modal>

    <AlertBox type={'success'} show={alertVisible} text={'Treasury import successful!'}/>
    </>
  )
}

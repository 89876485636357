import React, { useEffect, useState } from 'react'
import { AlertBox } from '../helpers/components/AlertBox'
import { Button, Form, Modal } from 'react-bootstrap'
import { Autocomplete, IconButton, Paper, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useApi from '../hooks/useApi'
import { getUserId } from '../helpers/common'
import _ from 'lodash'

export const AddModal = ({ visible = false, portfolios = [], setAddModalVisible = () => {}, aspect = 'add', portfolio = {}, refreshTable = () => {} }) => {
  const [forms, setForms] = useState({})
  const [trigger, setTrigger] = useState()
  const [formErrors, setFormErrors] = useState({})
  const [alert, setAlert] = useState({
    visible: false,
    type: 'success',
    msg: ''
  })

  const handleFormSubmit = (e) => {
    e.preventDefault()

    setForms({
      ...forms,
      userId: getUserId(),
      id: aspect === 'add' ? 0 : portfolio?.id,
      batchId: '-'
    })

    validateForm()
  }

  const validateForm = () => {
    console.log(forms.active);
    setFormErrors({
      portfolioId: !forms.portfolioId || forms.portfolioId === '' ? 'Portfolio is required' : undefined,
      office: !forms.office || forms.office === '' ? 'Office is required' : undefined,
      boNumber: !forms.boNumber || forms.boNumber === '' ? 'BO Number is required' : undefined,
      bo: !forms.bo || forms.bo === '' ? 'BO is required' : undefined,
      boType: !forms.boType || forms.boType === '' ? 'BO Type is required' : undefined,
      ubo: !forms.ubo || forms.ubo === '' ? 'UBO is required' : undefined,
      propId: !forms.propId || forms.propId === '' ? 'ID is required' : undefined,
      currency: !forms.currency || forms.currency === '' ? 'Currency is required' : undefined,
      active: forms.active == null || forms.active === '' ? 'Active is required' : undefined,
      country: !forms.country || forms.country === '' ? 'Country is required' : undefined,
      propType: !forms.propType || forms.propType === '' ? 'Property type is required' : undefined,
      tenureFinalDt: !forms.tenureFinalDt || forms.tenureFinalDt === '' ? 'Tenure finalize date is required' : undefined,
      project: !forms.project || forms.project === '' ? 'Project has to be a number' : undefined,
      unit: !forms.unit || forms.unit === '' ? 'Unit is required' : undefined,
      type: !forms.type || forms.type === '' ? 'Type is required' : undefined,
      existingUse: !forms.existingUse || forms.existingUse === '' ? 'Existing use is required' : undefined,
      acquisitionDt: !forms.acquisitionDt || forms.acquisitionDt === '' ? 'Acquisition date is required' : undefined,
      valuationDt: !forms.valuationDt || forms.valuationDt === '' ? 'Valuation date is required' : undefined,
      floorArea: !forms.floorArea || forms.floorArea === '' || isNaN(forms.floorArea) ? 'Floor area has to be a number' : undefined,
      valuationAmt: !forms.valuationAmt || forms.valuationAmt === '' || isNaN(forms.valuationAmt) ? 'Valuation amount has to be a number' : undefined,
      purchasePrice: !forms.purchasePrice || forms.purchasePrice === '' || isNaN(forms.purchasePrice) ? 'Purchase price has to be a number' : undefined,
      capex: !forms.capex || forms.capex === '' || isNaN(forms.capex) ? 'Capex has to be a number' : undefined,
      historicalCost: !forms.historicalCost || forms.historicalCost === '' || isNaN(forms.historicalCost) ? 'Historical cost has to be a number' : undefined,
      valuationCostUSD: !forms.valuationCostUSD || forms.valuationCostUSD === '' || isNaN(forms.valuationCostUSD) ? 'Valuation USD has to be a number' : undefined,
      acquisitionCostUSD: !forms.acquisitionCostUSD || forms.acquisitionCostUSD === '' || isNaN(forms.acquisitionCostUSD) ? 'Acquisition cost USD has to be a number' : undefined,
      fxAcquisition: !forms.fxAcquisition || forms.fxAcquisition === '' || isNaN(forms.fxAcquisition) ? 'FX acquisition has to be a number' : undefined,
      fxActual: !forms.fxActual || forms.fxActual === '' || isNaN(forms.fxActual) ? 'FX actual has to be a number' : undefined,
      client: !forms.client || forms.client === '' || isNaN(forms.client) ? 'Client has to be a number' : undefined,
    })
  }

  function formatDate (date) {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [year, month, day].join('-')
  }

  const response = useApi({
    method: 'POST',
    options: {
      ...forms
    },
    trigger,
    url: `${process.env.REACT_APP_BASE_URL}/property/save`
  })

  useEffect(() => {
    if (!_.isEmpty(formErrors)) {
      if (!_.some(formErrors, (x) => x !== undefined)) {
        setTrigger(+new Date())
      }
    }
  }, [formErrors])

  useEffect(() => {
    if (aspect === 'add' && visible === true) {
      setForms({
        ...forms,
        lastUpdate: new Date(Date.now()).toISOString().split('T')[0]
      })
    } else {
      setForms([])
    }
    setTrigger()
  }, [visible])

  useEffect(() => {
    if (response.response) {
      refreshTable()
      setAlert({
        visible: true,
        msg: `Real Estate ${aspect == 'edit' ? 'Updated' : 'Added'}!`,
        type: 'success'
      })
      setForms([])
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
        setAddModalVisible({
          visible: false,
          aspect: ''
        })
      }, 2000)
    }

    if (response.error) {
      setAlert({
        visible: true,
        msg: response.error,
        type: 'error'
      })
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
      }, 5000)
    }
  }, [response?.response, response?.error])

  useEffect(() => {
    if (!_.isEmpty(portfolio)) {
      console.log(portfolio)
      setForms(portfolio)
    }
  }, [portfolio])

  return (
    <>
    <Modal
      onHide={() => { setAddModalVisible({ visible: false, aspect: '' }) }}
      show={visible}
      style={{
        width: '29%',
        marginLeft: '35%',
        overflowY: 'auto'
      }} >
      <div style={{ border: '1px solid white' }}>
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <IconButton
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: 'white'
            }}
            onClick={() => { setAddModalVisible({ visible: false, aspect: '' }) }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#222429' }}>
            <Form
                className="custom-form"
                noValidate
                validated={false}
                onSubmit={handleFormSubmit}
              >
                <h4>
                {aspect == 'edit' ? 'Update' : 'Add'} Real Estate
                </h4>
              <Autocomplete
                  disabled={aspect === 'edit'}
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={portfolios.find((p) => { return p.id === forms.portfolioId })?.name || null}
                  options={portfolios?.map((e) => e.name)}
                  onChange={(e, k) => { setForms({ ...forms, portfolioId: portfolios.find((p) => { return p.name == k }).id }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    },
                    '.Mui-disabled': {
                      WebkitTextFillColor: 'gray !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Portfolios"
                  />
                  )}
              />
              {formErrors.portfolioId
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.portfolioId}</span>
                : <></>}

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.office || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, office: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Office"
                      onChange={(e) => { setForms({ ...forms, office: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.office
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.office}</span>
                 : <></>}

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.boNumber || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, boNumber: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="BO #"
                      onChange={(e) => { setForms({ ...forms, boNumber: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.boNumber
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.boNumber}</span>
                 : <></>}

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.bo || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, bo: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="BO"
                      onChange={(e) => { setForms({ ...forms, bo: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.bo
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.bo}</span>
                 : <></>}

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.boType || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, boType: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="BO Type"
                      onChange={(e) => { setForms({ ...forms, boType: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.boType
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.boType}</span>
                 : <></>}   

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.ubo || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, ubo: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="UBO"
                      onChange={(e) => { setForms({ ...forms, ubo: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.ubo
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.ubo}</span>
                 : <></>}  

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.propId || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, propId: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="ID"
                      onChange={(e) => { setForms({ ...forms, propId: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.propId
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.propId}</span>
                 : <></>}  

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.currency || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, currency: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Currency"
                      onChange={(e) => { setForms({ ...forms, currency: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.currency
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.currency}</span>
                 : <></>}  

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }].find((p) => { return p.value === forms.active })?.text || null}
                  options={[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }].map((e) => e.text)}
                  onChange={(e, k) => { setForms({ ...forms, active: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }].find((p) => { return p.text === k })?.value }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Active"
                  />
                  )}
              />
               {formErrors.active
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.active}</span>
                 : <></>}   

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.country || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, country: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Country"
                      onChange={(e) => { setForms({ ...forms, country: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.country
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.country}</span>
                 : <></>}  

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.propType || null}
                  options={['Freehold', 'Leasehold'].map((e) => e)}
                  onChange={(e, k) => { setForms({ ...forms, propType: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Freehold - Leasehold"
                  />
                  )}
              />
               {formErrors.propType
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.propType}</span>
                 : <></>}     

            <LocalizationProvider className='p-2' dateAdapter={AdapterDayjs} style={{ transform: 'none' }}>
                <DatePicker
                    label="Tenure - Finalization Date"
                    className='p-2'
                    inputFormat="DD/MM/YYYY"
                    autoOk
                    value={forms.tenureFinalDt}
                    onChange={(newValue) => {
                      console.log(newValue.$d)
                      setForms({ ...forms, tenureFinalDt: formatDate(newValue.$d) })
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{
                          borderRadius: '15px',
                          color: 'white',
                          width: '81%',
                          marginTop: '2%',
                          marginLeft: '10%'
                        }}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            borderRadius: '4px',
                            width: '90%',
                            height: '50px',
                            // backgroundColor: '#fff',
                            fontSize: '14px',
                            border: '1px solid grey !important'

                          },
                          '.MuiInputBase-input': {
                            height: '0rem'
                          },
                          '.MuiSvgIcon-root': {
                            fill: 'white'
                          },
                          '.MuiInputLabel-root': {
                            top: '9px',
                            left: '-8px',
                            fontSize: '14px',
                            background: '#1f2125'
                          }
                        }}
                    />
                    )}
                />
                </LocalizationProvider>                                                                                                               

                <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.project || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, project: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Project"
                      onChange={(e) => { setForms({ ...forms, project: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.project
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.project}</span>
                 : <></>} 

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.unit || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, unit: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Unit (Individual Title Deed)"
                      onChange={(e) => { setForms({ ...forms, unit: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.unit
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.unit}</span>
                 : <></>}   

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.type || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, type: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Type"
                      onChange={(e) => { setForms({ ...forms, type: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.type
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.type}</span>
                 : <></>}     

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.existingUse || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, existingUse: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Existing Use"
                      onChange={(e) => { setForms({ ...forms, existingUse: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.existingUse
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.existingUse}</span>
                 : <></>}                              

            <LocalizationProvider className='p-2' dateAdapter={AdapterDayjs} style={{ transform: 'none' }}>
                <DatePicker
                    label="Acquisition Date"
                    className='p-2'
                    inputFormat="DD/MM/YYYY"
                    autoOk
                    value={forms.acquisitionDt}
                    onChange={(newValue) => {
                      console.log(newValue.$d)
                      setForms({ ...forms, acquisitionDt: formatDate(newValue.$d) })
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{
                          borderRadius: '15px',
                          color: 'white',
                          width: '81%',
                          marginTop: '2%',
                          marginLeft: '10%'
                        }}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            borderRadius: '4px',
                            width: '90%',
                            height: '50px',
                            // backgroundColor: '#fff',
                            fontSize: '14px',
                            border: '1px solid grey !important'

                          },
                          '.MuiInputBase-input': {
                            height: '0rem'
                          },
                          '.MuiSvgIcon-root': {
                            fill: 'white'
                          },
                          '.MuiInputLabel-root': {
                            top: '9px',
                            left: '-8px',
                            fontSize: '14px',
                            background: '#1f2125'
                          }
                        }}
                    />
                    )}
                />
                </LocalizationProvider>   

                <LocalizationProvider className='p-2' dateAdapter={AdapterDayjs} style={{ transform: 'none' }}>
                <DatePicker
                    label="Valuation Date"
                    className='p-2'
                    inputFormat="DD/MM/YYYY"
                    autoOk
                    value={forms.valuationDt}
                    onChange={(newValue) => {
                      console.log(newValue.$d)
                      setForms({ ...forms, valuationDt: formatDate(newValue.$d) })
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{
                          borderRadius: '15px',
                          color: 'white',
                          width: '81%',
                          marginTop: '2%',
                          marginLeft: '10%'
                        }}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            borderRadius: '4px',
                            width: '90%',
                            height: '50px',
                            // backgroundColor: '#fff',
                            fontSize: '14px',
                            border: '1px solid grey !important'

                          },
                          '.MuiInputBase-input': {
                            height: '0rem'
                          },
                          '.MuiSvgIcon-root': {
                            fill: 'white'
                          },
                          '.MuiInputLabel-root': {
                            top: '9px',
                            left: '-8px',
                            fontSize: '14px',
                            background: '#1f2125'
                          }
                        }}
                    />
                    )}
                />
                </LocalizationProvider>   

            <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.floorArea || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, floorArea: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Floor Area (sqft)"
                      onChange={(e) => { setForms({ ...forms, floorArea: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.floorArea
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.floorArea}</span>
                 : <></>}

            <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.valuationAmt || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, valuationAmt: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Valuation AED"
                      onChange={(e) => { setForms({ ...forms, valuationAmt: e.target.value }) }}
                  />
                  )}
              />
               {formErrors.valuationAmt
                 ? <span style={{
                   width: '100%',
                   marginTop: '0.25rem',
                   fontSize: '.875em',
                   color: '#dc3545'
                 }}>{formErrors.valuationAmt}</span>
                 : <></>}

            <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.purchasePrice || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, purchasePrice: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Purchase Price AED"
                      onChange={(e) => { setForms({ ...forms, purchasePrice: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.purchasePrice
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.purchasePrice}</span>
                : <></>}

            <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.capex || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, capex: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="CAPEX AED"
                      onChange={(e) => { setForms({ ...forms, capex: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.capex
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.capex}</span>
                : <></>}

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.historicalCost || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, historicalCost: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Historical Cost"
                      onChange={(e) => { setForms({ ...forms, historicalCost: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.historicalCost
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.historicalCost}</span>
                : <></>}

              <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.valuationCostUSD || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, valuationCostUSD: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Valuation USD"
                      onChange={(e) => { setForms({ ...forms, valuationCostUSD: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.valuationCostUSD
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.valuationCostUSD}</span>
                : <></>}    

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.acquisitionCostUSD || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, acquisitionCostUSD: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Acquisition Cost USD"
                      onChange={(e) => { setForms({ ...forms, acquisitionCostUSD: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.acquisitionCostUSD
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.acquisitionCostUSD}</span>
                : <></>} 

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.fxAcquisition || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, fxAcquisition: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="FX Acquisition"
                      onChange={(e) => { setForms({ ...forms, fxAcquisition: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.fxAcquisition
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.fxAcquisition}</span>
                : <></>}  

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.fxActual || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, fxActual: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="FX Actual"
                      onChange={(e) => { setForms({ ...forms, fxActual: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.fxActual
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.fxActual}</span>
                : <></>}  

             <Autocomplete
                  freeSolo
                  disablePortal
                  className='p-2'
                  id="controllable-states-demo"
                  value={forms.client || null}
                  options={[]}
                  onChange={(e, k) => { setForms({ ...forms, client: k }) }}
                  PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }} >
                      {children}
                  </Paper>
                  )}
                  style={{
                    fill: 'white',
                    boxShadow: 'none',
                    borderRadius: '30%',
                    width: '85%',
                    marginTop: '2%',
                    marginLeft: '14%'
                  }}
                  sx={{
                    width: 300,
                    '.MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      width: '84%',
                      height: '50px',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      border: '1px solid grey !important',
                      left: '4px',
                      paddingTop: '14px !important'
                    },
                    '.MuiButtonBase-root': {
                      color: 'white'
                    },
                    '.MuiInputLabel-root': {
                      top: '1px',
                      left: '-8px',
                      fontSize: '14px !important',
                      background: '#1f2125',
                      fontWeight: '500 !important'
                    }

                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      style={{ color: 'white', fontSize: '14px' }}
                      label="Client"
                      onChange={(e) => { setForms({ ...forms, client: e.target.value }) }}
                  />
                  )}
              />
              {formErrors.client
                ? <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  color: '#dc3545'
                }}>{formErrors.client}</span>
                : <></>}                                                                

            <Button
              type="submit"
              variant=""
              className="btn-gray"
              style={{
                width: '50%',
                marginLeft: '25%',
                boxShadow: 'none',
                color: 'white',
                marginBottom: '50px',
                marginTop: '10px'
              }}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </div>
    </Modal>

    <AlertBox type={alert.type} show={alert.visible} text={alert.msg}/>
    </>
  )
}

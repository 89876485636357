import { Alert, Snackbar } from '@mui/material'
import { React, useEffect, useState } from 'react'

// type: success || error
export const AlertBox = ({ show, text = '', type }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (show) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [show])

  return (
    <Snackbar
        open={visible}
        onClose={() => setVisible(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          width: '20em'
        }}
        >
            <Alert
                onClose={() => setVisible(false)}
                severity={type}
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
            >
                {text}
            </Alert>
      </Snackbar>
  )
}

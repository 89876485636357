import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import HistoryIcon from '@mui/icons-material/History'
import GroupsIcon from '@mui/icons-material/Groups'
import InventoryIcon from '@mui/icons-material/Inventory'
import GridViewIcon from '@mui/icons-material/GridView'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import { Accordion } from 'react-bootstrap';
import './SidebarAdmin.css'
import '../../pms-navbar.css'
import { current } from '@reduxjs/toolkit'
const load = false
const loadUpper = true
const SidebarAdmin = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const portfolioRef = useRef(null)

  const roleId = localStorage.getItem('role')?.split(',') ?? []
  const [showPortfolioDetails, setShowPortfolioDetails] = useState(true)
  const [showAccountancyDetails, setShowAccountancyDetails] = useState(true)
  const [showTreasuryItems, setShowTreasuryItems] = useState(true)
  const [active, setActive] = useState(false)

  const initializeNavbar = () => {
    //* Loop through all dropdown buttons to toggle between hiding and showing its dropdown content - This allows the user to have multiple dropdowns without any conflict */
    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;    
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function (evnt) {
        tonggleObject(evnt.currentTarget);
      });
    }
  };

  const tonggleObject = (obj) => {
    var arrows = obj.getElementsByClassName('mui-dropdown-arrow');
    for (var i = 0; i < arrows.length; i++) {
      var x = arrows[i];
      if (x.style.display == 'none')
        x.style.display = '';
      else
        x.style.display = 'none';
    }

    obj.classList.toggle("active");
    var dropdownContent = obj.nextElementSibling;
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  };

  const highlightCurrentPage = () => {
    var containers = document.getElementsByClassName('sidenav');
    var foundLnk = false;
    for (var cidx = 0; cidx < containers.length; cidx++) {
      var links = containers[cidx].getElementsByTagName('a');
      for (var lidx = 0; lidx < links.length; lidx++) {
        if (links[lidx].getAttribute('href') == window.document.location.href.replace(window.location.origin, '').replace(window.location.search, '')) {
          foundLnk = true;
          if (links[lidx].parentElement && links[lidx].parentElement.previousElementSibling &&
            links[lidx].parentElement.previousElementSibling.tagName.toUpperCase() == 'BUTTON')
            tonggleObject(links[lidx].parentElement.previousElementSibling);
          links[lidx].classList.add('active-lnk');
          break;
        }
      }
      if (foundLnk) break;
    }
  };

  useEffect(() => {
    initializeNavbar();
    highlightCurrentPage();
  });

  const UserNavbar = () => (
    <>
      <li>
        <NavLink
          to={'/PMS/dashboard'}
          className="nav-link"

        >
          <InventoryIcon style={{ fontSize: '25px', color: '#FFC107' }} />
          <span style={{ padding: '4%' }}>Dashboard</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/PMS/UsersAsset"
          className="nav-link"

        >
          <AccountBalanceWalletIcon
            style={{ fontSize: '25px', color: '#FFC107' }}
          />
          <span style={{ padding: '4%' }}>Assets</span>
        </NavLink>
      </li>
    </>
  )

  const PortfolioNavbar = () => (
    <>
      <li>
        <NavLink
          to="/PMS/Admin_dashboard"
          className="nav-link"

          onClick={() => {
            setShowPortfolioDetails(!showPortfolioDetails)
            setTimeout(() => {
              navigate('/PMS/Admin_dashboard')
            }, 500)
          }}
        >
          <GridViewIcon style={{ fontSize: '24px', color: '#FFC107' }} />
          <span style={{ padding: '4%' }}>Crypto Portfolios</span>
        </NavLink>
      </li>
      {showPortfolioDetails == true
        ? (
          <>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/MainManageAssetsWallets"
                className="nav-link"

              >
                <AccountBalanceWalletIcon
                  style={{ fontSize: '25px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Wallets</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/Investments"
                className="nav-link"

              >
                <InventoryIcon
                  style={{ fontSize: '25px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Investments</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/ViewExchanges"
                className="nav-link"

              >
                <CurrencyExchangeIcon
                  style={{ fontSize: '23px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Exchanges</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/Admin/Transactions"
                className="nav-link"

              >
                <AccountBalanceWalletIcon
                  style={{ fontSize: '23px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Transactions</span>
              </NavLink>
            </li>

          </>
        )
        : (
          <></>
        )}
    </>
  )

  const AccountancyNavbar = () => (
    <>
      <li>
        <hr id="separator" />
        <NavLink
          to="/PMS/Accounting_Data"
          className="nav-link"

          onClick={() => {
            setShowAccountancyDetails(!showAccountancyDetails)
          }}
        >
          <HistoryIcon style={{ fontSize: '23px', color: '#FFC107' }} />
          <span style={{ padding: '2%' }}>Accountancy</span>
        </NavLink>
      </li>
      {showAccountancyDetails == true
        ? (
          <>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/Accounting_data"
                className="nav-link"

              >
                <AccountBalanceWalletIcon
                  style={{ fontSize: '25px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Accounts</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/deleted_entries"
                className="nav-link"

              >
                <InventoryIcon
                  style={{ fontSize: '25px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Trash</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/payments"
                className="nav-link"

              >
                <CurrencyExchangeIcon
                  style={{ fontSize: '23px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Payments</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/ledger"
                className="nav-link"

              >
                <AccountBalanceWalletIcon
                  style={{ fontSize: '23px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Ledgers</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/balance"
                className="nav-link"

              >
                <AccountBalanceWalletIcon
                  style={{ fontSize: '23px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Balance</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/shareholders"
                className="nav-link"

              >
                <AccountBalanceWalletIcon
                  style={{ fontSize: '23px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Shareholders</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/entity"
                className="nav-link"

              >
                <AccountBalanceWalletIcon
                  style={{ fontSize: '23px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Entity</span>
              </NavLink>
            </li>
          </>
        )
        : (
          <></>
        )}
    </>
  )

  const ProfileNavbar = () => (
    <>
      <li>
        <NavLink
          to="/PMS/Admin/Person"
          className="nav-link"

        >
          <PersonIcon style={{ fontSize: '26px', color: '#FFC107' }} />
          <span style={{ padding: '4%' }}>Person</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/PMS/Admin/Organisation"
          className="nav-link"

        >
          <GroupsIcon style={{ fontSize: '26px', color: '#FFC107' }} />
          <span style={{ padding: '4%' }}>Organisation</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/PMS/Accountant"
          className="nav-link"

        >
          <HistoryIcon style={{ fontSize: '23px', color: '#FFC107' }} />
          <span style={{ padding: '2%' }}>Accountant</span>
        </NavLink>
      </li>
    </>
  )

  const AuditLogNavbar = () => (
    <>
      <hr id="separator" />
      <li style={{ marginBottom: '3em' }}>
        <NavLink
          to="/PMS/Investment/LogHistory"
          className="nav-link"

        >
          <HistoryIcon style={{ fontSize: '23px', color: '#FFC107' }} />
          <span style={{ padding: '2%' }}>Audit Info</span>
        </NavLink>
      </li>
    </>
  )

  const TreasurerNavbar = () => (
    <>
      <li>
        <NavLink
          //to="/PMS/TreasuryMain"
          className="nav-link"

          onClick={() => {
            setShowTreasuryItems(!showTreasuryItems)
            //setTimeout(() => {
            //                navigate('/PMS/Treasury')
            //            }, 500)
          }}
        >
          <RequestQuoteIcon style={{ fontSize: '26px', color: '#FFC107' }} />
          <span style={{ padding: '4%' }}>Treasury</span>
        </NavLink>
      </li>
      {
        showTreasuryItems ? (
          <>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/Treasury"
                className="nav-link"

              >
                <HistoryIcon
                  style={{ fontSize: '25px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Portfolios</span>
              </NavLink>
            </li>
            <li style={{ marginLeft: '2.5em' }}>
              <NavLink
                to="/PMS/TreasuryRecords"
                className="nav-link"

              >
                <HistoryIcon
                  style={{ fontSize: '25px', color: '#FFC107' }}
                />
                <span style={{ padding: '4%' }}>Records</span>
              </NavLink>
            </li>
          </>
        ) :
          (<></>)
      }
    </>
  )

  const TreasuryNavbarNew = () => {
    return (
      <ul className="sidebar">
        <li style={{ marginLeft: '2.5em' }}>
          <NavLink
            to="/PMS/Treasury"
            className="nav-link"

          >
            <HistoryIcon
              style={{ fontSize: '25px', color: '#FFC107' }}
            />
            <span style={{ padding: '4%' }}>Portfolios</span>
          </NavLink>
        </li>
        <li style={{ marginLeft: '2.5em' }}>
          <NavLink
            to="/PMS/TreasuryRecords"
            className="nav-link"

          >
            <HistoryIcon
              style={{ fontSize: '25px', color: '#FFC107' }}
            />
            <span style={{ padding: '4%' }}>Records</span>
          </NavLink>
        </li>
      </ul>
    );
  }

  const NewAdminMenu = () => {
    return (
      <>
        <hr />
        <a href="/PMS/Admin/Person" title="Person">
          <PersonIcon />
          <span>Person</span>
        </a>
        <a href="/PMS/Admin/Organisation" title="Organisation">
          <GroupsIcon />
          <span>Organisation</span>
        </a>

        <a href="/PMS/Accountant">
          <CoPresentIcon />
          <span>Accountant</span>
        </a>

        <a href="/PMS/ActivityLog">
          <HistoryIcon />
          <span>Activity Log</span>
        </a>        
      </>
    );
  }

  const NewDropMenu = () => {
    return (
      
      <div className="sidenav">
        {
          (roleId.includes('superadmin') || roleId.includes('admin') || roleId.includes('crypto') ? (<>         
        <button className="dropdown-btn" code="CRYPTO">
          <GridViewIcon />
          <span>Crypto</span>
          <ArrowDropDown className="mui-dropdown-arrow" style={{ display: 'none' }} />
          <ArrowDropUp className="mui-dropdown-arrow" />
        </button>
        <div className="dropdown-container">
          <a href="/PMS/CryptoDashboard" title="Dashboard">
            <AccountBalanceWalletIcon />
            <span>Dashboard</span>
          </a>
          <a href="/PMS/Admin_dashboard" title="Porfolios">
            <AccountBalanceWalletIcon />
            <span>Portfolios</span>
          </a>
          <a href="/PMS/MainManageAssetsWallets" title="Wallets">
            <AccountBalanceWalletIcon />
            <span>Wallets</span>
          </a>
          <a href="/PMS/Investments" title="Investments">
            <InventoryIcon />
            <span>Investments</span>
          </a>
          <a href="/PMS/ViewExchanges" title="Exchanges">
            <CurrencyExchangeIcon />
            <span>Exchanges</span>
          </a>
          <a href="/PMS/Admin/Transactions">
            <AccountBalanceWalletIcon />
            <span>Transactions</span>
          </a>
        </div>
        </>) : <></>)
      }
        {
          (roleId.includes('superadmin') || roleId.includes('admin') || roleId.includes('treasury') ? (<>        
        <hr />
        <button className="dropdown-btn" code="TREASURY">
          <GridViewIcon />
          <span>Treasury</span>
          <ArrowDropDown className="mui-dropdown-arrow" style={{ display: 'none' }} />
          <ArrowDropUp className="mui-dropdown-arrow" />
        </button>
        <div className="dropdown-container">
          <a href="/PMS/TreasuryDashboard" title="Dashboard">
            <AccountBalanceWalletIcon />
            <span>Dashboard</span>
          </a>
          <a href="/PMS/Treasury" title="Porfolios">
            <AccountBalanceWalletIcon />
            <span>Portfolios</span>
          </a>
          <a href="/PMS/TreasuryRecords" title="Records">
            <AccountBalanceWalletIcon />
            <span>Records</span>
          </a>
        </div>
        </>):<></>)
        }
        {
          (roleId.includes('superadmin') || roleId.includes('admin') || roleId.includes('realestate') ? (<>
        <hr />
        <button className="dropdown-btn" code="PROPERTY">
          <GridViewIcon />
          <span>Real Estate</span>
          <ArrowDropDown className="mui-dropdown-arrow" style={{ display: 'none' }} />
          <ArrowDropUp className="mui-dropdown-arrow" />
        </button>
        <div className="dropdown-container">
          <a href="/PMS/Property" title="Porfolios">
            <AccountBalanceWalletIcon />
            <span>Portfolios</span>
          </a>
          <a href="/PMS/PropertyRecords" title="Records">
            <AccountBalanceWalletIcon />
            <span>Records</span>
          </a>
        </div></>) : (<></>))
        }
        {(roleId.includes('superadmin') || roleId.includes('admin')) && (<NewAdminMenu />)}
      </div>
    );
  };

  return (
    <div>
      <aside
        className="sidebar-admin-container"
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100vh',
          position: 'sticky'
        }}
      >
        <NewDropMenu />
      </aside>
    </div>
  )
}
export default SidebarAdmin

import { React, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "./CommonTable.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Image, Row, Col, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SortIcon from "@mui/icons-material/Sort";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import AuditHistorySingleInvestment from "../../Admin/DashboardAdmin/AuditHistorySingleInvestment";
import InvestmentHistoryTop from "../../Admin/DashboardAdmin/InvestmentHistoryTop";
import InvestmentLogHistoryTop from "../../Admin/DashboardAdmin/InvestmentLogHistoryTop";
import { setExpandRowCol } from "../../Redux/appSlice";
import { useLoaderData } from "react-router-dom";
import PaymentHistoryTop from "../../PaymentLogs/PaymentHistoryTop";

const CommonTableInvTop = (props) => {
  const location = useLocation();
  const { expandRowCol } = useSelector((store) => store.app);
  // console.log("expandRowCol",expandRowCol);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  console.log(props);
  const expandRow = {
    // onlyOneExpanding: true,
    // expandByColumnOnly: true,
    renderer: (row) => {
      return (
        <>
          {location.pathname == "/PMS/TransactionInvestmentHistory" ? (
            <div>
              {<InvestmentHistoryTop days={props.days} portfolio_id={row} />}
            </div>
          ) : location.pathname == "/PMS/Investment/LogHistory" ? (
            <div>{<InvestmentLogHistoryTop invest_id={row} />}</div>
          ) : (
            <div>{<PaymentHistoryTop pay_id={row} />}</div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expanded: [0],
  };
  console.log(expandRow);
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  let ab = [];
  const handleToggle = (toggle, datafield) => {
    if (toggle == true) {
      // props.setSelectData([])
      // console.log(localStorage.getItem('key')==null ? 'a' :localStorage.getItem('key') )
        console.log('no')
        ab.push(datafield);
      let emptyData=''
      let a=localStorage.getItem('key')==null ? emptyData : localStorage.getItem('key').split(',')
      let c=a[0]=='' ? [ab.filter(i=>!a.includes(i))] : [ab.filter(i=>!a.includes(i)),...a]
      localStorage.setItem("key", c);
      // console.log('true',a,c)
      // dispatch(setSelectedColumnId({type:"ADD_FILTER",value:datafield}));
    } else {
      // console.log('false')
      let d1 = localStorage.getItem("key");
      // console.log(d1.split(","));
      let data1 = d1.split(",").filter((i) => i!=datafield);
      // let d2=[...d1,...datafield]
      // console.log(data1,datafield,localStorage.getItem('key')==null)
      localStorage.setItem("key", data1);
      ab=[]
    }
    // console.log(toggle, datafield);
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div style={{ marginLeft: "-2px", top: "-9px", position: "relative" }}>
        <span
          style={{ color: "#FFC107", cursor: "pointer" }}
          onClick={handleClick}
        >
          <SystemUpdateAltIcon style={{ marginTop: "4px", height: "20px" }} />
        </span>
      </div>
    );
  };
  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    <Dropdown alignRight={true} style={{ top: "-18px", marginLeft: "56px" }}>
      <Dropdown.Toggle
        id={"options-button"}
        // @ts-ignore
        variant="borderless-dark"
        bsPrefix="no-chevron"
        size="sm"
        style={{ color: "#FFC107", fontSize: "25px", fontWeight: "700" }}
      >
        <SortIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          willChange: "transform",
          fontSize: "14px",
          background: "rgb(31, 33, 37)",
          borderColor: "white",
        }}
      >
        {columns
          ?.map((column) => ({
            ...column,
            toggle: toggles[column.dataField],
          }))
          .map((column, index) => (
            <Form.Check
              type="switch"
              key={column.dataField}
              inline
              multiple
              label={column.text}
              id={column.dataField}
              checked={column.toggle}
              aria-checked={column.toggle ? "true" : "false"}
              onChange={() => {
                // console.log(column.toggle)
                handleToggle(column.toggle, column.dataField);
                onColumnToggle(column.dataField);
              }}
            />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
  const Record = props.loading
    ? ""
    : !(props.data.length > 0) && "No Record Found";
  const keyName =
    location.pathname == "/PMS/TransactionInvestmentHistory"
      ? "investment_name"
      : location.pathname == "/PMS/Investment/LogHistory"
      ? "investment_id_time_updated"
      : "payment_id";
  const tableClass =
    location.pathname == "/PMS/TransactionInvestmentHistory"
      ? "table-responsive investment-border"
      : "table-responsive";

  return (
    <div className="tablet" style={{ width: "100%" }}>
      {props.data && (
        <ToolkitProvider
          bootstrap4
          keyField={keyName}
          data={props.data}
          columns={props.columns}
          columnToggle
          draggable
          search
          exportCSV={{
            fileName: "Transactions.csv",
            onlyExportFiltered: true,
            exportAll: false,
          }}
          defaultSorted={defaultSorted}
        >
          {(props) => (
            <Row>
              <Col
                style={{ flexDirection: "column", maxWidth: "100%", flex: 1 }}
              >
                 {Object.values(props.columnToggleProps.toggles).filter(
                  (i) => i == true
                ).length > 0 ? (
                <BootstrapTable
                  filter={filterFactory()}
                  {...props.baseProps}
                  wrapperClasses={tableClass}
                  noDataIndication={Record}
                  expandRow={expandRow}
                  expandColumnOptions={{ expandColumnVisible: true }}
                />) : <></>}
              </Col>
              <Col
                style={{
                  flexDirection: "column",
                  flex: 0,
                  position: "fixed",
                  width: ".5em",
                  right: "16.5em",
                  top: "5em",
                  display: "flex",
                  flexDirection: "row",
                  zIndex: 1,
                }}
              >
                {location.pathname == "/PMS/TransactionInvestmentHistory" ||
                // location.pathname=='/PMS/payment_logs' || 
                location.pathname == "/PMS/Investment/LogHistory" ? (
                  <></>
                ) : (
                  <CustomToggleList {...props.columnToggleProps} />
                )}
                {/* {location.pathname=='/PMS/payment_logs' ? <></> :
                  <MyExportCSV { ...props.csvProps } />} */}
              </Col>
            </Row>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
};

export default CommonTableInvTop;

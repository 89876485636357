import React, { useEffect, useState, useRef } from 'react'
import { Row, Container, Col, Card, Table, Modal, Button, NavLink } from 'react-bootstrap'
import { postAsync } from '../common/HttpClient';
import { numberWithCommas } from '../helpers/common';
import { AspectRatio, Close } from '@mui/icons-material'
import { Select, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material';

export default () => {
    const getId = localStorage.getItem('sub_Id');

    const [dashboardData, setDashboardData] = useState([]);
    const [showZoom, setShowZoom] = useState(false);
    const [zoomContent, setZoomContent] = useState();

    const [byPortfolio, setByPortfolio] = useState([]);
    const [byWallet, setByWallet] = useState([]);
    const [byNetwork, setByNetwork] = useState([]);
    const [byToken, setByToken] = useState([]);
    const [filterNetwork, setFilterNetwork] = useState([]);
    const [filterToken, setFilterToken] = useState([]);

    // filters
    const [widgetFilter, setWidgetFilter] = useState({
        portfolio: [],
        portfolioOptions: [],
        wallet: [],
        walletOptions: [],
        network: [],
        networkOptions: [],
        token: [],
        tokenOptions: []
    });     

    const renderDropDownList = (label, data, setData, key, optionKey, func) => {
        return (
            <Select
                //id="ddlByClient"
                multiple
                value={data[key]}
                onChange={(e, a) => {
                    if(a.props.value == 'ALL' && data[optionKey].includes(a.props.value)) {
                        data[optionKey] = [];
                    }
                    else if(a.props.value == 'ALL' && !widgetFilter[optionKey].includes(a.props.value)) {
                        data[optionKey] = widgetFilter[key];
                    }
                    else {
                        if(!data[optionKey].includes(a.props.value)) {
                            data[optionKey] = [... [a.props.value].concat(data[optionKey])];
                        }
                        else {
                            data[optionKey] = data[optionKey].filter(x => x != a.props.value);
                        }
                        if(data[optionKey].filter(x => x != 'ALL').length == data[key].filter(x => x != 'ALL').length && 
                            !data[optionKey].includes('ALL'))
                            data[optionKey] = [... ['ALL'].concat(data[optionKey])];
                        else if (data[optionKey].filter(x => x != 'ALL').length != data[key].filter(x => x != 'ALL').length && 
                            data[optionKey].includes('ALL'))
                            data[optionKey] = data[optionKey].filter(x => x != 'ALL');
    
                    }
                    setData({... data});
    
                    if(func)
                        func();
                }}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => label}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiCheckbox-root': {
                                color: 'white'
                            },
                            '& .MuiList-root': {
                                background: 'rgb(31, 33, 37) !important',
                                color: 'white !important',
                                //width: '150px'                                
                            }
                        }
                    }
                }}
                sx={{
                    width: '150px',
                    height: '27px',
                    border: '1px solid #d9d9d9 !important',
                    '.MuiButtonBase-root': {
                        color: 'white'
                    }
                }}
                style={{
                    borderRadius: '4px',
                    width: '200px !important',
                    height: '32px !important',
                    fontSize: '14px',
                    left: '0px'
                }}
            >
    
                {
                    data[key].map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={data[optionKey].indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))
                }
    
            </Select>);
    }    

    const buildCardButton = (isZoom, evnt) => {
        return (
            <span className="card-zoom">
                <NavLink
                    title="Expand"
                    onClick={() => { setZoomContent(evnt()); setShowZoom(true);  }}
                    style={{ display: (isZoom ? 'none' : '') }}
                >
                    <AspectRatio />
                </NavLink>
                <NavLink
                    title="Close"
                    onClick={() => { setShowZoom(false); }}
                    style={{ display: (isZoom ? '' : 'none') }}
                >
                    <Close />
                </NavLink>
            </span>
        );
    };

    const getCardBodyStyle = (isZoom = false) => {
        return { height: isZoom ? 'auto' : '250px', overflowY: !isZoom ? 'auto' : 'unset' };
    };

    const generateCard = (title, contents, balance, evnt, isZoom = false) => {        
        return (
            <Card style={{ marginBottom: (isZoom ? '0px' : '30px') }}>
                <Card.Header>
                    {title}
                    {buildCardButton(isZoom, evnt)}
                </Card.Header>
                <Card.Body
                    style={getCardBodyStyle(isZoom)}
                >
                    {contents()}
                </Card.Body>
                <Card.Footer>
                    <div className="dashboard-widget-footer">
                        <span>Grand Total</span>
                        {numberWithCommas(balance(), true)}
                    </div>
                </Card.Footer>
            </Card>
        );
    };

    const buildByPortfolio = () => {
        return (

            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {                                
                                renderDropDownList("Portfolio", widgetFilter, setWidgetFilter, 'portfolio', 'portfolioOptions', () =>
                                    setByPortfolio(dashboardData.map(row => { 
                                        return {
                                            Id: row.portfolioId,
                                            portfolioName: row.portfolioName, 
                                            CurrentBalanceUSD: row.usd_Balance 
                                        }
                                    }).filter(x => widgetFilter.portfolioOptions.includes(x.portfolioName))))
                            }                            
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {byPortfolio == null || byPortfolio.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        byPortfolio.map(x => {
                            return (<tr key={x.Id}><td>{x.portfolioName}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(x.CurrentBalanceUSD, true)}</td></tr>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const buildByWallet = () => {
        var pfName = [... new Set(dashboardData.filter(x => widgetFilter.walletOptions.includes(x.portfolioName)).map(row => row.portfolioName))];
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th> 
                            {                                                          
                                renderDropDownList("Portfolio/Wallet", widgetFilter, setWidgetFilter, 'wallet', 'walletOptions', () => {
                                    var wallet = [];
                                    dashboardData.filter(x => widgetFilter.walletOptions.includes(x.portfolioName)).forEach(row => {
                                        row.wallets.forEach(w => {
                                            wallet.push({
                                                portfolioId: row.portfolioId,
                                                portfolioName: row.portfolioName,
                                                walletId: w.walletId,
                                                walletName: w.walletName,
                                                CurrentBalanceUSD: w.usd_Balance
                                            })
                                        });
                                    });                                    
                                    setByWallet(wallet);
                                })
                            }
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {pfName == null || pfName.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        pfName.map(x => {
                            var subTree = byWallet.filter(y => y.portfolioName === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.walletId}><td style={{ paddingLeft: '40px' }}>{sub.walletName}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}
                </tbody>
            </table>
        );
    };    

    const buildByNetwork = () => {
        var filter = filterNetwork.filter(x => widgetFilter.networkOptions.includes(x));
        return (

            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {                                
                                renderDropDownList("Network/Token", widgetFilter, setWidgetFilter, 'network', 'networkOptions', () => generateNetworkData())
                            }                            
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {filter == null || filter.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        filter.map(x => {
                            var subTree = byNetwork.filter(y => y.networkName === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.tokenName}><td style={{ paddingLeft: '40px' }}>{sub.tokenName}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}                   
                </tbody>
            </table>
        );
    };    

    const generateNetworkData = () => {
        var networks = [];              
        dashboardData.forEach(pf => {            
            pf.wallets.forEach(w => {
                w.addresses.forEach(addr => {
                    addr.tokens.forEach(t => {
                        var fil = networks.filter(x => x.networkName == addr.chain && x.tokenName == t.token);
                        if(fil.length > 0)
                            fil[0].CurrentBalanceUSD += t.usd_Balance;
                        else
                            networks.push({ Id: addr.address, networkName: addr.chain, tokenName: t.token, CurrentBalanceUSD: t.usd_Balance });
                    });
                });
            });
        });
        setByNetwork(networks);
    }

    const buildByToken = () => {
        var filter = filterToken.filter(x => widgetFilter.tokenOptions.includes(x));
        return (

            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {                                
                                renderDropDownList("Token/Portfolio", widgetFilter, setWidgetFilter, 'token', 'tokenOptions', () => generateTokenData())
                            }                            
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {filter == null || filter.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        filter.map(x => {
                            var subTree = byToken.filter(y => y.token === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.portfolioId}><td style={{ paddingLeft: '40px' }}>{sub.portfolioName}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}                   
                </tbody>
            </table>
        );
    };    

    const generateTokenData = () => {
        var tokens = [];              
        dashboardData.forEach(pf => {            
            pf.wallets.forEach(w => {
                w.addresses.forEach(addr => {
                    addr.tokens.forEach(t => {
                        var fil = tokens.filter(f => f.portfolioId == pf.portfolioId && f.token == t.token);
                        if(fil.length > 0)
                            fil[0].CurrentBalanceUSD += t.usd_Balance;        
                        else
                            tokens.push({ portfolioId: pf.portfolioId, portfolioName: pf.portfolioName, token: t.token, CurrentBalanceUSD: t.usd_Balance });
                    });
                });
            });
        });
        setByToken(tokens);
    }    

    useEffect(async () => {
        var results = await postAsync('wallet', { userId: getId });
        results = results.results.data;

        var pf = [... new Set(results.map(row => row.portfolioName))];
        pf = pf.sort();
        pf = ['ALL'].concat(pf);

        var wallet = [];
        var networkFilter = [];
        var tokenFilter = [];
        results.forEach(row => {
            row.wallets.forEach(w => {
                wallet.push({
                    portfolioId: row.portfolioId,
                    portfolioName: row.portfolioName,
                    walletId: w.walletId,
                    walletName: w.walletName,
                    CurrentBalanceUSD: w.usd_Balance
                });
                w.addresses.forEach(addr => {
                    networkFilter.push(addr.chain);
                    addr.tokens.forEach(t => {
                        tokenFilter.push(t.token);
                    });
                });
            });
        });
        networkFilter = [... new Set(networkFilter)];
        setFilterNetwork(networkFilter);
        networkFilter = ['ALL'].concat(networkFilter);
        tokenFilter = [... new Set(tokenFilter)];
        setFilterToken(tokenFilter);
        tokenFilter = ['ALL'].concat(tokenFilter);
        setWidgetFilter({
            portfolio: pf,
            portfolioOptions: pf,
            wallet: pf,
            walletOptions: pf,
            network: networkFilter,
            networkOptions: networkFilter,
            token: tokenFilter,
            tokenOptions: tokenFilter
        });  

        setByPortfolio(results.map(row => { 
            return {
                Id: row.portfolioId,
                portfolioName: row.portfolioName, 
                CurrentBalanceUSD: row.usd_Balance 
            }
        }));

        setByWallet(wallet);
        setDashboardData(results);        
    }, []);

    useEffect(() => {
        generateNetworkData();     
        generateTokenData();  
    }, [dashboardData]);

    useEffect(() => {
        setZoomContent(generateCard('By Portfolio', buildByPortfolio, () => byPortfolio.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByPortfolio, true));
    }, [byPortfolio]);    

    useEffect(() => {
        setZoomContent(generateCard('By Wallet', buildByWallet, () => byWallet.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByWallet, true));
    }, [byWallet]);   
    
    useEffect(() => {
        setZoomContent(generateCard('By Network', buildByNetwork, () => byNetwork.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByNetwork, true));
    }, [byNetwork]);       

    useEffect(() => {
        setZoomContent(generateCard('By Network', buildByToken, () => byToken.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByToken, true));
    }, [byToken]);      

    return (
        <Container fluid>
            <Modal
                className="dashboard-modal"
                show={showZoom}
                size="lg"
                top
                aria-labelledby="zoom-header"
                onHide={() => setShowZoom(false)}
            >
                <Modal.Body>
                    <Row className="pms-dashboard">
                        <Col lg={12}>
                            {zoomContent}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Row>
                <Col lg={12}>
                    <Row style={{ marginBottom: '8px' }}>
                        <span className="p-2 pageheader">
                            <h3 className="pagetitle" >
                                Crypto Dashboard
                            </h3>
                        </span>
                    </Row>
                </Col>
            </Row>
            <Row className="pms-dashboard" style={{ marginTop: '30px' }}>
                <Col lg={4}>
                    {
                        generateCard('By Portfolio', buildByPortfolio, () => byPortfolio.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0),
                            () => generateCard('By Portfolio', buildByPortfolio, () => byPortfolio.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByPortfolio, true))
                    }
                </Col>
                <Col lg={4}>
                    {
                        generateCard('By Wallet', buildByWallet, () => byWallet.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0),
                            () => generateCard('By Wallet', buildByWallet, () => byWallet.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByWallet, true))
                    }
                </Col>    
                <Col lg={4}>
                    {
                        generateCard('By Network', buildByNetwork, () => byNetwork.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0),
                            () => generateCard('By Network', buildByNetwork, () => byNetwork.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByNetwork, true))
                    }
                </Col>  
                <Col lg={4}>
                    {
                        generateCard('By Token', buildByToken, () => byToken.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0),
                            () => generateCard('By Token', buildByToken, () => byToken.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), buildByToken, true))
                    }
                </Col>                                           
            </Row>
        </Container>
    );
};
import React, { useEffect, useState, useRef } from 'react'
import { Row, Container, Col, Card, Table, Modal, Button, NavLink } from 'react-bootstrap'
import { postAsync } from '../common/HttpClient';
import { numberWithCommas } from '../helpers/common';
import { AspectRatio, Close } from '@mui/icons-material'
import { Select, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material';

export default () => {
    const getId = localStorage.getItem('sub_Id');

    const [dashboardData, setDashboardData] = useState([]);
    const [byClient, setByClient] = useState([]);
    const [byCountry, setByCountry] = useState([]);
    const [byCountryAndBank, setByCountryAndBank] = useState([]);
    const [byCurrency, setByCurrency] = useState([]);
    const [byCurrencyAndType, setByCurrencyAndType] = useState([]);
    const [showZoom, setShowZoom] = useState(false);
    const [zoomContent, setZoomContent] = useState();

    // filters
    const [widgetFilter, setWidgetFilter] = useState({
        client: [],
        clientOptions: [],
        country: [],
        countryOptions: [],
        countryBank: [],
        countryBankOptions: [],
        currency: [],
        currencyOptions: [],
        currencyType: [],
        currencyTypeOptions: []
    });   

    const renderDropDownList = (label, data, setData, key, optionKey, func) => {
        return (
            <Select
                //id="ddlByClient"
                multiple
                value={data[key]}
                onChange={(e, a) => {
                    if(a.props.value == 'ALL' && data[optionKey].includes(a.props.value)) {
                        data[optionKey] = [];
                    }
                    else if(a.props.value == 'ALL' && !widgetFilter[optionKey].includes(a.props.value)) {
                        data[optionKey] = widgetFilter[key];
                    }
                    else {
                        if(!data[optionKey].includes(a.props.value)) {
                            data[optionKey] = [... [a.props.value].concat(data[optionKey])];
                        }
                        else {
                            data[optionKey] = data[optionKey].filter(x => x != a.props.value);
                        }
                        if(data[optionKey].filter(x => x != 'ALL').length == data[key].filter(x => x != 'ALL').length && 
                            !data[optionKey].includes('ALL'))
                            data[optionKey] = [... ['ALL'].concat(data[optionKey])];
                        else if (data[optionKey].filter(x => x != 'ALL').length != data[key].filter(x => x != 'ALL').length && 
                            data[optionKey].includes('ALL'))
                            data[optionKey] = data[optionKey].filter(x => x != 'ALL');
    
                    }
                    setData({... data});
    
                    if(func)
                        func();
                }}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => label}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiCheckbox-root': {
                                color: 'white'
                            },
                            '& .MuiList-root': {
                                background: 'rgb(31, 33, 37) !important',
                                color: 'white !important',
                                width: '150px'                                
                            }
                        }
                    }
                }}
                sx={{
                    width: '150px',
                    height: '27px',
                    border: '1px solid #d9d9d9 !important',
                    '.MuiButtonBase-root': {
                        color: 'white'
                    }
                }}
                style={{
                    borderRadius: '4px',
                    width: '200px !important',
                    height: '32px !important',
                    fontSize: '14px',
                    left: '0px'
                }}
            >
    
                {
                    data[key].map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={data[optionKey].indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))
                }
    
            </Select>);
    }    

    const buildCardButton = (isZoom, evnt) => {
        return (
            <span className="card-zoom">
                <NavLink
                    title="Expand"
                    onClick={() => { setZoomContent(evnt()); setShowZoom(true);  }}
                    style={{ display: (isZoom ? 'none' : '') }}
                >
                    <AspectRatio />
                </NavLink>
                <NavLink
                    title="Close"
                    onClick={() => { setShowZoom(false); }}
                    style={{ display: (isZoom ? '' : 'none') }}
                >
                    <Close />
                </NavLink>
            </span>
        );
    };

    const getCardBodyStyle = (isZoom = false) => {
        return { height: isZoom ? 'auto' : '250px', overflowY: !isZoom ? 'auto' : 'unset' };
    };

    const generateCard = (title, contents, balance, evnt, isZoom = false) => {        
        return (
            <Card style={{ marginBottom: (isZoom ? '0px' : '30px') }}>
                <Card.Header>
                    {title}
                    {buildCardButton(isZoom, evnt)}
                </Card.Header>
                <Card.Body
                    style={getCardBodyStyle(isZoom)}
                >
                    {contents()}
                </Card.Body>
                <Card.Footer>
                    <div className="dashboard-widget-footer">
                        <span>Grand Total</span>
                        {balance()}
                    </div>
                </Card.Footer>
            </Card>
        );
    };

    const buildByClient = () => {
        var client = [... new Set(byClient.map(x => x.Category))];        
        client = client.sort();
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {widgetFilter == null || widgetFilter.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        client.map(x => {
                            var subTree = byClient.filter(y => y.Category === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.Id}><td style={{ paddingLeft: '40px' }}>{sub.Code}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const calculateByClientTotal = () => {
        return numberWithCommas((byClient ? byClient.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0) : 0), true);
    };

    const buildByCountry = () => {
        return (

            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {                                
                                renderDropDownList("Country", widgetFilter, setWidgetFilter, 'country', 'countryOptions', () =>
                                    setByCountry(dashboardData.byCountry.filter(x => widgetFilter.countryOptions.includes(x.Region))))
                            }                            
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {byCountry == null || byCountry.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        byCountry.map(x => {
                            return (<tr key={x.Id}><td>{x.Region}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(x.CurrentBalanceUSD, true)}</td></tr>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const calculateByCountryTotal = () => {
        return numberWithCommas((byCountry ? byCountry.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0) : 0), true);
    };

    const buildByCountryAndBank = () => {
        var countries = [... new Set(byCountryAndBank.map(row => row.Region))];
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th> 
                            {                                                          
                                renderDropDownList("Country/Bank", widgetFilter, setWidgetFilter, 'countryBank', 'countryBankOptions', () =>
                                    setByCountryAndBank(dashboardData.byCountryBank.filter(x => widgetFilter.countryBankOptions.includes(x.Region))))
                            }
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {countries == null || countries.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        countries.map(x => {
                            var subTree = byCountryAndBank.filter(y => y.Region === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.Id}><td style={{ paddingLeft: '40px' }}>{sub.BankName}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const buildByCurrency = () => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {                                                          
                                renderDropDownList("Currency", widgetFilter, setWidgetFilter, 'currency', 'currencyOptions', () =>
                                    setByCurrency(dashboardData.byCurrency.filter(x => widgetFilter.currencyOptions.includes(x.Currency))))
                            }                            
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {byCurrency == null || byCurrency.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        byCurrency.map(x => {
                            return (<tr key={x.Id}><td>{x.Currency}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(x.CurrentBalanceUSD, true)}</td></tr>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const buildByCurrencyAndType = () => {
        var currencies = [... new Set(byCurrencyAndType.map(row => row.Currency))];
        return (

            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {                                
                                renderDropDownList("Currency/Type", widgetFilter, setWidgetFilter, 'currencyType', 'currencyTypeOptions', () =>
                                    setByCurrencyAndType(dashboardData.byCurrencyType.filter(x => widgetFilter.currencyTypeOptions.includes(x.Currency))))
                            }                            
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {currencies == null || currencies.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        currencies.map(x => {
                            var subTree = byCurrencyAndType.filter(y => y.Currency === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.Id}><td style={{ paddingLeft: '40px' }}>{sub.Type}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}
                </tbody>
            </table>
        );
    }

    const bindStates = (results) => {
        setDashboardData(results.results);        
        setByCountry(results.results.byCountry);
        setByCountryAndBank(results.results.byCountryBank);
        setByCurrency(results.results.byCurrency);
        setByCurrencyAndType(results.results.byCurrencyType);        
    }

    useEffect(async () => {
        var results = await postAsync('dashboard/treasury', { userId: getId });
        var client = [... new Set(results.results.byClient.map(row => row.Category))];
        client = client.sort();
        client = ['ALL'].concat(client);
        var country = [... new Set(results.results.byCountry.map(row => row.Region))];
        country = country.sort();
        country = ['ALL'].concat(country);        
        var currency = [... new Set(results.results.byCurrency.map(row => row.Currency))];
        currency = currency.sort();
        currency = ['ALL'].concat(currency);                
        setWidgetFilter({ 
            client: client, 
            clientOptions: client, 
            country: country, 
            countryOptions: country,
            countryBank: country,
            countryBankOptions: country,
            currency: currency,
            currencyOptions: currency,
            currencyType: currency,
            currencyTypeOptions: currency
        });        
        setByClient(results.results.byClient);
        bindStates(results);
    }, []);

    useEffect(async () => {
        setZoomContent(generateCard('By Client', buildByClient, calculateByClientTotal, buildByClient, true));

        var results = await postAsync('dashboard/treasury', { userId: getId, boCode: JSON.stringify(widgetFilter.clientOptions) });
        bindStates(results);
    }, [byClient]);

    useEffect(() => {
        setZoomContent(generateCard('By Country', buildByCountry, calculateByCountryTotal, buildByCountry, true));
    }, [byCountry]);    

    useEffect(() => {
        setZoomContent(generateCard('By Country and Bank', buildByCountryAndBank,
            () => numberWithCommas(byCountryAndBank.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCountryAndBank, true));
    }, [byCountryAndBank]);     

    useEffect(() => {
        setZoomContent(generateCard('By Currency', buildByCurrency,
            () => numberWithCommas(byCurrency.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrency, true));
    }, [byCurrency]);  
    
    useEffect(() => {
        setZoomContent(generateCard('By Currency And Type', buildByCurrencyAndType,
            () => numberWithCommas(byCurrencyAndType.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrencyAndType, true));
    }, [byCurrencyAndType]);     

    return (
        <Container fluid>
            <Modal
                className="dashboard-modal"
                show={showZoom}
                size="lg"
                top
                aria-labelledby="zoom-header"
                onHide={() => setShowZoom(false)}
            >
                <Modal.Body>
                    <Row className="pms-dashboard">
                        <Col lg={12}>
                            {zoomContent}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Row>
                <Col lg={12}>
                    <Row style={{ marginBottom: '8px' }}>
                        <span className="p-2 pageheader">
                            <h3 className="pagetitle" >
                                Treasury Dashboard
                            </h3>
                        </span>
                    </Row>
                </Col>
            </Row>
            <Row className="pms-dashboard" style={{ marginTop: '30px' }}>
                <Col lg={12} style={{ marginBottom: "20px" }}>
                    <span style={{ color: "white" }}>Filter by: </span>
                    { 
                        renderDropDownList("Client", widgetFilter, setWidgetFilter, 'client', 'clientOptions', () =>
                            setByClient(dashboardData.byClient.filter(x => widgetFilter.clientOptions.includes(x.Category))))                    
                    }
                </Col>
                <Col lg={4}>
                    {
                        generateCard('By Client', buildByClient, calculateByClientTotal,
                            () => generateCard('By Client', buildByClient, calculateByClientTotal, buildByClient, true))
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard('By Country', buildByCountry, calculateByCountryTotal,
                            () => generateCard('By Country', buildByCountry, calculateByCountryTotal, buildByCountry, true))
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard(
                            'By Country and Bank',
                            buildByCountryAndBank,
                            () => numberWithCommas(byCountryAndBank.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true),
                            () => generateCard('By Country and Bank', buildByCountryAndBank,
                                () => numberWithCommas(byCountryAndBank.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCountryAndBank, true)
                        )
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard(
                            'By Currency',
                            buildByCurrency,
                            () => numberWithCommas(byCurrency.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true),
                            () => generateCard('By Currency', buildByCurrency,
                                () => numberWithCommas(byCurrency.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrency, true)
                        )
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard(
                            'By Currency And Type',
                            buildByCurrencyAndType,
                            () => numberWithCommas(byCurrencyAndType.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true),
                            () => generateCard('By Currency And Type', buildByCurrencyAndType,
                                () => numberWithCommas(byCurrencyAndType.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrencyAndType, true)
                        )
                    }
                </Col>
            </Row>
        </Container>
    );
};
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import useApi from '../hooks/useApi'
import { getUserId } from '../helpers/common'
import { AlertBox } from '../helpers/components/AlertBox'

export const DeleteModal = ({ visible, handleClose = () => {}, portfolio = {}, refreshTable = () => {} }) => {
  const [trigger, setTrigger] = useState()
  const [alert, setAlert] = useState({
    visible: false,
    type: 'success',
    msg: ''
  })

  const handleDelete = () => {
    setTrigger(+new Date())
  }

  const response = useApi({
    method: 'DELETE',
    options: {
      data: {
        userId: getUserId(),
        portfolioId: portfolio.portfolioId,
        id: portfolio.id
      }
    },
    trigger,
    url: `${process.env.REACT_APP_BASE_URL}/property`
  })

  useEffect(() => {
    if (response.response) {
      refreshTable()
      setAlert({
        visible: true,
        msg: 'Real estate Deleted!',
        type: 'success'
      })
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
        handleClose()
      }, 2000)
    }

    if (response.error) {
      setAlert({
        visible: true,
        msg: response.error,
        type: 'error'
      })
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
      }, 5000)
    }
  }, [response?.response, response?.error])

  return (
    <>
    <Modal
          show={visible}
          onHide={handleClose}
          style={{
            width: '30rem',
            marginTop: '17rem',
            overflow: 'auto',
            marginLeft: '35%',
            backgroundColor: '#222429',
            height: 'auto',
            border: '1px solid grey',
            borderRadius: '15px'
          }}
        >
          <Modal.Header
            style={{ backgroundColor: '#222429', border: 'none' }}
          >
            <Modal.Title
              style={{
                color: 'white',
                fontSize: '16px',
                marginTop: '-5%',
                marginLeft: '11%'
              }}
            >
              Are you sure you want to Delete this Real Estate?
            </Modal.Title>
          </Modal.Header>

        <Modal.Body style={{
          backgroundColor: '#222429',
          paddingTop: '-5%',
          paddingLeft: '20%'
        }}>
            <Row>
                <Col md={6}>ID</Col> {portfolio.propId}
            </Row>
            <Row>
                <Col md={6}>Project</Col> {portfolio.project}
            </Row>
            <Row>
                <Col md={6}>Unit</Col> {portfolio.unit}
            </Row>
            <Row>
                <Col md={6}>BO</Col> {portfolio.boNumber}
            </Row>
        </Modal.Body>

          <Modal.Footer
            style={{
              backgroundColor: '#222429',
              borderTop: 'none',
              paddingRight: '34%',
              marginTop: '-3%'
            }}
          >
            <Button
              variant="success"
              style={{ width: '25%', backgroundColor: '#006400', marginBottom: '2%' }}
              onClick={() => handleDelete()}
            >
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={handleClose}
              style={{ width: '25%', backgroundColor: '#b30000' }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <AlertBox type={alert.type} show={alert.visible} text={alert.msg}/>
    </>
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { Row, Container, Col, Card, Table, Modal, Button, NavLink } from 'react-bootstrap'
import { postAsync } from '../common/HttpClient';
import { numberWithCommas } from '../helpers/common';
import { AspectRatio, Close } from '@mui/icons-material'
import { Select, OutlinedInput, MenuItem, Checkbox, ListItemText, FormControl, InputLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles'

export default () => {
    const useStyles = makeStyles({
        noOptions: {
            color: 'white'
        }
    })

    const styles = useStyles()

    const getId = localStorage.getItem('sub_Id');

    const [dashboardData, setDashboardData] = useState({
        portfolios: []
    });
    const [byClient, setByClient] = useState([]);
    const [byCountry, setByCountry] = useState([]);
    const [byCountryAndBank, setByCountryAndBank] = useState([]);
    const [byCurrency, setByCurrency] = useState([]);
    const [byCurrencyAndType, setByCurrencyAndType] = useState([]);
    const [showZoom, setShowZoom] = useState(false);
    const [zoomContent, setZoomContent] = useState();
    const [filters, setFilters] = useState({
        portfolio: {
            PortfolioId: '',
            PortfolioName: ''
        },
        countries: {
            countryList: [],
            countrySelected: []
        },
        bankAndCountry: {
            list: [],
            selected: []
        },
        currencies: {
            list: [],
            selected: []
        },
        currencyType: {
            list: [],
            selected: []
        }
    })

    // filters
    const [widgetFilter, setWidgetFilter] = useState({
        client: [],
        clientOptions: [],
        country: [],
        countryOptions: [],
        countryBank: [],
        countryBankOptions: [],
        currency: [],
        currencyOptions: [],
        currencyType: [],
        currencyTypeOptions: []
    });

    const renderDropDownList = (label, data, setData, key, optionKey, func) => {

        return (
            <Select
                //id="ddlByClient"
                multiple
                value={data[key]}
                onChange={(e, a) => {
                    if (a.props.value == 'ALL' && data[optionKey].includes(a.props.value)) {
                        data[optionKey] = [];
                    }
                    else if (a.props.value == 'ALL' && !widgetFilter[optionKey].includes(a.props.value)) {
                        data[optionKey] = widgetFilter[key];
                    }
                    else {
                        if (!data[optionKey].includes(a.props.value)) {
                            data[optionKey] = [...[a.props.value].concat(data[optionKey])];
                        }
                        else {
                            data[optionKey] = data[optionKey].filter(x => x != a.props.value);
                        }
                        if (data[optionKey].filter(x => x != 'ALL').length == data[key].filter(x => x != 'ALL').length &&
                            !data[optionKey].includes('ALL'))
                            data[optionKey] = [...['ALL'].concat(data[optionKey])];
                        else if (data[optionKey].filter(x => x != 'ALL').length != data[key].filter(x => x != 'ALL').length &&
                            data[optionKey].includes('ALL'))
                            data[optionKey] = data[optionKey].filter(x => x != 'ALL');

                    }
                    setData({ ...data });

                    if (func)
                        func();
                }}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => label}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiCheckbox-root': {
                                color: 'white'
                            },
                            '& .MuiList-root': {
                                background: 'rgb(31, 33, 37) !important',
                                color: 'white !important',
                                width: '150px'
                            }
                        }
                    }
                }}
                sx={{
                    width: '150px',
                    height: '27px',
                    border: '1px solid #d9d9d9 !important',
                    '.MuiButtonBase-root': {
                        color: 'white'
                    }
                }}
                style={{
                    borderRadius: '4px',
                    width: '200px !important',
                    height: '32px !important',
                    fontSize: '14px',
                    left: '0px'
                }}
            >

                {
                    data[key].map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={data[optionKey].indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))
                }

            </Select>);
    }

    const renderDropDownListV2 = (label, data, objName, key, optionKey, func) => {
        return (
            <Select
                //id="ddlByClient"
                multiple
                value={data[objName][key]}
                onChange={(e, a) => {
                    if (a.props.value == 'ALL' && data[objName][optionKey].includes(a.props.value)) {
                        data[objName][optionKey] = [];
                    }
                    else if (a.props.value == 'ALL' && !data[objName][optionKey].includes(a.props.value)) {
                        data[objName][optionKey] = data[objName][key];
                    }
                    else {
                        if (!data[objName][optionKey].includes(a.props.value)) {
                            data[objName][optionKey] = [...[a.props.value].concat(data[objName][optionKey])];
                        }
                        else {
                            data[objName][optionKey] = data[objName][optionKey].filter(x => x != a.props.value);
                        }
                        if (data[objName][optionKey].filter(x => x != 'ALL').length == data[objName][key].filter(x => x != 'ALL').length &&
                            !data[objName][optionKey].includes('ALL'))
                            data[objName][optionKey] = [...['ALL'].concat(data[objName][optionKey])];
                        else if (data[objName][optionKey].filter(x => x != 'ALL').length != data[objName][key].filter(x => x != 'ALL').length &&
                            data[objName][optionKey].includes('ALL'))
                            data[objName][optionKey] = data[objName][optionKey].filter(x => x != 'ALL');

                    }

                    setFilters({ ...data });

                    if (func)
                        func();
                }}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => label}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiCheckbox-root': {
                                color: 'white'
                            },
                            '& .MuiList-root': {
                                background: 'rgb(31, 33, 37) !important',
                                color: 'white !important',
                                width: '150px'
                            }
                        }
                    }
                }}
                sx={{
                    width: '150px',
                    height: '27px',
                    border: '1px solid #d9d9d9 !important',
                    '.MuiButtonBase-root': {
                        color: 'white'
                    }
                }}
                style={{
                    borderRadius: '4px',
                    width: '200px !important',
                    height: '32px !important',
                    fontSize: '14px',
                    left: '0px'
                }}
            >

                {
                    data[objName][key].map((row) => (
                        <MenuItem key={row} value={row}>
                            <Checkbox checked={data[objName][optionKey].indexOf(row) > -1} />
                            <ListItemText primary={row} />
                        </MenuItem>
                    ))
                }

            </Select>);
    }

    const renderSingleDropDownList = (label, list, textKey, valueKey, filter, setFilter, filterKey, func) => {

        return (
            <Select
                value={filter[filterKey][valueKey]}
                onChange={(e, a) => {
                    filter[filterKey] = list.filter(row => row[valueKey] == a.props.value)[0];
                    setFilter({...filter});
                    if (func)
                        func();
                }}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => filter[filterKey][textKey]}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: 'lightgrey',
                                color: 'black'
                            },
                            '& .MuiCheckbox-root': {
                                color: 'white'
                            },
                            '& .MuiList-root': {
                                background: 'rgb(31, 33, 37) !important',
                                color: 'white !important',
                                width: '250px'
                            },
                            '& .MuiListItemText-primary': {
                                fontSize: '13px !important'
                            }
                        }
                    }
                }}
                sx={{
                    width: '250px',
                    height: '27px',
                    border: '1px solid #d9d9d9 !important',
                    '.MuiButtonBase-root': {
                        color: 'white'
                    }
                }}
                style={{
                    borderRadius: '4px',
                    width: '200px !important',
                    height: '32px !important',
                    fontSize: '14px',
                    left: '0px'
                }}
            >

                {
                    list.map((row) => (
                        <MenuItem key={row[valueKey]} value={row[valueKey]}>
                            <ListItemText primary={row[textKey]} />
                        </MenuItem>
                    ))
                }

            </Select>);
    }

    const buildCardButton = (isZoom, evnt) => {
        return (
            <span className="card-zoom">
                <NavLink
                    title="Expand"
                    onClick={() => { setZoomContent(evnt()); setShowZoom(true); }}
                    style={{ display: (isZoom ? 'none' : '') }}
                >
                    <AspectRatio />
                </NavLink>
                <NavLink
                    title="Close"
                    onClick={() => { setShowZoom(false); }}
                    style={{ display: (isZoom ? '' : 'none') }}
                >
                    <Close />
                </NavLink>
            </span>
        );
    };

    const getCardBodyStyle = (isZoom = false) => {
        return { height: isZoom ? 'auto' : '250px', overflowY: !isZoom ? 'auto' : 'unset' };
    };

    const generateCard = (title, contents, balance, evnt, isZoom = false) => {
        return (
            <Card style={{ marginBottom: (isZoom ? '0px' : '30px') }}>
                <Card.Header>
                    {title}
                    {buildCardButton(isZoom, evnt)}
                </Card.Header>
                <Card.Body
                    style={getCardBodyStyle(isZoom)}
                >
                    {contents()}
                </Card.Body>
                <Card.Footer>
                    <div className="dashboard-widget-footer">
                        <span>Grand Total</span>
                        {balance()}
                    </div>
                </Card.Footer>
            </Card>
        );
    };

    const buildByClient = () => {
        var client = [... new Set(byClient.map(x => x.Category))];
        client = client.sort();
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {client == null || client.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        client.map(x => {
                            var subTree = byClient.filter(y => y.Category === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.Id}><td style={{ paddingLeft: '40px' }}>{sub.Code}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const calculateByClientTotal = () => {
        return numberWithCommas((byClient ? byClient.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0) : 0), true);
    };

    const buildByCountry = () => {
        return (

            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {
                                renderDropDownListV2("Country", filters, 'countries', 'countryList', 'countrySelected', () =>
                                    setByCountry(byCountry.filter(x => filters.countries.countrySelected.includes(x.Region))))
                            }
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {byCountry == null || byCountry.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        byCountry.map(x => {
                            return (<tr key={x.Id}><td>{x.Region}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(x.CurrentBalanceUSD, true)}</td></tr>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const calculateByCountryTotal = () => {
        return numberWithCommas((byCountry ? byCountry.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0) : 0), true);
    };

    const buildByCountryAndBank = () => {
        var countries = [... new Set(byCountryAndBank.map(row => row.Region))];
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {
                                renderDropDownListV2("Country/Bank", filters, 'bankAndCountry', 'list', 'selected', () =>
                                    setByCountryAndBank(byCountryAndBank.filter(x => filters.bankAndCountry.selected.includes(x.Region))))
                            }
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {countries == null || countries.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        countries.map(x => {
                            var subTree = byCountryAndBank.filter(y => y.Region === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.Id}><td style={{ paddingLeft: '40px' }}>{sub.BankName}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const buildByCurrency = () => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {
                                renderDropDownListV2("Currency", filters, 'currencies', 'list', 'selected', () =>
                                    setByCurrency(filters.currencies.list.filter(x => filters.currencies.selected.includes(x.Currency))))
                            }
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {byCurrency == null || byCurrency.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        byCurrency.map(x => {
                            return (<tr key={x.Id}><td>{x.Currency}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(x.CurrentBalanceUSD, true)}</td></tr>);
                        })
                    )}
                </tbody>
            </table>
        );
    };

    const buildByCurrencyAndType = () => {
        var currencies = [... new Set(byCurrencyAndType.map(row => row.Currency))];
        return (

            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {
                                renderDropDownListV2("Currency/Type", filters, 'currencyType', 'list', 'selected', () =>
                                    setByCurrencyAndType(filters.currencyType.list.filter(x => filters.currencyType.selected.includes(x.Currency))))
                            }
                        </th>
                        <th>Current Balance (USD)</th>
                    </tr>
                </thead>
                <tbody>
                    {currencies == null || currencies.length == 0 ? (
                        <tr><td colSpan="2" style={{ textAlign: 'center' }}>No record</td></tr>
                    ) : (
                        currencies.map(x => {
                            var subTree = byCurrencyAndType.filter(y => y.Currency === x);
                            return (<><tr key={x}><td style={{ fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{x}</td><td style={{ width: "150px", textAlign: "right", fontWeight: 'bold', color: 'rgb(255, 193, 7)' }}>{numberWithCommas(subTree.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true)}</td></tr>
                                {subTree.map(sub => {
                                    return (<tr key={sub.Id}><td style={{ paddingLeft: '40px' }}>{sub.Type}</td><td style={{ width: "150px", textAlign: "right" }}>{numberWithCommas(sub.CurrentBalanceUSD, true)}</td></tr>);
                                })}</>);
                        })
                    )}
                </tbody>
            </table>
        );
    }

    const bindStates = (results, pf) => {
        if(results.byClient == null) return null;
        setDashboardData(results);
        setByClient([... new Set(results.byClient.filter(row => row.PortfolioId == pf.PortfolioId && widgetFilter.clientOptions.includes(row.Category)))]);

        /* Country */
        setByCountry(results.byCountry.filter(row => row.PortfolioId == pf.PortfolioId));
        var country = results.byCountry.filter(row => row.PortfolioId == pf.PortfolioId).map(row => row.Region);
        country = country.sort();
        country = ['ALL'].concat(country);
        filters.countries.countryList = country;
        filters.countries.countrySelected = country;

        /* Bank And Country */
        filters.bankAndCountry.list = country;
        filters.bankAndCountry.selected = country;
        setByCountryAndBank(results.byCountryBank.filter(row => row.PortfolioId == pf.PortfolioId));

        /* Currency */
        var currency = [... new Set(results.byCurrency.filter(row => row.PortfolioId == pf.PortfolioId).map(row => row.Currency))];
        currency = currency.sort();
        currency = ['ALL'].concat(currency);
        filters.currencies.list = currency;
        filters.currencies.selected = currency;
        setByCurrency(results.byCurrency.filter(row => row.PortfolioId == pf.PortfolioId));

        /* Currency And Type */
        filters.currencyType.list = currency;
        filters.currencyType.selected = currency;
        setByCurrencyAndType(results.byCurrencyType.filter(row => row.PortfolioId == pf.PortfolioId));

        setFilters({...filters});
    }

    useEffect(async () => {
        var results = await postAsync('dashboard/treasury', { userId: getId });
        
        var pf = results.results.portfolios.length > 0 ? results.results.portfolios[0] : { PortfolioId: '', PortfolioName: '' };
        filters.portfolio = pf;
        setFilters({...filters})
        buildWidgetPattern(results.results, pf.PortfolioId);
        bindStates(results.results, pf);
    }, []);

    const buildWidgetPattern = (results, portfolioId) => {
        if(results.byClient == null) return;
        var client = [... new Set(results.byClient.filter(row => row.PortfolioId == portfolioId).map(row => row.Category))];
        client = client.sort();
        client = ['ALL'].concat(client);
        
        var country = [... new Set(results.byCountry.filter(row => row.PortfolioId == portfolioId).map(row => row.Region))];
        
        country = country.sort();
        country = ['ALL'].concat(country);
        var currency = [... new Set(results.byCurrency.filter(row => row.PortfolioId == portfolioId).map(row => row.Currency))];
        currency = currency.sort();
        currency = ['ALL'].concat(currency);
        setWidgetFilter({
            client: client,
            clientOptions: client,
            country: country,
            countryOptions: country,
            countryBank: country,
            countryBankOptions: country,
            currency: currency,
            currencyOptions: currency,
            currencyType: currency,
            currencyTypeOptions: currency
        });
    }

    useEffect(async () => {
        var results = await postAsync('dashboard/treasury', { userId: getId, boCode: JSON.stringify(widgetFilter.clientOptions) });
        bindStates(results.results, filters.portfolio);
        //buildWidgetPattern(results.results, filters.portfolio.PortfolioId);
    }, [widgetFilter.clientOptions])

    useEffect(() => {
        buildWidgetPattern(dashboardData, filters.portfolio.PortfolioId);
        bindStates(dashboardData, filters.portfolio);
    }, [filters.portfolio]);

    useEffect(async () => {
        setZoomContent(generateCard('By Client', buildByClient, calculateByClientTotal, buildByClient, true));

    }, [byClient]);

    useEffect(() => {
        setZoomContent(generateCard('By Country', buildByCountry, calculateByCountryTotal, buildByCountry, true));
    }, [byCountry]);

    useEffect(() => {
        setZoomContent(generateCard('By Country and Bank', buildByCountryAndBank,
            () => numberWithCommas(byCountryAndBank.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCountryAndBank, true));
    }, [byCountryAndBank]);

    useEffect(() => {
        setZoomContent(generateCard('By Currency', buildByCurrency,
            () => numberWithCommas(byCurrency.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrency, true));
    }, [byCurrency]);

    useEffect(() => {
        setZoomContent(generateCard('By Currency And Type', buildByCurrencyAndType,
            () => numberWithCommas(byCurrencyAndType.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrencyAndType, true));
    }, [byCurrencyAndType]);

    /* Filtering */
    useEffect(() => {
        if(dashboardData.byCountry == null) return;
        setByCountry(dashboardData.byCountry.filter(row => filters.countries.countrySelected.includes(row.Region)));
    }, [filters.countries.countrySelected]);

    useEffect(() => {
        if(dashboardData.byCountryBank == null) return;
        setByCountryAndBank(dashboardData.byCountryBank.filter(row => filters.bankAndCountry.selected.includes(row.Region)));
    }, [filters.bankAndCountry.selected]);

    useEffect(() => {
        if(dashboardData.byCurrency == null) return;
        setByCurrency(dashboardData.byCurrency.filter(row => filters.currencies.selected.includes(row.Currency)));
    }, [filters.currencies.selected]);

    useEffect(() => {
        if(dashboardData.byCurrencyType == null) return;
        setByCurrencyAndType(dashboardData.byCurrencyType.filter(row => filters.currencyType.selected.includes(row.Currency)));
    }, [filters.currencyType.selected]);
    /* End Filtering */

    return (
        <Container fluid>
            <Modal
                className="dashboard-modal"
                show={showZoom}
                size="lg"
                top
                aria-labelledby="zoom-header"
                onHide={() => setShowZoom(false)}
            >
                <Modal.Body>
                    <Row className="pms-dashboard">
                        <Col lg={12}>
                            {zoomContent}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Row>
                <Col lg={12}>
                    <Row style={{ marginBottom: '8px' }}>
                        <span className="p-2 pageheader">
                            <h3 className="pagetitle" >
                                Treasury Dashboard
                            </h3>
                        </span>
                    </Row>
                </Col>
            </Row>
            <Row className="pms-dashboard" style={{ marginTop: '30px' }}>
                <Col lg={12} style={{ marginBottom: "20px" }}>
                    <span style={{ color: "white" }}>Filter by: </span>
                    {
                        renderSingleDropDownList("Portfolio", dashboardData.portfolios, 'PortfolioName', 'PortfolioId', filters, setFilters, 'portfolio', () => {
                            
                        })
                    }
                    &nbsp;&nbsp;
                    {
                        renderDropDownList("Client", widgetFilter, setWidgetFilter, 'client', 'clientOptions', () =>
                            setByClient(dashboardData.byClient.filter(x => widgetFilter.clientOptions.includes(x.Category))))
                    }
                </Col>
                <Col lg={4}>
                    {
                        generateCard('By Client', buildByClient, calculateByClientTotal,
                            () => generateCard('By Client', buildByClient, calculateByClientTotal, buildByClient, true))
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard('By Country', buildByCountry, calculateByCountryTotal,
                            () => generateCard('By Country', buildByCountry, calculateByCountryTotal, buildByCountry, true))
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard(
                            'By Country and Bank',
                            buildByCountryAndBank,
                            () => numberWithCommas(byCountryAndBank.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true),
                            () => generateCard('By Country and Bank', buildByCountryAndBank,
                                () => numberWithCommas(byCountryAndBank.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCountryAndBank, true)
                        )
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard(
                            'By Currency',
                            buildByCurrency,
                            () => numberWithCommas(byCurrency.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true),
                            () => generateCard('By Currency', buildByCurrency,
                                () => numberWithCommas(byCurrency.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrency, true)
                        )
                    }
                </Col>

                <Col lg={4}>
                    {
                        generateCard(
                            'By Currency And Type',
                            buildByCurrencyAndType,
                            () => numberWithCommas(byCurrencyAndType.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true),
                            () => generateCard('By Currency And Type', buildByCurrencyAndType,
                                () => numberWithCommas(byCurrencyAndType.reduce((ori, row) => ori += row.CurrentBalanceUSD, 0), true), buildByCurrencyAndType, true)
                        )
                    }
                </Col>
            </Row>
        </Container>
    );
};
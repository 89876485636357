import React from 'react'

import { TablePagination as MuiTablePagination } from '@mui/material'

export const TablePagination = ({ totalRecords = 0, pageIndex = 0, pageSize = 500, setPagination, rowsPerPageOptions = [500, 1000, 2000] }) => {
  return (
    <MuiTablePagination
        component="div"
        count={totalRecords}
        page={pageIndex}
        onPageChange={(e, page) => {
          setPagination({ pageSize, pageIndex: page })
        }}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(e) => {
          setPagination({ pageIndex, pageSize: e.target.value })
        }}
        sx={{
          '.MuiTablePagination-displayedRows': {
            'margin-top': '1em',
            'margin-bottom': '1em'
          },
          '.MuiTablePagination-selectLabel': {
            'margin-top': '1em'
          }
        }}
        rowsPerPageOptions={rowsPerPageOptions}
        style={{ color: 'white' }}
        />
  )
}

import React, { useEffect, useState, useRef } from 'react'
import { Row, Container, Col, Card, Table, Modal, Button, NavLink } from 'react-bootstrap'
import { TablePagination } from '../helpers/components/TablePagination'
import CommonTableTransaction from '../common/CommonTable/CommonTableTransaction'
import { columns } from './activityLogTableColumns'
import useApi from '../hooks/useApi'
import Filters from './filters'
import { getUserId } from '../helpers/common'
import { ViewDetailModal } from './ViewDetailModal'

export default function ActivityLog () {
    const [filters, setFilters] = useState({ 
        module: null,
        startDate: null,
        endDate: null,
        accessMode: null,
        userId: null
    });
    const [trigger, setTrigger] = useState();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 500,
        totalRecords: 0
    });
    const [viewModalVisible, setViewModalVisible] = useState({
        visible: false,
        logId: 0
    })

    const onFormSubmit = () => {
        setTrigger(+new Date())
    };    
    
    const activityLogs = useApi({
        method: 'POST',
        options: {
            ...filters,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize
        },
        trigger,
        url: `${process.env.REACT_APP_BASE_URL}/audit/activity`
    });

    const handleViewDetails = (row) => {
        setViewModalVisible(
        {
            visible: true,
            logId: row.Id
        });
    }    

    useEffect(() => {
        onFormSubmit()
    }, [pagination.pageIndex, pagination.pageSize]);

    return (
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <Row style={{ marginBottom: '8px' }}>
                        <span className="p-2 pageheader">
                            <h3 className="pagetitle" >
                                Activity Log
                            </h3>
                        </span>
                    </Row>
                    <Filters
                        filters={filters}
                        setFilters={setFilters}
                        onFormSubmit={onFormSubmit}
                    />                    
                    <div className='transaction'>
                        <CommonTableTransaction
                            loading={activityLogs?.loading || false}
                            data={activityLogs?.response?.data || []}
                            columns={columns({ handleViewDetails })}
                        />
                    </div>
                </Col>
                <Col lg={12}>
                    <TablePagination
                        totalRecords={activityLogs?.response?.totalRecords}
                        pageIndex={pagination.pageIndex}
                        pageSize={pagination.pageSize}
                        setPagination={setPagination}
                    />
                </Col>
            </Row>
            <ViewDetailModal
                visible={viewModalVisible.visible}
                logId={viewModalVisible.logId}
                handleClose={() => setViewModalVisible({ visible: false, logId: 0 }) }
            />
        </Container>
    );
};
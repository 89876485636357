import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { IconButton, Snackbar, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

export default forwardRef((props, ref) => {
    // Variables
    const base_url = process.env.REACT_APP_BASE_URL;
    const getId = localStorage.getItem('sub_Id');
    const roleId = localStorage.getItem('role')?.split(',') ?? [];
    const useStyles = makeStyles({
        paper: {
            background: 'rgb(31, 33, 37) !important',
            color: 'white !important'
            // So we see the popover when it's empty.
            // It's most likely on issue on userland.
        },
        option: {
            '&:hover': {
                backgroundColor: 'grey !important',
                color: 'white !important'
            }
        }
    });
    const styles = useStyles();

    // States
    const [alertAl, setAlertAl] = useState(false);
    const [portfolio, setPortfolio] = useState({ portfolioId: '', portfolioName: '' });
    const [alertAccountant, setAlertAccountant] = useState(false);
    const [accountantName, setAccountantName] = useState('');
    const [validated, setValidated] = useState(false);
    const [resultAccountantData, setResultAccountantData] = useState([]);
    const [blankAccountantError, setBlankAccountantError] = useState(false);
    const [showAccountant, setShowAccountant] = useState(false);
    const [accountantId, setAccountantId] = useState('');
    const [isDeleteAccountant, setIsDeleteAccountant] = useState(false);

    // Methods
    useImperativeHandle(ref, () => ({

        showModal: async(portfolioId, portfolioName) => {
            setPortfolio({ portfolioId: portfolioId, portfolioName: portfolioName });
            setShowAccountant(true);
            await accountantData(portfolioId);
        },
        deleteAccountantAsync: async (accountantId, portfolioId) => {
            setAccountantId(accountantId);
            setPortfolio({ portfolioId: portfolioId });
            setIsDeleteAccountant(true);
        }
    }));

    const accountantData = async (portfolioId) => {
        if (roleId.includes('superadmin') || roleId.includes('admin')) {
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}/accontant_list`, {
                    params: {
                        module: 'realestate',
                        portfolioId: portfolioId,
                        user_role: 'admin'
                    }
                })
                .then(function (response) {
                    // console.log(response.data)
                    setResultAccountantData(response.data)
                })
        }
    };

    const handleSubmitAccountant = async (e) => {
        e.preventDefault()
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else if (accountantName == '' || accountantName == null) {
            setBlankAccountantError(true)
        } else if (
            resultAccountantData?.filter(
                (i) =>
                    i.accountant_name === accountantName && i.portfolio_id == props.portfolioId
            ).length > 0
        ) {
            setAlertAl(true)
            setTimeout(() => {
                setAlertAl(false)
            }, 3000)
        } else {
            const accountantId = resultAccountantData?.filter(
                (i) => i.username === accountantName
            )
            const config = {
                method: 'post',
                url: `${base_url}/admin/add_portfolio_manager`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    user_id: getId,
                    portfolio_id: portfolio.portfolioId,
                    accountant_id: accountantId?.[0]?.user_id,
                    user_role: 'admin'
                }
            }
            await axios(config).then(function (response) {
                // accountant();
                setAlertAccountant(true)
                refreshParent();
                setTimeout(() => {
                    setAccountantName('')
                    setAlertAccountant(false)
                    setShowAccountant(false)
                }, 3000)
            })
        }
    };

    const refreshParent = () => {
        if(props.callbackEvnt)
            props.callbackEvnt();
    };

    // delete accountant
    const DeleteAccountant = async () => {
        await axios
            .delete(
                `${base_url}/delete_accountant_portfolio`,
                {
                    params: {
                        portfolio_account_id: `${portfolio.portfolioId}${accountantId}`
                    }
                }
            )
            .then((response) => {
                setIsDeleteAccountant(false)
            });

        refreshParent();
    }

    return (
        <>
            <Modal
                show={showAccountant}
                onHide={() => setShowAccountant(false)}
                style={{ width: '28%', marginLeft: '35%' }}
            >
                <div style={{ border: '1px solid white' }}>
                    <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                color: 'white'
                            }}
                            onClick={() => setShowAccountant(false)}
                        >
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    {alertAl
                        ? (
                            <Snackbar
                                open={alertAl}
                                onClose={() => setAlertAl(false)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                            >
                                <Alert
                                    onClose={() => setAlertAl(false)}
                                    severity="error"
                                    sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                                >
                                    Accountant name already exist
                                </Alert>
                            </Snackbar>
                        )
                        : (
                            <></>
                        )}
                    {alertAccountant
                        ? (
                            <Snackbar
                                open={alertAccountant}
                                onClose={() => setAlertAccountant(false)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                            >
                                <Alert
                                    onClose={() => setAlertAccountant(false)}
                                    severity="success"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: 'white',
                                        color: 'black'
                                    }}
                                >
                                    Added accountant successfully
                                </Alert>
                            </Snackbar>
                        )
                        : (
                            <></>
                        )}
                    <Modal.Body style={{ backgroundColor: '#222429' }}>
                        <Form
                            className="custom-form"
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmitAccountant}
                        >
                            <h4>Add Accountant</h4>
                            <span
                                style={{
                                    color: 'white',
                                    marginLeft: '4px',
                                    fontWeight: 'bold'
                                }}
                            >
                                Portfolio name -{' '}
                                <span style={{ marginLeft: '2px' }}>{portfolio.portfolioName}</span>
                            </span>
                            <br />
                            <FormControl
                                style={{
                                    marginLeft: '10px',
                                    marginBottom: '33px',
                                    marginTop: '7px'
                                }}
                            >
                                <InputLabel
                                    id="demo-simple-select-helper-label"
                                    style={{
                                        fontSize: '17px',
                                        marginLeft: '-2%',
                                        marginTop: '-1%',
                                        overflow: 'visible',
                                        color: 'grey'
                                    }}
                                >
                                    Accountant
                                </InputLabel>

                                <Select
                                    MenuProps={{
                                        classes: {
                                            paper: styles.paper
                                        },
                                        PaperProps: {
                                            sx: {
                                                '& .MuiMenuItem-root:hover': {
                                                    backgroundColor: 'lightgrey',
                                                    color: 'black'
                                                },
                                                '& .MuiMenuItem-root.Mui-selected:hover': {
                                                    backgroundColor: 'lightgrey',
                                                    color: 'black'
                                                }
                                            }
                                        }
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={accountantName}
                                    label="Select"
                                    style={{
                                        width: '253px',
                                        borderRadius: '15px',
                                        boxShadow: 'none'
                                    }}
                                    sx={{
                                        width: 300,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '0px',
                                            height: '54px',
                                            border: '1px solid hsl(0deg 0% 44% / 63%) !important'
                                        },
                                        '.MuiInputLabel-root': {
                                            color: 'grey !important'
                                        },
                                        '.MuiSelect-icon': {
                                            fill: 'grey !important'
                                        },
                                        '.MuiOutlinedInput-input': {
                                            color: 'white !important',
                                            fontSize: '15px',
                                            //  backgroundColor:'white',
                                            paddingBottom: '13px',
                                            paddingRight: '61%',
                                            paddingTop: '12px'
                                        }
                                    }}
                                    onChange={(e) => {
                                        setAccountantName(e.target.value)
                                        setBlankAccountantError(false)
                                    }}
                                >
                                    {resultAccountantData?.map((i) => (
                                        <MenuItem value={i.username} key={i.user_id}>{i.username}</MenuItem>
                                    ))}
                                </Select>
                                {blankAccountantError == true
                                    ? <span style={{
                                        width: '100%',
                                        marginTop: '0.25rem',
                                        fontSize: '.875em',
                                        color: '#dc3545'
                                    }}>Accountant name is required</span>
                                    : <></>}
                            </FormControl>

                            <Button
                                type="submit"
                                variant=""
                                className="btn-gray"
                                style={{
                                    width: '50%',
                                    marginLeft: '25%',
                                    marginTop: '-3%',
                                    color: 'white',
                                    boxShadow: 'none'
                                }}
                            >
                                Save
                            </Button>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>

            <Modal
                show={isDeleteAccountant}
                onHide={() => setIsDeleteAccountant(false)}
                style={{
                    width: '30rem',
                    marginTop: '17rem',
                    overflow: 'hidden',
                    marginLeft: '35%',
                    backgroundColor: '#222429',
                    height: '8rem',
                    border: '1px solid white',
                    borderRadius: '15px'
                }}
            >
                <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
                    <Modal.Title
                        style={{
                            color: 'white',
                            fontSize: '16px',
                            marginTop: '-5%',
                            marginLeft: '11%'
                        }}
                    >
                        Are you sure you want to Delete this accountant ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer
                    style={{
                        backgroundColor: '#222429',
                        border: 'none',
                        paddingRight: '34%',
                        marginTop: '-3%'
                    }}
                >
                    <Button
                        variant="danger"
                        style={{
                            width: '25%',
                            marginBottom: '2%',
                            backgroundColor: '#b30000'
                        }}
                        onClick={() => {
                            DeleteAccountant()
                            setIsDeleteAccountant(false)
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="success"
                        onClick={() => setIsDeleteAccountant(false)}
                        style={{ width: '25%', backgroundColor: '#006400' }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});
import { dateFilter, numberFilter, textFilter } from 'react-bootstrap-table2-filter'
import { React } from 'react'
import { numberWithCommas } from '../helpers/common'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCommentIcon from '@mui/icons-material/AddComment'

export const columns = ({ handleEdit = () => {}, handleDelete = () => {}, roleId = [], handleAddComment = () => {} }) => [
  {
    dataField: 'Id',
    text: 'No',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'No'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return(rowIndex + 1);
    }
  },
  {
    dataField: 'Office',
    text: 'Office',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Office'
    })
  },
  {
    dataField: 'BONumber',
    text: 'BO #',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'BO #'
    })
  },   
  {
    dataField: 'BO',
    text: 'BO',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'BO'
    })
  },  
  {
    dataField: 'BOType',
    text: 'BO Type',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'BO Type'
    })
  },    
  {
    dataField: 'UBO',
    text: 'UBO',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'UBO'
    })
  },   
  {
    dataField: 'PropId',
    text: 'ID',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'ID'
    })
  },
  {
    dataField: 'Currency',
    text: 'Currency',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Currency'
    })
  },
  {
    dataField: 'Active',
    text: 'Active',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Active'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return( row.Active ? 'Yes' : 'No' );
    }
  },
  {
    dataField: 'Country',
    text: 'Country',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Country'
    })
  },
  {
    dataField: 'PropType',
    text: 'Property Type',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Property Type'
    })
  },
  {
    dataField: 'TenureFinalDt',
    text: 'Tenure - Finalization Date',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Tenure - Finalization Date'
    })
  },
  {
    dataField: 'Project',
    text: 'Project',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Project'
    })
  },
  {
    dataField: 'Unit',
    text: 'Unit',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Unit'
    })
  },
  {
    dataField: 'Type',
    text: 'Type',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Type'
    })
  },
  {
    dataField: 'ExistingUse',
    text: 'Existing Use',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Existing Use'
    })
  },
  {
    dataField: 'AcquisitionDt',
    text: 'Acquisition Date',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Acquisition Date'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      const date = moment(row.AcquisitionDt).format('Do MMMM YYYY')
      return (
        <p
          style={{
            color: 'white',
            // width: '110%',
            fontSize: '14px',
            display: 'inline-block'
          }}
        >
            <span style={{ color: 'white', fontSize: '14px' }}>
              {row.AcquisitionDt ? date : '-'}
            </span>
        </p>
      )
    }
  },
  {
    dataField: 'ValuationDt',
    text: 'Valuation Date',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Valuation Date'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      const date = moment(row.ValuationDt).format('Do MMMM YYYY')

      return (
        <p
          style={{
            color: 'white',
            // width: '110%',
            fontSize: '14px',
            display: 'inline-block'
          }}
        >
            <span style={{ color: 'white', fontSize: '14px' }}>
              {row.ValuationDt ? date : '-'}
            </span>
        </p>
      )
    }    
  },
  {
    dataField: 'FloorArea',
    text: 'Floor Area',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Floor Area'
    })
  },
  {
    dataField: 'ValuationAmt',
    text: 'Valuation AED',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Valuation AED'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {
            `${numberWithCommas(row.ValuationAmt, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'PurchasePrice',
    text: 'Purchase Price AED',
    sort: true,
    toggle: true,
    width: 100,
    filter: numberFilter({
      placeholder: 'Purchase Price AED'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {
            `${numberWithCommas(row.PurchasePrice, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'Capex',
    text: 'Capex AED',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Capex AED'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {
            `${numberWithCommas(row.Capex, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'HistoricalCost',
    text: 'Historical Cost',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Historical Cost'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {
            `${numberWithCommas(row.HistoricalCost, true)}`
          }
        </span>
      )
    }
  },
  {
    dataField: 'ValuationCostUSD',
    text: 'Valuation USD',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Valuation USD'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {
            `${numberWithCommas(row.ValuationCostUSD, true)}`
          }
        </span>
      )
    }
  },  
  {
    dataField: 'AcquisitionCostUSD',
    text: 'Acquisition Cost USD',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Acquisition Cost USD'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          {
            `${numberWithCommas(row.AcquisitionCostUSD, true)}`
          }
        </span>
      )
    }
  },  
  {
    dataField: 'FxAcquisition',
    text: 'FX Acquisition',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'FX Acquisition'
    })
  },  
  {
    dataField: 'FxActual',
    text: 'FX Actual',
    sort: true,
    toggle: true,
    width: 150,
    filter: numberFilter({
      placeholder: 'Fx Actual'
    })
  },   
  {
    dataField: 'Client',
    text: 'Client',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Client'
    })
  },              
  {
    dataField: 'Remarks',
    text: 'Remarks',
    sort: true,
    toggle: true,
    width: 150,
    filter: textFilter({
      placeholder: 'Remarks'
    })
  },     
  {
    dataField: 'Id',
    text: 'Action',
    sort: false,
    toggle: false,
    csvExport: false,
    width: 50,
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return (
        <span>
          <Tooltip title={'edit'} style={{ cursor: 'pointer', color: '#FFC107' }}>
            <EditOutlinedIcon onClick={() => handleEdit(row)}/>
          </Tooltip>

          <Tooltip title={'comment'} style= {{ cursor: 'pointer', color: 'chocolate' }}>
            <AddCommentIcon onClick={() => handleAddComment(row)} />
          </Tooltip>

          {
            (roleId.includes('superadmin') || roleId.includes('admin')) && (
              <Tooltip title={'delete'} style={{ cursor: 'pointer', color: '#b30000' }}>
                <DeleteOutlineOutlinedIcon onClick={() => handleDelete(row)}/>
              </Tooltip>
            )
          }
        </span>
      )
    }
  }
]

import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getAsync } from '../common/HttpClient'

export const ViewDetailModal = ({ visible = false, logId = 0, handleClose = () => {} }) => {
  const [different, setDifferent] = useState([]);
  const [isJsonDiff, setIsJsonDiff] = useState(false);

  useEffect(async () => {
    var res = await getAsync(`audit/${logId}`);
    if(res.results) {
      var oldVal = null;
      var newVal = null;
      try {
        oldVal = res.results.OldValue ? JSON.parse(res.results.OldValue) : null;
        newVal = res.results.NewValue ? JSON.parse(res.results.NewValue) : null;

        var diff = [];
        if(oldVal) {
          Object.keys(oldVal).forEach((k) => {
            var nv = null;
            if(newVal) 
              nv = newVal[k];
            
            if(oldVal[k] != nv)
              diff.push({ key: k, oldValue: oldVal[k], newValue: nv });
          });
        }
        
        if(newVal) {
          Object.keys(newVal).forEach((k) => {
            var ov = null;
            if(oldVal) 
              ov = oldVal[k];
            
            if(diff.filter(x => x.key == k).length == 0 && ov != newVal[k]) 
              diff.push({ key: k, oldValue: ov, newValue: newVal[k] });
          });          
        }
        
        setDifferent(diff);
        setIsJsonDiff(true);
      }
      catch {
        oldVal = res.results.OldValue;
        newVal = res.results.NewValue;
        setDifferent([{ oldValue: oldVal, newValue: newVal }]);
        setIsJsonDiff(false);
      }
    }
  }, [logId])   

  return (
    <>
    <Modal
      size="lg"
      show={visible}
      onHide={() => { handleClose() }}
      >
      <div style={{ border: '1px solid white' }}>
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <Modal.Title style={{ "fontSize": "16px" }}>Differences</Modal.Title>
          <IconButton
            style={{
              position: 'absolute',
              top: '10px',
              right: '0',
              color: 'white'
            }}
            onClick={() => { handleClose() }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#222429' }}>
            <table class="table">
              <thead>
                <tr>
                  {isJsonDiff ? <th>Field</th> : <></>}
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {
                  different.map((row, idx) => {
                    return (<tr key={idx}>
                      {isJsonDiff ? <td style={{ textAlign: "left", width: '40%' }}>{row.key}</td> : <></>}
                      <td style={{ textAlign: "left", width: '30%' }}>{row.oldValue ? row.oldValue : '-'}</td>
                      <td style={{ textAlign: "left", width: '30%' }}>{row.newValue ? row.newValue : '-'}</td>
                    </tr>)
                  })
                }
              </tbody>
            </table>
        </Modal.Body>
      </div>
    </Modal>
    </>
  )
}
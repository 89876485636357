import * as defaultAxios from 'axios'
import { useEffect, useState } from 'react'

export default ({
  axios = defaultAxios,
  url,
  method = 'GET',
  options = {},
  trigger,
  forceDispatchEffect,
  customHandler,
  file = false
} = {}) => {
  const [innerTrigger, setInnerTrigger] = useState(0)
  const [results, setResults] = useState({
    error: null,
    response: null,
    loading: false
  })

  const outerTrigger = trigger
  const dispatchEffect = forceDispatchEffect || (() => true)

  const composeHeaders = () => {
    const headers = {
      'Content-Type': file ? 'multipart/form-data' : 'application/json'
    }

    return headers
  }

  useEffect(async () => {
    const handler = (error, response) => {
      if (customHandler) {
        customHandler(error, response)
      }
    }

    if (!url || !dispatchEffect()) return

    if (typeof outerTrigger === 'undefined' && !innerTrigger) return

    handler(null, null)

    setResults({
      error: null,
      response: null,
      loading: true
    })
    try {
      const res = await axios[method.toLowerCase()](url, {
        ...options
      }, { headers: composeHeaders() })

      if (res.status === 200) {
        if (res.data.success) {
          setResults({
            error: null,
            response: res.data.results || [],
            loading: false
          })
        } else {
          setResults({
            error: res.data.message,
            response: null,
            loading: false
          })
        }
      } else {
        setResults({
          error: res.statusText,
          response: null,
          loading: false
        })
      }
    } catch (err) {
      setResults({
        error: err,
        response: null,
        loading: false
      })
    }
  }, [innerTrigger, outerTrigger])

  if (results) {
    return {
      ...results,
      executeRequest: () => {
        setInnerTrigger(+new Date())
      }
    }
  } else {
    return null
  }
}
export const axios = defaultAxios

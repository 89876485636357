import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import axios from 'axios'
import moment from 'moment'
import Spinner from '../../common/spinner'
import { Container, Col, Image, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Header from '../../common/Header/Header'
import RawTableExchange from '../../common/RawTableExchange'
import wallateIcon from '../../assets/images/wallateicon.png'
import investmentIcon from '../../assets/images/investmentIcon.png'
import exchangeIcon from '../../assets/images/exchangeIcon.png'
import '../../modules/Assets/Assets.css'
import CommonTableWalletInfo from '../../common/CommonTable/CommonTableWalletInfo'
import CommonTable from '../../common/CommonTable/CommonTable'
import Tooltip from '@mui/material/Tooltip'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import SidebarAdmin from '../../Admin/DashboardAdmin/SidebarAdmin'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp'
import CommonTableInvestmentInfo from '../../common/CommonTable/CommonTableInvestmentInfo'
import { ROW_SELECT_DISABLED } from 'react-bootstrap-table-next'
import RawTable from '../../common/RawTable'

const AdminAssets = () => {
  const location = useLocation()
  const portNa = location.state?.data?.portfolio_name

  const [loading, setLoading] = useState(false)
  const [investShow, setInvestShow] = useState(false)
  const [exchangeShow, setExchangetShow] = useState(false)
  const [walletShowId, setWalletShowId] = useState('')
  const [Address, setAddress] = useState('')
  const [portName, setPortName] = useState(portNa)
  const getId = localStorage.getItem('sub_Id')
  const [portfolio, setPortfolio] = useState([])
  const [totalExchangeBalance, setTotalExchangeBalance] = useState(0)
  const [totalInvestmentBalance, setTotalInvestmentBalance] = useState(0)
  const [totalWalletBalance, setTotalWalletBalance] = useState(0)

  const base_url = process.env.REACT_APP_BASE_URL
  const { id: portfolio_id } = useParams()
  const navigate = useNavigate()

  const wallet_columns = [
    {
      dataField: 'createdDt',
      text: 'Date of Entry',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const entry_date = moment(row.createdDt).format('Do MMMM YYYY, h:mm:ss a')?.split(',')
        return (
          <p style={{ fontSize: '14px', textAlign: 'center', marginLeft: '-2em' }}>
            {entry_date[0]}<br />
            {entry_date[1]}
          </p>
        )
      }
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ fontSize: '14px', textAlign: 'center', marginLeft: '1em' }}>
            {row.address}
          </p>
        )
      }
    },
    {
      dataField: 'chain',
      text: 'Chain',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ fontSize: '14px', textAlign: 'center', marginLeft: '-3em' }}>
            {row.chain}
          </p>
        )
      }
    },
    {
      dataField: 'total_sent',
      text: 'Total sent',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ fontSize: '14px', textAlign: 'center' }}>
            ${parseFloat(row.usd_TotalSent).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        )
      }
    },
    {
      dataField: 'usd_TotalReceived',
      text: 'Total received',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ fontSize: '14px', textAlign: 'center', marginLeft: '-2em' }}>
            ${parseFloat(row.usd_TotalReceived).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        )
      }
    },
    {
      dataField: 'usd_Balance',
      text: 'Balance',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <span>
              ${parseFloat(row.usd_Balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}<br />
            </span>
          </div>
        )
      }
    },
    {
      dataField: 'count',
      text: 'Transaction Count',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p className="namePortData" style={{ fontSize: '14px', textAlign: 'center', marginLeft: '-8.5em' }}>
            {
              row.tokens.reduce((ori, row) => { return ori += row.totalCount }, 0)
            }
          </p>
        )
      }
    },
    {
      dataField: 'lastTranDate',
      text: 'Last Transaction Date',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.lastTranDate) {
          const entry_date = moment(row.lastTranDate).format('Do MMMM YYYY, h:mm:ss a')?.split(',')
          return (
            <p style={{ fontSize: '14px', textAlign: 'center', marginLeft: '-2em' }}>
              {entry_date[0]} <br /> {entry_date[1]}
            </p>
          )
        } else {
          return (
            <p style={{ fontSize: '14px', textAlign: 'center', marginLeft: '-2em' }}>-</p>
          )
        }
      }
    }
  ]
  const investment_columns = [
    {
      dataField: 'investment_name',
      text: 'Name',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p className="namePortData" style={{ cursor: 'pointer' }} onClick={() => navigate('/PMS/Investments', { state: { id: 1, data: { portfolioId: row.portfolio_id } } })}>
            {row.investment_name}
          </p>
        )
      }
    },
    {
      dataField: 'investment_type',
      text: 'Type',
      sort: true
    },
    {
      dataField: 'date_of_investment',
      text: 'Date',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p>
            {moment(row.date_of_investment).format('Do MMMM YYYY')}
          </p>
        )
      }
    },
    {
      dataField: 'quantity',
      text: 'Qty',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '14px' }}>
            {row.quantity}
          </p>
        )
      }
    },
    {
      dataField: 'purchase_price',
      text: 'Purchase Price',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '14px' }}>
            ${(parseFloat(row.purchase_price) * parseFloat(row.exchange_rate ?? 1)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        )
      }
    },
    {
      dataField: 'purchase_value',
      text: 'Purchase Value',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '14px' }}>
            ${(parseFloat(row.buy_value) * parseFloat(row.exchange_rate ?? 1)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        )
      }
    }
  ]
  const exchange_columns = [
    {
      dataField: 'exchange_name',
      text: 'Name'
    },
    {
      dataField: 'exchange_type',
      text: 'Type'
    },
    {
      dataField: 'asset',
      text: 'Asset'
    },
    {
      dataField: 'accountType',
      text: 'Account'
    },
    {
      dataField: 'free',
      text: 'Balance'
    }
  ]

  const getPortfolios = async () => {
    /// wallet
    await axios
      .post(`${base_url}/wallet`, {
        portfolioId: portfolio_id,
        userId: localStorage.getItem('sub_Id')
      })
      .then((response) => {
        setPortfolio(response.data.results.data)
        
        if (response.data.results.data.length > 0) {
          setPortName(response.data.results.data[0].portfolioName)
          setTotalExchangeBalance(response.data.results.data[0].exchanges.reduce((ori, row) => { return ori += row.balance }, 0))
          setTotalInvestmentBalance(response.data.results.data[0].investments.reduce((ori, row) => { return ori += parseFloat(row.buy_value) * (row.exchange_rate ?? 1) }, 0))
          setTotalWalletBalance(response.data.results.data[0].wallets.reduce((ori, row) => { return ori += parseFloat(row.usd_Balance) }, 0))
        }
        setLoading(false)
      })
  }

  useEffect(async () => {
    await getPortfolios()
  }, [])

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12} className='admin-scroll' style={{ height: '85vh', overflow: 'auto' }}>
            <Row className="d-flex justify-content-start flex-row" >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Portfolio name - {portName}</h3>
              </span>
              <span
                className="p-2 pageheader" style={{ marginTop: '2.2%' }}
                onClick={() => navigate('/PMS/Admin/Transactions',
                  { state: { id: 1, data1: location?.state?.data, data1: walletN, value: Address } }
                )} >
                <Tooltip title="Transaction History">
                  <ReceiptLongIcon style={{ color: '#FFC107', fontSize: '25px' }} />
                </Tooltip>
              </span>
            </Row>
            <Row className="d-flex justify-content-end flex-column" >
              <div
                className="assets-chart-box "
                style={{
                  margin: '1%',
                  padding: '1%',
                  width: '53rem',
                  background: 'transparent',
                  borderRadius: '15px',
                  boxShadow: 'none'
                }}>
                <div
                  className='justify-content-center'
                  style={{
                    width: 'fit-content',
                    padding: '10px',
                    background: 'hsl(218deg 9% 18% / 67%)',
                    color: 'white',
                    height: '40px',
                    display: 'flex',
                    transition: 'all 0.5s',
                    flexDirection: 'row',
                    borderRadius: '22px',
                    marginLeft: '1%',
                    justifyContent: 'center'
                  }}
                >
                  <h6 style={{
                    fontWeight: 'bold',
                    marginTop: 'auto'
                  }}>
                    Total -
                  </h6>
                  <h4 style={{ fontSize: '15px', marginLeft: '6px', marginTop: 'auto' }} >
                    {(totalExchangeBalance + totalInvestmentBalance + totalWalletBalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </h4>
                </div>
              </div>
            </Row>
            {loading
              ? (
                <Spinner
                  style={{
                    marginTop: '-30%',
                    marginLeft: '32%',
                    height: '70px',
                    width: '70px'
                  }}
                  animation="border"
                  variant="primary"
                />
                )
              : null}
            <Container fluid className=" overflow-auto invest_scroll" style={{ height: '85vh', overflow: 'auto' }}>
              <Row className="d-flex flex-column justify-content-center m-0 datatable" >
                {
                portfolio[0]?.wallets.length > 0
                  ? portfolio[0]?.wallets.map((item, index) => (
                    <div
                      key={index}
                      className="assets-table-item"
                      style={{ flex: '0 0 100%', height: 'auto', marginTop: '3%', paddingTop: '8px', width: '100%' }}
                    >
                      <div className="table-heading-wrap">
                        <h5 style={{ fontSize: '22px' }}>
                          <span>
                            <Image src={wallateIcon} />
                          </span>
                          Wallets - {item.walletName}
                        </h5>

                        <span style={{ display: 'flex', flexDirection: 'row', gap: '1em', marginTop: '1%' }}>
                          <p style={{ color: 'white' }}>
                            ${parseFloat(item.usd_Balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </p>

                          <p>
                            {walletShowId != item.walletId &&
                              <AddCircleOutlineOutlinedIcon style={{ color: '#FFC107', cursor: 'pointer' }}
                                onClick={() => { setWalletShowId(item.walletId) }}
                              />
                            }

                            {walletShowId == item.walletId &&
                              <RemoveCircleOutlineSharpIcon style={{ color: '#FFC107', cursor: 'pointer' }}
                                onClick={() => setWalletShowId('')}
                              />
                            }
                          </p>
                        </span>
                      </div>

                      {walletShowId == item.walletId
                        ? <div>
                          <CommonTableWalletInfo
                            data={item.addresses}
                            columns={wallet_columns}
                          />
                        </div>
                        : <></>}
                    </div>
                  ))
                  : (
                  <div
                    className="assets-table-item"
                    style={{ flex: '0 0 100%', height: 'auto', marginTop: '3%', paddingTop: '8px', width: '100%' }}
                  >
                    <div className="table-heading-wrap">
                      <h5 style={{ fontSize: '22px' }}>
                        <span>
                          <Image src={wallateIcon} />
                        </span>
                        Wallets
                      </h5>

                      <span style={{ display: 'flex', flexDirection: 'row', gap: '1em', marginTop: '1%' }}>
                          <p style={{ color: 'white' }}>
                            $0.00
                          </p>
                          <p>
                            <AddCircleOutlineOutlinedIcon style={{ color: '#FFC107', cursor: 'pointer' }}/>
                          </p>
                        </span>
                    </div>
                  </div>
                    )}

              </Row>
              <Row className="d-flex flex-column justify-content-center m-0 datatable " >
                <div
                  className="assets-table-item"
                  style={{ flex: '0 0 100%', height: 'auto', marginTop: '3%', paddingTop: '8px' }}
                >
                  <div className="table-heading-wrap">
                    <h5 style={{ fontSize: '22px' }}>
                      <span>
                        <Image src={investmentIcon} />
                      </span>
                      Investments
                    </h5>
                    <span style={{ display: 'flex', flexDirection: 'row', gap: '1em', marginTop: '1%' }}>
                      <p style={{ color: 'white' }}>${totalInvestmentBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                      <p onClick={() => { setInvestShow(!investShow) }}>
                        {investShow === false
                          ? <AddCircleOutlineOutlinedIcon style={{ color: '#FFC107', cursor: 'pointer' }} />
                          : <RemoveCircleOutlineSharpIcon style={{ color: '#FFC107', cursor: 'pointer' }} />}
                      </p>
                    </span>
                  </div>
                  {investShow === true
                    ? <div >
                      <CommonTableInvestmentInfo data={portfolio[0].investments} columns={investment_columns} />
                    </div>
                    : <></>}
                </div>
              </Row>
              <Row className="d-flex flex-column justify-content-center m-0 datatable " >
                <div
                  className="assets-table-item"
                  style={{ flex: '0 0 100%', height: 'auto', marginTop: '3%', paddingTop: '8px' }}
                >
                  <div className="table-heading-wrap">
                    <h5 style={{ fontSize: '22px' }}>
                      <span>
                        <Image src={exchangeIcon} />
                      </span>
                      Exchanges
                    </h5>
                    <span style={{ display: 'flex', flexDirection: 'row', gap: '1em', marginTop: '1%' }}>
                      <p style={{ color: 'white' }}>${totalExchangeBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                      <p onClick={() => { setExchangetShow(!exchangeShow) }}>
                        {exchangeShow === false
                          ? <AddCircleOutlineOutlinedIcon style={{ color: '#FFC107', cursor: 'pointer' }} />
                          : <RemoveCircleOutlineSharpIcon style={{ color: '#FFC107', cursor: 'pointer' }} />}
                      </p>
                    </span>
                  </div>
                  {exchangeShow === true
                    ? <div style={{ height: '20vh' }}>
                      <RawTable data={portfolio} columns={exchange_columns} isFromPortfolio={true}/>
                    </div>
                    : <></>}
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container >
    </React.Fragment >
  )
}
export default AdminAssets

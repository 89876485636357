export const columns = [
  {
    dataField: 'errorMessage',
    text: 'Error',
    sort: true,
    toggle: true,
    width: 300
  },
  {
    dataField: 'acctType',
    text: 'Type',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'acctName',
    text: 'Name',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'boName',
    text: 'BO',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'boCode',
    text: 'Code',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'boType',
    text: 'BO Type',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'bankName',
    text: 'Bank',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'region',
    text: 'Region',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'acctNo',
    text: 'A/C No.',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'currency',
    text: 'Currency',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'prevBalance',
    text: 'Previous Balance',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'currentBalance',
    text: 'Current Balance',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'movements',
    text: 'Movement',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'fxRate',
    text: 'FX Rate',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'preBalanceUSD',
    text: 'Previous Balance USD',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'preCurrentBalanceUSD',
    text: 'Current Balance USD',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'preMovementsUSD',
    text: 'Movements USD',
    sort: true,
    toggle: true,
    width: 500
  },
  {
    dataField: 'lastUpdate',
    text: 'Last Update per Bank',
    sort: true,
    toggle: true,
    width: 150
  }
]

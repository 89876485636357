import React, { useState, useEffect } from 'react'
import CommonTableAuditTop from '../common/CommonTable/CommonTableAuditTop'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import '../Admin/DashboardAdmin/DashboardAdmin.css'
import Tooltip from '@mui/material/Tooltip'
import Header from '../common/Header/Header'
import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import copy from 'copy-to-clipboard'
import { Alert, TextField } from '@mui/material'
import Modal from 'react-bootstrap/Modal'
import '../common/Modal.css'
import Spinner from '../common/spinner'
import Snackbar from '@mui/material/Snackbar'
import { Icon } from '@iconify/react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import SidebarAdmin from '../Admin/DashboardAdmin/SidebarAdmin'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import SearchBox from '../common/SearchBox/SearchBox'
import CommonTableInvTop from '../common/CommonTable/CommonTableInvTop'
const useStyles = makeStyles({
  paper: {
    background: 'rgb(31, 33, 37) !important',
    color: 'white !important'
  },
  option: {
    '&:hover': {
      backgroundColor: 'grey !important',
      color: 'white !important'
    }
  }
})
function InvestmentLogHistory () {
  const styles = useStyles()
  const getId = localStorage.getItem('sub_Id')
  // console.log(getId)
  const [resultPortfolio, setResultPortfolio] = useState([])
  const [resultTransaction, setResultTransaction] = useState([])
  const [resultWalletInfo, setResultWalletInfo] = useState([])
  const handleCloseInvestment = () => setShowInvestment(false)
  const [bigData, setBigData] = useState(false)
  const [search1, setSearch1] = useState([])
  const [search2, setSearch2] = useState([])
  const [open, setOpen] = useState(false)
  const [alertC, setAlertC] = useState(true)
  const [resultExchange, setResultExchange] = useState([])
  const [isHovering, setIsHovering] = useState(false)
  const roleId = localStorage.getItem('role').split(',')
  const handleMouseEnter = () => {
    setIsHovering(true)
  }
  const handleMouseLeave = () => {
    setIsHovering(false)
    setBigData(false)
  }
  const location = useLocation()
  const portfolioId = location.pathname.slice(30)
  // console.log(location.pathname.slice(20))
  const [alertInvest, setAlertInvest] = useState(true)
  const [loading, setloading] = useState(true)
  const [defaultSelect, setDefaultSelect] = useState('wallet')
  const [showdata, setShowData] = useState(false)
  const [resultAccount, setResultAccount] = useState([])
  const [allportfolio, setAllPortfolio] = useState([])
  const [result5, setResult5] = useState([])
  const [walletDetails, setWalletDetails] = useState([])
  let acdata = []
  const accountant = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/all_accountant_and_portfolio`)
      .then(function (response) {
        const p_data = response.data
        acdata = p_data
        setResultAccount(p_data)
      })
  }
  const allportfolioUser = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/all_portfolios_details`, {
        params: {
          user_id: getId
        }
        // [...new Set(result.map(e=>e.investment_name))]
      }).then((response) => {
        const rs = response.data
        setWalletDetails(rs)
      })
    }
  const portfolio_data = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/all_portfolio_users_data`, {
        params: {
          user_id: getId,
          user_role: (roleId?.includes('admin') === true) ? 'admin' : 'accountant'
        }
      }).then((response) => {
        const rs1 = response.data
        // console.log(rs1)
        if (rs1 !== 'error') {
          setResult5(rs1)
        } else {
          setResult5([])
        }
      })
  }
  const handleSelect = (event) => {
    // console.log(event.target.value)
    setDefaultSelect(event.target.value)
    if (event.target.value == 'wallet') {
      setShowData(false)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getTronAuditHistory`
      }
     axios(config).then(function (response1) {
        // console.log(response.data)
       const rs1=response1.data
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getBtcAuditHistory`
      }
       axios(config).then(function (response2) {
        // console.log(response.data)
       const rs2=response2.data
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getAddressTransactionAuditHistory`
      }
       axios(config).then(function (response) {
        // console.log(response.data)
        const rs=[...response.data, ...rs1, ...rs2].map((rec,index)=>{
          return {...rec, no:index+1, hash_id: rec.address_type=='TRON' ? rec.hash : rec.address_type=='BTC'  ? rec.hash_id : rec.transaction_id, chain: rec.address_type=='TRON' ? rec.address_type : rec.address_type=='BTC'  ? rec.address_type : rec.asset_chain, date_created:rec.address_type=='TRON' ? rec.created_date : rec.address_type=='BTC' ? rec.created_date : rec.created_date, date_updated:rec.address_type=='TRON' ? new Date(parseInt(rec.updated_time)).toUTCString() : rec.address_type=='BTC' ? new Date(parseInt(rec.date_create)).toUTCString() : rec.updated_time  }
         })
      //  console.log(rs,rs1,rs2)
      let response_wallet = rs.filter(
        (item, pos, self) =>
          self.findIndex((v) => v.hash_id === item.hash_id) === pos
      );
        const arr = []
        const arr1 = []
        if (roleId.includes('accountant') === true && roleId.includes('admin') == false) {
          const a = acdata?.filter(i => i.accountant_id == getId)
          a?.forEach(el => {
            const m = response_wallet?.filter(j => j.portfolio_id == el.portfolio_id)
            const me = { ...m }
            if (Object.values(me)[0] != undefined) {
              arr.push(Object.values(me)[0])
            }
          })
          a?.forEach(el => {
            const m = rs?.filter(j => j.portfolio_id == el.portfolio_id)
            const me = { ...m }
            if (Object.values(me)[0] != undefined) {
              arr1.push(Object.values(me)[0])
            }
          })
          setloading(false)
          setResultTransaction(
            arr.map((rec, index) => {
              return { ...rec, no: index + 1 }}))
              setResultWalletInfo( arr1.map((rec, index) => {
                return { ...rec, no: index + 1 }}))
        } else {
          setloading(false)
          setResultTransaction(
            response_wallet.map((rec, index) => {
              return { ...rec, no: index + 1 }}))
              setResultWalletInfo( rs.map((rec, index) => {
                return { ...rec, no: index + 1 }}))
        }
      })
    })
  })
    } else if (event.target.value == 'investment') {
      setloading(true)
      setShowData(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getAllInvestmentLogs`
      }
      axios(config).then(function (response) {
    
        const rs = response.data.map(rec=>{
          let ac_type=rec.created_by_name!=null && rec.deleted_by!=null && rec.updated_by_name==null ? 'deleted' :
          rec.created_by_name!=null && rec.deleted_by==null && rec.updated_by_name!=null 
          ||  rec.created_by_name==null && rec.deleted_by!=null && rec.updated_by_name!=null ||
          rec.created_by_name==null && rec.deleted_by==null && rec.updated_by_name!=null ? 'updated' :
          rec.created_by_name!=null && rec.deleted_by==null && rec.updated_by_name==null && 'created'
          return {...rec, ac_type:ac_type, timestamp: rec.timestamp.length==13 ? new Date(parseInt(rec.timestamp)).toUTCString() : rec.timestamp  }
        })
        const arr = []
        if (roleId.includes('accountant') === true && roleId.includes('admin') == false) {
          const a = resultAccount?.filter(i => i.accountant_id == getId)
          a?.forEach(el => {
            const m = rs?.filter(j => j.portfolio_id == el.portfolio_id)
            const me = { ...m }
            arr.push(Object.values(me)[0])
          })
          setloading(false)
          setResultPortfolio(arr.map((rec,index)=>{return {...rec, no:index+1} }))
        } else {
          setloading(false)
          rs.sort((a,b)=>{
            const x = new Date(a.new_date_of_investment_modified).getTime()
            const y = new Date(b.new_date_of_investment_modified).getTime()
            return  x>y ? 1 : x<y ? -1 : 0 
          })
          let res1 = [...new Map(rs.map(x=>[(x.investment_Id),x])).values()].reverse()
         
          setResultPortfolio( res1.map((rec,index)=>{return {...rec, no:index+1} }))
        }
      })
    } else if (event.target.value == 'exchange') {
      setShowData(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/get_exchange_history_logs?offset=0`
      }
      axios(config).then(function (response) {
        const rs = response.data
        const arr = []
        if (roleId.includes('accountant') === true && roleId.includes('admin') == false) {
          const a = resultAccount?.filter(i => i.accountant_id == getId)
          // console.log(acdata, a)
          a?.forEach(el => {
            const m = rs?.filter(j => j.portfolio_id == el.portfolio_id)
            const me = { ...m }
            // console.log(Object.values(me)[0], m, me)
            if (Object.values(me)[0] != undefined) {
              arr.push(Object.values(me)[0])
            }
          })
          setResultExchange(arr)
        } else {
          setResultExchange(rs)
        }
      })
    }
  }
  for(let c of resultPortfolio){
    c.portfolio=[]
    for(let d of result5){
      if(c.portfolio_id==d.portfolio_id){
        c.portfolio.push(d.portfolio_name)
        
      }
    }
  }
  for(let e of resultExchange){
    e.portfolio=[]
    for(let f of result5){
      if(e.portfolio_id==f.portfolio_id){
        e.portfolio.push(f.portfolio_name)
      }
    }
  }
  for (let a of resultTransaction){
    a.portfolio=[]
    a.ownership=[]
    a.wallets=[]
    for(let b of result5){
      if(a.portfolio_id==b.portfolio_id){
        a.portfolio.push(b.portfolio_name)
        a.ownership.push(b.partydata)
      }
    }
    for(let c of walletDetails){
      if(a.portfolio_id==c.portfolio_id){
        a.wallets.push(c.walletsdata)
       
      }
    }
  }
  // console.log(resultTransaction)
  const handleTransactionLogs = async () => {
    setloading(true)
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/getTronAuditHistory`
    }
    await axios(config).then(function (response1) {
      // console.log(response.data)
     const rs1=response1.data
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/getBtcAuditHistory`
    }
     axios(config).then(function (response2) {
      // console.log(response.data)
     const rs2=response2.data
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/getAddressTransactionAuditHistory`
    }
     axios(config).then(function (response) {
      // console.log(response.data)
     const rs=[...response.data, ...rs1, ...rs2].map((rec,index)=>{
      return {...rec,hash_id: rec.address_type=='TRON' ? rec.hash : rec.address_type=='BTC'  ? rec.hash_id : rec.transaction_id, chain: rec.address_type=='TRON' ? rec.address_type : rec.address_type=='BTC'  ? rec.address_type : rec.asset_chain, date_created:rec.address_type=='TRON' ? rec.created_date : rec.address_type=='BTC' ? rec.created_date : rec.created_date, date_updated:rec.address_type=='TRON' ? new Date(parseInt(rec.updated_time)).toUTCString() : rec.address_type=='BTC' ? new Date(parseInt(rec.date_create)).toUTCString() : rec.updated_time  }
     })
    //  console.log(rs,rs1,rs2)
    let response_wallet = rs.filter(
      (item, pos, self) =>
        self.findIndex((v) => v.hash_id === item.hash_id) === pos
    );
      const arr = []
      const arr1=[]
      if (roleId.includes('accountant') === true && roleId.includes('admin') == false) {
        const a = acdata?.filter(i => i.accountant_id == getId)
        a?.forEach(el => {
          const m = response_wallet?.filter(j => j.portfolio_id == el.portfolio_id)
          const me = { ...m }
          if (Object.values(me)[0] != undefined) {
            arr.push(Object.values(me)[0])
          }
        })
        a?.forEach(el => {
          const m = rs?.filter(j => j.portfolio_id == el.portfolio_id)
          const me = { ...m }
          if (Object.values(me)[0] != undefined) {
            arr1.push(Object.values(me)[0])
          }
        })
        setloading(false)
        setResultTransaction(
          arr.map((rec, index) => {
            return { ...rec, no: index + 1 }}))
            setResultWalletInfo( arr1.map((rec, index) => {
              return { ...rec, no: index + 1 }}))
      } else {
        setloading(false)
        setResultTransaction(
          response_wallet.map((rec, index) => {
            return { ...rec, no: index + 1 }}))
            setResultWalletInfo( rs.map((rec, index) => {
              return { ...rec, no: index + 1 }}))
      }
    })
  })
})
  }
  const refresh_wallet = async () => {
    if (defaultSelect == 'investment') {
      setloading(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getAllInvestmentLogs`
      }
      await axios(config).then(function (response) {
        // console.log(response.data)
        response.data.sort((a,b)=>{
          const x = new Date(a.new_date_of_investment_modified).getTime()
          const y = new Date(b.new_date_of_investment_modified).getTime()
          return  x>y ? 1 : x<y ? -1 : 0 
        })
        const rs = response.data.map(rec=>{
          let ac_type=rec.created_by_name!=null && rec.deleted_by!=null && rec.updated_by_name==null ? 'deleted' :
          rec.created_by_name!=null && rec.deleted_by==null && rec.updated_by_name!=null 
          ||  rec.created_by_name==null && rec.deleted_by!=null && rec.updated_by_name!=null ||
          rec.created_by_name==null && rec.deleted_by==null && rec.updated_by_name!=null ? 'updated' :
          rec.created_by_name!=null && rec.deleted_by==null && rec.updated_by_name==null && 'created'
          return {...rec, ac_type:ac_type, timestamp: rec.timestamp.length==13 ? new Date(parseInt(rec.timestamp)).toUTCString() : rec.timestamp  }
        })
        let res1 = [...new Map(rs.map(x=>[(x.investment_Id),x])).values()].reverse()
        setResultPortfolio(res1.map((rec,index)=>{return {...rec, no:index+1} }))
        setloading(false)
      })
    } else if (defaultSelect == 'wallet') {
      setloading(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getTronAuditHistory`
      }
      await axios(config).then(function (response1) {
        // console.log(response.data)
       const rs1=response1.data
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getBtcAuditHistory`
      }
       axios(config).then(function (response2) {
        // console.log(response.data)
       const rs2=response2.data
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getAddressTransactionAuditHistory`
      }
       axios(config).then(function (response) {
        // console.log(response.data)
        const rs=[...response.data, ...rs1, ...rs2].map((rec,index)=>{
          return {...rec, no:index+1, hash_id: rec.address_type=='TRON' ? rec.hash : rec.address_type=='BTC'  ? rec.hash_id : rec.transaction_id, chain: rec.address_type=='TRON' ? rec.address_type : rec.address_type=='BTC'  ? rec.address_type : rec.asset_chain, date_created:rec.address_type=='TRON' ? rec.created_date : rec.address_type=='BTC' ? rec.created_date : rec.created_date, date_updated:rec.address_type=='TRON' ? new Date(parseInt(rec.updated_time)).toUTCString() : rec.address_type=='BTC' ? new Date(parseInt(rec.date_create)).toUTCString() : rec.updated_time  }
         })
      //  console.log(rs,rs1,rs2)
      let response_wallet = rs.filter(
        (item, pos, self) =>
          self.findIndex((v) => v.hash_id === item.hash_id) === pos
      );
        const arr = []
        const arr1=[]
        if (roleId.includes('accountant') === true && roleId.includes('admin') == false) {
          const a = acdata?.filter(i => i.accountant_id == getId)
          a?.forEach(el => {
            const m = response_wallet?.filter(j => j.portfolio_id == el.portfolio_id)
            const me = { ...m }
            if (Object.values(me)[0] != undefined) {
              arr.push(Object.values(me)[0])
            }
          })
          a?.forEach(el => {
            const m = rs?.filter(j => j.portfolio_id == el.portfolio_id)
            const me = { ...m }
            if (Object.values(me)[0] != undefined) {
              arr1.push(Object.values(me)[0])
            }
          })
          setloading(false)
          setResultTransaction(
            arr.map((rec, index) => {
              return { ...rec, no: index + 1 }}))
              setResultWalletInfo( arr1.map((rec, index) => {
                return { ...rec, no: index + 1 }}))
              
        } else {
          setloading(false)
          setResultTransaction(
            response_wallet.map((rec, index) => {
              return { ...rec, no: index + 1 }}))
              setResultWalletInfo( rs.map((rec, index) => {
                return { ...rec, no: index + 1 }}))
        }
      })
    })
  })
    }
  }
  const [dataId, setDataId] = useState('')
  const [validated, setValidated] = useState('')
  const [dataRow, setDataRow] = useState([])
  const [show, setShow] = useState(false)
  const [showDashboard, setShowDashboard] = useState(true)
  const [newWidth, setNewWidth] = useState('10')
  const [widthData, setWidthData] = useState('-4%')
  const [margin, setMargin] = useState('8%')
  const [w, setW] = useState('110%')
  const [m, setm] = useState('-10%')
  const [wid, setWid] = useState('170%')
  const [mar, setmar] = useState('-6%')
  const [search, setSearch] = useState([])
  const [sea, setSea] = useState('')
  const [showInvestment, setShowInvestment] = useState(false)
  const handleToggle = () => {
    setShowDashboard(!showDashboard)
    if (showDashboard === true) {
      setNewWidth('10')
      setW('110%')
      setWid('181%')
      setmar('-20%')
      setm('-9%')
      setMargin('8%')
      setWidthData('-4%')
      // console.log(showDashboard)
      // console.log(newWidth)
    } else {
      setNewWidth('10')
      setm('2.5%')
      setWid('159%')
      setmar('0%')
      setW('100%')
      setMargin('22%')
      setWidthData('6%')
      // console.log(showDashboard)
      // console.log(newWidth)
    }
  }
  const handleSubmitForm = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)
  }
  const handleClose = () => setShow(false)
  const handleShow = (id) => {
    // console.log(id)
    setDataId(id)
    setShow(true)
  }
  const [result, setResult] = useState([])
  const handleShowInvestment = () => {
    // console.log('Clicked Investment')
    setShowInvestment(true)
  }
  useEffect(async () => {
    await accountant()
    await portfolio_data()
    await allportfolioUser()
    await handleTransactionLogs()
  }, [])
  const columns1 = [
    {
      dataField: 'no',
      text: 'No',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '13px' }}>
             {row.no} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'portfolio',
      text: 'Portfolio',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '13px', whiteSpace:'nowrap' }}>
             {row.portfolio[0]!=null ? row.portfolio[0] : "N/A"} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'wallets',
      text: 'Wallet Name',
      sort: true,
       formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            {row.wallets.length==0 ?
            <p style={{marginLeft:'2em'}}>-</p>
            :
            <p>{row.wallets?.[0]?.filter(i=>i.wallet_id==row.wallet_id)?.[0]?.wallet_name}</p>
       }
          </p>
        )
      }
    },
    {
      dataField: 'hash_id',
      text: 'Hash/ID',
      sort: true,
      toggle: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const txn = row.hash_id
        const copyToClipboard2 = (txn) => {
          copy(txn, {
            debug: true,
            message: 'Press #{key} to copy'
          })
          setOpen(true)
          setAlertC(true)
          setTimeout(() => {
            setAlertC(false)
          }, 3000)
        }
        return (
          <div style={{whiteSpace:'nowrap'}}>
            {/* /<li style={{ whiteSpace: 'nowrap', color: '#F1C40F' }}> */}
            <span className="namePortData" style={{ cursor: 'pointer',whiteSpace:'nowrap' }}
             onClick={()=> row.asset_chain==='eth' ? window.open(`https://etherscan.io/tx/${row.hash_id}`)
            : row.address_type==='TRON' ? window.open(`https://tronscan.org/#/transaction/${row?.hash_id}`) :
            window.open(`https://www.blockchain.com/btc/tx/${row.hash_id}`)
            }
            >
              {row?.hash_id != undefined ? row?.hash_id.slice(0, 6) + "..." + row?.hash_id.slice(-4) : <></>}
            </span>
            <Tooltip title="Copy to Clipboard">
              {row.hash_id != undefined
                ? (
                  <Icon
                    icon="cil:copy"
                    style={{
                      cursor: 'pointer',
                      color: '#FFC107',
                      marginLeft: '5px'
                    }}
                    onClick={() => copyToClipboard2(row.hash_id)}
                  />
                )
                : (
                  <></>
                )}
            </Tooltip>
            {/* </li> */}
          </div>
        )
      }
    },
    {
      dataField: 'chain',
      text: 'Chain',
      sort: true,
      toggle: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
              {row.chain?.toUpperCase()} 
          </p>
        )
      }
      
    },
    {
      dataField: 'date_created',
      width: 150,
      text: 'Date Creation',
      sort: true,
      toggle: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
          let date1 = moment(row.date_created).format('Do MMMM YYYY, h:mm:ss a').split(',')
        return (
          <p
            style={{
              color: 'white',
              // width: '110%',
              fontSize: '14px',
              display: 'inline-block'
            }}
          >
            {row.date_created==null ? 
              <span style={{ color: 'white',marginLeft:'2em' }}></span> :
              <span style={{ color: 'white', fontSize: '14px',whiteSpace:'nowrap' }}>
                {date1[0]} <br />
                {date1[1]}
              </span>
      }
          </p>
        )
      }
    },
    {
      dataField: 'date_updated',
      width: 150,
      text: 'Date Change',
      sort: true,
      toggle: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
          let date1 = moment(row.date_updated).format('Do MMMM YYYY, h:mm:ss a').split(',')
        return (
          <p
            style={{
              color: 'white',
              // width: '110%',
              fontSize: '14px',
              display: 'inline-block'
            }}
          >  
           {row.date_updated==null ? 
              <span style={{ color: 'white',marginLeft:'2em' }}></span> :
              <span style={{ color: 'white', fontSize: '14px', whiteSpace:'nowrap' }}>
                {date1[0]} <br />
                {date1[1]}
              </span>
      }
          </p>
        )
      }
    },

    {
      dataField: 'usertype',
      text: 'Role',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
         console.log(row.usertype)
        return (
          <>
            <div>
             
              <span
                style={{
                  color: 'white',
                  fontSize: '13px',
                  whiteSpace:'nowrap'
                }}
              >
                {row.usertype=='shubham1' ? 'shubham1' : row.usertype=='admin' ? 'admin' : JSON?.parse(row.usertype)=='accountant,uma_protection,admin,user' || (JSON?.parse(row.usertype)?.includes('accountant') && JSON?.parse(row.usertype)?.includes('user') && JSON?.parse(row.usertype)?.includes('admin') || JSON?.parse(row.usertype)?.includes('user') && JSON?.parse(row.usertype)?.includes('admin') )  ? 'super admin' :JSON?.parse(row.usertype)=='accountant'? JSON?.parse(row.usertype) : JSON?.parse(row.usertype) }
              </span>
      
            </div>
          </>
        )
      }
    },
    {
      dataField: 'username',
      text: 'User',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
         console.log(row.username)
        return (
          <>
            <div>
              <span
                style={{
                  color: 'white',
                  fontSize: '13px',
                }}
              >
                {row.username}
              </span>
            </div>
          </>
        )
      }
    },
    {
      dataField: 'ownership',
      text: 'Ownership',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
          {row.ownership.length==0 || row.ownership[0].length==0   ? 
          <span style={{marginLeft:'3em'}}>-</span> :
          <ul style={{marginLeft:'-3em', whiteSpace:'nowrap'}} >
            {
              row.ownership?.[0]?.map(e =>
                <li key={row.portfolio_id + e.party_id} style={{  color: '#FFC107', cursor: 'pointer',whiteSpace:'nowrap' }}  >
                  <span > {e.name.charAt(0).toUpperCase()+e.name.slice(1)} - {e.ownership}% </span>
                </li>
              )
            }
          </ul>}
          </>
        )
      }
    },
    // {
    //   dataField: 'new_comment_modified',
    //   text: 'Comment',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //       <p style={{ color: 'white', fontSize: '13px' }}>
    //         { row.new_comment_modified==null ? <span style={{marginLeft:'2em'}}> -</span> :
    //             row.comments== row.new_comment_modified || row.deleted_by!=null ? 
    //           <span>{row.new_comment_modified}</span>
    //           :
    //           <span style={{textDecoration:'underline'}}>{row.new_comment_modified}</span>
    //         }
    //       </p>
    //     )
    //   }
    // },
  ]
  const columns2 = [
      {
      dataField: 'no',
      text: 'No',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '13px' }}>
             {row.no} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'portfolio',
      text: 'Portfolio',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '13px', whiteSpace:'nowrap' }}>
             {row.portfolio[0]!=null ? row.portfolio[0] : "N/A"} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'new_investment_name',
      text: 'Name',
      sort: true,
       formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            {    row.investment_name== row.new_investment_name || row.deleted_by!=null ?
              row.new_investment_name!=null ?
                 <p >{row.new_investment_name}</p> :
               
                 <p >{row.investment_name}</p> :
                 <p style={{textDecoration:'underline'}}>{row.new_investment_name}</p>
          }
          </p>
        )
      }
    },
    {
      dataField: 'new_investment_type',
      text: 'Type',
      sort: true,
      width: 50,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(resultPortfolio.filter(i=>i.investment_Id==row.investment_Id && i.new_investment_type!= row.new_investment_type))
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>

            {    row.investment_type== row.new_investment_type || row.deleted_by!=null ?
              row.new_investment_type!=null ?
                 <p >{row.new_investment_type}</p> :
               
                 <p >{row.investment_type}</p> :
                 <p style={{textDecoration:'underline'}}>{row.new_investment_type}</p>
          }
          </p>
        )
      }
    },
    {
      dataField: 'timestamp',
      text: 'Created Date',
      sort: true,
      width: 150,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const date = new Date(row.date_of_investment).toString()
        const newDate = new Date(date).getTime()
        const istDate = new Date(newDate)
       
        return (
          <p
            style={{
              color: 'white',
              // width: '110%',
              fontSize: '13px',
              display: 'inline-block'
            }}
          >
            
            <span style={{ color: 'white', fontSize: '13px' }}>
            {moment(row.timestamp).format('Do MMMM YYYY, h:mm:ss a')}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'new_date_of_investment_modified',
      text: 'Updated Date',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const date = new Date(row.updated_date).toString()
        const newDate = new Date(date).getTime()
        const istDate = new Date(newDate)
       
        return (
          <p
            style={{
              color: 'white',
              // width: '110%',
              fontSize: '13px',
              display: 'inline-block'
            }}
          >
            
            <span style={{ color: 'white', fontSize: '13px' }}>
            {moment(row.new_date_of_investment_modified).format('Do MMMM YYYY, h:mm:ss a')}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'action',
      text: 'Type of action',
      sort: true,
      width: 150,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(JSON.parse(row.user_type))
        return (
          <>
            <div>
              {row.created_by_name!=null && row.deleted_by!=null && row.updated_by_name==null ?
              <span>Deleted</span> :
              row.created_by_name!=null && row.deleted_by==null && row.updated_by_name!=null 
              ||  row.created_by_name==null && row.deleted_by!=null && row.updated_by_name!=null ||
              row.created_by_name==null && row.deleted_by==null && row.updated_by_name!=null ?
              <span>Updated</span> :
              row.created_by_name!=null && row.deleted_by==null && row.updated_by_name==null ?
              <span>Created</span> : 
              <span>-</span>}
            </div>
          </>
        )
      }
    },
     {
      dataField: 'purchase_type',
      text: 'Type of transaction',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
           { row.purchase_type!=null ?
          <p>{row.purchase_type}</p>
           :  <p >{row.sell_type}</p>}
          </p>
        )
      }
    },
    {
      dataField: 'new_quantity',
      text: 'Quantity',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(resultPortfolio.filter(i=>i.investment_name==row.investment_name && i.new_quantity!= row.new_quantity))
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>

            { row.purchase_type!=null ?
             row.previous_quantity== row.new_quantity  || row.deleted_by!=null ? 
          <p>{parseInt(row.new_quantity).toLocaleString()}</p> :
          <p style={{textDecoration:'underline'}}>{parseInt(row.new_quantity).toLocaleString()}</p>
        : <p>{parseInt(row.new_quantity).toLocaleString()}</p>}
          </p>
        )
      }
    },
    {
      dataField: 'new_purchase_price',
      text: 'Price',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            {row.new_purchase_price==null ? '-' :
             row.purchase_type!=null ?
            row.previous_purchase_price== row.new_purchase_price || row.deleted_by!=null ?
          <p>$ {parseFloat(row.new_purchase_price).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p> :
          <p style={{textDecoration:'underline'}}>$ {parseFloat(row.new_purchase_price).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
          :
          row.purchase_type==null ?
          <p>$ {parseInt(row.sell_nav)}</p>
           :  <p>$ {parseFloat(row.new_purchase_price).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
            }
          </p>
        )
      }
    },
    {
      dataField: 'new_buy_value',
      text: 'Value',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            { row.new_buy_value==null ? '-' :
            row.purchase_type!=null ?
            <p>${parseFloat(row.new_buy_value).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p> 
            :
             row.purchase_type==null ?
            <p>${(parseFloat(row.sell_nav) * parseFloat(row.new_quantity)).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
             :  <p>$ {parseFloat(row.new_buy_value).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
            }
          </p>
        )
      }
    },
    {
      dataField: 'user_type',
      text: 'Role',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(JSON.parse(row.user_type))
        return (
          <>
            <div>
              <span
                style={{
                  color: 'white',
                  fontSize: '13px',
                  whiteSpace:'nowrap'
                }}
              >
                {JSON.parse(row.user_type)=='accountant,uma_protection,admin,user' || (JSON.parse(row.user_type)?.includes('accountant') && JSON.parse(row.user_type)?.includes('user') && JSON.parse(row.user_type)?.includes('admin') || JSON.parse(row.user_type)?.includes('user') && JSON.parse(row.user_type)?.includes('admin') )  ? 'super admin' :JSON.parse(row.user_type)=='accountant'? JSON.parse(row.user_type) : JSON.parse(row.user_type) }
              </span>
             
            </div>
          </>
        )
      }
    },
    {
      dataField: 'user_type',
      text: 'User',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(JSON.parse(row.user_type))
        return (
          <>
            <div>
              <span
                style={{
                  color: 'white',
                  fontSize: '13px',
                }}
              >
                {row.username}
              </span>
            </div>
          </>
        )
      }
    },
    {
      dataField: 'new_comment_modified',
      text: 'Comment',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px' }}>
            { row.new_comment_modified==null ? <span style={{marginLeft:'2em'}}> -</span> :
                row.comments== row.new_comment_modified || row.deleted_by!=null ? 
              <span>{row.new_comment_modified}</span>
              :
              <span style={{textDecoration:'underline'}}>{row.new_comment_modified}</span>
            }
          </p>
        )
      }
    },
  ]
  const columns3 = [
    {
      dataField: 'portfolio',
      text: 'Portfolio',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '12px' }}>
             {row.portfolio[0]!=null ? row.portfolio[0] : "N/A"} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'created_time',
      text: 'Date',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p
            style={{
              color: 'white',
              fontSize: '12px',
              display: 'inline-block'
            }}
          >
            <span style={{ color: 'white', fontSize: '13px' }}>
            {moment(row.created_time).format('MMMM Do YYYY, h:mm:ss a')}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      width: 50,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '12px' }}>
            {row.amount}
          </p>
        )
      }
    },
    {
      dataField: 'price',
      text: 'Price',
      sort: true,
      width: 150
    },
    {
      dataField: 'previous_comment',
      text: 'Previous Comment',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '12px' }}>
            {row.previous_comment}
          </p>
        )
      }
    },
    {
      dataField: 'new_comment',
      text: 'Updated Comment',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '12px' }}>
            {row.new_comment}
          </p>
        )
      }
    },
    {
      dataField: 'user_type',
      text: 'Role User',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div>
              <span
                style={{
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row.usertype}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: 'white',
                  fontSize: '12px',
                  marginLeft: '5px'
                }}
              >
                {row.username}
              </span>
            </div>
          </>
        )
      }
    }
  ]
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12} >
          {alertC
              ? (
                <Snackbar
                  open={open}
                >
                  <Alert
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{
                      marginBottom: '295%',
                      marginLeft: '300%',
                      backgroundColor: 'black'
                    }}
                  >
                    Copied
                  </Alert>
                </Snackbar>
              )
              : (
                <></>
              )}

            <Row className="d-flex justify-content-center" >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Audit History</h3>
              </span>
              <Link
                className="p-2 pageheader"
                to="#"
                onClick={refresh_wallet}
                style={{top:'10px',position:'relative'}}
              >
                <Tooltip title="Refresh">
                  <Icon
                    icon="ic:sharp-refresh"
                    style={{
                      fontSize: '25px',
                      color: '#FFC107',
                      
                    }}
                  />
                </Tooltip>
              </Link>
              <div className="p-2 pageheader">
                <FormControl >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    style={{
                      fontSize: '17px',
                      overflow: 'visible',
                      color: 'white',
                      top:'-6px'
                    }}
                  >
                    Type
                  </InputLabel>
                  <Select
                    MenuProps={{
                      classes: {
                        paper: styles.paper
                      },
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor: 'grey',
                            color: 'black'
                          },
                          '& .MuiMenuItem-root.Mui-selected:hover': {
                            backgroundColor: 'grey',
                            color: 'black'
                          }
                        }
                      }
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={defaultSelect}
                    label="Select"
                    sx={{
                      width: '200px',
                       height: '32px',
                       border:'1px solid #d9d9d9 !important',
                      '.MuiButtonBase-root': {
                        color: 'white'
                      },
                    }}
                    style={{
                      borderRadius: '4px',
                      width: '200px !important',
                      height: '32px !important',
                      // backgroundColor: '#fff',
                      fontSize: '14px',
                      // left: '4px'
                    }}
                    onChange={handleSelect}
                  >
                    <MenuItem value={'wallet'}>Wallet</MenuItem>
                    <MenuItem value={'investment'}>Investment</MenuItem>
                    <MenuItem value={'exchange'}>Exchange</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <SearchBox
                className="p-2 pageheader"
                onChange={(event) => {
                  setSea(event.target.value)
                  if (defaultSelect === 'investment') {
                    const x = resultPortfolio?.filter((i) =>
                      i.investment_name
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase())
                    )
                    setSearch(x)
                  } else if (defaultSelect === 'exchange') {
                    const x = resultExchange?.filter((i) =>
                      i.new_comment
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase())
                    )
                    setSearch2(x)
                  } else {
                    const x = resultTransaction?.filter(
                      (i) =>
                        i.address_name != null &&
                        i.address_name
                          .toLowerCase()
                          .includes(event.target.value.toLowerCase())
                    )
                    setSearch1(x)
                  }
                }}
              />
            </Row>
            {loading
              ? (
                <Spinner
                  style={{
                  position:'fixed',
                  top:'20em',
                  left:'59%',
                    height: '70px',
                    width: '70px'
                  }}
                  animation="border"
                  variant="primary"
                />
                )
              : null}
            <div >
              {sea
                ? showdata &&
                defaultSelect === 'investment' && (
                  <CommonTableInvTop loading={loading} data={search} columns={columns2} />
                )
                : showdata &&
                defaultSelect === 'investment' && (
                  <CommonTableInvTop
                  loading={loading} 
                    data={resultPortfolio}
                    columns={columns2}
                  />
                )}
              {sea
                ? !showdata &&
                defaultSelect === 'wallet' && (
                  <CommonTableAuditTop  walletInfo={resultWalletInfo} loading={loading}  data={search1} columns={columns1} />
                  )
                : !showdata &&
                defaultSelect === 'wallet' && (
                  <CommonTableAuditTop
                  loading={loading} 
                  walletInfo={resultWalletInfo}
                    data={resultTransaction}
                    columns={columns1}
                  />
                  )}
              {sea
                ? showdata &&
                defaultSelect === 'exchange' && (
                  <CommonTable  loading={loading}   data={search2} columns={columns3} />
                )
                : showdata &&
                defaultSelect === 'exchange' && (
                  <CommonTable
                  loading={loading} 
                    data={resultExchange}
                    columns={columns3}
                  />
                )}
            </div>
          </Col>
        </Row>
      </Container >
      <Modal
        show={show}
        onHide={handleClose}
        style={{
          width: '35%',
          marginTop: '20%',
          overflow: 'hidden',
          marginLeft: '39%',
          backgroundColor: '#222429',
          height: '22%',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '18px',
              marginTop: '-5%', 
              marginLeft: '11%'
            }}
          >
            Are you sure you want to Delete this Investment ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429', 
            borderTop: 'none',
            paddingRight: '34%',
            marginTop: '-4%'
          }}
        >
          <Button
            variant="danger"
            style={{ width: '25%' }}
            onClick={() => {
              handleClose()
            }}
          >
            Yes
          </Button>
          <Button
            variant="success"
            onClick={handleClose}
            style={{ width: '25%' }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment >
  )
}
export default InvestmentLogHistory
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MdOutlineDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { useState } from "react";
import {
  setOpenCommonModalDeleteStatus,
  setOpenEditExchangeModalStatus,
} from "../Redux/appSlice";
import { useSelector, useDispatch } from "react-redux";
import "./BalanceTable.css";
import { useFormik } from "formik";
import { useEffect } from "react";
import axios from "axios";
import ModalToEditExchangeValue from "./commonModals/modalToEditExchangeValue";
import ModalDeleteExchangeValue from "./commonModals/ModalToDeleteValue";
import Spinner from "../common/spinner";
export default function BalanceTable(props) {
  const [result, setResult] = useState([]);

  const dispatch = useDispatch();
  let roleId = localStorage.getItem("role").split(",");
  const [tableData, setTableData] = useState([]);
  // const [result1,setResult1]=useState([]);
  const [name, setName] = useState(null);
  const [keyStatus, setKeyStatus] = useState(null);
  const [condition, setCondition] = useState(false);
  const [spanOrderKey, setOrderKey] = useState(null);
  const [spanOrderName, setSpanOrderName] = useState(null);

  const iconData = [
    {
      name: "Up Arrow",
      icon: <FaArrowUp />,
    },
    {
      name: "Down Arrow",
      icon: <FaArrowDown />,
    },
  ];

  //     const handleClickSpan=(item,key)=>{
  //       console.log(item,key);
  //       setOrderKey(key);
  //       setSpanOrderName(item);
  //       setSpanCondition((prev)=>!prev);
  //     //  handleOrderingData()
  //     if(item.dataField=='exchange_name'){
  //       result.sort( (a, b) => a > b ? 1 : -1)
  //     } else if(item.dataField=='exchange_type'){
  //       result.sort( (a, b) => a.exchange_type > b.exchange_type ? 1 : a.exchange_type > b.exchange_type ? -1 : 0)
  //     }else if(item.dataField=='asset'){
  //       result.reverse();
  //     } else if(item.dataField=='accountType'){
  //       result.sort( (a, b) => a.accountType > b.accountType ? 1 : a.accountType > b.accountType ? -1 : 0)
  //     }else if(item.dataField=='free'){
  //       result.reverse()
  //     }
  //   }

  // const handleOrderingData=()=>{
  //    console.log(data.reverse(),result1)
  // }

  // console.log('DELETEMODAL', openCommonModalDeleteStatus,dataDeleteRowModal,openCommonModalDeleteStatus);
  // const handleClickEdit=(item)=>{
  //   dispatch(setOpenEditExchangeModalStatus(true))
  //   setDataEditRowModal(item)
  //   // console.log("Exchange",item);
  //    exchange()
  // }
  // console.log(tableData);
  console.log(
    props.balanceTotal,
    props.balanceTotal.asset?.reduce((acu, cur) => acu + cur.amount, 0)
  );
  const Record = props.loading
    ? ""
    : !(props.data.length > 0) && "No Record Found";
  return (
    <div className="container_exchange">
      <div className="container_row_fixed">
        <table className="balance_table">
          <tr>
            {/* {props.columns?.map((info, key) => (
                 <th style={{borderRight:'1px solid grey'}}>
                   {info.text} */}

            <th style={{ borderRight: "1px solid grey" }}>Assets</th>

            <th>Liabilities</th>
          </tr>

          {props.data.length > 0 ? (
            <></>
          ) : (
            <tr>
              <th
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "100",
                  borderBottom: "none",
                  left: "25%",
                }}
              >
                {Record}
              </th>{" "}
            </tr>
          )}
          <tbody className="balance_table_body">
            {props.data?.map((row, key) => (
              <tr key={key}>
                <td style={{ borderRight: "1px solid grey" }}>
                  {row.liability?.map((item) => (
                    <li style={{ listStyle: "none", marginBottom: "1em" }}>
                      {/* {row.creditor?.charAt(0)?.toUpperCase() + row.creditor?.slice(1)} */}
                      {item.person}
                      {item.balance.currency.map((i, index) => (
                        <li
                          style={{
                            listStyle: "lower-latin",
                            marginLeft: "1em",
                          }}
                        >
                          <span>{i}____________________</span>{" "}
                          <span
                            style={{
                              position: "absolute",
                              right: "61%",
                              color: "#00ff00",
                            }}
                          >
                            {parseFloat(item.balance.amount[index])
                              .toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              .replace(/\.00$/, "")
                              .replace("-", "")}
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              position: "absolute",
                              right: "52%",
                            }}
                            to="#"
                            onClick={() =>
                              props.handleOpenModal({
                                name: item.person,
                                currency: i,
                                amt: item.balance.amount[index],
                                rate: item.balance.rate[index],
                              })
                            }
                            className=" retrieve-transaction"
                          >
                            {" "}
                            (Cancel)
                          </span>
                        </li>
                      ))}
                    </li>
                  ))}
                </td>

                <td>
                  {row.asset?.map((item) => (
                    <li style={{ listStyle: "none", marginBottom: "1em" }}>
                      {/* {row.creditor?.charAt(0)?.toUpperCase() + row.creditor?.slice(1)} */}
                      {item.person}
                      {item.balance.currency.map((i, index) => (
                        <li
                          style={{
                            listStyle: "lower-latin",
                            marginLeft: "1em",
                          }}
                        >
                          <span>{i}____________________</span>
                          <span
                            style={{
                              position: "absolute",
                              right: "12%",
                              color: "#ff0000",
                            }}
                          >
                            {parseFloat(item.balance.amount[index])
                              .toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              .replace(/\.00$/, "")}
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              position: "absolute",
                              right: "3%",
                            }}
                            to="#"
                            onClick={() =>
                              props.handleOpenModal({
                                name: item.person,
                                currency: i,
                                amt: item.balance.amount[index],
                                key: "creditor",
                                rate: item.balance.rate[index],
                              })
                            }
                            className=" retrieve-transaction"
                          >
                            {" "}
                            (Cancel)
                          </span>
                        </li>
                      ))}
                    </li>
                  ))}
                </td>

                {/* <td style={{maxHeight:"10rem"}}>123</td> */}
                {/* <td>{item?.accountType}</td> */}
                {/* <td>
                   <div style={{display:"flex",gap:'0.3rem'}}>
                   <p style={{cursor:"pointer"}} 
                    onClick={()=>handleClickDelete(item)}
                   >
                   <MdOutlineDeleteOutline color="red" fontSize="1.2rem"/>
                   </p>
                   </div>
                 </td> */}
              </tr>
            ))}
          </tbody>
          {props.data?.[0]?.asset?.length > 0 ||
          props.data?.[0]?.liability?.length > 0 ? (
            <tr>
              {props.balanceTotal.asset?.length > 0 ? (
                <th
                  style={{
                    borderRight: "1px solid grey",
                    position: "relative",
                    paddingLeft: "1%",
                  }}
                >
                  Total($):{" "}
                  <span style={{ position: "absolute", right: "22%" }}>
                    {props.balanceTotal.asset
                      ?.reduce((acu, cur) => acu + cur.amount, 0)
                      .toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      .replace(/\.00$/, "")}
                  </span>
                </th>
              ) : (
                <th
                  style={{
                    borderRight: "1px solid grey",
                    position: "relative",
                    paddingLeft: "1%",
                  }}
                ></th>
              )}
              {props.balanceTotal.liability?.length > 0 ? (
                <th style={{ borderLeft: "1px solid grey", paddingLeft: "1%" }}>
                  Total($):{" "}
                  <span style={{ position: "absolute", right: "23%" }}>
                    {props.balanceTotal.liability
                      ?.reduce((acu, cur) => acu + cur.amount, 0)
                      .toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      .replace(/\.00$/, "")}
                  </span>
                </th>
              ) : (
                <th
                  style={{ borderLeft: "1px solid grey", paddingLeft: "1%" }}
                ></th>
              )}
            </tr>
          ) : (
            <></>
          )}
        </table>
      </div>
    </div>
  );
}

export const columns = [
  {
    dataField: 'errorMessage',
    text: 'Error',
    sort: true,
    toggle: true,
    width: 300
  },
  {
    dataField: 'office',
    text: 'Office',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'propId',
    text: 'ID',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'currency',
    text: 'Currency',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'active',
    text: 'Active',
    sort: true,
    toggle: true,
    width: 100
  },
  {
    dataField: 'country',
    text: 'Country',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'propType',
    text: 'Property Type',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'tenureFinalDt',
    text: 'Tenure - Finalization Date',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'project',
    text: 'Project',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'unit',
    text: 'Unit',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'type',
    text: 'Type',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'existingUse',
    text: 'Existing Use',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'acquisitionDt',
    text: 'Acquisition Date',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'valuationDt',
    text: 'Valuation Date',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'floorArea',
    text: 'Floor Area',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'valuationAmt',
    text: 'Valuation AED',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'purchasePrice',
    text: 'Purchase Price',
    sort: true,
    toggle: true,
    width: 500
  },
  {
    dataField: 'capex',
    text: 'Capex',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'historicalCost',
    text: 'Historical Cost',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'valuationCostUSD',
    text: 'Valuation USD',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'acquisitionCostUSD',
    text: 'Acquisition USD',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'fxAcquisition',
    text: 'FX Acquisition',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'fxActual',
    text: 'FX Actual',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'bo',
    text: 'BO',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'ubo',
    text: 'UBO',
    sort: true,
    toggle: true,
    width: 150
  },
  {
    dataField: 'client',
    text: 'Client',
    sort: true,
    toggle: true,
    width: 150
  }
]

import React, { useState, useEffect } from 'react'
import CommonTable from '../../common/CommonTable/CommonTable'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import Tooltip from '@mui/material/Tooltip'
import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { Icon } from '@iconify/react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import CommonTableInvH1 from '../../common/CommonTable/CommonTableInvH1'
// import CommonTableInvTop from '../common/CommonTable/CommonTableInvTop'
const useStyles = makeStyles({
  paper: {
    background: 'rgb(31, 33, 37) !important',
    color: 'white !important'
  },
  option: {
    '&:hover': {
      backgroundColor: 'grey !important',
      color: 'white !important'
    }
  }
})
function InvestmentLogHistoryTop (row) {
  const styles = useStyles()
  const getId = localStorage.getItem('sub_Id')
  // console.log(getId)
  const [resultPortfolio, setResultPortfolio] = useState([])
  const [resultTransaction, setResultTransaction] = useState([])
  const handleCloseInvestment = () => setShowInvestment(false)
  const [bigData, setBigData] = useState(false)
  const [search1, setSearch1] = useState([])
  const [search2, setSearch2] = useState([])
  const [resultExchange, setResultExchange] = useState([])
  const [isHovering, setIsHovering] = useState(false)
  const roleId = localStorage.getItem('role').split(',')
  const handleMouseEnter = () => {
    setIsHovering(true)
  }
  const handleMouseLeave = () => {
    setIsHovering(false)
    setBigData(false)
  }
  const location = useLocation()
  const portfolioId = location.pathname.slice(30)
  // console.log(location.pathname.slice(20))
  const [alertInvest, setAlertInvest] = useState(true)
  const [loading, setloading] = useState(true)
  const [defaultSelect, setDefaultSelect] = useState('wallet')
  const [showdata, setShowData] = useState(false)
  const [resultAccount, setResultAccount] = useState([])
  const [allportfolio, setAllPortfolio] = useState([])
  const [result5, setResult5] = useState([])
  let acdata = []
  console.log(row)
  const accountant = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/all_accountant_and_portfolio`)
      .then(function (response) {
        const p_data = response.data
        acdata = p_data
        setResultAccount(p_data)
      })
  }
  const portfolio_data = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/all_portfolio_users_data`, {
        params: {
          user_id: getId,
          user_role: (roleId?.includes('admin') === true) ? 'admin' : 'accountant'
        }
      }).then((response) => {
        const rs1 = response.data
        // console.log(rs1)
        if (rs1 !== 'error') {
          setResult5(rs1)
        } else {
          setResult5([])
        }
      })
  }
 const investLogApi=()=>{
      setShowData(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getAllInvestmentLogs`
      }
      axios(config).then(function (response) {
        const rs = response.data
        const arr = []
        if (roleId.includes('accountant') === true && roleId.includes('admin') == false) {
          const a = resultAccount?.filter(i => i.accountant_id == getId)
          a?.forEach(el => {
            const m = rs?.filter(j => j.portfolio_id == el.portfolio_id && i.investment_Id==row.invest_id.investment_Id)
            const me = { ...m }
            arr.push(Object.values(me)[0])
          })
          setResultPortfolio(arr.map((rec,index)=>{return {...rec, no:index+1} }))
        } else {
          rs.sort((a,b)=>{
            const x = new Date(a.new_date_of_investment_modified).getTime()
            const y = new Date(b.new_date_of_investment_modified).getTime()
            return  x>y ? 1 : x<y ? -1 : 0 
          }).reverse()
          setResultPortfolio(rs.filter(i=>i.investment_Id==row.invest_id.investment_Id).map((rec,index)=>{return {...rec, no:index+1,timestamp: rec.timestamp.length==13 ? new Date(parseInt(rec.timestamp)).toUTCString() : rec.timestamp } }))
        }
      })
    } 


  for(let c of resultPortfolio){
    c.portfolio=[]
    for(let d of result5){
      if(c.portfolio_id==d.portfolio_id){
        c.portfolio.push(d.portfolio_name)
      }
    }
  }

  // console.log(resultTransaction)
  const handleTransactionLogs = async () => {
    setloading(true)
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/getAddressTransactionAuditHistory`
    }
    await axios(config).then(function (response) {
      // console.log(response.data)
      const rs = response.data
      const arr = []
      if (roleId.includes('accountant') === true && roleId.includes('admin') == false) {
        const a = acdata?.filter(i => i.accountant_id == getId)
        a?.forEach(el => {
          const m = rs?.filter(j => j.portfolio_id == el.portfolio_id)
          const me = { ...m }
          if (Object.values(me)[0] != undefined) {
            arr.push(Object.values(me)[0])
          }
        })
        setloading(false)
        setResultTransaction(arr)
      } else {
        setloading(false)
        setResultTransaction(rs)
      }
    })
  }
  const refresh_wallet = async () => {
    if (defaultSelect == 'investment') {
      setloading(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getAllInvestmentLogs`
      }
      await axios(config).then(function (response) {
        // console.log(response.data)
        setResultPortfolio(response.data.map((rec,index)=>{return {...rec, no:index+1} }))
        setloading(false)
      })
    } else if (defaultSelect == 'wallet') {
      setloading(true)
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/getAddressTransactionAuditHistory`
      }
      await axios(config).then(function (response) {
        // console.log(response.data)
        setResultTransaction(response.data)
        setloading(false)
      })
    }
  }
  const [dataId, setDataId] = useState('')
  const [validated, setValidated] = useState('')
  const [dataRow, setDataRow] = useState([])
  const [show, setShow] = useState(false)
  const [showDashboard, setShowDashboard] = useState(true)
  const [newWidth, setNewWidth] = useState('10')
  const [widthData, setWidthData] = useState('-4%')
  const [margin, setMargin] = useState('8%')
  const [w, setW] = useState('110%')
  const [m, setm] = useState('-10%')
  const [wid, setWid] = useState('170%')
  const [mar, setmar] = useState('-6%')
  const [search, setSearch] = useState([])
  const [sea, setSea] = useState('')
  const [showInvestment, setShowInvestment] = useState(false)
  const handleToggle = () => {
    setShowDashboard(!showDashboard)
    if (showDashboard === true) {
      setNewWidth('10')
      setW('110%')
      setWid('181%')
      setmar('-20%')
      setm('-9%')
      setMargin('8%')
      setWidthData('-4%')
      // console.log(showDashboard)
      // console.log(newWidth)
    } else {
      setNewWidth('10')
      setm('2.5%')
      setWid('159%')
      setmar('0%')
      setW('100%')
      setMargin('22%')
      setWidthData('6%')
      // console.log(showDashboard)
      // console.log(newWidth)
    }
  }
  const handleSubmitForm = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)
  }
  const handleClose = () => setShow(false)
  const handleShow = (id) => {
    // console.log(id)
    setDataId(id)
    setShow(true)
  }
  const [result, setResult] = useState([])
  const handleShowInvestment = () => {
    // console.log('Clicked Investment')
    setShowInvestment(true)
  }
  useEffect(async () => {
    await accountant()
    await portfolio_data()
    await handleTransactionLogs()
    await investLogApi()
  }, [])
  const columns1 = [
    {
      dataField: 'portfolio',
      text: 'Portfolio',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '14px' }}>
            {row.portfolio[0]!=null ? row.portfolio[0] : "N/A"}
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'address_name',
      text: 'Address Name',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '14px' }}>
            {row.address_name == undefined ? <p>-</p> : row.address_name}
          </span>
        )
      }
    },
    {
      dataField: 'asset_chain',
      width: 50,
      text: 'Chain',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '14px' }}>
            {row.asset_chain}
          </p>
        )
      }
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      width: 200,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <ul style={{marginLeft:'-3em'}}>
            {
              row.send_data != null
                ? (
                  <li
                    style={{
                      whiteSpace: 'nowrap',
                      color: '#FFC107',
                    }}
                  >
                    <span style={{ color: '#FFC107',display:'flex' }}> amount:
                   <p style={{color:'white'}}> 
                   ${JSON.parse(row?.send_data)[0]?.amount?.toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
                   </span>
                  </li>
                  )
                : (
                  <></>
                  )
            }
            {JSON.parse(row.amount)?.eth_gas_fee?.toFixed(4) == null
              ? (
                <></>
                )
              : (
                <li
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#FFC107',
                    fontSize: '14px'
                  }}
                >
                  <span style={{ color: '#FFC107',display:'flex' }}>gas fee:
                  <p style={{color:'white'}}>${JSON.parse(row?.amount)?.eth_gas_fee.toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
                  </span>
                </li>
                )}
          </ul>
        )
      }
    },
    {
      dataField: 'new_comment_added',
      text: 'Comment',
      sort: true,
      width: 150,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '14px' }}>
            {row.new_comment_added}
          </p>
        )
      }
    },
    {
      dataField: 'amount',
      text: 'Address',
      sort: true,
      width: 150,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <ul style={{marginLeft:'-3em'}}>
            <li style={{ whiteSpace: 'nowrap',color:'#FFC107' }}>
              {' '}
              <span style={{ color: '#FFC107',display:'flex' }}>from:{' '}
              {JSON.parse(row.amount)?.from_addr != null
                ? (
                   <p style={{color:'white'}}> {JSON.parse(row.amount)?.from_addr.slice(0, 17)}</p>
                  )
                : (
                  <></>
                  )}</span>
            </li>
            <li style={{ whiteSpace: 'nowrap',color: '#FFC107'}}>
              <span style={{ color: '#FFC107',display:'flex' }}> to:{' '}
             <p style={{color:'white'}}> {JSON.parse(row.amount)?.to_addr.slice(0, 20)}</p></span>
            </li>
            <li style={{ whiteSpace: 'nowrap',color: '#FFC107' }}>
              <span style={{ color: '#FFC107',display:'flex' }}> txn: {' '}
              <p style={{color:'white'}}>{row.transaction_id.slice(0, 19)}</p></span>
            </li>
          </ul>
        )
      }
    },
    {
      dataField: 'transaction_time',
      width: 150,
      text: 'Transaction Time',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p
            style={{
              color: 'white',
              fontSize: '14px',
              display: 'inline-block'
            }}
          >
            <span style={{ color: 'white', fontSize: '14px' }}>
              {moment(row.transaction_time).format('Do MMMM YYYY hh:mm:ss a')?.split(',')[0]}<br/>
              {moment(row.transaction_time).format('Do MMMM YYYY hh:mm:ss a')?.split(',')[1]}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'updated_date',
      text: 'Updated Time',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p
            style={{
              color: 'white',
              fontSize: '14px',
              display: 'inline-block'
            }}
          >
            <span
              style={{ color: 'white', fontSize: '14px' }}
            >
              {moment(row.updated_time).format('Do MMMM YYYY hh:mm:ss a')?.split(',')[0]}<br/>
              {moment(row.updated_time).format('Do MMMM YYYY hh:mm:ss a')?.split(',')[1]}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'usertype',
      text: 'Role User',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(row.usertype.split(','))
        return (
          <>
            <div style={{ whiteSpace: 'nowrap' }}>
              <span
                style={{
                  color: 'white',
                  fontSize: '14px',
                  marginRight: '5px'
                }}
              >
                {row.usertype.split(',').includes('admin')===true ? 'admin' : 'accountant'}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: 'white',
                  fontSize: '14px',
                  marginLeft: '5px'
                }}
              >
                {row.username}
              </span>
            </div>
          </>
        )
      }
    }
  ]
  const columns2 = [
      {
      dataField: 'no',
      text: 'No',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '13px' }}>
             {row.no} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'portfolio',
      text: 'Portfolio',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
             {row.portfolio[0]!=null ? row.portfolio[0] : "N/A"} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'new_investment_name',
      text: 'Name',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
          {row.new_investment_name==null ? 
            <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
              {row.investment_name}
            </p> :
            <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            {row.new_investment_name}
          </p>
             }
             </>
        )
      }
    },
    {
      dataField: 'new_investment_type',
      text: 'Type',
      sort: true,
      width: 50,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
          {row.new_investment_type==null ? 
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            {row.investment_type}
          </p> :
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
          {row.new_investment_type}
        </p>
           }
          </>
        )
      }
    },
    {
      dataField: 'timestamp',
      text: 'Created Date',
      sort: true,
      width: 150,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const date = new Date(row.date_of_investment).toString()
        const newDate = new Date(date).getTime()
        const istDate = new Date(newDate)
       
        return (
          <p
            style={{
              color: 'white',
              // width: '110%',
              fontSize: '13px',
              display: 'inline-block'
            }}
          >
            
            <span style={{ color: 'white', fontSize: '13px' }}>
            {moment(row.timestamp).format('Do MMMM YYYY, h:mm:ss a')}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'new_date_of_investment_modified',
      text: 'Updated Date',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const date = new Date(row.updated_date).toString()
        const newDate = new Date(date).getTime()
        const istDate = new Date(newDate)
       
        return (
          <p
            style={{
              color: 'white',
              // width: '110%',
              fontSize: '12px',
              display: 'inline-block'
            }}
          >
            
            <span style={{ color: 'white', fontSize: '13px' }}>
            {moment(row.new_date_of_investment_modified).format('Do MMMM YYYY, h:mm:ss a')}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'action',
      text: 'Type of action',
      sort: true,
      width: 150,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(JSON.parse(row.user_type))
        return (
          <>
            <div>
              {row.created_by_name!=null && row.deleted_by!=null && row.updated_by_name==null ?
              <span>Deleted</span> :
              row.created_by_name!=null && row.deleted_by==null && row.updated_by_name!=null 
              ||  row.created_by_name==null && row.deleted_by!=null && row.updated_by_name!=null ||
              row.created_by_name==null && row.deleted_by==null && row.updated_by_name!=null ?
              <span>Updated</span> :
              row.created_by_name!=null && row.deleted_by==null && row.updated_by_name==null ?
              <span>Created</span> : 
              <span>-</span>}
            </div>
          </>
        )
      }
    },
     {
      dataField: 'purchase_type',
      text: 'Type of transaction',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
           { row.purchase_type!=null ?
          <p>{row.purchase_type}</p>
           :  <p >{row.sell_type}</p>}
          </p>
        )
      }
    },
    {
      dataField: 'new_quantity',
      text: 'Quantity',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
             {row.purchase_type!=null ?
          <p>{parseInt(row.new_quantity).toLocaleString()}</p> :
          <p>{parseInt(row.new_quantity).toLocaleString()}</p>}
         
          </p>
        )
      }
    },
    {
      dataField: 'new_purchase_price',
      text: 'Price',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            {row.new_purchase_price==null ? '-' :
            row.purchase_type!=null ?
          <p>$ {parseFloat(row.new_purchase_price).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p> :
          row.purchase_type==null ?
          <p>$ {parseInt(row.sell_nav)}</p>
           :  <p>$ {parseFloat(row.new_purchase_price).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
            }
          </p>
        )
      }
    },
    {
      dataField: 'new_buy_value',
      text: 'Value',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px',whiteSpace:'nowrap' }}>
            { row.new_buy_value==null ? '-' :
            row.purchase_type!=null ?
            <p>${parseFloat(row.new_buy_value).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p> :
             row.purchase_type==null ?
            <p>${(parseFloat(row.sell_nav) * parseFloat(row.new_quantity)).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
             :  <p>$ {parseFloat(row.new_buy_value).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</p>
            }
          </p>
        )
      }
    },
    {
      dataField: 'user_type',
      text: 'Role',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(JSON.parse(row.user_type))
        return (
          <>
            <div>
              <span
                style={{
                  color: 'white',
                  fontSize: '13px',
                  whiteSpace:'nowrap'
                }}
              >
                {JSON.parse(row.user_type)=='accountant,uma_protection,admin,user' || (JSON.parse(row.user_type)?.includes('accountant') && JSON.parse(row.user_type).includes('user') && JSON.parse(row.user_type).includes('admin') ||  JSON.parse(row.user_type)?.includes('user') && JSON.parse(row.user_type)?.includes('admin') ) ? 'super admin' :JSON.parse(row.user_type)=='accountant'? JSON.parse(row.user_type) : JSON.parse(row.user_type) }
              </span>
             
            </div>
          </>
        )
      }
    },
    {
      dataField: 'user_type',
      text: 'User',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(JSON.parse(row.user_type))
        return (
          <>
            <div>
              <span
                style={{
                  color: 'white',
                  fontSize: '13px',
                }}
              >
                {row.username}
              </span>
            </div>
          </>
        )
      }
    },
    {
      dataField: 'new_comment_modified',
      text: 'Comment',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '13px' }}>
            { row.new_comment_modified==null ? <span style={{marginLeft:'2em'}}> -</span> :
          
            <span>{row.new_comment_modified}</span>
          
          
            }
          </p>
        )
      }
    },
  ]
  const columns3 = [
    {
      dataField: 'portfolio',
      text: 'Portfolio',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: 'white', fontSize: '12px' }}>
             {row.portfolio[0]!=null ? row.portfolio[0] : "N/A"} 
            {/* {row.address_name == undefined ? <p>-</p> : row.address_name} */}
          </span>
        )
      }
    },
    {
      dataField: 'created_time',
      text: 'Date',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p
            style={{
              color: 'white',
              fontSize: '12px',
              display: 'inline-block'
            }}
          >
            <span style={{ color: 'white', fontSize: '13px' }}>
            {moment(row.created_time).format('MMMM Do YYYY, h:mm:ss a')}
            </span>
          </p>
        )
      }
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      width: 50,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '12px' }}>
            {row.amount}
          </p>
        )
      }
    },
    {
      dataField: 'price',
      text: 'Price',
      sort: true,
      width: 150
    },
    {
      dataField: 'previous_comment',
      text: 'Previous Comment',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '12px' }}>
            {row.previous_comment}
          </p>
        )
      }
    },
    {
      dataField: 'new_comment',
      text: 'Updated Comment',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <p style={{ color: 'white', fontSize: '12px' }}>
            {row.new_comment}
          </p>
        )
      }
    },
    {
      dataField: 'user_type',
      text: 'Role User',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div>
              <span
                style={{
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row.usertype}
              </span>{' '}
              -{' '}
              <span
                style={{
                  color: 'white',
                  fontSize: '12px',
                  marginLeft: '5px'
                }}
              >
                {row.username}
              </span>
            </div>
          </>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <Container fluid>
            <div >
                  <CommonTableInvH1
                  loading={loading} 
                    data={resultPortfolio}
                    columns={columns2}
                  />
            </div>
      </Container >
          </React.Fragment >
  )
  }
export default InvestmentLogHistoryTop
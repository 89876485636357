import { Autocomplete, Paper, TextField } from '@mui/material'
import React from 'react'
import { Row } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'

export default function Filters({ filters, setFilters, onFormSubmit = () => { } }) {
  const useStyles = makeStyles({
    noOptions: {
      color: 'white'
    }
  })

  const styles = useStyles()
  return (
    <React.Fragment>
      <Row style={{ marginLeft: '1px', marginBottom: '20px', gap: '10px' }}>
        <div className='p-1'>
          <button
            className='apply'
            onClick={onFormSubmit}
            style={{ marginLeft: '0' }}
          >
            Apply
          </button>
          <button
            className='clear'
            onClick={() => { setFilters({}) }} >
            Clear
          </button>
        </div>
      </Row>
    </React.Fragment>
  )
}

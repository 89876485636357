import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { IconButton, Snackbar, Alert } from '@mui/material';
import { Close, AddCircleOutlineOutlined } from '@mui/icons-material';
import cx from 'classnames';
import axios from 'axios';
import { postAsync } from '../common/HttpClient';

export default forwardRef((props, ref) => {
    // Variables
    const base_url = process.env.REACT_APP_BASE_URL;
    const roleId = localStorage.getItem('role')?.split(',') ?? [];
    const getId = localStorage.getItem('sub_Id');

    // States
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [name, setname] = useState('');
    const [blankSpaceError, setBlankSpaceError] = useState(false);
    const [alertPort, setAlertPort] = useState(false);
    const [alertSp, setAlertSp] = useState(false);
    const [alertAl, setAlertAl] = useState(false);
    const [showEp, setShowEp] = useState(false);
    const [p_name, setp_name] = useState('');
    const [editPort, setEditPort] = useState('');
    const [alert, setAlert] = useState(false);
    const [spError, setSpError] = useState(false);
    const [respMessage, setRespMessage] = useState('');

    // Event Handlers
    useImperativeHandle(ref, () => ({
        editPortfolio: async (portfolioId, portfolio_name) => {
            setEditPort(portfolioId);
            setp_name(portfolio_name);
            setShowEp(true);
        }
    }));

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setAlertAl(false);
        setAlertSp(false);
        setAlertPort(false);
        setBlankSpaceError(false);
        setValidated(false);
        setname('');
        setShow(true);
    };

    const handleClosePort = () => {
        setAlertPort(false)
    }

    const handleSubmitForm = async (e) => {
        setValidated(true)
        e.preventDefault()
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else if (name.trim() == '') {
            setBlankSpaceError(true)
            setTimeout(() => {
                setBlankSpaceError(false)
            }, 3000)
        } else {
            // console.log(m)
            if (name !== ' ') {
                const config = {
                    method: 'post',
                    url: `${base_url}/admin/createPortfolio`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        portfolio_name: name,
                        user_id: getId,
                        user_role: 'admin',
                        portfolio_type: props.portfolioType
                    }
                }
                await axios(config)
                    .then(function (response) {
                        // console.log(response)
                        if (response.data == 'ALREADY EXIST') {
                            // console.log('already exist')
                            setAlertAl(true)
                            setTimeout(() => {
                                setAlertAl(false)
                            }, 2000)
                        } else {
                            setAlertPort(true)
                            setTimeout(() => {
                                setAlertPort(false)
                                setShow(false)
                            }, 3000)
                        }
                        if (props.callbackEvnt)
                            props.callbackEvnt();
                        setValidated(true)
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            } else {
                setAlertSp(true)
                setTimeout(() => {
                    setAlertSp(false)
                    setShow(false)
                }, 2000)
            }
        }
    }

    // update portfolio name
    const handleSubmitEditForm = async (e) => {
        e.preventDefault()
        setValidated(true)
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else if (p_name.trim() == '') {
            //  console.log('not accept blank space')
            setBlankSpaceError(true)
            setTimeout(() => {
                setBlankSpaceError(false)
            }, 3000)
        } else {
            var payload = {
                portfolio_name: p_name,
                portfolio_id: editPort,
                userId: getId
            };
            var resp = await postAsync(`EditPortfolioName_New`, payload);

            if (!resp.success) {
                setRespMessage(resp.message);
                setSpError(true);
                setTimeout(() => {
                    setRespMessage('');
                    setSpError(false);
                }, 3000)                
            }
            else {
                setAlert(true)
                setTimeout(() => {
                    setAlert(false)
                    setShowEp(false)
                }, 3000)
                if (props.callbackEvnt)
                    props.callbackEvnt();
                setValidated(false)
            }
        }
    }

    return (
        <>
            {
                roleId.includes('superadmin') || roleId.includes('admin')
                    ?
                    <AddCircleOutlineOutlined
                        style={{
                            boxShadow: 'none',
                            cursor: 'pointer',
                            background: 'none',
                            marginLeft: '1%',
                            color: '#FFC107',
                            top: '11px',
                            position: 'relative'
                        }}
                        onClick={handleShow}
                    ></AddCircleOutlineOutlined>
                    : <></>
            }
            <Modal
                show={show}
                onHide={handleClose}
                style={{ width: '28%', marginLeft: '35%' }}
            >
                <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            color: 'white'
                        }}
                        onClick={() => setShow(false)}
                    >
                        <Close />
                    </IconButton>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#222429' }}>
                    <Form
                        className="custom-form"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmitForm}
                    >
                        <h4>Create Treasury Portfolio</h4>
                        <Form.Label
                            htmlFor="exchange"
                            className={cx('custom-form-box', {
                                'focus-add': name
                            })}
                            style={{ width: '62%', marginLeft: '19%' }}
                        >
                            <Form.Control
                                type="text"
                                id="portfolio_name"
                                name="portfolio_name"
                                placeholder="Portfolio Name"
                                onChange={(e) => {
                                    setname(e.target.value)
                                }}
                                required
                                style={{ color: 'white' }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Portfolio Name is Required.
                            </Form.Control.Feedback>
                        </Form.Label>
                        <Button
                            type="submit"
                            variant=""
                            className="btn-gray"
                            style={{ width: '50%', marginLeft: '25%', boxShadow: 'none' }}
                        >
                            Save
                        </Button>
                    </Form>
                </Modal.Body>

                {alertPort ? (
                    <Snackbar
                        open={alertPort}
                        onClose={handleClosePort}
                        sx={{
                            // marginTop:'-2%',
                            marginLeft: '35%',
                            marginBottom: '38%',

                            width: '25%'
                        }}
                    >
                        <Alert
                            onClose={handleClosePort}
                            severity="success"
                            sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                        >
                            Portfolio Added successfully
                        </Alert>
                    </Snackbar>
                ) : (
                    <></>
                )}
                {blankSpaceError ? (
                    <Snackbar
                        open={blankSpaceError}
                        onClose={() => setBlankSpaceError(false)}
                        sx={{
                            // marginTop:'-2%',
                            marginLeft: '35%',
                            marginBottom: '38%',

                            width: '25%'
                        }}
                    >
                        <Alert
                            onClose={() => setBlankSpaceError(false)}
                            severity="error"
                            sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                        >
                            Portfolio name cannot be blank
                        </Alert>
                    </Snackbar>
                ) : (
                    <></>
                )}
                {alertSp ? (
                    <Snackbar
                        open={alertSp}
                        onClose={() => setAlertSp(false)}
                        sx={{
                            // marginTop:'-2%',
                            marginLeft: '35%',
                            marginBottom: '38%',

                            width: '25%'
                        }}
                    >
                        <Alert
                            onClose={() => setAlertSp(false)}
                            severity="error"
                            sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                        >
                            Please enter your proper name
                        </Alert>
                    </Snackbar>
                ) : (
                    <></>
                )}
                {alertAl ? (
                    <Snackbar
                        open={alertAl}
                        onClose={() => setAlertAl(false)}
                        sx={{
                            // marginTop:'-2%',
                            marginLeft: '35%',
                            marginBottom: '38%',
                            width: '25%'
                        }}
                    >
                        <Alert
                            onClose={() => setAlertAl(false)}
                            severity="error"
                            sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                        >
                            Already exist
                        </Alert>
                    </Snackbar>
                ) : (
                    <></>
                )}

            </Modal>

            <Modal
                show={showEp}
                style={{ width: '28%', marginLeft: '35%' }}
            >
                <div style={{ border: '1px solid white' }}>
                    <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
                        {/* <Modal.Title>Create PortFolio Section</Modal.Title> */}
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                color: 'white'
                            }}
                            onClick={() => setShowEp(false)}
                        >
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    {alert
                        ? (
                            <Snackbar
                                open={alert}
                                autoHideDuration={4000}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity="success"
                                    sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                                >
                                    Portfolio Name Updated Successfully
                                </Alert>
                            </Snackbar>
                        )
                        : (
                            <></>
                        )}
                    {alertAl ? (
                        <Snackbar
                            open={alertAl}
                            onClose={() => setAlertAl(false)}
                            sx={{
                                // marginTop:'-2%',
                                marginLeft: '35%',
                                marginBottom: '38%',

                                width: '25%'
                            }}
                        >
                            <Alert
                                onClose={() => setAlertAl(false)}
                                severity="error"
                                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                            >
                                Already exist
                            </Alert>
                        </Snackbar>
                    ) : (
                        <></>
                    )}
                    {blankSpaceError ? (
                        <Snackbar
                            open={blankSpaceError}
                            onClose={() => setBlankSpaceError(false)}
                            sx={{
                                // marginTop:'-2%',
                                marginLeft: '35%',
                                marginBottom: '38%',

                                width: '25%'
                            }}
                        >
                            <Alert
                                onClose={() => setBlankSpaceError(false)}
                                severity="error"
                                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                            >
                                Portfolio name cannot be blank
                            </Alert>
                        </Snackbar>
                    ) : (
                        <></>
                    )}

                    {spError ? (
                        <Snackbar
                            open={spError}
                            onClose={() => setSpError(false)}
                            sx={{
                                // marginTop:'-2%',
                                marginLeft: '35%',
                                marginBottom: '38%',

                                width: '25%'
                            }}
                        >
                            <Alert
                                onClose={() => setSpError(false)}
                                severity="error"
                                sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                            >
                                {respMessage }
                            </Alert>
                        </Snackbar>
                    ) : (
                        <></>
                    )}
                    <Modal.Body style={{ backgroundColor: '#222429' }}>
                        {/* {!isWalletConnected ? ( */}
                        <Form
                            className="custom-form"
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmitEditForm}
                        >
                            <h4>Edit Portfolio</h4>

                            <Form.Label
                                htmlFor="exchange"
                                className={cx('custom-form-box', {
                                    'focus-add': p_name
                                })}
                                style={{ width: '62%', marginLeft: '19%' }}
                            >
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={p_name}
                                    onChange={(e) => setp_name(e.target.value)}
                                    required
                                    style={{ color: 'white' }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Portfolio name is Required.
                                </Form.Control.Feedback>
                            </Form.Label>
                            <Button
                                type="submit"
                                variant=""
                                className="btn-gray"
                                style={{
                                    width: '50%',
                                    marginLeft: '25%',
                                    boxShadow: 'none',
                                    color: 'white'
                                }}
                            >
                                save
                            </Button>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
});
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
const useStyles = makeStyles({
  paper: {
    background: "rgb(31, 33, 37) !important",
    color: "white !important",
  },
  option: {
    "&:hover": {
      backgroundColor: "grey !important",
      color: "white !important",
    },
  },
});

export const SelectBox = ({ value, onChange, label, options, err }) => {
  console.log(options);
  const styles = useStyles();
  return (
    <>
      <FormControl
        style={{
          width: "72%",
          marginLeft: "1.9%",
          marginBottom: "2em",
        }}
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          style={{
            fontSize: "17px",
            overflow: "visible",
            color: "grey",
            top: "1px",
            left: "-8px",
            background: "#1f2125",
          }}
        >
          {" "}
          {label}
        </InputLabel>

        <Select
          MenuProps={{
            classes: {
              paper: styles.paper,
            },
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "lightgrey",
                  color: "black",
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: "lightgrey",
                  color: "black",
                },
              },
            },
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Select"
          style={{
            // width:"239px",
            height: "50px",
            border: "1px solid hsl(0deg 0% 44% / 63%) ",
            boxShadow: "none",
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderRadius: "0px",
              height: "54px",
              // border:'1px solid hsl(0deg 0% 44% / 63%) !important',
              borderColor: "unset",
            },
            ".MuiInputLabel-root": {
              color: "grey !important",
            },
            ".MuiSelect-icon": {
              fill: "grey !important",
            },
            ".MuiOutlinedInput-input": {
              color: "white !important",
              fontSize: "15px",
              //  backgroundColor:'white',
              paddingBottom: "13px",
              paddingRight: "62% ",
              paddingTop: "12px",
            },
            " .MuiInputLabel-root.Mui-focused": {
              marginTop: "-3% !important",
              marginLeft: "-5% !important",
            },
          }}
          onChange={onChange}
        >
          {options.map((item, key) => (
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))}
        </Select>
        {err ? (
          <span
            style={{
              color: "#dc3545",
              fontSize: ".875em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {label} is required.
          </span>
        ) : (
          <></>
        )}
      </FormControl>
    </>
  );
};

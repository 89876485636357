import { dateFilter, textFilter } from 'react-bootstrap-table2-filter'
import { React } from 'react'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import ArticleIcon from '@mui/icons-material/Article'

export const columns = ({ handleViewDetails = () => {} }) => [
  {
    dataField: 'Id',
    text: 'No',
    sort: false,
    toggle: false,
    width: 30,
    filter: textFilter({
      placeholder: 'No'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      const date = moment(row.RecDate).format('Do MMMM YYYY')
      const time = moment(row.RecDate).format('hh:mmA')
      return (<span>{rowIndex + 1}</span>)
    }
  },
  {
    dataField: 'RecDate',
    text: 'Record Date',
    sort: false,
    toggle: false,
    width: 50,
    filter: dateFilter({
      dateFormat: 'mm/dd/yyyy',
      placeholder: 'custom placeholder'
    }),
    formatter: (cell, row, rowIndex, formatExtraData) => {
      const date = moment(row.RecDate).format('Do MMMM YYYY')
      const time = moment(row.RecDate).format('hh:mmA')
      return (
        <p
          style={{
            color: 'white',
            // width: '110%',
            fontSize: '14px',
            display: 'inline-block'
          }}
        >
            <span style={{ color: 'white', fontSize: '14px' }}>
              {date}, {time}
            </span>
        </p>
      )
    }
  },  
  {
    dataField: 'Username',
    text: 'Triggered By',
    sort: false,
    toggle: false,
    width: 75,
    filter: textFilter({
      placeholder: 'Username'
    })
  },  
  {
    dataField: 'Mode',
    text: 'Event',
    sort: false,
    toggle: false,
    width: 75,
    filter: textFilter({
      placeholder: 'Event'
    })
  },   
  {
    dataField: 'TableName',
    text: 'Module',
    sort: false,
    toggle: false,
    width: 75,
    filter: textFilter({
      placeholder: 'Module'
    })
  },  
  {
    dataField: 'Id',
    text: 'Details',
    sort: false,
    toggle: false,
    width: 50,
    formatter: (cell, row, rowIndex, formatExtraData) => {

      return (
        row.Mode == "Add" || row.Mode == "Modify" ?
        <span>
          <Tooltip title="Details" style={{ cursor: 'pointer', color: '#FFC107' }}>
            <ArticleIcon onClick={() => handleViewDetails(row)}/>
          </Tooltip>
        </span> : <></>
      )
    }
  },     
]

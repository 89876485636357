import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MdOutlineDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { useState } from "react";

import {
  setOpenCommonModalDeleteStatus,
  setOpenEditExchangeModalStatus,
} from "../Redux/appSlice";
import { useSelector, useDispatch } from "react-redux";
import "./RawTable.css";
import { useEffect } from "react";
import axios from "axios";
import ModalToEditExchangeValue from "./commonModals/modalToEditExchangeValue";
import ModalDeleteExchangeValue from "./commonModals/ModalToDeleteValue";
import Spinner from "./spinner";
export default function RawTable({
  search,
  data,
  columns,
  resultData,
  isFromPortfolio=false
}) {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  let roleId = localStorage.getItem("role").split(",");
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState(null);
  const [keyStatus, setKeyStatus] = useState([]);
  const [condition, setCondition] = useState(false);
  const [spanOrderKey, setOrderKey] = useState(null);
  const [spanOrderName, setSpanOrderName] = useState(null);
  const [spanCondition, setSpanCondition] = useState(false);
  const [dataEditRowModal, setDataEditRowModal] = useState([]);
  const [dataDeleteRowModal, setDataDeleteRowModal] = useState([]);
  const [loading, setLoading] = useState(false);
  const { openCommonModalDeleteStatus, openEditExchangeModalStatus, openModalAddExchangeStatus } =
  useSelector((store) => store.app);
  const exchange = async () => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/exchanges`,
      params: {
        portfolio_id: data[0]?.portfolioId,
      },
    };

    setLoading(true);
    await axios(config)
      .then(function (response) {
        setLoading(false);

        response.data.sort((a, b) => {
          const x = parseInt(a.takerCommission);
          const y = parseInt(b.takerCommission);
          return x > y ? -1 : x < y ? 1 : 0;
        });

        if (search !== "" && search !== undefined) {
          setResult(
            response.data?.filter((i) =>i.exchange_name.toLowerCase().includes(search.toLowerCase()))
          );
        } else {
          setResult(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if(data.length > 0)
      exchange();
    
  }, [data[0]?.portfolioId, openEditExchangeModalStatus, openCommonModalDeleteStatus, openModalAddExchangeStatus]);

  resultData = [
    ...new Map(result?.map((item) => [item.apikey, item])).values(),
  ];
  let uniqueKeyObject = {};
  for (let i = 0; i < resultData.length; i++) {
    let uniqueArray = result.filter(
      (item) => item.apikey === resultData[i].apikey
    );
    uniqueKeyObject = { ...uniqueKeyObject, [i]: [...uniqueArray] };
  }
  let apiObject = {};

  for (let j = 0; j < Object.keys(uniqueKeyObject).length; j++) {
    let apiTempObject = {
      assetsName: uniqueKeyObject[j].map((ele) => ele.assetName),
      free: uniqueKeyObject[j].map((ele) => ele.free),
      usdt_amount: uniqueKeyObject[j].map((ele) => ele.usdt_amount),
    };
    apiObject = { ...apiObject, [j]: apiTempObject };
    let total = apiTempObject.free.reduce(
      (prevValue, currentValue) => prevValue + parseFloat(currentValue),
      0
    );
    apiObject = { ...apiObject, [j]: apiTempObject };
  }

  resultData = resultData.map((ele, index) => {
    return {
      ...ele,
      asset: {
        assetName: apiObject[index]?.assetsName,
        free: apiObject[index]?.free,
        usdt_amount: apiObject[index]?.usdt_amount,
      },
    };
  });

  const handleReduce = (item) => {
    let result = item?.asset.balance.reduce(
      (prevValue, currentValue) => prevValue + parseFloat(currentValue),
      0
    );
    return result.toFixed(2);
  };
  // resultData = resultData.map((record) => {
  //   return { ...record, total: handleReduce(record) };
  // });


  const iconData = [
    {
      name: "Up Arrow",
      icon: <FaArrowUp />,
    },
    {
      name: "Down Arrow",
      icon: <FaArrowDown />,
    },
  ];

  const handleClickSpan = (item, key) => {
    setOrderKey(key);
    setSpanOrderName(item);
    setSpanCondition((prev) => !prev);
    //  handleOrderingData()
    if (item.dataField == "exchange_name") {
      result.sort((a, b) => (a > b ? 1 : -1));
    } else if (item.dataField == "exchange_type") {
      result.sort((a, b) =>
        a.exchange_type > b.exchange_type
          ? 1
          : a.exchange_type > b.exchange_type
          ? -1
          : 0
      );
    } else if (item.dataField == "asset") {
      result.reverse();
    } else if (item.dataField == "accountType") {
      result.sort((a, b) =>
        a.accountType > b.accountType
          ? 1
          : a.accountType > b.accountType
          ? -1
          : 0
      );
    } else if (item.dataField == "free") {
      result.reverse();
    }
  };

  const handleClickDelete = (item) => {
    dispatch(setOpenCommonModalDeleteStatus(true));
    setDataDeleteRowModal(item);
    // exchange();
  };

  const handleClickEdit = (item) => {
    dispatch(setOpenEditExchangeModalStatus(true));
    setDataEditRowModal(item);
  };

  const Record = loading ? "" : !(result.length > 0) && "No Record Found";
  return (
    <div className="container_exchange">
      <div className="container_row_fixed">
        <table className="raw_table">
          <tr>
            {columns?.map((info, key) => (
              <th>
                {info.text}
                {iconData?.map((item, key) => (
                  <span
                    key={key}
                    style={{
                      fontSize: "12px",
                      color:
                        key === spanOrderKey && info.text === spanOrderName.text
                          ? "white"
                          : "grey",
                      paddingLeft: "0.2em",
                      cursor: "pointer",
                      fontWeight: "600",
                      fontFamily: "Inter,Helvetica,Arial",
                    }}
                    onClick={() => handleClickSpan(info, key)}
                  >
                    {item.icon}
                  </span>
                ))}
              </th>
            ))}
          </tr>
          {result.length > 0 ? (
            <></>
          ) : (
            <tr>
              <th
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "100",
                  borderBottom: "none",
                  left: "45%",
                }}
              >
                {Record}
              </th>{" "}
            </tr>
          )}
          <tbody className="raw_table_body">
          {loading && (
              <Spinner
                style={{
                  position: "fixed",
                  top: "20em",
                  left: "59%",
                  height: "70px",
                  width: "70px",
                }}
                animation="border"
                variant="primary"
              />
          )}
            {result?.map((item, key) => (
              <tr key={key}>
                <td>{item?.exchange_name}</td>
                <td>{item?.assets[0]?.exchange_type}</td>
                {item?.assets.length > 0 &&
                  keyStatus[key] == 'more' ? (
                    <td style={{ cursor: "pointer" }}>
                      <ul style={{ marginLeft: "-2em" }}>
                        {item?.assets?.map((value, key) => (
                          <li
                            style={{
                              fontSize: "14px",
                              color: "rgb(255, 193, 7)",
                            }}
                          >
                            <span>
                              {value.assetName} &nbsp;&nbsp;
                              {value.free}
                            </span>
                          </li>
                        ))}
                      </ul>
                    <div
                      onClick={() => {
                        setCondition((prev) => !prev);
                        setName(item);
                        setKeyStatus({...keyStatus, [key]: 'less'});
                      }}
                      className="less"
                    >
                      Show less
                    </div>
                  </td>
                ) : (
                  <td style={{ cursor: "pointer" }}>
                    <ul style={{ marginLeft: "-2em" }}>
                      {item?.assets?.slice(0, 5)?.map((value, key) => (
                          <li
                            style={{
                              fontSize: "14px",
                              color: "rgb(255, 193, 7)",
                            }}
                          >
                            <span>
                              {value.assetName} &nbsp;&nbsp;
                              {value.free}
                            </span>
                          </li>
                        ))}
                    </ul>
                    <div
                      onClick={() => {
                        setCondition((prev) => !prev);
                        setName(item);
                        setKeyStatus({...keyStatus, [key]: 'more'});
                      }}
                      className="more"
                    >
                      Show more
                    </div>
                  </td>
                )}
                <td>{item?.assets[0]?.accountType}</td>
                <td>${item?.balance.toFixed(2)}</td>
                {
                  (roleId.includes("superadmin") || roleId.includes("admin") || roleId.includes("accountant")) && !isFromPortfolio && (
                    <td>
                      <div style={{ display: "flex", gap: "0.3rem" }}>
                        {
                          !roleId.includes("accountant") && (
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClickDelete(item)}
                            >
                              <MdOutlineDeleteOutline color="red" fontSize="1.2rem" />
                            </p>
                          )
                        }

                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickEdit(item)}
                        >
                          <MdModeEditOutline
                            color="rgb(255, 193, 7)"
                            fontSize="1.2rem"
                          />
                        </p>
                      </div>
                    </td>
                  )
                }
              </tr>
            ))}
          </tbody>
        </table>

        {openCommonModalDeleteStatus ? (
          <ModalDeleteExchangeValue row={dataDeleteRowModal} />
        ) : (
          <></>
        )}
        {openEditExchangeModalStatus ? (
          <ModalToEditExchangeValue row={dataEditRowModal} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import Spinner from '../common/spinner'
import 'react-phone-number-input/style.css'
import { Container, Row, Col,Modal,Form, Button } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import SearchBox from '../common/SearchBox/SearchBox'
import { LegendToggleRounded } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core/styles'
import { Label } from 'react-md';
import { setDayWithOptions } from 'date-fns/fp';
import Paper from '@material-ui/core/Paper'
import BalanceTable from '../common/BalanceTable';
const useStyles = makeStyles({
  paper: {
    background: 'rgb(31, 33, 37) !important',
    color: 'white !important'
  },
  option: {
    '&:hover': {
      backgroundColor: 'grey !important',
      color: 'white !important'
    }
  }
})
function Balance () {
  const styles = useStyles()
  const getId = localStorage.getItem('sub_Id')
  const [balanceData, setBalanceData] = useState([])
  const [balanceKey, setBalanceKey] = useState('')
  const [sea, setSea] = useState('')
  const [loading,setLoading]=useState(true)
  const [search, setSearch] = useState([])
  const [defaultEntity, setDefaultEntity]=useState('')
  const [showModal, setShowModal]=useState(false)
  const [rowData, setRowData] = useState()
  const [entityData, setEntityData] = useState([])
  const [alertNoRecord,setAlertNoRecord]=useState(false)
  const [paymentData,setPaymentData]=useState([])
  const [balanceTotal, setBalanceTotal]=useState([])
  const [fullPaymentData,setFullPaymentData]=useState([])
  const handleOpenModal=(row)=>{
    setRowData(row)
    console.log(row)
    setShowModal(true)
  }
  const handleClose=()=>{
    setShowModal(false)
  }
  
  const paymentApi=async()=>{
    await axios.get(`${process.env.REACT_APP_BASE_URL}/get_payment`
    ).then(function (response) {
      console.log(response.data)
      const temp2 = response.data?.map(record => {
        let type
        if(record.currency==="BTC" || record.currency==="ETH" || record.currency==="USDT" || record.currency==="USDC"){
          type="Crypto"
        }else{
           type="FIAT"
        }
        let res = parseFloat(record.amount) / parseFloat(record.exchange_rate)
        return { ...record,payment_id:parseInt(record.payment_id), payment_type:type, result:res, date_updated:moment(record.date_updated).format('YYYY-MM-DD'),amount:parseFloat(record.amount),exchange_rate:parseFloat(record.exchange_rate), date:record.date_updated }
      })
      if (temp2) {
        temp2.sort((a, b) => {
          const x = new Date(a.date).getTime() / 1000
          const y = new Date(b.date).getTime() / 1000
          return x > y ? -1 : x < y ? 1 : 0
        })
      }
      if(temp2.length==0){
         setPaymentData([])
      }else{
      setFullPaymentData(temp2)
      setPaymentData(temp2.filter(i=>i.status=='Active'|| i.status==null))
      }
    })
  }

  const AddPaymentApi=async()=>{
    // e.preventDefault()
    // const form = e.currentTarget
      
      fullPaymentData?.sort((a, b) => {
        const x = parseInt(a.payment_id)
        const y = parseInt(b.payment_id)
        return x < y ? -1 : x > y ? 1 : 0 
      })
      // console.log(parseInt(paymentData.slice(-1)[0].payment_id)+1)
       for(let i=1;i<100000;i++){
        let  m = fullPaymentData.filter(f=>f.payment_id==i)
        if(m.length===0 || fullPaymentData.length==0 ){
          const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/new_pay`,
            data:
            {
              "user_id":getId,
              "payment_id":fullPaymentData.length==0 ? 1 : parseInt(fullPaymentData.slice(-1)[0]?.payment_id)+1,
              "sender":rowData.key=='creditor' ? defaultEntity : rowData.name ,
              "reciever":rowData.key=='creditor' ? rowData.name : defaultEntity,
              "amount":rowData.amt* parseFloat(rowData.rate),
              // "result_usd": paymentData.filter(i=>i.sender==receiver && i.reciever==sender).length>0 ? '-'+amount : amount,
              "currency":rowData.currency,
              "status":'Active',
              "type_of_sender":'null',
              "type_of_reciever":'null',
              "exchange_rate":rowData.rate,
              "payment_type":rowData.currency==='BTC' || rowData.currency==='ETH' || rowData.currency==='USDT' || rowData.currency==='USDT' ? "Crypto" : "FIAT",
              // "result_usd":result,
              "comment":''
            }
          }
          console.log(config.data)
          await axios(config).then(function (response) {
    
            console.log(response)
            console.log(response.data);
            
          }).catch(function (error) {
            console.log(error)
          })
         break
        }
        }  
  }

  const  BalanceGetApi=async(balance_id,name)=>{
    setLoading(true)
     await axios.get(`${process.env.REACT_APP_BASE_URL}/get_balnce`,
     {
        params: { name: name }
       }
    )
      .then(function (response1) {
        let finalArr=response1.data.filter(i=>i.balance_status==null || i.balance_status=='Active')
        let creditorData=[]
        let debitorData=[]
        for(let i=0;i<finalArr.length;i++){
          if(finalArr[i]['sum(amount)']>0 && finalArr[i]['sum(amount)']!=0 ){
            debitorData.push(finalArr[i])
          }else if(finalArr[i]['sum(amount)']<0 && finalArr[i]['sum(amount)']!=0){
            creditorData.push(finalArr[i])
          }
        }
        setBalanceTotal({'asset':debitorData.map(rec=>{return {...rec,amount:rec['sum(amount)']}}),'liability':creditorData.map(rec=>{return {...rec,amount:rec['sum(amount)']}})})
      console.log(debitorData,creditorData)  
    
      let uniquePersonA=[]
      uniquePersonA=[...new Set(creditorData.map(el=>el?.person))]
console.log(uniquePersonA)



let  uniqueAsset=uniquePersonA.map((ele) => {

    let filteredPerson = creditorData.filter((item) => item?.person === ele );
        let filteredAmount = filteredPerson.map((value)=>value["sum(amount)"]);
        let filteredRate = filteredPerson.map((value)=>value?.rate);
        let filteredCurrency = filteredPerson.map((value)=>value?.currency);
    return {person:ele,balance_status:ele.balance_status,balance:{amount:filteredAmount,rate:filteredRate,currency:filteredCurrency}}
  });
// console.log("output ...............",uniqueAsset);
          let uniquePerson=[]
          uniquePerson=[...new Set(debitorData.map(el=>el?.person))]
//  console.log(uniquePerson)



    let  uniqueLiability=uniquePerson.map((ele) => {

        let filteredPerson = debitorData.filter((item) => item?.person === ele );
            let filteredAmount = filteredPerson.map((value)=>value["sum(amount)"]);
            let filteredRate = filteredPerson.map((value)=>value?.rate);
            let filteredCurrency = filteredPerson.map((value)=>value?.currency);
        return {person:ele,balance_status:ele.balance_status,balance:{amount:filteredAmount,rate:filteredRate,currency:filteredCurrency}}
      });
    // console.log("output ...............",uniqueLiability);


        const temp=[{asset:uniqueAsset.map(el=>{return {...el, amount:el.sum_amount}}),liability:uniqueLiability.map(el=>{return {...el
      }})}]
      console.log(temp)
      
        setBalanceData(temp[0].asset.length==0 && temp[0].liability.length==0 ? [] : temp)
        setLoading(false)
  })
  }
  console.log(balanceData)
  const BalanceGetApi1 = async (balance_id,name) => {
    setLoading(true)
    let remaining_bal
     await axios.get(`${process.env.REACT_APP_BASE_URL}/get_pms_balance`,
     {
        params: { creditor: name }
       }
    )
      .then(function (response1) {
        axios.get(`${process.env.REACT_APP_BASE_URL}/get_payment`
    ).then(function (response) {
      const temp2 = response.data?.map(record => {
        let type
        if(record.currency==="BTC" || record.currency==="ETH" || record.currency==="USDT" || record.currency==="USDC"){
          type="Crypto"
        }else{
           type="FIAT"
        }
        let res = parseFloat(record.amount) / parseFloat(record.exchange_rate)
        return { ...record,payment_id:parseInt(record.payment_id), payment_type:type, result:res, date_updated:moment(record.date_updated).format('YYYY-MM-DD'),amount:parseFloat(record.amount),exchange_rate:parseFloat(record.exchange_rate), date:record.date_updated }
      })
      const creditArr = temp2.filter((ele) => ele.sender.toLowerCase() ===name.toLowerCase() && ele.status=='Active' );
const debit = temp2.filter((ele)=> ele.reciever.toLowerCase() === name.toLowerCase() && ele.status=='Active');
const totalBuyandSell =creditArr.reduce((acc,curr)=>{
  if(!acc.name.includes(curr?.reciever.toLowerCase())){
    acc = {...acc , name:[...acc.name,curr?.reciever.toLowerCase()]}
  }
  return acc;
},{name:[]});
const totalCreditPerson = totalBuyandSell.name.map((ele) => {
  const t = creditArr.reduce((acc,curr)=>{
      if(curr.reciever.toLowerCase() === ele){
        acc = acc + curr.result;
      }
    return acc;
  },0)
  return {name:ele,amount:t}
})

const totalDebitPerson = totalBuyandSell.name.map((ele) => {
  const t = debit.reduce((acc,curr)=>{
      if(curr.sender.toLowerCase() === ele){
        acc = acc + curr.result;
      }
    return acc;
  },0)
  return {name:ele,amount:t}
})
remaining_bal = totalBuyandSell.name.map((ele)=>{
  const balance = totalCreditPerson.find(item=>item.name === ele).amount - totalDebitPerson.find(item=>item.name === ele).amount
  
  return {creditor:name,debiter:ele,balance:balance}
});

// if(response1.data.filter(i=>i.e_id===balance_id).length==0){
//   setAlertNoRecord(true)
//   setBalanceData([])
//   // BalancePostApi(balance_id)
// }else
if(response.data.length>0){
 setLoading(false)
  let res=[]
  let temp=[]
 for(let a of response1.data){
  if(a.game==''){
    for(let b of remaining_bal){
      if(a.debtor.toLowerCase()==b.debiter){
        res.push({...a,balance:b.balance})
      }
    }
  // if(a.creditor==remaining_bal?.[0]?.creditor){
  //   res=[{...a,balance:remaining_bal?.[0]?.balance}]
  // }
//  console.log(res)
//  setBalanceData(res)
//   console.log(response.data,name)
  
//   let res=[]
//   let res1=[]
//   let res2=[]
//   for(let a of response.data){
//     console.log(a)
//     if(a.creditor==name){
//       console.log(a.creditor)
//     res.push(a)
//     }
//      if(a.debtor==name){
//      res1.push(a)
//     }
//   }
//   console.log(res,res1)
//   if(res.length>0){
//     if(res1.length==0){
//      const result = res.map((obj, i, srcArray) => {
//       return { ...obj, balance: parseFloat(obj.amount) - parseFloat(srcArray[i + 1]?.amount) || parseFloat(obj.amount) };
//     })
//      result.sort((a, b) => {
//       var x = parseInt(a.date)
//      var y = parseInt(b.date)
//      return x < y ? 1 : x > y ? -1 : 0
//    })
//     setBalanceData([...new Map(result.map(x=>[(x.creditor),x])).values()])
//     console.log(result)
//   }
//   }else{
//  for(let b of res){
//   if(res1.filter(i=>i.amount!=b.amount).length>0){
//     res2.push(b)
//   }
//  }
//  console.log(res2)
//   let cred_res = res2.reduce((sum, current) => parseFloat(sum) + parseFloat(current.amount), 0);
//   let debt_res = res1.reduce((sum, current) => parseFloat(sum) + parseFloat(current.amount), 0);
//      console.log(cred_res,res1,res2,debt_res)
//    const temp=res2.map(record=>{
//     return {...record, balance: parseFloat(cred_res)-parseFloat(debt_res)}
//    })  
//   // res.sort((a, b) => {
//   //     var x = parseInt(a.date)
//   //    var y = parseInt(b.date)
//   //    return x < y ? 1 : x > y ? -1 : 0
//   //  })
//   // console.log(res.slice(-1)[0])
//   //   const result = [...new Map(res.map(x=>[(x.creditor),x])).values()].map((obj, i, srcArray) => {
//   //     return { ...obj, balance: parseFloat(obj.amount) - parseFloat(srcArray[i + 1]?.amount) || parseFloat(obj.amount) };
//   //   })
 
  
//     //  console.log(result,name,[...result])
//   //   console.log([...new Map(result.map(x=>[(x.e_id),x])).values()])
//      setBalanceData([...new Map(temp.map(x=>[(x.creditor),x])).values()])
}
else{
  for(let c of response1.data){
    if(c.game!=''){
    res.push({...c,balance: parseFloat(c.amount)})
    }
  }
//  temp = response1.data.map(record=>{
//   if(record.game!='' && recor){
//   return {...record, balance: parseFloat(record.amount)}
//   }
// })
// setBalanceData(temp)
}   
 }  
//  console.log([...[...new Map(res.map(x=>[(x.debtor),x])).values()],...temp])
//  setBalanceData([...[...new Map(res.map(x=>[(x.debtor),x])).values()]])
}
setLoading(false)
})
      })
  }
  const BalancePostApi = async (balance_id) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/pms_balance`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        id:balance_id
      }
    }
    await axios(config)
      .then(function (response) { 
        if(response.data=='No Record Found'){
          setBalanceData([])
          setAlertNoRecord([])
         
        }else if(response.data.affectedRows){
          BalanceGetApi(balance_id)
        } 
         
      })
  }

  const BalanceUpdateApi = async () => {

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/balance_status`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        currency:rowData.currency,
        name:rowData.name,
        result_usd:rowData.amt,
        en_name:defaultEntity,
        user_id:getId,
        cre: 'cred' 

      }
    }
    console.log(config.data)
    await axios(config)
      .then(function (response) { 

          BalanceGetApi(balanceKey.e_id,defaultEntity)
      })
        await AddPaymentApi()
  }
  const EntityApi = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/get_entity`)
      .then(function (response) {
        if(response.data.length>0){
          setEntityData(response.data.filter(i=>i.type!='Other' && i.name!=null))
        let UniqueBalance= response.data.filter(i=>i.type!='Other' && i.name!=null)?.[0]?.id
          BalanceGetApi(UniqueBalance,response.data.filter(i=>i.type!='Other' && i.name!=null)?.[0]?.name)
          setDefaultEntity(response.data.filter(i=>i.type!='Other' && i.name!=null)?.[0]?.name)
         
        }
      })
  }

  const handleChangeEntity=(k)=>{
    let res=[]
    let temp=[]
    setBalanceData([])
    let entityId = entityData.filter(i=>i.name==k)
      BalanceGetApi(entityId?.[0]?.id,entityId?.[0]?.name)
      setDefaultEntity(k)
  }
 
  useEffect(async () => {
    await EntityApi()
    await paymentApi()
   
   
    //await allportfolioUser()
  }, [])
const balanceD=[{'asset':[
  {'name':'suprava',
'balance':[{'currency':'USD','amount':'1000'},{'currency':'USDT','amount':'100'}]
},
{'name':'ankit',
'balance':[{'currency':'EUR','amount':'500'}]
}],
'liability':[
{'name':'priyank',
'balance':[{'currency':'USD','amount':'10'},{'currency':'USDT','amount':'600'}]
},
{'name':'ankit',
'balance':[{'currency':'AED','amount':'300'}]
}
]}
]

  const columns4 = [
    {
      dataField: 'asset',
      text: 'Assets',
      sort: true,
      // hidden: (selectedColumnId?.includes("creditor") == true),
      // filter: textFilter({
      //   placeholder: 'creditor'
      // }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
              {row.asset?.map((item)=>(
              <li style={{listStyle:'none'}}>
                {/* {row.creditor?.charAt(0)?.toUpperCase() + row.creditor?.slice(1)} */}
                  {item.CREDITOR} 
                   {item.balance.currency.map((i,index)=>(  
                  <li style={{listStyle:'lower-latin'}}>
                   <span>{i}</span>........................ <span>{parseFloat(item.balance.amount[index]).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</span>
                   <span style={{cursor:'pointer'}}
                to="#"
                onClick={()=>handleOpenModal({'name':item.CREDITOR,'currency':i,'amt':item.balance.amount[index],'key':'creditor'})}
                className=" retrieve-transaction"
              > Cancel
              </span>
                  </li>
                   ))} 
              </li>
      ))}
            
          </>
        )
      }
    },
    {
      dataField: 'liability',
      text: 'Liabilities',
      sort: true,
      // hidden: (selectedColumnId?.includes("debtor") == true),
      // filter: textFilter({
      //   placeholder: 'debtor'
      // }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            {row.liability?.map((item)=>(
              <li style={{listStyle:'none'}}>
                {/* {row.creditor?.charAt(0)?.toUpperCase() + row.creditor?.slice(1)} */}
                  {item.DEBTOR} 
                   {item.balance.currency.map((i,index)=>(  
                  <li style={{listStyle:'lower-latin'}}>
                   <span>{i}</span>.......................<span>{parseFloat(item.balance.amount[index]).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}</span>
                   <span  style={{cursor:'pointer'}}
                to="#"
                onClick={()=>handleOpenModal({'name':item.DEBTOR,'currency':i,'amt':item.balance.amount[index]})}
                className=" retrieve-transaction"
              > Cancel
              </span>
                  </li>
                ))} 
              </li>
      ))}
          </>
        )
      }
    },
    // {
    //   dataField: 'date',
    //   text: 'Date',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     let date = moment(parseInt(row.date)/1000,'X').format('Do MMMM YYYY, h:mm:ss a').split(',')
    //     return (
    //       <>
    //       {row.date===null ?
    //             <span style={{ color: "white", fontSize: "13px" }}>N/A</span> :
    //            <span style={{ color: "white", fontSize: "13px" }}>
    //            {date[0] } <br/>
    //             {date[1]}
    //           </span>}
    //        </>
    //     );
    //   },
    // },
    // {
    //   dataField: 'balance',
    //   text: 'Balance',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //       <>
    //         <div>
    //         {row.amount>0 ?
    //           <span style={{ whiteSpace: 'nowrap',color:'#00ff00' }}>
    //             $ {parseFloat(row.balance).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}
    //           </span> :
    //           <span style={{ whiteSpace: 'nowrap',color:'#ff0000' }}>
    //           $ {parseFloat(row.balance).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '')}
    //         </span> 
    //          }      
    //         </div>
    //       </>
    //     )
    //   }
    // },
    // {
    //   dataField: 'currency',
    //   text: 'Currency',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //       <>
    //         <div>
    //           <span style={{ whiteSpace: 'nowrap' }}>
    //             {row.currency?.charAt(0)?.toUpperCase() + row.currency?.slice(1)}
    //           </span>

    //         </div>
    //       </>
    //     )
    //   }
    // },
    // {
    //   dataField: 'type',
    //   text: 'Type',
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //       <>
    //         <div>
    //           <span style={{ whiteSpace: 'nowrap' }}>
    //             {row.type?.charAt(0)?.toUpperCase() + row.type?.slice(1)}
    //           </span>
    //         </div>
    //       </>
    //     )
    //   }
    // },
    // {
    //   dataField: 'game',
    //   text: 'Game info',
    //   sort: true,
    //      formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return ( 
    //       <>
    //       {row.game=='' ? <span style={{marginLeft:'2em'}}>-</span> :
    //       <ul style={{marginLeft:'-2em'}}>
    //           <li style={{ whiteSpace: 'nowrap', color: '#FFC107', width:'99%' }}>
    //           <span style={{ color: '#FFC107' }}>Game</span>
    //           <span style={{ color: 'white',marginLeft: '3%' }}>{row.game}</span>
    //           </li>
    //           <li style={{ whiteSpace: 'nowrap', color: '#FFC107',width:'99%' }}>
    //           <span style={{ color: '#FFC107' }}>Game-Details</span>
    //           <span style={{ color: 'white',marginLeft: '3%' }}>{row.game_details}</span>
    //           </li>
    //           <li style={{ whiteSpace: 'nowrap', color: '#FFC107', width:'99%' }}>
    //           <span style={{ color: '#FFC107' }}>Venue</span>
    //           <span style={{ color: 'white',marginLeft: '3%' }}>{row.venue}</span>
    //           </li> 
    //           {row.percentage=='' ? "" : <>
    //           <li style={{ whiteSpace: 'nowrap', color: '#FFC107', width:'99%' }}>
    //           <span style={{ color: '#FFC107' }}>Ownership</span>
    //           <span style={{ color: 'white',marginLeft: '3%' }}>{row.percentage+'%'}</span> 
    //           </li></>}
    //       </ul>}
    //       </>
    //     )
    //   }
    // },
    // {
    //   dataField: '',
    //   text: 'Action',
    //   sort: false,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //       <div style={{ whiteSpace: 'nowrap' }}>
    //        <button  className={row.balance==0 ? 'cancel-disabled' : 'cancel'} onClick={()=> row.balance==0 ? null : handleOpenModal(row)}>cancel</button>
    //       </div>
    //     )
    //   }
    // }
  ]
  return (
    <React.Fragment>
      <Container fluid>
        <Row >
          <Col lg={12}>
            <Row className="d-flex" >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Balance</h3>
              </span>
              <Autocomplete
                className="p-2 pageheader"
                value={defaultEntity}
                options={entityData.sort((a, b) => -b.name.localeCompare(a.name))?.map((e) =>
                  (e.name)
                )}
                classes={{
                  option: styles.option
                }}
                PaperComponent={({ children }) => (
                  <Paper style={{ background: 'rgb(31, 33, 37)', color: 'white' }}>{children}</Paper>
                )}
                onChange={(e, k) => {
                   handleChangeEntity(k)
                }}
                style={{
                  fill: 'white',
                  boxShadow: 'none',
                  borderRadius: '30%',
                  width:'220px'
                }}
                sx={{
                  width: 300,
                  '.MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    width: '200px',
                    height: '32px',
                    // backgroundColor: '#fff',
                    fontSize: '14px',
                    border:'1px solid #d9d9d9 !important',
                    left: '4px'
                  },
                  '.MuiButtonBase-root': {
                    color: 'white'
                  },
                  '.MuiInputLabel-root':{
                    marginTop:'-6px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ color: 'white' }}
                    label="Entity"
                  />
                )}
              />
              {/* <SearchBox
                className="auto-ml p-2 pageheader"
                onChange={(event) => {
                  setSea(event.target.value)
                  const x = balanceData?.filter((i) =>
                  i.creditor.toLowerCase().includes(event.target.value.toLowerCase())
                  ||  i.debtor.toLowerCase().includes(event.target.value.toLowerCase())
                  || i.currency.toLowerCase().includes(event.target.value.toLowerCase())
                  ||  i.type.toLowerCase().includes(event.target.value.toLowerCase())
                  ||  moment(parseInt(i.date)).format('Do MMMM YYYY h:mm:ss a')==event.target.value
                   ||  parseFloat(i.balance).toLocaleString('en-US', {minimumFractionDigits:2,maximumFractionDigits:2}).replace(/\.00$/, '').replace('-','')==event.target.value
                   ||  i.game!='' && i.game.toLowerCase().includes(event.target.value.toLowerCase())
                    || i.game_details!='' && i.game_details.toLowerCase().includes(event.target.value.toLowerCase())
                    || i.venue.toLowerCase().includes(event.target.value.toLowerCase())
                     || i.percentage!='' && i.percentage.includes(event.target.value) 
                    )
                  if(x.length==0){
                    setSearch([])
                    // setAlertNoRecord(true)
                  }
                  console.log(x)
                  setSearch(x)
                }}
              /> */}
            </Row>
            {loading
              ? (
                <Spinner
                  style={{
                  position:'fixed',
                  top:'20em',
                  left:'59%',
                    height: '70px',
                    width: '70px'
                  }}
                  animation="border"
                  variant="primary"
                />
                )
              : null}
            {/* {sea
              ? (
                <CommonTable
                loading={loading}
                  data={search}
                  columns={columns4}
                />
                )
              : (
                <CommonTable
                loading={loading}
                  data={balanceData}
                  columns={columns4}
                />
                )} */}
                {sea
              ? (
                <BalanceTable
                loading={loading}
                balanceTotal={balanceTotal}
                handleOpenModal={handleOpenModal}
                  data={search}
                  columns={columns4}
                />
                )
              : (
                <BalanceTable
                loading={loading}
                balanceTotal={balanceTotal}
                handleOpenModal={handleOpenModal}
                  data={balanceData}
                  columns={columns4}
                />
                )}
          </Col>
        </Row>
        <Modal
        show={showModal}
        onHide={handleClose}
        style={{
          width: '30rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '35%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '18px',
              marginTop: '-5%',
              marginLeft: '11%'
            }}
          >
            Are you sure you want to cancel the debt ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            borderTop: 'none',
            paddingRight: '34%',
            marginTop: '-4%'
          }}
        >
          <Button
            variant="success"
            style={{ width: '25%',backgroundColor: '#006400'
          
          }}
            onClick={() => {
              BalanceUpdateApi()
              handleClose()
            }}
          >
            Yes
          </Button>
          <Button
             variant="danger"
            onClick={handleClose}
            style={{ width: '25%',backgroundColor: '#b30000'  }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={alertNoRecord}
        onHide={()=>setAlertNoRecord(false)}
        style={{
          width: '14rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '45%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '18px',
              marginTop: '-13%',
              marginLeft: '15%',
              fontWeight:'bold'
            }}
          >
            No record found.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            borderTop: 'none',
            paddingRight: '34%',
            paddingTop:'0%',
            // marginTop: '-10%',
            width:'19.5em',
            justifyContent:'center'
          }}
        >
          <button
          //  variant="success"
           className='no-record-found'
          
            onClick={() => {
              setAlertNoRecord(false)
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal> */}
              </Container>
    </React.Fragment >
  )
}
export default Balance;
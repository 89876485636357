import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { Row, Col, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import SortIcon from "@mui/icons-material/Sort";
import { useSelector, useDispatch } from "react-redux";
import { useLocalStorage } from "../useLocalStorage";
import {
  setSelectedColumnId,
  setSelectedColumnValueTable,
  setHiddenValueForColumn,
  setSelectedColumnIdValue,
} from "../../Redux/appSlice";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ToolkitProvider, {
  ColumnToggle,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "./CommonTable.css";
import { useLocation } from "react-router-dom";
let s = [];
let d = [];
let f = [];

let selectArray = [];
const CommonTableAccountant = (props) => {
  
  const [name, setName] = useLocalStorage("name", []);
  const location = useLocation();
  const [selected, setSelected] = useState([]);
  console.log(location, props);
  let columnName;
  let columnToggle;
  const dispatch = useDispatch();
  const {
    selectedColumnValueTable,
    selectedColumnId,
    hiddenValueForColumn,
    selectedColumnIdValue,
    addCheckboxValue,
  } = useSelector((store) => store.app);
  
  console.log(name);
  console.log(props);
  // for(let b of props.columns){
  //  for(let c of f){
  //   var x = props.columns.filter(i=>i.dataField!=c.dataField)
  //   console.log(c)
  //  }
  // }
if(props?.selectData?.length==0){
selectArray=[]
}
  const handleBtnClick = () => {
       selectArray=[]
  }
  // handleBtnClick()
  let ab = [];
  const handleToggle = (toggle, datafield) => {
    if (toggle == true) {
      // props.setSelectData([])
      // console.log(localStorage.getItem('key')==null ? 'a' :localStorage.getItem('key') )
      if(selectArray.length>0){
        console.log('yes')
      ab.push(datafield);
      let emptyData=''
      let a=localStorage.getItem('key')==null ? emptyData : localStorage.getItem('key').split(',')
      let c=a[0]=='' ? [ab.filter(i=>!a.includes(i))] : [ab.filter(i=>!a.includes(i)),...a]
      localStorage.setItem("key", c);
      selectArray=[]
      props.setSelectData([])
      }else{
        console.log('no')
        ab.push(datafield);
      let emptyData=''
      let a=localStorage.getItem('key')==null ? emptyData : localStorage.getItem('key').split(',')
      let c=a[0]=='' ? [ab.filter(i=>!a.includes(i))] : [ab.filter(i=>!a.includes(i)),...a]
      localStorage.setItem("key", c);
    
      }
      // console.log('true',a,c)
      // dispatch(setSelectedColumnId({type:"ADD_FILTER",value:datafield}));
    } else {
      // console.log('false')
      let d1 = localStorage.getItem("key");
      // console.log(d1.split(","));
      let data1 = d1.split(",").filter((i) => i!=datafield);
      // let d2=[...d1,...datafield]
      // console.log(data1,datafield,localStorage.getItem('key')==null)
      localStorage.setItem("key", data1);
      ab=[]
    }
    // console.log(toggle, datafield);
  };
  const handleToggleValue = (data) => {
    dispatch(setHiddenValueForColumn(true));
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div style={{ marginLeft: "8px" }}>
        <span
          style={{ color: "#FFC107", cursor: "pointer" }}
          onClick={handleClick}
        >
          <SystemUpdateAltIcon style={{ marginTop: "4px", height: "20px" }} />
        </span>
      </div>
    );
  };
  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    <Dropdown alignRight={true} style={{ position: "fixed", top: "63px" }}>
      <Dropdown.Toggle
        id={"options-button"}
        variant="borderless-dark"
        bsPrefix="no-chevron"
        size="sm"
        style={{ color: "#FFC107", fontSize: "25px", fontWeight: "700" }}
      >
        <SortIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown_scroll"
        style={{
          willChange: "transform",
          fontSize: "14px",
          height: "20rem",
          overflow: "auto",
          background: "rgb(31, 33, 37)",
          border: "0.3em solid white",
        }}
      >
        {columns.filter(i=> i.dataField!='shareholders_percentage')
          ?.map((column) => ({
            ...column,
            toggle: toggles[column.dataField],
          }))
          .map((column, index) => (
            <Form.Check
              type="switch"
              key={column.dataField}
              inline
              multiple
              label={column.text}
              id={column.dataField}
              checked={column.toggle}
              // checked={!selectedColumnId.includes(column.dataField)}
              //  aria-checked={column.toggle ? 'true' : 'false'}
              onClick={() => {
                onColumnToggle(column.dataField);
                handleToggle(column.toggle, column.dataField);
                
                //   dispatch(setSelectedColumnIdValue(column?.dataField))
                //   if(column.toggle==true)
                //   {
                //     dispatch(setSelectedColumnId({type:"ADD_FILTER",value:column?.dataField}));
                // }
                //  else
                // {
                //     dispatch(setSelectedColumnId(column?.dataField))
                //      dispatch(setSelectedColumnId({type:"REMOVE_FILTER",value:column?.dataField}))
                //    handleToggleValue(column);
                // }
              }}
            />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
     selected:selectArray,
    onSelect: (row, isSelect, rowIndex, e) => {
      // console.log(row);
      console.log(isSelect);
      if (isSelect) {
        console.log(props);
        props.selectData.push(row);
        console.log(props);
        props.setSelectData(props.selectData);
        selectArray.push(row.game_id)
      } else {
        let propsData=props.selectData.filter(i=>i.game_id!=row.game_id);
        selectArray=selectArray.filter(i=>i!=row.game_id)
        props.setSelectData(propsData);
      }
      // console.log(rowIndex);
      // console.log(e);
    },
    onSelectAll: (isSelect, rows, e) => {
      // console.log(isSelect);
      // console.log(rows);
      // console.log(e);
      if (isSelect) {
        console.log(props);
      
         setSelected(rows.map(e=>e.game_id))
        props?.selectData?.push(rows);
        console.log(props, props.selectData);
        selectArray=rows.map(e=>e.game_id)
        props?.setSelectData(props.selectData[0]);
      } else {
        selectArray=[]
         setSelected([])
        props?.selectData?.pop(rows);
        props?.setSelectData([]);
      }
    },
  };

  const Record = props.loading
    ? ""
    : !(props.data?.length > 0) && "No Record Found";
  console.log(selectRow);
  return (
    <div className="tablet" style={{ width: "100%" }}>
      {props.data && (
        <ToolkitProvider
          bootstrap4
          keyField={location.pathname == "/PMS/ledger" ? "id" : "game_id"}
          data={props.data}
          columns={props.columns}
          columnToggle
          // selectRow={ selectRow }
          draggable
          search
          exportCSV={{
            fileName: "Transactions.csv",
            onlyExportFiltered: true,
            exportAll: false,

            // separator: '|',
            // ignoreHeader: true,
            // noAutoBOM: false,
            // blobType: 'text/csv;charset=ansi'
          }}
        >
          {(props) => (
            <Row>
              <Col
                style={{ flexDirection: "column", maxWidth: "100%", flex: 1 }}
              >
                 {Object.values(props.columnToggleProps.toggles).filter(
                  (i) => i == true
                ).length > 0 ? (
                <BootstrapTable
                  filter={
                    location.pathname == "/PMS/deleted_entries"
                      ? undefined
                      : filterFactory()
                  }
                  {...props.baseProps}
                  // selectRow={selectRow}
                  selectRow={
                    location.pathname == "/PMS/payments"
                      ? undefined
                      : location.pathname == "/PMS/ledger"
                      ? undefined
                      : selectRow
                  }
                  wrapperClasses="table-responsive"
                  noDataIndication={Record}
                  //      cellEdit={cellEditFactory({
                  //   mode: "checkbox",
                  //   blurToSave: true,
                  //   afterSaveCell: (oldValue, newValue, row, column) => {}
                  // })}
                />) : <></>}
              </Col>
              {location.pathname == "/PMS/deleted_entries" || location.pathname == "/PMS/ledger" ||  location.pathname == "/PMS/payments"   ? (
                <></>
              ) : (
                <Col
                  style={{
                    flexDirection: "column",
                    flex: 0,
                    position: "fixed",
                    right: "16.5em",
                    marginTop: "-2.6em",
                    width: "0.5em",
                    display: "flex",
                    flexDirection: "row",
                    zIndex: 1,
                  }}
                >
                  <CustomToggleList {...props.columnToggleProps} />
                  {/* <MyExportCSV { ...props.csvProps } />  */}
                </Col>
              )}
            </Row>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
};

export default CommonTableAccountant;

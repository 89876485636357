import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close'
import { AlertBox } from '../helpers/components/AlertBox'
import cx from 'classnames'
import useApi from '../hooks/useApi'
import { getUserId } from '../helpers/common'

export const AddCommentModal = ({ visible, setShowComment = () => {}, portfolio = {}, handleClose = () => {}, refreshTable }) => {
  const [comment, setComment] = useState()
  const [trigger, setTrigger] = useState()
  const [alert, setAlert] = useState({
    visible: false,
    type: 'success',
    msg: ''
  })

  const handleFormSubmitComment = (e) => {
    setTrigger(+new Date())
  }

  const response = useApi({
    method: 'POST',
    options: {
      userId: getUserId(),
      portfolioId: portfolio.PortfolioId,
      id: portfolio.Id,
      remarks: comment
    },
    trigger,
    url: `${process.env.REACT_APP_BASE_URL}/property/remarks`
  })

  useEffect(() => {
    if (response.response) {
      refreshTable()
      setAlert({
        visible: true,
        msg: 'Remark Added!',
        type: 'success'
      })
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
        handleClose()
      }, 2000)
    }

    if (response.error) {
      setAlert({
        visible: true,
        msg: response.error,
        type: 'error'
      })
      setTimeout(() => {
        setAlert({
          visible: false,
          msg: '',
          type: 'success'
        })
      }, 5000)
    }
  }, [response?.response, response?.error])

  useEffect(() => {
    if (visible) {
      if (portfolio.Remarks !== '' && portfolio.Remarks !== null) {
        setComment(portfolio.Remarks)
      }
    } else {
      setComment('')
    }
  }, [visible])

  return (
    <>
        <Modal
            onHide={handleClose}
            show={visible}
            style={{ width: '28%', marginLeft: '35%' }} >
            <div style={{ border: '1px solid white' }}>
            <Modal.Header
                style={{ backgroundColor: '#222429', border: 'none' }}
            >
                <IconButton
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  color: 'white'
                }}
                onClick={handleClose}
                >
                <CloseIcon />
                </IconButton>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#222429' }}>
                <Form
                className="custom-form"
                noValidate
                >
                { portfolio?.Remarks != null && portfolio?.Remarks !== ''
                  ? <h4>
                    Update Tags/Comments
                    </h4>
                  : <h4>
                    Add Tags/Comments
                    </h4>}
                <Form.Label
                    htmlFor="exchange"
                    className={cx('custom-form-box', {
                      'focus-add': comment
                    })}
                    style={{ width: '72%', marginLeft: '15%' }}
                >
                    <Form.Control
                      type="text"
                      id="name"
                      name="name"
                      value={comment || null }
                      placeholder="Comment"
                      onChange={(e) => setComment(e.target.value)}
                      required
                      style={{ color: 'white' }}
                      />
                </Form.Label>
                <Button
                    type="button"
                    variant=""
                    className="btn-gray"
                    style={{
                      width: '50%',
                      marginLeft: '25%',
                      boxShadow: 'none',
                      color: 'white'
                    }}
                    onClick={handleFormSubmitComment}
                >
                    Save
                </Button>
                </Form>
            </Modal.Body>
            </div>
        </Modal>

        <AlertBox type={alert.type} show={alert.visible} text={alert.msg}/>
      </>
  )
}

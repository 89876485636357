import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import HistoryIcon from '@mui/icons-material/History'
import GroupsIcon from '@mui/icons-material/Groups'
import InventoryIcon from '@mui/icons-material/Inventory'
import GridViewIcon from '@mui/icons-material/GridView'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import './SidebarAdmin.css'
import { current } from '@reduxjs/toolkit'
const load = false
const loadUpper = true
const SidebarIconAdmin = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const portfolioRef = useRef(null)

  const roleId = localStorage.getItem('role')?.split(',')
  const [showPortfolioDetails, setShowPortfolioDetails] = useState(true)
  const [showAccountancyDetails, setShowAccountancyDetails] = useState(true)
  const [active, setActive] = useState(false)

  const UserNavbar = () => (
    <>
      <li style={{ width: '34px' }}>
        <NavLink
          to={'/PMS/dashboard'}
          className="nav-link"

        >
          <InventoryIcon style={{ fontSize: '25px', color: '#FFC107' }} />
          {/* <span style={{ padding: '4%' }}>Dashboard</span> */}
        </NavLink>
      </li>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/UsersAsset"
          className="nav-link"

        >
          <AccountBalanceWalletIcon
            style={{ fontSize: '25px', color: '#FFC107' }}
          />
          {/* <span style={{ padding: '4%' }}>Assets</span> */}
        </NavLink>
      </li>
    </>
  )

  const PortfolioNavbar = () => (
    <>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/Admin_dashboard"
          className="nav-link"

          onClick={() => {
            setShowPortfolioDetails(!showPortfolioDetails)
            setTimeout(() => {
              navigate('/PMS/Admin_dashboard')
            }, 500)
          }}
        >
          <GridViewIcon style={{ fontSize: '24px', color: '#FFC107' }} />
        </NavLink>
      </li>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/MainManageAssetsWallets"
          className="nav-link"

        >
          <AccountBalanceWalletIcon
            style={{ fontSize: '25px', color: '#FFC107' }}
          />
        </NavLink>
      </li>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/Investments"
          className="nav-link"

        >
          <InventoryIcon style={{ fontSize: '25px', color: '#FFC107' }} />
        </NavLink>
      </li>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/ViewExchanges"
          className="nav-link"

        >
          <CurrencyExchangeIcon
            style={{ fontSize: '23px', color: '#FFC107' }}
          />
        </NavLink>
      </li>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/Admin/Transactions"
          className="nav-link"

        >
          <AccountBalanceWalletIcon
            style={{ fontSize: '23px', color: '#FFC107' }}
          />
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/PMS/Treasury"
          className="nav-link"

        >
          <RequestQuoteIcon style={{ fontSize: '23px', color: '#FFC107' }} />
        </NavLink>
      </li>
    </>
  )

  const ProfileNavbar = () => (
    <>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/Admin/Person"
          className="nav-link"

        >
          <PersonIcon style={{ fontSize: '26px', color: '#FFC107' }} />
        </NavLink>
      </li>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/Admin/Organisation"
          className="nav-link"

        >
          <GroupsIcon style={{ fontSize: '26px', color: '#FFC107' }} />
        </NavLink>
      </li>
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/Accountant"
          className="nav-link"

        >
          <HistoryIcon style={{ fontSize: '23px', color: '#FFC107' }} />
        </NavLink>
      </li>
    </>
  )

  const AccountancyNavbar = () => (
    <>
    <hr id="separator" />
    <li style={{ width: '34px' }}>
      <NavLink
        to="/PMS/Accounting_data"
        className="nav-link"

      >
        <AccountBalanceWalletIcon
          style={{ fontSize: '25px', color: '#FFC107' }}
        />
      </NavLink>
    </li>
    <li style={{ width: '34px' }}>
      <NavLink
        to="/PMS/deleted_entries"
        className="nav-link"

      >
        <InventoryIcon style={{ fontSize: '25px', color: '#FFC107' }} />
      </NavLink>
    </li>
    <li style={{ width: '34px' }}>
      <NavLink
        to="/PMS/payments"
        className="nav-link"

      >
        <CurrencyExchangeIcon
          style={{ fontSize: '23px', color: '#FFC107' }}
        />
      </NavLink>
    </li>
    <li style={{ width: '34px' }}>
      <NavLink
        to="/PMS/ledger"
        className="nav-link"

      >
        <AccountBalanceWalletIcon
          style={{ fontSize: '23px', color: '#FFC107' }}
        />
      </NavLink>
    </li>
    <li style={{ width: '34px' }}>
      <NavLink
        to="/PMS/balance"
        className="nav-link"

      >
        <AccountBalanceWalletIcon
          style={{ fontSize: '23px', color: '#FFC107' }}
        />
      </NavLink>
    </li>
    <li style={{ width: '34px' }}>
      <NavLink
        to="/PMS/shareholders"
        className="nav-link"

      >
        <AccountBalanceWalletIcon
          style={{ fontSize: '23px', color: '#FFC107' }}
        />
      </NavLink>
    </li>
    <li style={{ width: '34px' }}>
      <NavLink
        to="/PMS/entity"
        className="nav-link"

      >
        <AccountBalanceWalletIcon
          style={{ fontSize: '23px', color: '#FFC107' }}
        />
      </NavLink>
    </li>
    </>
  )

  const AuditLogNavbar = () => (
    <>
      <hr id="separator" />
      <li style={{ width: '34px' }}>
        <NavLink
          to="/PMS/Investment/LogHistory"
          className="nav-link"

        >
          <HistoryIcon style={{ fontSize: '23px', color: '#FFC107' }} />
        </NavLink>
      </li>
    </>
  )

  const TreasurerNavbar = () => (
    <li>
      <NavLink
        to="/PMS/Treasury"
        className="nav-link"

      >
        <RequestQuoteIcon style={{ fontSize: '23px', color: '#FFC107' }} />
      </NavLink>
    </li>
  )

  return (
    <div>
      <aside
        className="sidebar-admin-container"
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '93vh',
          position: 'sticky'
        }}
      >
        <ul className="sidebar">
            {/* { roleId.includes("user") && (<UserNavbar></UserNavbar>)  } */}
            <PortfolioNavbar></PortfolioNavbar>
            <hr id="separator" />
            {/* { (roleId.includes('superadmin') || roleId.includes('admin') || roleId.includes('treasurer')) && (<TreasurerNavbar></TreasurerNavbar>) } */}
            <hr id="separator" />
            { (roleId.includes('superadmin') || roleId.includes('admin')) && (<ProfileNavbar></ProfileNavbar>) }
            {/* <AccountancyNavbar></AccountancyNavbar> */}
            {/* { (roleId.includes("superadmin") || roleId.includes("admin")) && (<AuditLogNavbar></AuditLogNavbar>) } */}
        </ul>
      </aside>
    </div>
  )
}
export default SidebarIconAdmin

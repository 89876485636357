import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../../common/Modal.css";
import axios from "axios";
import SearchBox from "../../common/SearchBox/SearchBox";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Spinner from "../../common/spinner";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Tooltip from "@mui/material/Tooltip";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@material-ui/core/Paper";

import ModalToAddExchangeValue from "../../common/commonModals/ModalToAddExchangeValue";
import RawTable from "../../common/RawTable";
import { setOpenModalAddExchangeStatus } from "../../Redux/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  paper: {
    background: "rgb(31, 33, 37) !important",
    color: "white !important",
  },
  option: {
    "&:hover": {
      backgroundColor: "grey !important",
      color: "white !important",
    },
  },
});

const ViewExchanges = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const getId = localStorage.getItem("sub_Id");
  const roleId = localStorage.getItem("role").split(",");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const from = location?.state?.from;
  const portfolio_id = location.pathname.slice(20);
  const port_id = location.state?.data?.portfolioId;
  const state_portfolioName = location?.state?.data.portfolioName;
  const [result, setResult] = useState([]);
  const [result3, setResult3] = useState([]);
  const [showExchanges, setShowExchanges] = useState(false);
  const handleCloseExchanges = () => setShowExchanges(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [sea, setSea] = useState("");
  const [exch, setExch] = useState("");
  const [dataAddModal, setDataAddModal] = useState([]);
  const [validated, setValidated] = useState(false);
  const { openModalAddExchangeStatus } = useSelector((store) => store.app);
  const [credentialsInfoInvest, setCredentialsInfoInvest] = useState({
    portfolio_id: port_id,
  });
  const [isHoveringColor, setIsHoveringColor] = useState(false);
  const [portfolios, setPortfolios] = useState([]);

  const handleMouseEnterColor = () => {
    setIsHoveringColor(true);
  };
  const handleMouseLeaveColor = () => {
    setIsHoveringColor(false);
  };
  
  const handleShowExchanges = () => {
    const r = portfolios?.filter((i) => i.portfolioName == exch);
    credentialsInfoInvest.portfolio_id = r?.[0]?.portfolioId;
    setDataAddModal(r);

    dispatch(setOpenModalAddExchangeStatus(true));
    setValidated(false);
  };

  // list of exchange of specific portfolio api
  // const exchange = async (port_id) => {
  //   setLoading(true);
  //   const config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_BASE_URL}/getExchangeData`,
  //     params: {
  //       portfolio_id: port_id,
  //     },
  //   };
  //   await axios(config)
  //     .then(function (response) {
  //       response.data.sort((a, b) => {
  //         const x = parseInt(a.takerCommission);
  //         const y = parseInt(b.takerCommission);
  //         return x > y ? -1 : x < y ? 1 : 0;
  //       });
  //       setLoading(false);
  //       // console.log( '===========================');
  //       console.log( response.data);
  //       // console.log( '===========================');
  //       setResult(response.data);
  //     })
  //     .catch(function (error) {
  //       // console.log(error)
  //     });
  // };

  const handleChange = async (k) => {
       const portfolio_id = portfolios.filter((i) => i.portfolioName == k);
       setExch(k);
  //   const config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_BASE_URL}/getExchangeData`,
  //     params: {
  //       portfolio_id: x?.[0]?.portfolio_id,
  //     },
  //   };
  //   await axios(config)
  //     .then(function (response) {
  //       if (response.data.length == 0) {
  //         setLoading(false);
  //       } else {
  //         response.data.sort((a, b) => {
  //           const x = parseInt(a.takerCommission);
  //           const y = parseInt(b.takerCommission);
  //           return x > y ? -1 : x < y ? 1 : 0;
  //         });
  //         setResult(response.data);
  //       }
  //     })
  //     .catch(function (error) {
  //       // console.log(error)
  //     });
  };

  //list of portfolio api
  const assets = async () => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/getAllPortfolio`,
      params: {
        user_id: getId,
        user_role: roleId,
      }
    };
    await axios(config)
      .then(function (response) {
        // console.log(response)
        const rs = response.data;
        const arr = [];
        if (
          roleId.includes("accountant") === true &&
          roleId.includes("admin") === false
        ) {
          const a = acdata?.filter((i) => i.accountant_id == getId);
          a?.forEach((el) => {
            const m = rs?.filter((j) => j.portfolio_id == el.portfolio_id);
            if (m.length > 0) {
              const me = { ...m };
              arr.push(Object.values(me)[0]);
            }
          });
          setResult3(arr);
          exchange(arr?.[0]?.portfolio_id);
          setExch(arr?.[0]?.portfolio_name);
        } else {
          if (port_id) {
            const p = rs?.filter((i) => i.portfolio_id == port_id);
            setExch(p?.[0]?.portfolio_name);
            const pi = p?.[0]?.portfolio_id;
            setResult3(rs);
            exchange(pi);
          } else if (from != undefined) {
            const p = rs?.filter((i) => i.portfolio_name == from);
            setExch(p?.[0]?.portfolio_name);
            setResult3(rs);
            const pi = p?.[0]?.portfolio_id;
            exchange(pi);
          } else {
            setResult3(rs);
            setExch(rs?.[0]?.portfolio_name);
            const port = rs?.[0]?.portfolio_id;
            exchange(port);
          }
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
  };

  const portfolioData = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/portfolios`,
      params: {
        user_id: getId,
      }
    };
    await axios(config)
      .then(function (response) {
        const rs = response.data;
        setPortfolios(rs);

        if(state_portfolioName != undefined){
            setExch(state_portfolioName);
        }
        else {
          setExch(rs?.[0]?.portfolioName);
        }
      })
      .catch(function (error) {
        // console.log(error)
      })
      .finally(function () {
        setLoading(false);
      });
  };

  let acdata = [];
  //list of accountant and portfolio api
  const accountant = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/all_accountant_and_portfolio`)
      .then(function (response) {
        const p_data = response.data;
        acdata = p_data;
      });
  };
  useEffect(async () => {
    await portfolioData();
  }, []);

  let result1 = [
    ...new Map(result.map((item) => [item.apikey, item])).values(),
  ];
  
  let uniqueKeyObject = {};
  for (let i = 0; i < result1.length; i++) {
    let uniqueArray = result.filter(
      (item) => item.apikey === result1[i].apikey
    );
    uniqueKeyObject = { ...uniqueKeyObject, [i]: [...uniqueArray] };
  }
  let apiObject = {};

  for (let j = 0; j < Object.keys(uniqueKeyObject).length; j++) {
    let apiTempObject = {
      assetsName: uniqueKeyObject[j].map((ele) => ele.assetName),
      free: uniqueKeyObject[j].map((ele) => ele.free),
    };
    apiObject = { ...apiObject, [j]: apiTempObject };
  }

  result1 = result1.map((ele, index) => {
    return {
      ...ele,
      asset: {
        assetName: apiObject[index]?.assetsName,
        free: apiObject[index]?.free,
      },
    };
  });

  const handleReduce = (item) => {
    let result = item?.asset.free.reduce(
      (prevValue, currentValue) => prevValue + parseFloat(currentValue),
      0
    );
    return Math.round(result);
  };
  result1 = result1.map((record) => {
    return { ...record, total: handleReduce(record) };
  });

  const columns = [
    {
      dataField: "exchange_name",
      text: "Name",
    },
    {
      dataField: "exchange_type",
      text: "Type",
    },
    {
      dataField: "asset",
      text: "Asset",
    },
    {
      dataField: "accountType",
      text: "Account",
    },
    {
      dataField: "free",
      text: "Balance",
    },
  ];

  const admin_columns = [
    {
      dataField: "action",
      text: "Actions",
    }
  ];

  if(roleId.includes("superadmin") || roleId.includes("admin") || roleId.includes("accountant"))
      Array.prototype.push.apply(columns,admin_columns);

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Row className="d-flex justify-content-center">
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Exchanges</h3>
              </span>
              {roleId.includes("superadmin") || roleId.includes("admin") ? (
                <span
                  className="p-2 pageheader btn btn-gray"
                  style={{
                    color: "#FFC107",
                    boxShadow: "none",
                    cursor: "pointer",
                    background: "none",
                    top: "11px",
                    position: "relative",
                  }}
                  onClick={()=> handleShowExchanges()}
                >
                  <AddCircleOutlineOutlinedIcon />
                </span>
              ) : (
                <></>
              )}
              {/* <span
                className="p-2 pageheader"
                onClick={() =>
                  navigate("/PMS/TransactionExchangeHistory", {
                    state: { id: 1, dataP: exch },
                  })
                }
                onMouseEnter={handleMouseEnterColor}
                onMouseLeave={handleMouseLeaveColor}
                style={{
                  background: "transparent",
                  color: "#FFC107",
                  cursor: "pointer",
                  top: "11px",
                  position: "relative",
                }}
              >
                <Tooltip title="Exchange History">
                  <ReceiptLongIcon />
                </Tooltip>
              </span> */}
              <Autocomplete
                className="p-2 pageheader"
                value={exch}
                options={portfolios?.map((e) => e.portfolioName)}
                classes={{
                  option: styles.option,
                }}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{ background: "rgb(31, 33, 37)", color: "white" }}
                  >
                    {children}
                  </Paper>
                )}
                onChange={(e, k) => {
                  handleChange(k);
                }}
                style={{
                  fill: "white",
                  boxShadow: "none",
                  borderRadius: "30%",
                  width: "220px",
                }}
                sx={{
                  width: 300,
                  ".MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    width: "200px",
                    height: "32px",
                    fontSize: "14px",
                    border: "1px solid #d9d9d9 !important",
                    left: "4px",
                  },
                  ".MuiButtonBase-root": {
                    color: "white",
                  },
                  ".MuiInputLabel-root": {
                    marginTop: "-6px",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ color: "white" }}
                    label="Portfolios"
                  />
                )}
              />
              <SearchBox
                onChange={(event) => {
                  setSea(event.target.value);
                }}
              />
            </Row>
              <RawTable
                resultData={result1}
                rowEvents={()=>{}}
                search={sea}
                data={portfolios.filter((item) => item.portfolioName === exch)}
                columns={columns}
              />

          </Col>
        </Row>
        {openModalAddExchangeStatus ? (
          <ModalToAddExchangeValue data={dataAddModal} exchangeData={result} />
        ) : (
          <></>
        )}
      </Container>
    </React.Fragment>
  );
};
export default ViewExchanges;

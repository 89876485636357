import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../common/CommonTable/CommonTable.css";
import CommonTableWalletInfoFirst from "../../common/CommonTable/CommonTableWalletInfoFirst";
import moment from "moment";
import { ROW_SELECT_DISABLED } from "react-bootstrap-table-next";

const base_url = process.env.REACT_APP_BASE_URL;

const getCryptoBalance = async (addr, cryptoType) => {
  let result = {};
  let urlSwitch = ""

  if (cryptoType === "ETH") urlSwitch = "get_filtered_eth_balance"
  if (cryptoType === "BTC") urlSwitch = "get_filtered_btc_balance"
  if (cryptoType === "TRON") urlSwitch = "get_filtered_tron_balance"

  await axios
    .get(`${base_url}/${urlSwitch}/${addr}`)
    .then((response) => {
      result = response.data;
    }).catch(err => {
      console.log(err)
    })

  return result;
}

const repResData = async (data) => {
  let ret = await getCryptoBalance(data.address_id.address_id, data.address_id.address_type);

  return ret;
}

const WalletInfoFirstLevel = (props) => {
  const columns = [
    {
      dataField: "token",
      text: "Token",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: "white", fontSize: "14px" }}>{row.token}</span>
        );
      },
    },
    {
      dataField: "send",
      text: "Total send",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span style={{ color: "white", fontSize: "14px" }}>
            <p>
              {row.token} {row.totalSent}<br />
              ${row.usd_TotalSent.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </span>
        );
      },
    },
    {
      dataField: "rcv",
      text: "Total received",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span
            style={{
              color: "white",
              fontSize: "14px",
              whiteSpace: "break-spaces",
            }}
          >
           <p>
              {row.token} {row.totalReceived}<br />
              ${row.usd_TotalReceived.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </span>
        );
      },
    },

    {
      dataField: "total",
      text: "Balance",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span>
            <p>
              {row.token} {row.balance}<br />
              ${row.usd_Balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </span>
        );
      },
    },
    {
      dataField: "count",
      text: "Transaction count",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span
            style={{
              color: "white",
              fontSize: "14px",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{row.totalCount}</p>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <CommonTableWalletInfoFirst data={props.address_id.tokens} columns={columns} />
    </div>
  );
}

export default WalletInfoFirstLevel;

import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./Header.css";
import { LOGOUT_URL, LOCATION_URL } from "../../config/env";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout_url = `${LOGOUT_URL}?post_logout_redirect_uri=${LOCATION_URL}&id_token_hint=${localStorage.getItem('id_token')}`;
  const name = localStorage.getItem("name");

  const handleClick = () => {
    localStorage.clear();
    window.location = logout_url;
  };
  return (
    <div>
      <header
        style={{
          paddingBottom: "0%",
          paddingTop: "1%",
          paddingLeft: "3%",
          marginRight: "-3.9em",
        }}
      >
        <span
          style={{
            position: "absolute",
            left: "0.2em",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => props.setOpenSidebar(!props.openSidebar)}
        >
          <MenuIcon />
        </span>
        <div className="profile" style={{ marginRight: "1%" }}>
          <AccountCircleIcon sx={{ color: "white", marginRight: "4%" }} />
          <span style={{ fontSize: "14px" }}>{name}</span>
        </div>
        <div className="btnpoweoff" onClick={handleClick}>
          <Link to="#">
            <svg
              id="shut-down-line"
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
            >
              <path
                id="Path_95"
                data-name="Path 95"
                d="M0,0H23V23H0Z"
                fill="none"
              />
              <path
                id="Path_96"
                data-name="Path 96"
                d="M6.052,3.717l1.09,1.557a7.6,7.6,0,1,0,8.717,0l1.09-1.557a9.5,9.5,0,1,1-10.9,0Zm4.5,7.783V2h1.9v9.5Z"
                fill="#0f8bfe"
              />
            </svg>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Header;

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "./CommonTable.css";
import { Image, Row, Col, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SortIcon from "@mui/icons-material/Sort";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const CommonTableInvestmentInfo = (props) => {
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div style={{ marginLeft: "-2px", top: "-9px", position: "relative" }}>
        <span
          style={{ color: "#FFC107", cursor: "pointer" }}
          onClick={handleClick}
        >
          <SystemUpdateAltIcon style={{ marginTop: "4px", height: "20px" }} />
        </span>
      </div>
    );
  };

  return (
    <div
      className="tablet wallet-table"
      style={{ width: "100%", overflow: "auto" }}
    >
      {props.data && (
        <ToolkitProvider
          bootstrap4
          keyField="address_id"
          data={props.data}
          columns={props.columns}
          columnToggle
          draggable
          search
          exportCSV={{
            fileName: "Transactions.csv",
            onlyExportFiltered: true,
            exportAll: false,
          }}
          defaultSorted={defaultSorted}
        >
          {(props) => (
            <Row>
              <Col
                style={{ flexDirection: "column", maxWidth: "100%", flex: 1 }}
              >
                <BootstrapTable
                  filter={filterFactory()}
                  {...props.baseProps}
                  wrapperClasses=" wallet-border"
                  noDataIndication="no data found"
                />
              </Col>
            </Row>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
};

export default CommonTableInvestmentInfo;

import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Modal, Form } from 'react-bootstrap'
import '../../common/Modal.css'
import axios from 'axios'
import cx from 'classnames'
import Spinner from '../../common/spinner'
import moment from 'moment'
import Loader from '../../common/loader'
import InputLabel from '@mui/material/InputLabel'
import { read, utils, writeFile } from 'xlsx'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined'
import MenuItem from '@mui/material/MenuItem'
import CommonTableWallet from '../../common/CommonTable/CommonTableWallet'
import SearchBox from '../../common/SearchBox/SearchBox'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import 'react-circular-progressbar/dist/styles.css'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import Snackbar from '@mui/material/Snackbar'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Autocomplete from '@mui/material/Autocomplete'
import Tooltip from '@mui/material/Tooltip'
import { Alert, TextField } from '@mui/material'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  paper: {
    background: 'rgb(31, 33, 37) !important',
    color: 'white !important'
    // So we see the popover when it's empty.
    // It's most likely on issue on userland.
  },
  option: {
    '&:hover': {
      backgroundColor: 'grey !important',
      color: 'white !important'
    }
  }
})
let tronD = []
let btcD = []

// let elliptic_data = process.env.REACT_APP_ELLIPTIC;
// const wallet_sample_data = JSON.parse(elliptic_data);

//  localStorage.setItem('json',elliptic_data )
const MainManageAssetsWallets = () => {
  const styles = useStyles()
  const getId = localStorage.getItem('sub_Id')
  const roleId = localStorage.getItem('role').split(',')
  const location = useLocation()
  const [result, setResult] = useState([])
  const [loadData, setLoadData] = useState(false)
  const [disabledButton, setDisabledButton] = useState('')
  const [result3, setResult3] = useState([])
  const [dataId, setDataId] = useState('')
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [alertDownload, setAlertDownload] = useState(true)
  const [showAddress, setShowAddress] = useState(false)
  const handleCloseAddress = () => setShowAddress(false)

  const handleClose = () => setShow(false)
  const [alertNoRecord, setAlertNoRecord] = useState(false)
  const [search, setSearch] = useState([])
  const [sea, setSea] = useState('')
  const [w_data, setW_data] = useState([])
  const [portN, setPortN] = useState('')
  const [portI, setPortI] = useState('')
  const [walletJson, setWalletJson] = useState([])
  const navigate = useNavigate()
  const from = location?.state?.from
  const [walletDataInfo, setWalletDataInfo] = useState([])
  const state_portfolioName = location?.state?.data.portfolioName

  const trondata = async (ab) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/get_tron_balance_portfolio`,
      params: {
        portfolio_id: ab,
        address_type: 'TRON'
      }
    }
    await axios(config).then(function (response1) {
      tronD = response1.data
      console.log(tronD)
    })
  }

  // list of btc address api
  const btcdata = async (ab) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/get_btc`,
      params: {
        portfolio_id: ab,
        address_type: 'BTC'
      }
    }
    await axios(config).then(function (response2) {
      btcD = response2.data
    })
  }

  const handleShow = (id) => {
    setShow(true)
    setW_data(id)
  }

  // delete eth address api, tron address api, btc address api
  const handleDeleteUpdate2 = async () => {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/delete_address`,
      params: {
        address_id: dataId,
        address_type: addressTypeCheck
      }
    }

    await axios(config)
      .then(function (response) {
        walletData(portI)
      })
      .catch(function (error) {
      })
  }

  // list of portfolio api
  const portfolioData = async () => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/portfolios`,
      params: {
        user_id: getId
      }
    }
    await axios(config)
      .then(function (response) {
        const rs = response.data

        setResult(rs)
        if (from != undefined) {
          const p = rs?.filter((i) => i.portfolioName == from)
          const pi = p?.[0]?.portfolioId
          walletData(pi)
          setPortN(p?.[0]?.portfolioName)
        } else if (state_portfolioName != undefined) {
          const p = rs?.filter((i) => i.portfolioName == state_portfolioName)
          const pi = p?.[0]?.portfolioId
          walletData(pi)
          setPortN(p?.[0].portfolioName)
        } else {
          const d = rs?.[0].portfolioId
          setPortN(rs?.[0].portfolioName)
          walletData(d)
        }
      })
      .catch(function (error) {
        // console.log(error)
      })
      .finally(function () {
        setLoading(false)
      })
  }

  const handleChange = async (k) => {
    setPortN(k)
    const x = result.filter((p) => p.portfolioName == k)
    const pi = x?.[0]?.portfolioId
    await walletData(pi)
  }

  useEffect(async () => {
    await portfolioData()
  }, [])

  const [walletIdAdd, setWalletIdAdd] = useState('')
  const [portfolioIdAdd, setPortfolioIdAdd] = useState('')
  const handleChangeWallet = (event) => {
    const x = walletDataInfo.filter((p) => p.wallet_name == event)
    setWalletIdAdd(x[0]?.walletId)
    setPortfolioIdAdd(x[0]?.portfolio_id)
  }

  // list of wallet and eth address in specific portfolio api
  const walletData = async (d) => {
    setLoading(true)
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/wallet`,
      data: {
        portfolioId: d,
        userId: localStorage.getItem('sub_Id')
      }
    }
    await axios(config).then(function (response) {
      if (response.data != 'error') {
        if (response.data == 0 || response.data.results.data[0].wallets.length == 0) {
          setResult3([])
          setLoading(false)
          setAlertNoRecord(true)
        } else {
          setResult3(response.data.results.data[0].wallets)
          setLoading(false)
        }
      } else {
        setResult3([])
      }
    })
  }

  const handleShowAddress = (id) => {
    const m = result?.filter((i) => i.portfolioName == portN)
    setPortI(m?.[0]?.portfolioId)
    const idx = id.id
    setAddressTypeCheck(id.address_type)
    setDataId(idx)
    setShowAddress(true)
  }

  // delete wallet api
  const delete_asset = async () => {
    const d_id = w_data.portfolioId
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_URL}/delete_wallet`,
      params: {
        walletId: w_data.walletId
      }
    }
    await axios(config)
      .then(function (response) { })
      .catch(function (error) {
      })
    walletData(d_id)
  }

  const [dataSetWallet, setDataSetWallet] = useState([])
  const [walletEditAlert, setWalletEditAlert] = useState(false)
  const [alertWalletName, setAlertWalletName] = useState(false)
  const [addressTypeCheck, setAddressTypeCheck] = useState('')
  const [walletName, setWalletName] = useState('')
  const [walletPurpose, setWalletPurpose] = useState('')

  const handleWalletNameEdit = (row) => {
    setDataSetWallet(row)
    setWalletName(row.walletName)
    setWalletPurpose(row.purpose)
    setWalletEditAlert(true)
    setValidated(false)
  }
  const [isHoveringColor, setIsHoveringColor] = useState(false)
  const handleMouseEnterColor = () => {
    setIsHoveringColor(true)
  }
  const handleMouseLeaveColor = () => {
    setIsHoveringColor(false)
  }
  const walletArray = []

  const elliptic_report = () => {
    const headings = [['Address_name', 'Address', 'Address_type', 'Date']]
    const wb = utils.book_new({ cellText: true })
    const ws = utils.json_to_sheet([])
    utils.sheet_add_aoa(ws, headings)
    utils.sheet_add_json(ws, walletArray, {
      origin: 'A1',
      skipHeader: false,
      cellDates: false,
      cellText: true,
      raw: false
    })
    utils.book_append_sheet(wb, ws, 'Report')
    writeFile(wb, 'elliptic report.xlsx')
  }

  // update wallet name api
  const WalletNameEdit = async (e) => {
    setValidated(true)
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else if (walletName.trim() === '' || walletPurpose.trim() === '') {
      setSpaceError(true)
      setTimeout(() => {
        setSpaceError(false)
      }, 3000)
    } else {
      const m = result3?.filter(
        (i) =>
          i.wallet_name === walletName &&
          i.wallet_id === dataSetWallet.walletId
      )
      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/renameWallet`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          wallet_id: dataSetWallet.walletId,
          wallet_name: walletName,
          wallet_purpose: walletPurpose
        }
      }
      await axios(config)
        .then(function (response) {
          if (response.data === 'Unable to Add Address.') {
            setAlreadyExist(true)
            setTimeout(() => {
              setAlreadyExist(false)
            }, 3000)
          } else {
            setAlertWalletName(true)
            setTimeout(() => {
              setAlertWalletName(false)
              setWalletEditAlert(false)
            }, 3000)
            trondata(dataSetWallet.portfolioId)
            btcdata(dataSetWallet.portfolioId)
            walletData(dataSetWallet.portfolioId)
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    }
  }

  const columns = [
    {
      dataField: 'walletName',
      text: 'Name',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            <span style={{ cursor: 'pointer', marginTop: '-2%' }}>
              {row.walletName.charAt(0).toUpperCase() +
                row.walletName.slice(1).toLowerCase()}
            </span>
          </div>
        )
      }
    },
    {
      dataField: 'purpose',
      text: 'Purpose',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            <span style={{ cursor: 'pointer', marginTop: '-2%' }}>
              {row.purpose.charAt(0).toUpperCase() +
                row.purpose.slice(1)}
            </span>
          </div>
        )
      }
    },
    {
      dataField: 'addresses',
      text: 'Address',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <ul style={{ marginLeft: '-3em' }}>
            {row.addresses?.map((i) => (
              <li
                style={{
                  color: '#FFC107',
                  marginBottom: '3em',
                  width: '99%',
                  whiteSpace: 'nowrap'
                }}
              >
                <span style={{ color: '#FFC107' }}>
                  {i.addressDesc.charAt(0).toUpperCase() +
                    i.addressDesc.slice(1).toLowerCase()}{' '}
                </span>
                <span
                  className="namePortData"
                  style={{ cursor: 'pointer', marginLeft: '3%' }}
                  onClick={() =>
                    navigate('/PMS/Admin/Transactions', {
                      state: {
                        id: 1,
                        data: row,
                        value: i.address
                      }
                    })
                  }
                >
                  {i.address}
                </span>
                <span style={{ color: '#FFC107', marginLeft: '3%' }}>
                  {' '}
                  ({i.chain})
                </span>
                {
                  roleId.includes('superadmin') || roleId.includes('admin') && (
                    <Tooltip title="Delete Address">
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#b30000',
                          float: 'right',
                          marginRight: '3.2em'
                        }}
                        onClick={() =>
                          handleShowAddress({
                            id: i.address,
                            address_type: i.chain
                          })
                        }
                      >
                        <DeleteOutlineOutlinedIcon style={{ fontSize: '20px' }} />
                      </span>
                    </Tooltip>
                  )
                }
              </li>
            ))}
          </ul>
        )
      }
    }
  ]

  const admin_columns = [
    {
      dataField: 'action',
      text: 'Action',
      // sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div
              style={{
                cursor: 'pointer',
                lineHeight: 'normal'
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  id={'options-button'}
                  variant="borderless-dark"
                  bsPrefix="no-chevron"
                  style={{ color: '#FFC107', fontSize: '25px' }}
                >
                  ...
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    willChange: 'transform',
                    background: '#222429',
                    borderColor: 'white'
                  }}
                >
                  <Dropdown.Item
                      href="#"
                      onClick={() => handleWalletNameEdit(row)}
                    >
                      <EditOutlinedIcon
                        style={{
                          fontSize: '14px',
                          color: '#FFC107',
                          marginRight: '4px',
                          marginLeft: '-15px',
                          marginTop: '-3px'
                        }}
                      />
                      <span>Edit wallet</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => handleShow(row)}>
                      <DeleteOutlineOutlinedIcon
                        style={{
                          fontSize: '14px',
                          color: '#FFC107',
                          marginRight: '4px',
                          marginLeft: '-15px',
                          marginTop: '-3px'
                        }}
                      />
                      <span>Delete wallet</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => handleAddress(row)}>
                      <AddCircleOutlineOutlinedIcon
                        style={{
                          fontSize: '14px',
                          color: '#FFC107',
                          marginRight: '4px',
                          marginLeft: '-15px',
                          marginTop: '-3px'
                        }}
                      />
                      <span>Add address</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )
      }
    }]

  if (roleId.includes('superadmin') || roleId.includes('admin')) { Array.prototype.push.apply(columns, admin_columns) }

  const [alert, setAlert] = useState(false)
  const [alertN, setAlertN] = useState(false)
  const [alert2, setAlert2] = useState(false)
  const [addr_id, setAddr_id] = useState('')
  const [addressT, setAddressT] = useState('')
  const [addr_name, setAddr_name] = useState('')
  const [showWallet, setShowWallet] = useState(false)
  const [validated, setValidated] = useState(false)
  const [alreadyExist, setAlreadyExist] = useState(false)
  const [spaceError, setSpaceError] = useState(false)

  const handleCloseExit = () => {
    setAlreadyExist(false)
  }

  const handleAddress = (id) => {
    setWalletIdAdd(id.walletId)
    setPortfolioIdAdd(id.portfolioId)
    setShowWallet(true)
    setAddressT('')
    setDisabledButton('')
    setValidated(false)
  }
  const handleClose2 = () => {
    setAlert(false)
  }
  const handleClose3 = () => {
    setAlert2(false)
  }
  const handleCloseWallet = () => {
    setShowWallet(false)
  }
  const [alertAddressValidate, setAlertAddressValidate] = useState(false)
  const handleClose2Validate = () => {
    setAlertAddressValidate(false)
  }

  // address validate api, add eth address api, add tron address api, add btc address api
  const handleSubmitForm = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/address_validate`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          address_id: addr_id,
          address_type:
            addressT == 'TRON' ? 'TRX' : addressT == 'ETH' ? 'ETH' : 'BTC'
        }
      }
      await axios(config)
        .then(function (response) {
          if (response.data === 'This is a valid address') {
            setDisabledButton('This is a valid address')

            const config = {
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}/add_address`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                address_id: addr_id,
                address_name: addr_name,
                address_type: addressT,
                portfolio_id: portfolioIdAdd,
                wallet_id: walletIdAdd
              }
            }

            axios(config).then(function (response) {
              if (response.data == 'Address Already Exist With Another Wallet or Portfolio') {
                document.body.style.opacity = 1
                setAlert2(true)
                setLoadData(false)
                walletData(portfolioIdAdd)
                setTimeout(() => setAlert2(false), 1000)
                setTimeout(() => setShowWallet(false), 2000)
              };

              document.body.style.opacity = 1
              setAlert(true)
              walletData(portfolioIdAdd)
              setLoadData(false)
              setTimeout(() => setAlert(false), 1000)
              setTimeout(() => setShowWallet(false), 2000)
            })
          } else {
            setAlertAddressValidate(true)
            setTimeout(() => {
              setAlertAddressValidate(false)
              // setShowWallet(false)
            }, 3000)
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    }
    setValidated(true)
  }
  const add_elliptic_report = (pi, wi, ai, an, at) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/elliptic_report`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        portfolio_id: pi,
        wallet_id: wi,
        address_id: ai,
        address_name: an,
        address_type: at
      }
    }
    axios(config).then(function (response1) { })
  }

  const [showWalletsData, setShowWalletsData] = useState(false)
  const handleCloseWalletsData = () => setShowWalletsData(false)
  const [alertWallet, setAlertWallet] = useState(false)
  const handleCloseWallet2 = () => {
    setAlertWallet(false)
  }
  const [w_name, setw_name] = useState('')
  const [w_purpose, setw_purpose] = useState('')

  const handleShowWallets = () => {
    const x = result?.filter((i) => i.portfolioName == portN)
    setPortI(x?.[0]?.portfolioId)
    setDisabledButton('')
    setShowWalletsData(true)
    setValidated(false)
    setw_purpose('')
  }

  // create wallet api
  const handleAddWalletData = async (e) => {
    setValidated(true)
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else if (w_name.trim() == '' || w_purpose.trim() == '') {
      setSpaceError(true)
      setTimeout(() => setSpaceError(false), 3000)
    } else {
      const configPostCreateWallet = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/create_wallet`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          portfolio_id: portI,
          walletName: w_name,
          wallet_purpose: w_purpose
        }
      }
      await axios(configPostCreateWallet)
        .then(function (response) {
          if (response.data === 'ALREADY EXIST') {
            setAlreadyExist(true)
            setTimeout(() => setAlreadyExist(false), 3000)
          } else {
            walletData(portI)
            // trondata(portfolioIdAdd);
            setAlertWallet(true)
            setTimeout(() => setAlertWallet(false), 3000)
            setTimeout(() => setShowWalletsData(false), 3000)
          }
        })
        .catch(function (error) {
          // console.log(error)
        })
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Row className="d-flex justify-content-center">
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Wallets</h3>
              </span>
              {roleId.includes('superadmin') || roleId.includes('admin')
                ? (
                <span className="p-2 pageheader">
                  <Link
                    to="#"
                    style={{
                      marginLeft: '9px',
                      boxShadow: 'none',
                      cursor: 'pointer',
                      background: 'none',
                      color: '#FFC107',
                      top: '11px',
                      position: 'relative'
                    }}
                    onClick={handleShowWallets}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </Link>
                </span>
                  )
                : (
                <></>
                  )}
              <span
                className="p-2 pageheader"
                onClick={() =>
                  navigate('/PMS/Admin/Transactions', {
                    state: { id: 1, data2: portN }
                  })
                }
                onMouseEnter={handleMouseEnterColor}
                onMouseLeave={handleMouseLeaveColor}
                style={{
                  background: 'transparent',
                  color: '#FFC107',
                  cursor: 'pointer',
                  top: '11px',
                  position: 'relative'
                }}
              >
                <Tooltip title="Transaction History">
                  <ReceiptLongIcon />
                </Tooltip>
              </span>

              <Autocomplete
                className="p-2 pageheader"
                classes={{
                  option: styles.option
                }}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      background: 'rgb(31, 33, 37)',
                      color: 'white'
                    }}
                  >
                    {children}
                  </Paper>
                )}
                value={portN}
                id="combo-box-demo"
                options={result?.map((e) => e.portfolioName)}
                style={{
                  fill: '#d9d9d9 !important',
                  boxShadow: 'none',
                  borderRadius: '30%'
                }}
                sx={{
                  width: 300,
                  '.MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    width: '239px',
                    height: '32px',
                    // backgroundColor: '#fff',
                    fontSize: '14px',
                    border: '1px solid #d9d9d9 !important',
                    left: '4px'
                  },
                  '.MuiButtonBase-root': {
                    color: 'white'
                  },
                  '.MuiInputLabel-root': {
                    top: '-6px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ color: '#d9d9d9' }}
                    label="Portfolios"
                  />
                )}
                onChange={(e, k) => {
                  handleChange(k)
                }}
              />
              {/* <div
                className=" p-2 pageheader"
                style={{
                  color: '#FFC107',
                  cursor: 'pointer',
                  right: '10rem',
                  position: 'fixed',
                  top: '46px'
                }}
                onClick={elliptic_report}
              >
                <SystemUpdateAltOutlinedIcon />
              </div> */}
              <SearchBox
                onChange={(event) => {
                  setSea(event.target.value)
                  const x = result3?.filter(
                    (i) =>
                      i.walletName
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase()) ||
                      i.purpose
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase())
                  )
                  setSearch(x)
                }}
              />
            </Row>

            {alertDownload
              ? (
              <Snackbar
                open={alertDownload}
                onClose={() => setAlertDownload(false)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Alert
                  onClose={setAlertDownload(false)}
                  severity="success"
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    color: 'black'
                  }}
                >
                  Elliptic report downloaded
                </Alert>
              </Snackbar>
                )
              : (
              <></>
                )}
            {loading
              ? (
              <Spinner
                style={{
                  position: 'fixed',
                  top: '20em',
                  left: '59%',
                  height: '70px',
                  width: '70px'
                }}
                animation="border"
                variant="primary"
              />
                )
              : null}

            <CommonTableWallet
              data={sea ? search : result3}
              loading={loading}
              columns={columns}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        show={showAddress}
        onHide={handleCloseAddress}
        style={{
          width: '30rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '35%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '18px',
              marginTop: '-5%',
              marginLeft: '11%'
            }}
          >
            Are you sure you want to Delete this Address ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            borderTop: 'none',
            paddingRight: '34%',
            marginTop: '-4%'
          }}
        >
          <Button
            variant="success"
            style={{ width: '25%', backgroundColor: '#006400' }}
            onClick={() => {
              handleDeleteUpdate2()
              handleCloseAddress()
            }}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={handleCloseAddress}
            style={{ width: '25%', backgroundColor: '#b30000' }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        style={{
          width: '30rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '35%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '18px',
              marginTop: '-5%',
              marginLeft: '11%'
            }}
          >
            Are you sure you want to Delete this wallet ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            borderTop: 'none',
            paddingRight: '34%',
            marginTop: '-4%'
          }}
        >
          <Button
            variant="success"
            style={{ width: '25%', backgroundColor: '#006400' }}
            onClick={() => {
              delete_asset()
              handleClose()
            }}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={handleClose}
            style={{ width: '25%', backgroundColor: '#b30000' }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showWallet}
        // onHide={handleCloseWallet}
        style={{ width: '28%', marginLeft: '35%' }}
      >
        <div style={{ border: '1px solid white' }}>
          <Modal.Header style={{ background: '#222429', border: 'none' }}>
            <IconButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'white'
              }}
              disabled={disabledButton == 'This is a valid address'}
              onClick={() => setShowWallet(false)}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#222429' }}>
            <Form
              className="custom-form form-flow"
              noValidate
              validated={validated}
              onSubmit={handleSubmitForm}
            >
              <h4>Add Address</h4>

              <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': addr_name
                })}
                style={{ width: '17rem' }}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="address_id"
                  placeholder="Name"
                  onChange={(e) => setAddr_name(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Address Name Required.
                </Form.Control.Feedback>
              </Form.Label>
              <FormControl
                style={{
                  width: '17rem',
                  marginBottom: '23px'
                }}
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  style={{
                    fontSize: '17px',
                    overflow: 'visible',
                    color: 'grey'
                  }}
                >
                  {' '}
                  Type
                </InputLabel>

                <Select
                  MenuProps={{
                    classes: {
                      paper: styles.paper
                    },
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                          backgroundColor: 'lightgrey',
                          color: 'black'
                        }
                      }
                    }
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addressT}
                  label="Select"
                  style={{
                    // width:"239px",
                    height: '54px',
                    borderRadius: '15px',
                    boxShadow: 'none'
                  }}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderRadius: '0px',
                      height: '54px',
                      border: '1px solid hsl(0deg 0% 44% / 63%) !important',
                      borderColor: 'unset'
                    },
                    '.MuiInputLabel-root': {
                      color: 'white !important'
                    },
                    '.MuiSelect-icon': {
                      fill: 'white !important'
                    },
                    '.MuiOutlinedInput-input': {
                      color: 'white !important',
                      fontSize: '15px',
                      //  backgroundColor:'white',
                      paddingBottom: '13px',
                      paddingRight: '61%',
                      paddingTop: '12px'
                    },
                    ' .MuiInputLabel-root.Mui-focused': {
                      marginTop: '-3% !important',
                      marginLeft: '-5% !important'
                    }
                  }}
                  onChange={(e) => setAddressT(e.target.value)}
                >
                  <MenuItem value={'BTC'}>BTC</MenuItem>
                  <MenuItem value={'ETH'}>ERC20/ETH</MenuItem>
                  <MenuItem value={'TRON'}>TRC20/TRON</MenuItem>
                </Select>
              </FormControl>
              <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': addr_id
                })}
                style={{ width: '17rem' }}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="address_id"
                  placeholder="Address-id"
                  onChange={(e) => setAddr_id(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Address Required.
                </Form.Control.Feedback>
              </Form.Label>
              <Button
                type="submit"
                disabled={disabledButton == 'This is a valid address'}
                variant=""
                className="btn-gray"
                style={{ width: '50%', boxShadow: 'none' }}
              >
                Save
              </Button>
            </Form>

            {loadData ? <Loader /> : <></>}
          </Modal.Body>
          {alert
            ? (
            <Snackbar
              open={alert}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={handleClose2}
                severity="success"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Address added successfully
              </Alert>
            </Snackbar>
              )
            : (
            <></>
              )}
          {alertN ? (
            <Snackbar
              open={alertN}
              // autoHideDuration={4000}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={handleClose2}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                please try again later
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {alert2 ? (
            <Snackbar
              open={alert2}
              // autoHideDuration={4000}
              onClose={handleClose3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={handleClose3}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Address already exist with another wallet/portfolio
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {alertAddressValidate ? (
            <Snackbar
              open={alertAddressValidate}
              // autoHideDuration={4000}
              onClose={handleClose2Validate}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={handleClose2Validate}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Invalid Address
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
        </div>
      </Modal>
      <Modal show={walletEditAlert} style={{ width: '28%', marginLeft: '35%' }}>
        <div style={{ border: '1px solid white' }}>
          <Modal.Header style={{ background: '#222429', border: 'none' }}>
            {/* <Modal.Title>Edit PortFolio Section</Modal.Title> */}
            <IconButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'white'
              }}
              onClick={() => setWalletEditAlert(false)}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#222429' }}>
            <Form
              className="custom-form"
              noValidate
              validated={validated}
              onSubmit={WalletNameEdit}
            >
              <h4>Update Wallet</h4>
              <span
                style={{
                  color: 'white',
                  // marginLeft: '52px',
                  fontWeight: '600'
                }}
              >
                Portfolio name -{' '}
                <span style={{ marginLeft: '2px' }}>{portN}</span>
              </span>
              <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': walletName
                })}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="address_id"
                  placeholder="wallet name"
                  value={walletName}
                  onChange={(e) => setWalletName(e.target.value)}
                  required
                  style={{ width: '72%', marginLeft: '15%' }}
                />

                <Form.Control.Feedback type="invalid">
                  Wallet name is required
                </Form.Control.Feedback>
              </Form.Label>
              <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': walletName
                })}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="address_id"
                  placeholder="wallet purpose"
                  value={walletPurpose}
                  onChange={(e) => setWalletPurpose(e.target.value)}
                  required
                  style={{ width: '72%', marginLeft: '15%' }}
                />

                <Form.Control.Feedback type="invalid">
                  Wallet purpose is required
                </Form.Control.Feedback>
              </Form.Label>
              <Button
                type="submit"
                variant=""
                className="btn-gray"
                style={{
                  width: '50%',
                  marginLeft: '25%',
                  boxShadow: 'none'
                }}
              >
                Save
              </Button>
            </Form>
          </Modal.Body>
          {alertWalletName ? (
            <Snackbar
              open={alertWalletName}
              // autoHideDuration={4000}
              onClose={() => setAlertWalletName(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={() => setAlertWalletName(false)}
                severity="success"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Wallet Updated successfully
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {spaceError ? (
            <Snackbar
              open={spaceError}
              // autoHideDuration={4000}
              onClose={() => setSpaceError(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={() => setSpaceError(false)}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Please enter valid input
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
          {alreadyExist ? (
            <Snackbar
              open={alreadyExist}
              // autoHideDuration={4000}
              onClose={() => setAlreadyExist(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert
                onClose={() => setAlreadyExist(false)}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Wallet name already exist
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
        </div>
      </Modal>
      <Modal
        show={showWalletsData}
        onHide={handleCloseWalletsData}
        style={{ width: '28%', marginLeft: '35%' }}
      >
        <div style={{ border: '1px solid white' }}>
          <Modal.Header style={{ backgroundColor: '#222429', border: 'none' }}>
            {/* <Modal.Title>Edit PortFolio Section</Modal.Title> */}
            <IconButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'white'
              }}
              onClick={() => setShowWalletsData(false)}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#222429' }}>
            <Form
              className="custom-form"
              noValidate
              validated={validated}
              onSubmit={handleAddWalletData}
            >
              <h4>Add Wallet</h4>
              <span
                style={{
                  color: 'white',
                  // marginLeft: '52px',
                  fontWeight: '600'
                }}
              >
                Portfolio name -{' '}
                <span style={{ marginLeft: '2px' }}>{portN}</span>
              </span>
              <Form.Label
                htmlFor="name"
                className={cx('custom-form-box', {
                  'focus-add': w_name
                })}
                style={{ width: '72%', marginLeft: '15%' }}
              >
                <Form.Control
                  type="text"
                  id="name"
                  name="walletName"
                  placeholder="Name"
                  onChange={(e) => setw_name(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
                {/* <span style={{ background: "none", color: "white" }}>
                   name
                </span> */}
                <Form.Control.Feedback type="invalid">
                  Wallet name is required
                </Form.Control.Feedback>
              </Form.Label>
              <Form.Label
                htmlFor="key"
                className={cx('custom-form-box', {
                  'focus-add': w_purpose
                })}
                style={{
                  width: '72%',
                  marginBottom: '10%',
                  marginLeft: '15%',
                  marginTop: '-2%'
                }}
              >
                <Form.Control
                  type="text"
                  id="key"
                  name="wallet_purpose"
                  placeholder="Purpose"
                  onChange={(e) => setw_purpose(e.target.value)}
                  required
                  style={{ color: 'white' }}
                />
                {/* <span style={{ background: "none", color: "white" }}>
                  purpose
                </span> */}
                <Form.Control.Feedback type="invalid">
                  Wallet purpose is required
                </Form.Control.Feedback>
              </Form.Label>
              <Button
                type="submit"
                variant=""
                className="btn-gray"
                style={{
                  width: '50%',
                  marginLeft: '25%',
                  marginTop: '-3%',
                  boxShadow: 'none'
                }}
              >
                Save
              </Button>
              {alertWallet ? (
                <Snackbar
                  open={alertWallet}
                  // autoHideDuration={4000}
                  onClose={handleCloseWallet2}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <Alert
                    onClose={handleCloseWallet2}
                    severity="success"
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                      color: 'black'
                    }}
                  >
                    Wallet added successfully
                  </Alert>
                </Snackbar>
              ) : (
                <></>
              )}
              {spaceError ? (
                <Snackbar
                  open={spaceError}
                  // autoHideDuration={4000}
                  onClose={() => setSpaceError(false)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <Alert
                    onClose={() => setSpaceError(false)}
                    severity="error"
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                      color: 'black'
                    }}
                  >
                    Please enter valid input
                  </Alert>
                </Snackbar>
              ) : (
                <></>
              )}
              {alreadyExist ? (
                <Snackbar
                  open={alreadyExist}
                  // autoHideDuration={4000}
                  onClose={handleCloseExit}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <Alert
                    onClose={handleCloseExit}
                    severity="error"
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                      color: 'black'
                    }}
                  >
                    Wallet Already Exist
                  </Alert>
                </Snackbar>
              ) : (
                <></>
              )}
            </Form>
          </Modal.Body>
        </div>
      </Modal>
      {/* <Modal
        show={alertNoRecord}
        onHide={()=>setAlertNoRecord(false)}
        style={{
          width: '14rem',
          marginTop: '17rem',
          overflow: 'hidden',
          marginLeft: '45%',
          backgroundColor: '#222429',
          height: '8rem',
          border: '1px solid white',
          borderRadius: '15px'
        }}
      >
        <Modal.Header
          style={{ backgroundColor: '#222429', border: 'none' }}
        >
          <Modal.Title
            style={{
              color: 'white',
              fontSize: '18px',
              marginTop: '-13%',
              marginLeft: '15%',
              fontWeight:'bold'
            }}
          >
            No record found.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            backgroundColor: '#222429',
            borderTop: 'none',
            paddingRight: '34%',
            paddingTop:'0%',
            // marginTop: '-10%',
            width:'19.5em',
            justifyContent:'center'
          }}
        >
          <button
          //  variant="success"
           className='no-record-found'

            onClick={() => {
              setAlertNoRecord(false)
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal> */}
    </React.Fragment>
  )
}

export default MainManageAssetsWallets
